<template>
  <div class="d-flex justify-space-between align-center mb-2">
    <div>
      <span style="width:75px;">仓库类型：</span>
      <a-tag :color="wtype === 1 ? 'blue' : ''" @click="wtype = 1" class="click">自建本地仓</a-tag>
      <a-tag :color="wtype === 0 ? 'blue' : ''" @click="wtype = 0" class="click">一件代发仓</a-tag>
    </div>
    <div>
      <a-button @click="getData" class="mr-2">刷新数据</a-button>
      <a-button v-if="wtype === 1" type="primary" @click="addWarehouse">
        <template #icon>
          <PlusCircleOutlined />
        </template> 新增仓库
      </a-button>
      <a-button v-if="wtype === 0" type="primary" class="btn-success" @click="getBinds" :loading="binding">
        <template #icon>
          <PlusCircleOutlined />
        </template> 绑定仓库
      </a-button>
    </div>
  </div>
  <a-table :columns="columns" :data-source="data" :loading="dataLoading" :scroll="{ x: width }"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }">
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'name'">
        {{ record.name }}
        <a-popconfirm v-if="record.is_default !== true" title="设置默认仓库?" @confirm="setdefault(record)">
          <a href="javascript:;" class="text-info">设置默认</a>
        </a-popconfirm>
        <template v-else><span class="text-error">(默认)</span></template>
      </template>
      <template v-if="column.dataIndex === 'state'">
        <template v-if="wtype === 0">
          <span v-if="record.bindstate === 'pending'" class="text-warning">审核中</span>
          <div v-if="record.bindstate === 'success'" class="d-flex flex-column">
            <span class="text-success">绑定成功</span>
          </div>
          <span v-if="record.bindstate === 'fail'" class="text-error">
            绑定失败
            <a-tooltip v-if="record.reason" placement="right">
              <template #title> {{ record.reason }} </template>
              <span class="text-error underline">原因</span>
            </a-tooltip>
            <a-button type="link" class="text-success" @click="requestBind(record.warehouseid, false)">申请绑定
            </a-button>
          </span>
        </template>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <template v-if="wtype === 1">
            <a href="javascript:;" @click="editWarehouse(record)">编辑</a>
          </template>
          <template v-if="wtype === 0">
            <a href="javascript:;" @click="editWarehouseBind(record)">编辑</a>
          </template>
        </a-space>
      </template>
    </template>
  </a-table>
  <a-modal v-model:visible="warehouseModal" :title="warehouseForm._id ? '编辑仓库' : '新增仓库'" @ok="saveWarehouse"
    @cancel="cancelSave" ok-text="确认" cancel-text="取消" :confirm-loading="saving"
    :cancel-button-props="{ disabled: saving }" :maskClosable="false" :closable="!saving">
    <a-form ref="formRef" name="warehouseForm" :model="warehouseForm" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }"
      autocomplete="off">
      <a-form-item label="仓库名称" name="name" :rules="[{ required: true, message: '请输入仓库名称' }]">
        <a-input v-model:value="warehouseForm.name" placeholder="仓库名称" :disabled="saving" />
      </a-form-item>
      <a-form-item label="仓库地址" name="address">
        <a-textarea v-model:value="warehouseForm.address" placeholder="仓库地址" :auto-size="{ minRows: 2, maxRows: 2 }"
          :disabled="saving" />
      </a-form-item>
      <a-form-item label="负责人" name="manager">
        <a-input v-model:value="warehouseForm.manager" placeholder="负责人" :disabled="saving" />
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="warehouseBindModal" :title="warehouseBindForm._id ? '编辑仓库' : '新增仓库'" @ok="saveWarehouseBind"
    @cancel="cancelSaveBind" ok-text="确认" cancel-text="取消" :confirm-loading="saving"
    :cancel-button-props="{ disabled: saving }" :maskClosable="false" :closable="!saving">
    <a-form ref="bindformRef" name="warehouseBindForm" :model="warehouseBindForm" :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }" autocomplete="off">
      <a-form-item label="仓库名称" name="name" :rules="[{ required: true, message: '请输入仓库名称' }]">
        <a-input v-model:value="warehouseBindForm.name" placeholder="仓库名称" :disabled="saving" />
      </a-form-item>
      <a-form-item label="仓库地址" name="address">
        <a-textarea v-model:value="warehouseBindForm.address" placeholder="仓库地址" :auto-size="{ minRows: 2, maxRows: 2 }"
          :disabled="saving" />
      </a-form-item>
      <a-form-item label="负责人" name="manager">
        <a-input v-model:value="warehouseBindForm.manager" placeholder="负责人" :disabled="saving" />
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="bindmodal" title="绑定仓库" :maskClosable="false" :closable="!binding" width="1000px"
    @cancel="() => bindmodal = false">
    <template #footer>
      <a-button @click="() => bindmodal = false" :disabled="binding">关闭</a-button>
    </template>
    <a-table :columns="bcolumns" :data-source="bindlist" :scroll="{ x: bind_width }" :loading="binding"
      :pagination="{ current: bindpage, pageSize: bindlimit, total: bindcount, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
      @change="(pagination) => { bindpage = pagination.current; bindlimit = pagination.pageSize; }">
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'bindstate'">
          <template v-if="record.warehousebind">
            <span v-if="record.warehousebind.bindstate === 'pending'" class="text-warning">已申请，审核中</span>
            <span v-if="record.warehousebind.bindstate === 'success'" class="text-success">绑定成功</span>
            <template v-if="record.warehousebind.bindstate === 'fail'">
              <span class="text-error">
                绑定失败
                <a-tooltip v-if="record.warehousebind.reason" placement="right">
                  <template #title> {{ record.warehousebind.reason }} </template>
                  <span class="text-error underline">原因</span>
                </a-tooltip>
              </span>
            </template>
          </template>
          <template v-else>
            <span class="text-error">未绑定</span>
          </template>
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <template v-if="record.warehousebind">
            <a-button v-if="record.warehousebind.bindstate === 'fail'" type="link" class="text-success"
              @click="requestBind(record._id, true)">申请绑定</a-button>
          </template>
          <template v-else>
            <a-button type="link" class="text-success" @click="requestBind(record._id, true)">申请绑定</a-button>
          </template>
        </template>
      </template>
    </a-table>
  </a-modal>
</template>
<script>
import { DownOutlined, SearchOutlined, PlusCircleOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onActivated,
  getCurrentInstance,
} from "vue";
import { useRouter } from "vue-router";
import _ from "underscore";
const columns = [
  { title: "编号", dataIndex: "number", width: 80, },
  { title: "名称", dataIndex: "name", width: 150, },
  { title: "类型", dataIndex: "type", width: 120, },
  { title: "地址", dataIndex: "address", width: 100, },
  { title: "负责人", dataIndex: "manager", width: 100, },
  { title: "状态", dataIndex: "state", width: 100, },
  { title: "操作", dataIndex: "operation", width: 150, fixed: "right", },
];
const bcolumns = [
  { title: "编号", dataIndex: "number", width: 80, },
  { title: "名称", dataIndex: "name", width: 120, },
  { title: "状态", dataIndex: "bindstate", width: 100, },
  { title: "操作", dataIndex: "operation", width: 150, fixed: "right", },
];
export default defineComponent({
  name: "WarehouseList",
  components: {
    DownOutlined,
    SearchOutlined,
    PlusCircleOutlined,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const formRef = ref();
    const bindformRef = ref();
    const initForm = {
      _id: "",
      name: "",
      address: "",
      manager: "",
    };
    const state = reactive({
      wtype: 1,
      warehouseModal: false,
      warehouseForm: {},
      warehouseBindModal: false,
      warehouseBindForm: {},
      dataLoading: false,
      page: 1,
      limit: 10,
      length: 0,
      count: 0,
      data: [],
      saving: false,
      binding: false,
      bindnumber: null,
      bindname: null,
      bindpage: 1,
      bindlimit: 10,
      bindlength: 0,
      bindcount: 0,
      bindlist: [],
      bindmodal: false,
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    const bind_width = computed(() => proxy.$utils.twidth(bcolumns));
    onMounted(() => {
    })
    onActivated(() => {
      getData();
    })
    watch(() => state.page, () => getData())
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    })
    watch(() => state.wtype, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    })
    watch(() => state.bindpage, () => getBinds())
    watch(() => state.bindlimit, () => {
      if (state.bindpage !== 1) state.bindpage = 1;
      else getBinds();
    })
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/warehouse/list", { page: state.page, limit: state.limit, wtype: state.wtype, }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.data = res.data.list;
          state.length = res.data.length;
          state.count = res.data.count;
          if (state.length > 0 && state.page > state.length) state.page = 1;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const addWarehouse = () => {
      state.warehouseForm = _.extend({}, initForm);
      state.warehouseModal = true;
    }
    const editWarehouse = (item) => {
      state.warehouseForm = _.extend({}, _.pick(item, _.keys(initForm)));
      state.warehouseModal = true;
    }
    const saveWarehouse = async () => {
      try {
        await formRef.value.validateFields();
        state.saving = true;
        proxy.$api.doAPI("/warehouse/save", { warehouseForm: state.warehouseForm, }).then((res) => {
          state.saving = false;
          if (res.code === 0) {
            message.success("提交成功");
            state.warehouseModal = false;
            getData();
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.saving = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    }
    const cancelSave = () => {
      console.log('cancelSave');
      formRef.value.clearValidate();
    }
    const editWarehouseBind = (item) => {
      state.warehouseBindForm = _.extend({}, _.pick(item, _.keys(initForm)));
      state.warehouseBindModal = true;
    }
    const saveWarehouseBind = async () => {
      try {
        await bindformRef.value.validateFields();
        state.saving = true;
        proxy.$api.doAPI("/warehouse/savebind", { warehouseBindForm: state.warehouseBindForm, }).then((res) => {
          state.saving = false;
          if (res.code === 0) {
            message.success("提交成功");
            state.warehouseBindModal = false;
            getData();
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.saving = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    }
    const cancelSaveBind = () => {
      console.log('cancelSaveBind');
      bindformRef.value.clearValidate();
    }
    const getBinds = () => {
      state.binding = true;
      proxy.$api.doAPI("/warehouse/bindlist", { warehouseForm: state.warehouseForm, }).then((res) => {
        state.binding = false;
        if (res.code === 0) {
          state.bindlist = res.data.list;
          state.bindlength = res.data.length;
          state.bindcount = res.data.count;
          if (state.bindlength > 0 && state.bindpage > state.bindlength) state.bindpage = 1;
          state.bindmodal = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.binding = false;
        console.error(err);
      });
    };
    const requestBind = (warehouseid, loading) => {
      state.binding = true;
      proxy.$api.doAPI("/warehouse/requestbind", { warehouseid, }).then((res) => {
        state.binding = false;
        if (res.code === 0) {
          if (loading) getBinds();
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.binding = false;
        console.error(err);
      });
    }
    const setdefault = (item) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/warehouse/setdefault", { warehouseid: state.wtype === 0 ? item.warehouseid : item._id }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    return {
      ...toRefs(state),
      formRef,
      bindformRef,
      columns,
      bcolumns,
      width,
      bind_width,
      getData,
      addWarehouse,
      editWarehouse,
      saveWarehouse,
      cancelSave,
      editWarehouseBind,
      saveWarehouseBind,
      cancelSaveBind,
      getBinds,
      requestBind,
      setdefault,
    };
  },
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>
