<template>
  <div class="d-flex align-center mb-2">
    <span style="width:75px;">查询搜索：</span>
    <a-input-search v-model:value="orderids" placeholder="多个单号用逗号隔开" enter-button="搜索" @search="getData" />
  </div>
  <div class="d-flex align-center mb-2">
    <a-row>
      <a-col><span style="width:75px;">所在仓库：</span></a-col>
      <a-col>
        <div>
          <a-tag :color="warehouseid === 0 ? 'processing' : 'default'" @click="changeWarehouse(0)" class="click">全部</a-tag>
          <a-tag v-for="item in warehouses" :key="item._id" :color="warehouseid === item._id ? 'processing' : 'default'"
            @click="changeWarehouse(item._id)" class="click">{{ item.name }}
          </a-tag>
        </div>
      </a-col>
    </a-row>
  </div>
  <div class="d-flex align-center mb-2">
    <a-row>
      <a-col><span style="width:75px;">所在店铺：</span></a-col>
      <a-col :span="22">
        <div>
          <a-tag :color="shopid === 0 ? 'processing' : 'default'" @click="changeShop(0)" class="click">全部</a-tag>
          <a-tag v-for="item in shops" :key="item._id" :color="shopid === item._id ? 'processing' : 'default'"
            @click="changeShop(item._id)" class="click">{{ item.name }}
          </a-tag>
        </div>
      </a-col>
    </a-row>
  </div>
  <div class="d-flex align-center mb-2">
    <a-row>
      <a-col>
        <span style="width:75px;">库存状态：</span>
      </a-col>
      <a-col>
        <div>
          <a-tag :color="inventorystate === 0 ? 'processing' : 'default'" @click="changeInventorystate(0)" class="click">全部</a-tag>
          <a-tag :color="inventorystate === 1 ? 'processing' : 'default'" @click="changeInventorystate(1)" class="click">有货</a-tag>
          <a-tag :color="inventorystate === 2 ? 'processing' : 'default'" @click="changeInventorystate(2)" class="click">缺货</a-tag>
        </div>
      </a-col>
    </a-row>
  </div>
  <div class="d-flex align-center mb-2">
    <a-row>
      <a-col>
        <span style="width:75px;">订单类型：</span>
      </a-col>
      <a-col>
        <div>
          <a-tag :color="ordertype === 0 ? 'processing' : 'default'" @click="changeOrdertype(0)" class="click">自发货</a-tag>
          <a-tag :color="ordertype === 1 ? 'processing' : 'default'" @click="changeOrdertype(1)" class="click">FBA</a-tag>
        </div>
      </a-col>
    </a-row>
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-space>
      <a-button type="primary" @click="getData">同步订单</a-button>
      <a-dropdown>
        <template #overlay>
          <a-menu @click="handleExportClick">
            <a-menu-item-group title="导出订单">
              <a-menu-item key="1">导出勾选订单</a-menu-item>
              <a-menu-item key="2">导出全部页订单</a-menu-item>
            </a-menu-item-group>
            <a-menu-item-group title="导出给货代">
              <a-menu-item key="3">E邮宝模版</a-menu-item>
              <a-menu-item key="4">中邮模版</a-menu-item>
            </a-menu-item-group>
          </a-menu>
        </template>
        <a-button type="primary">
          导出订单
          <DownOutlined />
        </a-button>
      </a-dropdown>
    </a-space>
    <a-space>
      <ZhengBoAssistant />
    </a-space>
  </div>
  <a-table :columns="columns" bordered :data-source="data" :loading="dataLoading" :scroll="{ x: width, y: 600 }"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
    :row-selection="{ selectedRowKeys, onChange: (keys) => selectedRowKeys = keys }"
    v-model:expandedRowKeys="expandedRowKeys">
    <template #title>
      <MinusOutlined v-if="expanded" class="click" style="border:1px solid #ddd;" @click="foldall" />
      <PlusOutlined v-else class="click" style="border:1px solid #ddd;" @click="expandall" />
    </template>
    <template #headerCell="{ column }">
      <template v-if="column.dataIndex === 'shop_orderid'">
        <p>店铺</p>
        <p>订单号</p>
        <p>SKU</p>
      </template>
      <template v-if="column.dataIndex === 'buyer'">
        <p>买家</p>
      </template>
      <template v-if="column.dataIndex === 'order_amount'">
        <p>订单金额(原始货币)</p>
        <p>收入运费(原始货币)</p>
      </template>
      <template v-if="column.dataIndex === 'time'">
        <p>创建时间</p>
        <p>付款时间</p>
        <p>剩余发货</p>
        <p>交运时间</p>
      </template>
    </template>
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'shop_orderid'">
        <p>{{ record.shop?.name }}</p>
        <a-tooltip color="white">
          <template #title>
            <a href="javascript:;" @click="doCopy(record.orderid)">复制</a>
          </template>
          <p>{{ record.orderid }}</p>
        </a-tooltip>
        <p class="text-info in1line"> {{ showorderitems(record) }} </p>
        <p>
          <svg-icon v-if="record.has_battery" iconClass="dianchi1" :style="{ 'width': '20px', 'height': '20px' }"
            title="带电池" />
          <svg-icon v-else iconClass="dianchi" :style="{ 'width': '20px', 'height': '20px' }" title="不带电池" />
          <svg-icon v-if="record.has_magnet" iconClass="citie1" :style="{ 'width': '20px', 'height': '20px' }"
            title="带磁" />
          <svg-icon v-else iconClass="citie" :style="{ 'width': '20px', 'height': '20px' }" title="不带磁" />
          <svg-icon v-if="record.is_liquid" iconClass="yeti1" :style="{ 'width': '20px', 'height': '20px' }" title="液体" />
          <svg-icon v-else iconClass="yeti" :style="{ 'width': '20px', 'height': '20px' }" title="非液体" />
          <svg-icon v-if="record.is_powder" iconClass="fenchen1" :style="{ 'width': '20px', 'height': '20px' }"
            title="粉末" />
          <svg-icon v-else iconClass="fenchen" :style="{ 'width': '20px', 'height': '20px' }" title="非粉末" />
          <svg-icon v-if="record.items[0]?.product?.is_combine" iconClass="zuhesku1"
            :style="{ 'width': '20px', 'height': '20px' }" :title="record.items[0]?.seller_sku" />
        </p>
      </template>
      <template v-if="column.dataIndex === 'warehouse'">
        <template v-if="record.warehouse">
          <span class="text-info">
            {{ record.warehouse.name }}
          </span>
          <a-tag v-if="record.inventory_status === 'success'" color="green">有货</a-tag>
          <a-tag v-else-if="record.inventory_status === 'fail'" color="red">缺货</a-tag>
          <a-tag v-else color="orange">正在匹配库存</a-tag>
        </template>
        <span v-else class="text-error">仓库未选择</span>
      </template>
      <template v-if="column.dataIndex === 'logistics_channel'">
        <template v-if="record.channelbind">
          <span class="text-info">
            【{{ record.supplierbind.name }}】{{ record.channelbind.name }}
          </span>
          <div class="d-flex align-center">
            <a-tag v-if="record.logistics_status === 'pending'" color="orange">正在获取物流单号</a-tag>
            <div v-else-if="record.logistics_status === 'success'" class="d-flex align-center">
              <a-tooltip color="white">
                <template #title>
                  <a href="javascript:;" @click="doCopy(record.logistics_tracking_number)">复制</a>
                </template>
                <a-tag color="green">
                  {{ record.logistics_tracking_number }}
                </a-tag>
              </a-tooltip>
              <a-tooltip title="打印面单">
                <a href="javascript:;" @click="printorder(record)">
                  <svg-icon iconClass="printer" :style="{ 'width': '20px', 'height': '20px' }" />
                </a>
              </a-tooltip>
              <a-tooltip title="物流追踪">
                <a :href="`https://t.17track.net/zh-cn#nums=${record.logistics_tracking_number}`" target="_blank">
                  <svg-icon iconClass="track" :style="{ 'width': '20px', 'height': '20px' }" />
                </a>
              </a-tooltip>
            </div>
            <div v-else>
              <a-tag color="red">物流单号获取失败</a-tag>
              <span class="text-error">{{ record.logistics_reason }}</span>
            </div>
          </div>
        </template>
        <span v-else class="text-error">物流渠道未选择</span>
      </template>
      <template v-if="column.dataIndex === 'buyer'">
        <p>{{ record.shipping_country }}</p>
        <p>{{ record.shipping_state }}</p>
        <p>{{ record.shipping_postalcode }}</p>
        <p>{{ record._shipping_name || record.shipping_name }}</p>
      </template>
      <template v-if="column.dataIndex === 'order_amount'">
        <p>{{ record.amount }} {{ record.order_currency }}</p>
        <p>{{ record.freight }} {{ record.order_currency }}</p>
      </template>
      <template v-if="column.dataIndex === 'time'">
        <p :title="record.create_time">{{ record.create_time }}</p>
        <p title="付款时间">{{ record.purchase_time }}</p>
        <template v-if="record.order_status === 'Unshipped'">
          <p v-if="record.ship_timeout" style="font-size:16px;font-weight:bold;color:#ed5466">
            过期：{{ record.ship_time }}
          </p>
          <p v-else style="font-size:16px;font-weight:bold;color:#91c854">
            剩余发货：{{ record.ship_time }}
          </p>
        </template>
        <template v-if="record.order_status === 'Shipped'">
          <p>-</p>
        </template>
        <p title="交运时间">{{ record.delivery_time }}</p>
      </template>
      <template v-if="column.dataIndex === 'remark'">
        <a-tag v-if="record.mark_status === 'shipped'" class="text-error">标记发货</a-tag>
        <a-tag v-if="record.mark_status === 'canceled'" class="text-error">作废</a-tag>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <a-button type="primary" size="small" danger>拆分</a-button>
          <a-button type="primary" size="small">备注</a-button>
        </a-space>
      </template>
    </template>
    <template #expandedRowRender="{ record }">
      <a-table :columns="itemcolumns" :data-source="record.items" :scroll="{ x: iwidth }" :pagination="false">
        <template #bodyCell="{ record: irecord, column: icolumn }">
          <template v-if="icolumn.dataIndex === 'cover'">
            <a-tooltip v-if="irecord.product?.cover || irecord.shoplisting?.image_url" color="white"
              placement="rightBottom">
              <template #title>
                <a-image :src="irecord.product?.cover || irecord.shoplisting?.image_url" :width="300" :height="300"
                  style="object-fit: contain;height:100%;" :preview="false" />
              </template>
              <a href="javascript:;">
                <a-image :src="irecord.product?.cover || irecord.shoplisting?.image_url" :width="50" :height="50"
                  style="object-fit: contain;height:100%;" :preview="false" />
              </a>
            </a-tooltip>
            <a-image v-else :src="nosku" :width="50" :height="50" style="object-fit: contain;height:100%;"
              :preview="false" />
          </template>
          <template v-if="icolumn.dataIndex === 'info'">
            <div>
              <a-tooltip color="white">
                <template #title>
                  <a href="javascript:;" @click="doCopy(irecord.product?.sku || irecord.seller_sku)">复制</a>
                </template>
                <a href="javascript:;">
                  <span v-if="irecord.product?.is_combine" class="text-error">(组合)</span>
                  {{ irecord.product?.sku || irecord.seller_sku }}
                </a>
              </a-tooltip>
              <span class="text-error text-h6">×{{ irecord.quantity_ordered }}</span>
              <a-tooltip color="white">
                <template #title>
                  <a href="javascript:;" @click="doCopy(irecord.asin)">复制</a>
                </template>
                <a v-if="record.amazon_product_url" :href="`${record.amazon_product_url}${irecord.asin}`" target="_blank"
                  class="underline ml-1">{{ irecord.asin }}</a>
                <a v-else href="javascript:;" class="underline ml-1">{{ irecord.asin }}</a>
              </a-tooltip>
            </div>
            <p>{{ irecord.product?.name_cn || irecord.title }}</p>
          </template>
          <template v-if="icolumn.dataIndex === 'cinfo'">
            <div v-for="item in irecord.product?.combine_list" :key="item.sku" class="d-flex justify-space-between"
              style="border-bottom:1px solid #ececec;">
              <div style="width:80px;">
                <a-tooltip v-if="item.cover" color="white" placement="rightBottom">
                  <template #title>
                    <a-image :src="item.cover" width="300px" height="300px" style="object-fit: contain;height:100%;"
                      :preview="false" />
                  </template>
                  <a href="javascript:;">
                    <a-image :src="item.cover" width="50px" height="50px" style="object-fit: contain;height:100%;"
                      :preview="false" />
                  </a>
                </a-tooltip>
                <a-image v-else :src="image_placeholder" width="50px" height="50px"
                  style="object-fit: contain;height:100%;" :preview="false" />
              </div>
              <div style="flex:1;">
                <div>
                  <a href="javascript:;">{{ item.sku }}</a>
                  <span class="text-error text-h6">×{{ item.quantity * irecord.quantity_ordered }}</span>
                </div>
                <p>{{ item.name_cn }}</p>
              </div>
            </div>
          </template>
          <template v-if="icolumn.dataIndex === 'warehouse'">
            <p>{{ irecord.warehouse?.name || '-' }}</p>
          </template>
          <template v-if="icolumn.dataIndex === 'inventory'">
            <p>{{ irecord.inventory?.quantity || '-' }}</p>
          </template>
          <template v-if="icolumn.dataIndex === 'skumatch_status'">
            <span v-if="irecord.skumatch_status === 'success'" class="text-success">成功</span>
            <span v-else class="text-error">{{ irecord.skumatch_reason || '-' }}</span>
          </template>
        </template>
      </a-table>
    </template>
  </a-table>
</template>
<script>
import { DownOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onActivated,
  getCurrentInstance,
} from "vue";
import _ from "underscore";
import ZhengBoAssistant from "../../../components/ZhengBoAssistant.vue";
import { printBlob } from "../../../zhengbo";
const columns = [
  { title: "店铺/店长/订单号", dataIndex: "shop_orderid", width: 200 },
  { title: "仓库", dataIndex: "warehouse", width: 150 },
  { title: "物流渠道", dataIndex: "logistics_channel", width: 180 },
  { title: "买家", dataIndex: "buyer", width: 200 },
  { title: "订单金额", dataIndex: "order_amount", width: 120 },
  { title: "时间", dataIndex: "time", width: 200 },
  { title: "操作", dataIndex: "operation", width: 150, fixed: "right" },
];
const itemcolumns = [
  { title: '图片', dataIndex: 'cover', width: 50 },
  { title: 'SKU编号/数量/中文名称', dataIndex: 'info', width: 200 },
  { title: 'SKU编号/数量/中文名称', dataIndex: 'cinfo', width: 200 },
  { title: '仓库', dataIndex: 'warehouse', width: 100 },
  { title: "库存", dataIndex: "inventory", width: 100 },
  { title: "匹配状态", dataIndex: "skumatch_status", width: 100 },
];
export default defineComponent({
  name: "OrderList",
  components: { DownOutlined, PlusOutlined, MinusOutlined, ZhengBoAssistant, },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      orderids: "",
      warehouseid: 0,
      warehouses: [],
      orderstate: 0,
      inventorystate: 0,
      ordertype: 0,
      page: 1,
      limit: 10,
      data: [],
      length: 0,
      count: 0,
      dataLoading: false,
      selectedRowKeys: [],
      orderprinting: false,
      expandedRowKeys: [],
      expanded: false,
      shopid: 0,
      shops: [],
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    const iwidth = computed(() => proxy.$utils.twidth(itemcolumns));
    watch(() => state.page, () => getData());
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    watch(() => state.warehouseid, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    watch(() => state.shopid, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    watch(() => state.inventorystate, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    watch(() => state.ordertype, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    onActivated(() => {
      getData();
    });
    const changeWarehouse = (item) =>{
      state.warehouseid = item;
    };
    const changeShop = (item) =>{
      state.shopid = item;
    };
    const changeInventorystate = (item) =>{
      state.inventorystate = item;
    };
    const changeOrdertype = (item) =>{
      state.ordertype = item;
    };
    const getData = () => {
      state.selectedRowKeys = [];
      state.expandedRowKeys = [];
      state.expanded = false;
      state.dataLoading = true;
      proxy.$api.doAPI("/order/list", {
        page: state.page,
        limit: state.limit,
        orderids: state.orderids,
        orderstate: state.orderstate,
        inventorystate: state.inventorystate,
        warehouseid: state.warehouseid,
        ordertype: state.ordertype,
        shopid: state.shopid,
      }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.data = res.data.list;
          state.warehouses = res.data.warehouses;
          state.shops = res.data.shops;
          state.length = res.data.length;
          state.count = res.data.count;
          if (state.length > 0 && state.page > state.length) state.page = 1;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const handleExportClick = ({ key }) => {
      console.log('handleExportClick', key);
    }
    const showorderitems = ({ items }) => {
      return _.reduce(items, (a, b) => a.concat(`${b.seller_sku}*${b.quantity_ordered}`), []).join(';')
    }
    const printorder = (item) => {
      _printorder([item._id]);
    }
    const _printorder = (order_ids) => {
      state.orderprinting = true;
      proxy.$api.doAPI("/order/printorder", { order_ids }).then((res) => {
        state.orderprinting = false;
        if (res.code === 0) {
          let urls = res.data.urls;
          proxy.$utils.mergePDF(urls).then((blob) => {
            printBlob(blob);
          });
          state.selectedRowKeys = [];
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.orderprinting = false;
        console.error(err);
      });
    }
    const expandall = () => {
      state.expandedRowKeys = _.pluck(state.data, "_id");
      state.expanded = true;
    }
    const foldall = () => {
      state.expandedRowKeys = [];
      state.expanded = false;
    }
    return {
      ...toRefs(state),
      columns,
      itemcolumns,
      width,
      iwidth,
      getData,
      handleExportClick,
      showorderitems,
      printorder,
      expandall,
      foldall,
      doCopy: proxy.$utils.doCopy,
      image_placeholder: proxy.$constant.image_placeholder,
      nosku: proxy.$constant.nosku,
      changeWarehouse,
      changeShop,
      changeInventorystate,
      changeOrdertype,
    };
  },
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>
