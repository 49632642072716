<template>
  <a-card title="FBA发货流程" :bordered="false" style="width: 100%；">
    <a-steps>
      <a-step title="创建发货计划" description="添加产品" />
      <a-step title="装箱" description="打印产品标签" />
      <a-step title="发货" description="打印箱子标签" />
      <a-step title="完成" description="上传物流信息" />
    </a-steps>
  </a-card>
  <div class="d-flex align-center mb-2">
    <span style="width:75px;">查询搜索：</span>
    <a-input-search v-model:value="skus" placeholder="输入SKU、ASIN、FNSKU查询" enter-button="搜索" allow-clear
      @search="getData" />
  </div>
  <div class="d-flex align-center mb-2">
    <a-row>
      <a-col><span style="width:75px;">选择店铺：</span></a-col>
      <a-col :span="22">
        <div>
          <a-tag :color="shopid === 0 ? 'processing' : 'default'" @click="changeShop(0)" class="click">全部</a-tag>
          <a-tag v-for="item in shops" :key="item._id" :color="shopid === item._id ? 'processing' : 'default'"
          @click="changeShop(item._id)" class="click">{{ item.name }}</a-tag>
        </div>
      </a-col>
    </a-row>
  </div>
  <div class="d-flex align-center mb-2">
    <a-row>
      <a-col>
        <span style="width:75px;">货件状态：</span>
      </a-col>
      <a-col>
        <div>
          <a-tag :color="inventorystate === '0' ? 'processing' : 'default'" @click="changeInventorystate('0')" class="click">全部</a-tag>
          <a-tag :color="inventorystate === '1' ? 'processing' : 'default'" @click="changeInventorystate('1')" class="click">待处理({{ pendingNum }})</a-tag>
          <a-tag :color="inventorystate === '2' ? 'processing' : 'default'" @click="changeInventorystate('2')" class="click">已处理({{ processedNum }})</a-tag>
          <a-tag :color="inventorystate === '3' ? 'processing' : 'default'" @click="changeInventorystate('3')" class="click">已作废({{ invalidatedNum }})</a-tag>      
        </div>
      </a-col>
    </a-row>
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-space>
      <a-button type="primary" @click="createShipment" v-if="inventorystate == '1'">创建货件</a-button>
      <a-button type="danger" @click="makePurchasePlan" v-if="inventorystate == '1'">生成采购计划</a-button>
      <a-dropdown>
        <template #overlay>
          <a-menu @click="handleBulkClick">
            <a-menu-item-group title="批量编辑">
              <a-menu-item key="1" :disabled="selectedRowKeys.length === 0">打印商品标签</a-menu-item>
              <a-menu-item key="2" :disabled="selectedRowKeys.length === 0">批量编辑</a-menu-item>
              <a-menu-item key="3" :disabled="selectedRowKeys.length === 0">批量作废</a-menu-item>
            </a-menu-item-group>
          </a-menu>
        </template>
        <a-button type="primary" v-if="inventorystate !== '3'">
          批量操作
          <DownOutlined />
        </a-button>
      </a-dropdown>
    </a-space>
    <a-space>
      <a-button type="primary" @click="addDeliveryPlanParam">创建发货计划</a-button>
      <a-button type="primary" @click="placemodel">启运地管理</a-button>
      <a-button type="primary" @click="getData">刷新数据</a-button>
      <a-dropdown>
        <template #overlay>
          <a-menu @click="processImportExport">
            <a-menu-item key="5" :disabled="selectedRowKeys.length === 0">导出勾选</a-menu-item>
            <a-menu-item key="6">导出所有页</a-menu-item>
          </a-menu>
        </template>
        <a-button type="primary">
          导出数据
          <DownOutlined />
        </a-button>
      </a-dropdown>
    </a-space>
  </div>
  <a-table :columns="columnsf" bordered :data-source="data" :loading="dataLoading" :scroll="{ x: width, y: 600 }"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
    :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectedRowKeys }">
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'shop'">
        <p>{{ record.shop?.name }}</p>
      </template>
      <template v-if="column.dataIndex === 'state'">
        <p v-if="record.approve_status === 'Pending'" style="font-size: 12px; color: #67C23A;">待处理</p>
        <p v-if="record.approve_status === 'Processed'" style="font-size: 12px; color: #108ee9;">已处理</p>
        <p v-if="record.approve_status === 'Invalidated'" style="font-size: 12px; color: red;">已作废</p>
      </template>
      <template v-if="column.dataIndex === 'expect_time'">
        <p>发货时间：{{ record.deliver_time }}</p>
        <p>到货时间：{{ record.arrive_time }}</p>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space direction="vertical">
          <a href="javascript:;">详情</a>
          <a href="javascript:;" v-if="record.approve_status !== 'Invalidated'"
            @click="updateInboundShipment(record, 'up')">编辑</a>
          <a-popconfirm title="确定作废？" description="作废后将无法恢复" @confirm="updateInboundShipment(record, 'de')"
            @cancel="cancel">
            <a href="javascript:;" class="text-error" v-if="record.approve_status !== 'Invalidated'">作废</a>
          </a-popconfirm>
        </a-space>
      </template>
    </template>
    <template #expandedRowRender="{ record }">
      <a-table :columns="columns" :data-source="record.items" :scroll="{ x: iwidth }" :pagination="false">
        <template #bodyCell="{ record: irecord, column: icolumn }">
          <template v-if="icolumn.dataIndex === 'cover'">
            <a-image :src="irecord.product?.cover || ''" width="80px" height="80px"
              style="object-fit: contain;height:100%;" :preview="!!irecord.product.cover">
              <template #placeholder>
                <a-image :src="image_placeholder" width="80px" height="80px" :preview="false" />
              </template>
            </a-image>
          </template>
          <template v-if="icolumn.dataIndex === 'ASIN'">
            <p>{{ irecord.asin }}</p>
            <p>{{ irecord.fnsku }}</p>
          </template>
          <template v-if="icolumn.dataIndex === 'info'">
            <p>{{ irecord.product?.sku || '-' }}</p>
            <p>{{ irecord.product?.name_cn || '-' }}</p>
          </template>
          <template v-if="icolumn.dataIndex === 'price'">
            <p>{{ irecord.product?.declare_amount || '-' }}</p>
            <p>({{ irecord.product?.declare_currency || '-' }})</p>
          </template>
          <template v-if="icolumn.dataIndex === 'shop_country'">
            <p>{{ record.shop?.name || '-' }}</p>
            <p>{{ record.shop?.country || '-' }}</p>
          </template>
          <template v-if="icolumn.dataIndex === 'inventory'">
            <p>{{ irecord.warehouse?.name || '-' }}</p>
          </template>
          <template v-if="icolumn.dataIndex === 'logistics_type'">
            <p v-if="irecord.logistics !== undefined">{{ irecord.logistics || '-' }}</p>
            <a-select v-else v-model:value="irecord.logistics" :options="logisticsData" placeholder="物流" show-search
              style="width: 150px" dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
              @change="uplogistics(irecord)" />
          </template>
        </template>
      </a-table>
    </template>
  </a-table>
  <a-modal :width="1700" v-model:visible="createShipmentModal" title="创建货件" @ok="okCreateShipment"
    @cancel="cancelCreateShipment" ok-text="创建" cancel-text="取消" :confirm-loading="createShipmentLoading"
    :cancel-button-props="{ disabled: createShipmentLoading }" :maskClosable="false" :closable="!createShipmentLoading">
    <div :margin-bottom="32">
      <a-form ref="createShipmentRef" name="createShipmentParam" :model="createShipmentParam" autocomplete="off">
        <a-row>
          <a-col>
            <a-form-item label="店铺名称" name="shopid" :rules="[{ required: true, message: '请选择店铺名称' }]">
              <a-select v-model:value="createShipmentParam.shopid" :disabled="createShipmentParam.shopid !== ''"
                :options="shops" placeholder="店铺" show-search style="width: 200px" />
            </a-form-item>
          </a-col>
          <a-col :offset="2">
            <a-form-item label="目标商城" name="targetmall" :rules="[{ required: true, message: '请输入目标商城' }]">
              <a-input v-model:value="createShipmentParam.targetmall"
                :disabled="createShipmentParam.targetmall !== ''"></a-input>
            </a-form-item>
          </a-col>
          <a-col :offset="2">
            <a-form-item label="货件批次号" name="batchNo">
              <a-input v-model:value="createShipmentParam.batchNo"
                :disabled="createShipmentParam.batchNo !== ''"></a-input>
            </a-form-item>

          </a-col>
          <a-col :offset="2">
            <a-form-item label="货件名称" name="shipmentName">
              <a-input v-model:value="createShipmentParam.shipmentName"></a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col>
            <a-form-item label="包装类型" name="packag_type" :rules="[{ required: true, message: '请选择包装类型' }]"
              style="width: 280px">
              <a-radio-group v-model:value="createShipmentParam.packag_type" size="small">
                <a-radio value="混装">混装商品</a-radio>
                <a-radio value="原厂包装">原厂包装商品</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :offset="2">
            <a-form-item label="标签类型" name="labeltype" :rules="[{ required: true, message: '请选择标签类型' }]"
              style="width: 395px">
              <a-select ref="select" v-model:value="createShipmentParam.labeltype" style="width: 185px">
                <a-select-option value="SELLER_LABEL">SELLER_LABEL</a-select-option>
                <a-select-option value="AMAZON_LABEL_ONLY">AMAZON_LABEL_ONLY</a-select-option>
                <a-select-option value="AMAZON_LABEL_PREFERRED">AMAZON_LABEL_PREFERRED</a-select-option>
              </a-select>
              &nbsp;
              <a-tooltip>
                <template #title>
                  <div>
                    <span>1、SELLER_LABEL：如需要标签，则卖家将为入库货件的商品贴标</span>
                  </div>
                  <div>
                    <span>2、AMAZON_LABEL_ONLY：如需要标签，则亚马逊将尝试为入库货件的商品贴标。如果亚马逊确定其不具备为商品成功贴标所需的信息，则入库货件计划中不包括该商品。</span>
                  </div>
                  <div>
                    <span>3、AMAZON_LABEL_PREFERRED：如需要标签，则亚马逊将尝试为入库货件的商品贴标。如果亚马逊确定其不具备为商品成功贴标所需的信息，则入库货件计划中包括该商品且卖家必须为其贴标。</span>
                  </div>
                </template>
                <QuestionCircleFilled style="font-size:20px ;" />
              </a-tooltip>
            </a-form-item>
          </a-col>
          <a-col>
            <a-form-item label="包装内商品信息来源" name="source" :rules="[{ required: true, message: '请选择商品信息来源' }]">
              <a-select ref="select" v-model:value="createShipmentParam.source" style="width: 185px">
                <a-select-option value="NONE">初始不提供(NONE)</a-select-option>
                <a-select-option value="FEED">通过接口上传(FEED)</a-select-option>
              </a-select>
              &nbsp;
              <a-tooltip>
                <template #title>
                  <div>
                    <span>1、NONE：申报货件时不提供，后续在ERP完成装箱后会将商品信息给到亚马逊并自动更新为Feed。此时不会收取处理费。但是如果在亚马逊后台操作变成send to
                      amazon货件后，无论在ERP或者亚马逊后台上传装箱信息，都会被收取处理费。</span>
                  </div>
                  <div>
                    <span>2、FEED：只能在ERP进行装箱操作，亚马逊后台不支持录入装箱信息，不会被收取处理费。如果变成send to amazon货件后，ERP和亚马逊后台录入装箱信息都会受影响。</span>
                  </div>
                </template>
                <QuestionCircleFilled style="font-size:20px ;" />
              </a-tooltip>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col>
            <a-form-item label="发货地址" name="name" :rules="[{ required: true, message: '请选择发货地址' }]">
              <a-input v-model:value="createShipmentParam.ship_from_address.name" placeholder="联系人"
                :disabled="createShipmentParam.ship_from_address.name !== ''"></a-input>
            </a-form-item>
          </a-col>
          <a-col>
            <a-form-item name="countrycode">
              <a-select v-model:value="createShipmentParam.ship_from_address.countrycode"
                :disabled="createShipmentParam.ship_from_address.countrycode !== ''" :options="countrys" placeholder="国家"
                show-search style="width: 200px" />
            </a-form-item>
          </a-col>
          <a-col>
            <a-form-item name="province">
              <a-input v-model:value="createShipmentParam.ship_from_address.province" placeholder="省"
                :disabled="createShipmentParam.ship_from_address.province !== ''"></a-input>
            </a-form-item>
          </a-col>
          <a-col>
            <a-form-item name="city">
              <a-input v-model:value="createShipmentParam.ship_from_address.city" placeholder="市"
                :disabled="createShipmentParam.ship_from_address.city !== ''"></a-input>
            </a-form-item>
          </a-col>
          <a-col>
            <a-form-item name="district">
              <a-input v-model:value="createShipmentParam.ship_from_address.district" placeholder="区"
                :disabled="createShipmentParam.ship_from_address.district !== ''"></a-input>
            </a-form-item>
          </a-col>
          <a-col>
            <a-form-item name="addressline1">
              <a-input v-model:value="createShipmentParam.ship_from_address.addressline1" placeholder="街道地址"
                :disabled="createShipmentParam.ship_from_address.addressline1 !== ''"></a-input>
            </a-form-item>
          </a-col>
          <a-col>
            <a-form-item name="postalcode">
              <a-input v-model:value="createShipmentParam.ship_from_address.postalcode" placeholder="邮编"
                :disabled="createShipmentParam.ship_from_address.postalcode !== ''"></a-input>
            </a-form-item>
          </a-col>
          <a-col>
            <BarsOutlined style="font-size:24px ; color:#59a5d3ce" @click="fbaAddressModal(createShipmentParam.shopid)" />
          </a-col>
        </a-row>
        <a-row>
          <a-form-item label="备注" name="remark">
            <a-input v-model:value="createShipmentParam.remark"></a-input>
          </a-form-item>
        </a-row>
        <a-space direction="vertical">
          <a-table :columns="shipmentItemsColumns" :data-source="createShipmentParam.items"
            :scroll="{ x: shipmentItemsColumnsWidth, y: 600 }">
            <template #bodyCell="{ record, column }">
              <template v-if="column.dataIndex === 'cover'">
                <a-image :src="record.product?.cover || ''" width="80px" height="80px"
                  style="object-fit: contain;height:100%;" :preview="!!record.product.cover">
                  <template #placeholder>
                    <a-image :src="image_placeholder" width="80px" height="80px" :preview="false" />
                  </template>
                </a-image>
              </template>
              <template v-if="column.dataIndex === 'msku'">
                <p>{{ record.msku }}</p>
              </template>
              <template v-if="column.dataIndex === 'productName'">
                <a-tooltip placement="topLeft">
                  <template #title> {{ record.name }} </template>
                  <p class="in1line">{{ record.name }}</p>
                </a-tooltip>
              </template>
              <template v-if="column.dataIndex === 'asin'">
                <p>{{ record.asin }}</p>
                <p>{{ record.fnsku }}</p>
              </template>
              <template v-if="column.dataIndex === 'sku'">
                <p>{{ record.product?.name_cn || '-' }}</p>
                <p>{{ record.product?.sku || '-' }}</p>
              </template>
            </template>
          </a-table>
        </a-space>
      </a-form>
    </div>
  </a-modal>
  <a-modal :width="1700" v-model:visible="confirmShipmentModal" title="货件确认" @ok="okConfirmShipment"
    @cancel="cancelConfirmShipment" ok-text="确认" cancel-text="取消" :confirm-loading="confirmShipmentLoading"
    :cancel-button-props="{ disabled: confirmShipmentLoading }" :maskClosable="false" :closable="!confirmShipmentLoading">
    <div>
      <a-page-header title="基本信息" class="site-page-header">
        <a-descriptions size="small" :column="4">
          <a-descriptions-item label="店铺名称">
            <span class="sp">{{ confirmShipmentParam[0].shop?.name }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="货件名称">
            <a-input v-model:value="confirmShipmentParam[0].shipmentName" style="width: 200px" />
          </a-descriptions-item>
          <a-descriptions-item label="货件单号">
            <span class="sp">{{ confirmShipmentParam[0].shipmentId }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="物流中心编码">
            <span class="sp">{{ confirmShipmentParam[0].fulfillmentCenterId }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="标签类型">
            <span class="sp" v-if="confirmShipmentParam[0].labeltype === 'SELLER_LABEL'">卖家贴标签</span>
            <span class="sp" v-else-if="confirmShipmentParam[0].labeltype === 'AMAZON_LABEL'">亚马逊贴标签</span>
            <span class="sp" v-else>不需要标签</span>
          </a-descriptions-item>
          <a-descriptions-item label="包装类型">
            <span class="sp">{{ confirmShipmentParam[0].packag_type }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="MSKU数量">
            <span class="sp">{{ confirmShipmentParam[0].mskuNum }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="商品总数">
            <span class="sp">{{ confirmShipmentParam[0].productNum }}</span>
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions size="small">
          <a-descriptions-item label="发货地址">
            <span class="sp">
              {{ confirmShipmentParam[0].ship_from_address?.countrycode }}&nbsp;
              {{ confirmShipmentParam[0].ship_from_address?.province }}&nbsp;
              {{ confirmShipmentParam[0].ship_from_address?.addressline1 }}&nbsp;
              {{ confirmShipmentParam[0].ship_from_address?.postalcode }}&nbsp;
              {{ confirmShipmentParam[0].ship_from_address?.name }}
            </span>
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions size="small">
          <a-descriptions-item label="配送地址">
            <span class="sp">
              {{ confirmShipmentParam[0].ship_to_address?.CountryCode }}&nbsp;
              {{ confirmShipmentParam[0].ship_to_address?.StateOrProvinceCode }}&nbsp;
              {{ confirmShipmentParam[0].ship_to_address?.AddressLine1 }}&nbsp;
              {{ confirmShipmentParam[0].ship_to_address?.PostalCode }}&nbsp;
              {{ confirmShipmentParam[0].ship_to_address?.Name }}
            </span>
          </a-descriptions-item>
        </a-descriptions>
      </a-page-header>
    </div>
    <div>
      <a-page-header title="寄件信息" class="site-page-header">
        <a-tabs v-model:activeKey="activeKey">
          <a-tab-pane key="1" tab="商品明细">
            <a-table :columns="shipmentItemsColumns" :data-source="confirmShipmentParam[0].items"
              :scroll="{ x: shipmentItemsColumnsWidth, y: 600 }">
              <template #bodyCell="{ record, column }">
                <template v-if="column.dataIndex === 'cover'">
                  <a-image :src="record.product?.cover || ''" width="80px" height="80px"
                    style="object-fit: contain;height:100%;" :preview="!!record.product.cover">
                    <template #placeholder>
                      <a-image :src="image_placeholder" width="80px" height="80px" :preview="false" />
                    </template>
                  </a-image>
                </template>
                <template v-if="column.dataIndex === 'msku'">
                  <p>{{ record.msku }}</p>
                </template>
                <template v-if="column.dataIndex === 'productName'">
                  <a-tooltip placement="topLeft">
                    <template #title> {{ record.name }} </template>
                    <p class="in1line">{{ record.name }}</p>
                  </a-tooltip>
                </template>
                <template v-if="column.dataIndex === 'asin'">
                  <p>{{ record.asin }}</p>
                  <p>{{ record.fnsku }}</p>
                </template>
                <template v-if="column.dataIndex === 'sku'">
                  <p>{{ record.product?.name_cn || '-' }}</p>
                  <p>{{ record.product?.sku || '-' }}</p>
                </template>
              </template>
            </a-table>
          </a-tab-pane>
        </a-tabs>
      </a-page-header>
    </div>
  </a-modal>
  <a-modal :width="1500" v-model:visible="FBAAddressModal" title="FBA发货地址薄" @ok="tongbuFbaAddress"
    @cancel="cancelFbaAddress" ok-text="确认" cancel-text="取消" :confirm-loading="fbaAddressLoading"
    :cancel-button-props="{ disabled: fbaAddressLoading }" :maskClosable="false" :closable="!fbaAddressLoading">
    <a-table :columns="fbaaddresscolumns" bordered :data-source="placeData" :loading="fbaAddressDataLoading"
      :scroll="{ x: width, y: 600 }" row-key="_id"
      :row-selection="{ selectedRowKeys: fbaaddressRowKeys, onChange: onFbaaddressRowKeys }">
    </a-table>
  </a-modal>
  <a-modal :width="1500" v-model:visible="deliveryPlanModal"
    :title="`${deliveryPlanParam._id !== undefined ? '编辑' : '创建'}发货计划`" @ok="okDeliveryPlan" @cancel="cancelDeliveryPlan"
    ok-text="确认" cancel-text="取消" :confirm-loading="deliveryPlanLoading"
    :cancel-button-props="{ disabled: deliveryPlanLoading }" :maskClosable="false" :closable="!deliveryPlanLoading">
    <div>
      <a-form ref="deliveryPlanParamRef" name="deliveryPlanParam" :model="deliveryPlanParam" autocomplete="off">
        <a-row>
          <a-col>
            <a-form-item label="店铺名称" name="shopid" :rules="[{ required: true, message: '请选择店铺名称' }]">
              <a-select v-model:value="deliveryPlanParam.shopid" :disabled="deliveryPlanParam._id !== undefined"
                :options="shops" placeholder="店铺" show-search style="width: 200px" />
            </a-form-item>
          </a-col>
          <a-col :offset="2">
            <a-form-item label="期望发货时间" name="deliver_time">
              <a-date-picker v-model:value="deliveryPlanParam.deliver_time" value-format="YYYY-MM-DD" />
            </a-form-item>
          </a-col>
          <a-col :offset="2">
            <a-form-item label="期望到货时间" name="arrive_time">
              <a-date-picker v-model:value="deliveryPlanParam.arrive_time" value-format="YYYY-MM-DD" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col>
            <a-form-item label="包装类型" name="packag_type" :rules="[{ required: true, message: '请选择包装类型' }]"
              style="width: 280px">
              <a-radio-group v-model:value="deliveryPlanParam.packag_type" size="small">
                <a-radio value="混装">混装商品</a-radio>
                <a-radio value="原厂包装">原厂包装商品</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :offset="2">
            <a-form-item label="备注" name="mark">
              <a-input v-model:value="deliveryPlanParam.mark" style="width: 590px" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-space direction="vertical">
          <a-space>
            <a-button @click="addproduct">添加商品</a-button>
            <a-button>导入商品</a-button>
            <a-button>批量删除</a-button>
          </a-space>
          <a-table :columns="plancolumns" :data-source="deliveryPlanParam.items" row-key="_id"
            :row-selection="{ selectedRowKeys: selectedPlanRowKeys, onChange: onSelectedPlanRowKeys }">
            <template #headerCell="{ column }">
              <template v-if="column.dataIndex === 'title'">
              </template>
              <template v-if="column.dataIndex === 'deliveryware'">
                <span style="color: red">*&nbsp;</span><span>发货仓库</span>
              </template>
              <template v-if="column.dataIndex === 'plannumber'">
                <span style="color: red">*&nbsp;</span><span>计划申报量</span>
              </template>
            </template>
            <template #bodyCell="{ record, column }">
              <template v-if="column.dataIndex === 'cover'">
                <a-image :src="record.product?.cover || ''" width="80px" height="80px"
                  style="object-fit: contain;height:100%;" :preview="!!record.cover">
                  <template #placeholder>
                    <a-image :src="image_placeholder" width="80px" height="80px" :preview="false" />
                  </template>
                </a-image>
              </template>
              <template v-if="column.dataIndex === 'msku'">
                <a href="javascript:;">{{ record.msku }}</a>
                <a-tooltip placement="topLeft">
                  <template #title> {{ record.name }} </template>
                  <div style="width:150px;height:50px; overflow:hidden;">{{ record.name }}</div>
                </a-tooltip>
              </template>
              <template v-if="column.dataIndex === 'asin'">
                <p>{{ record.asin }}</p>
                <p>{{ record.fnsku }}</p>
              </template>
              <template v-if="column.dataIndex === 'sku'">
                <p>{{ record.product?.name_cn }}</p>
                <a href="javascript:;">{{ record.sku }}</a>
              </template>
              <template v-if="column.dataIndex === 'deliveryware'">
                <a-select :disabled="record.warehouseid !== undefined && record.fbabatchid !== undefined"
                  v-model:value="record.warehouseid" :options="warehouses" placeholder="仓库" show-search
                  style="width: 150px" />
              </template>
              <template v-if="column.dataIndex === 'logisticsmode'">
                <a-select v-model:value="record.logistics" :options="logisticsData" placeholder="物流" show-search
                  style="width: 150px" dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false" />
              </template>
              <template v-if="column.dataIndex === 'del'">
                <a href="javascript:;" style="color: red;" @click="delproduct(record)">移除</a>
              </template>
              <template v-if="column.dataIndex === 'plannumber'">
                <a-input v-model:value="record.quantity" style="text-align: center;" />
              </template>
              <template v-if="column.dataIndex === 'mark'">
                <a-input v-model:value="record.mark" />
              </template>
            </template>
          </a-table>
        </a-space>
      </a-form>
    </div>
  </a-modal>
  <a-modal :width="1300" v-model:visible="productModal" title="添加商品" @ok="inboundproduct" @cancel="cancelSaveProduct"
    ok-text="确认" cancel-text="取消" :confirm-loading="productLoading" :cancel-button-props="{ disabled: productLoading }"
    :maskClosable="false" :closable="!productLoading">
    <a-space direction="vertical">
      <a-input-search v-model:value="searchText" placeholder="搜索SKU、MSKU、ASIN" style="width: 300px" @search="getGoods" />
      <a-table :columns="productcolumns" :data-source="productData" :scroll="{ x: productwidth, y: 600 }"
        :pagination="{ current: productPage, pageSize: productLimit, total: productCount, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
        @change="(pagination) => { productPage = pagination.current; productLimit = pagination.pageSize; }" row-key="_id"
        :row-selection="{ selectedRowKeys: selectedProductRowKeys, onChange: onSelectedProductRowKeys }">
        <template #bodyCell="{ record, column }">
          <template v-if="column.dataIndex === 'cover'">
            <a-image :src="record.image_url" width="80px" height="80px" style="object-fit: contain;height:100%;"
              :preview="!!record.cover">
              <template #placeholder>
                <a-image :src="image_placeholder" width="80px" height="80px" :preview="false" />
              </template>
            </a-image>
          </template>
          <template v-if="column.dataIndex === 'msku'">
            <a href="javascript:;">{{ record.msku }}</a>
            <p>{{ record.fnsku }}</p>
          </template>
          <template v-if="column.dataIndex === 'name'">
            <a-tooltip placement="topLeft">
              <template #title> {{ record.name }} </template>
              <p class="in1line">{{ record.name }}</p>
            </a-tooltip>
          </template>
          <template v-if="column.dataIndex === 'sku'">
            <p>{{ record.product?.name_cn }}</p>
            <a href="javascript:;">{{ record.sku }}</a>
          </template>
        </template>
      </a-table>
    </a-space>
  </a-modal>
  <!-- 启运地管理开始 -->
  <a-modal :width="1300" v-model:visible="placeModal" title="启运地管理" @ok="addplace(null)" @cancel="cancelPlace"
    ok-text="新增地址" cancel-text="取消" :confirm-loading="placeLoading" :cancel-button-props="{ disabled: placeLoading }"
    :maskClosable="false" :closable="!placeLoading">
    <a-table :columns="placecolumns" bordered :data-source="placeData" :loading="placeDataLoading"
      :scroll="{ x: width, y: 600 }">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'controls'">
          <a-space>
            <a href="javascript:;" @click="addplace(record)">编辑</a>
            <a href="javascript:;" class="text-error" @click="delAddress(record)">删除</a>
          </a-space>
        </template>
      </template>
    </a-table>
  </a-modal>
  <a-modal v-model:visible="placeAddressModal" title="地址" @ok="addOrUplAddress" ok-text="确认" cancel-text="取消"
    :confirm-loading="placeAddressLoading" :cancel-button-props="{ disabled: placeAddressLoading }" :maskClosable="false"
    :closable="!placeAddressLoading">
    <a-form ref="formRefAddress" name="placeAddress" :model="placeAddress" :label-col="{ span: 5 }" autocomplete="off">
      <a-form-item label="名称" name="name" :rules="[{ required: true, message: '请输入个人或企业的名称' }]">
        <a-input v-model:value="placeAddress.name" placeholder="个人或企业的名称" />
      </a-form-item>
      <a-form-item label="国家" name="countrycode" :rules="[{ required: true, message: '请选择国家' }]">
        <a-select v-model:value="placeAddress.countrycode" :options="countrys" placeholder="国家" show-search />
      </a-form-item>
      <a-form-item label="省份/州" name="province" :rules="[{ required: true, message: '请输入省份/州' }]">
        <a-input v-model:value="placeAddress.province" placeholder="省份/州" />
      </a-form-item>
      <a-form-item label="城市" name="city" :rules="[{ required: true, message: '请输入城市' }]">
        <a-input v-model:value="placeAddress.city" placeholder="城市" />
      </a-form-item>
      <a-form-item label="区" name="district">
        <a-input v-model:value="placeAddress.district" placeholder="区或县" />
      </a-form-item>
      <a-form-item label="街道地址" name="addressline1" :rules="[{ required: true, message: '请输入街道地址' }]">
        <a-input v-model:value="placeAddress.addressline1" placeholder="街道地址信息1" />
      </a-form-item>
      <a-form-item label="街道地址2" name="addressline2">
        <a-input v-model:value="placeAddress.addressline2" placeholder="街道地址信息2" />
      </a-form-item>
      <a-form-item label="邮编" name="postalcode" :rules="[{ required: true, message: '请输入邮编' }]">
        <a-input v-model:value="placeAddress.postalcode" placeholder="邮政编码" />
      </a-form-item>
      <a-form-item label="电话" name="phone">
        <a-input v-model:value="placeAddress.phone" placeholder="电话" />
      </a-form-item>
      <a-form-item label="关联店铺" name="shopids" :rules="[{ required: true, message: '请选择要关联的店铺' }]">
        <a-checkbox-group v-model:value="placeAddress.shopids" name="checkboxgroup" :options="shops" />
      </a-form-item>
    </a-form>
  </a-modal>
  <!-- 启运地管理结束 -->
  <!-- 商品标签开始 -->
  <a-modal :width="1300" v-model:visible="productlabelModal" @ok="okPrint" @cancel="cancelPrint" ok-text="打印"
    cancel-text="取消" :confirm-loading="productlabelLoading" :cancel-button-props="{ disabled: productlabelLoading }"
    :maskClosable="false" :closable="!productlabelLoading">
    <div :margin-bottom="32">
      <span>标签规格：默认60*30</span>
    </div>
    <a-table :columns="productLabelcolumns" bordered :data-source="printLabelData" :loading="productlabelLoading"
      :scroll="{ x: productLabelcolumnswidth, y: 600 }" row-key="_id"
      :row-selection="{ selectedRowKeys: productLabelRowKeys, onChange: onSelectChange }">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'cover'">
          <a-image :src="record.product?.cover || ''" width="80px" height="80px" style="object-fit: contain;height:100%;"
            :preview="!!record.cover">
            <template #placeholder>
              <a-image :src="image_placeholder" width="80px" height="80px" :preview="false" />
            </template>
          </a-image>
        </template>
        <template v-if="column.dataIndex === 'msku'">
          {{ record.msku }}<br />
          {{ record.fnsku }}
        </template>
        <template v-if="column.dataIndex === 'name'">
          <a-tooltip placement="topLeft">
            <template #title> {{ record.name }} </template>
            <p class="in1line">{{ record.name }}</p>
          </a-tooltip>
        </template>
        <template v-if="column.dataIndex === 'sku'">
          {{ record.sku }}<br />
          {{ record.product?.name_cn }}
        </template>
        <template v-if="column.dataIndex === 'printnum'">
          <a-input v-model:value="record.printnum" style="text-align: center;"></a-input>
        </template>
      </template>
    </a-table>
  </a-modal>
  <!-- 商品标签结束 -->
  <!--采购计划-->
  <a-modal :width="1400" title="生成采购计划" v-model:visible="purchasePlanModal" @ok="okPurchasePlan"
    @cancel="cancelPurchasePlan" ok-text="确定" cancel-text="取消" :confirm-loading="purchasePlanLoading"
    :cancel-button-props="{ disabled: purchasePlanLoading }" :maskClosable="false" :closable="!purchasePlanLoading">
    <a-table :columns="purchasePlancolums" bordered :data-source="purchasePlanData" :loading="purchasePlanLoading"
      :scroll="{ x: purchasePlancolumswidth, y: 600 }">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'cover'">
          <a-image :src="record.product?.cover || ''" width="80px" height="80px" style="object-fit: contain;height:100%;"
            :preview="!!record.cover">
            <template #placeholder>
              <a-image :src="image_placeholder" width="80px" height="80px" :preview="false" />
            </template>
          </a-image>
        </template>
        <template v-if="column.dataIndex === 'msku'">
          {{ record.msku }}<br />
          <a-tooltip placement="topLeft">
            <template #title> {{ record.name }} </template>
            <p class="in1line">{{ record.name }}</p>
          </a-tooltip>
        </template>
        <template v-if="column.dataIndex === 'sku'">
          {{ record.sku }}<br />
          {{ record.product?.name_cn }}
        </template>
        <template v-if="column.dataIndex === 'deliveryware'">
          <a-select v-model:value="record.warehouseid" :options="warehouses" show-search style="width: 150px" />
        </template>
        <template v-if="column.dataIndex === 'purchase_quantity'">
          <a-input v-model:value="record.purchase_quantity" />
        </template>
        <template v-if="column.dataIndex === 'purchase_remark'">
          <a-input v-model:value="record.purchase_remark" />
        </template>
      </template>
    </a-table>
  </a-modal>
</template>
<script>
import { DownOutlined, SearchOutlined, PlusCircleOutlined, BarsOutlined, QuestionCircleFilled } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { printUrl, printBlob } from "../../../zhengbo";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onActivated,
  getCurrentInstance,
} from "vue";
import _ from "underscore";
const columnsf = [
  { title: '补货批次号', dataIndex: 'batchNo', width: 150, align: 'center', },
  { title: '货件编号', dataIndex: 'shipmentId', width: 150, align: 'center', },
  { title: '创建人', dataIndex: 'create_by', width: 100, align: 'center', },
  { title: '店铺', dataIndex: 'shop', width: 150, align: 'center', },
  { title: '期望时间', dataIndex: 'expect_time', width: 200, align: 'center', },
  { title: '物流中心编码', dataIndex: 'fulfillmentCenterId', width: 200, align: 'center', },
  { title: '状态', dataIndex: 'state', width: 100, align: 'center', },
  { title: '备注', dataIndex: 'mark', width: 150, align: 'center', },
  { title: '创建时间', dataIndex: 'create_time', width: 150, align: 'center', },
  { title: '操作', dataIndex: 'operation', width: 100, fixed: 'right', align: 'center', },
];
const columns = [
  { title: '补货单号', dataIndex: 'replen_no', width: 150, align: 'center', },
  { title: '图片', dataIndex: 'cover', width: 80, align: 'center', },
  { title: 'ASIN/FNSKU', dataIndex: 'ASIN', width: 150, align: 'center', },
  { title: '商品信息', dataIndex: 'info', width: 200, align: 'center', },
  { title: '价格', dataIndex: 'price', width: 150, align: 'center', },
  { title: '店铺/国家', dataIndex: 'shop_country', width: 150, align: 'center', },
  { title: '发货仓库', dataIndex: 'inventory', width: 200, align: 'center', },
  { title: '7｜14｜30｜90天销量', dataIndex: 'sales', width: 200, align: 'center', },
  { title: '日均销量', dataIndex: 'date_sale', width: 150, align: 'center', },
  { title: 'FBA库存', dataIndex: 'FBA_store', width: 150, align: 'center', },
  { title: '计划采购量', dataIndex: 'quantity', width: 150, align: 'center', },
  { title: '物流方式', dataIndex: 'logistics_type', width: 100, align: 'center', },
  { title: '包装类型', dataIndex: 'packag_type', width: 100, align: 'center', },
  { title: '采购单号/采购量', dataIndex: 'buyorderID', width: 100, align: 'center', },
  { title: '状态', dataIndex: 'state', width: 100, align: 'center', },
  { title: '备注', dataIndex: 'mark', width: 150, align: 'center', }
];
const plancolumns = [
  { title: '图片', dataIndex: 'cover', width: 100, align: 'center', },
  { title: 'MSKU/标题', dataIndex: 'msku', width: 200, align: 'center', },
  { title: 'ASIN/FNSKU', dataIndex: 'asin', width: 200, align: 'center', },
  { title: '品名/SKU', dataIndex: 'sku', width: 200, align: 'center', },
  { title: '发货仓库', dataIndex: 'deliveryware', width: 150, align: 'center', },
  { title: '物流方式', dataIndex: 'logisticsmode', width: 150, align: 'center', },
  { title: '计划申报量', dataIndex: 'plannumber', width: 100, align: 'center', },
  { title: '备注', dataIndex: 'mark', width: 150, align: 'center', },
  { title: '操作', dataIndex: 'del', width: 100, align: 'center', }
]
const productcolumns = [
  { title: '图片', dataIndex: 'cover', width: 200, align: 'center', },
  { title: 'MSKU/FNSKU', dataIndex: 'msku', width: 200, align: 'center', },
  { title: 'ASIN', dataIndex: 'asin', width: 200, align: 'center', },
  { title: '标题', dataIndex: 'name', width: 200, align: 'center', },
  { title: '品名/SKU', dataIndex: 'sku', width: 300, align: 'center', },
]
const placecolumns = [
  { title: '名称', dataIndex: 'name', width: 200, align: 'center', },
  { title: '国家', dataIndex: 'country', width: 150, align: 'center', },
  { title: '省份/州', dataIndex: 'province', width: 150, align: 'center', },
  { title: '城市', dataIndex: 'city', width: 150, align: 'center', },
  { title: '操作', dataIndex: 'controls', width: 150, align: 'center', },
]
const fbaaddresscolumns = [
  { title: '名称', dataIndex: 'name', width: 200, align: 'center', },
  { title: '国家', dataIndex: 'country', width: 150, align: 'center', },
  { title: '省份/州', dataIndex: 'province', width: 150, align: 'center', },
  { title: '城市', dataIndex: 'city', width: 150, align: 'center', },
  { title: '区', dataIndex: 'district', width: 150, align: 'center', },
  { title: '街道地址1', dataIndex: 'addressline1', width: 150, align: 'center', },
  { title: '街道地址2', dataIndex: 'addressline2', width: 150, align: 'center', },
  { title: '邮编', dataIndex: 'postalcode', width: 150, align: 'center', },
]
const shipmentItemsColumns = [
  { title: '图片', dataIndex: 'cover', width: 150, align: 'center', },
  { title: 'MSKU', dataIndex: 'msku', width: 200, align: 'center', },
  { title: '标题', dataIndex: 'productName', width: 300, align: 'center', },
  { title: 'ASIN/FNSKU', dataIndex: 'asin', width: 200, align: 'center', },
  { title: '品名/SKU', dataIndex: 'sku', width: 300, align: 'center', },
  { title: '计划申报量', dataIndex: 'quantity', width: 150, align: 'center', },
  { title: '备注', dataIndex: 'mark', width: 200, align: 'center', },
]
const productLabelcolumns = [
  { title: '图片', dataIndex: 'cover', width: 100, align: 'center', },
  { title: 'MSKU/FNSKU', dataIndex: 'msku', width: 200, align: 'center', },
  { title: '标题', dataIndex: 'name', width: 200, align: 'center', },
  { title: 'SKU/品名', dataIndex: 'sku', width: 200, align: 'center', },
  { title: '申报量', dataIndex: 'quantity', width: 100, align: 'center', },
  { title: '打印数量', dataIndex: 'printnum', width: 100, align: 'center', },
]
const verifycolums = [
  { title: '货件名称', dataIndex: 'shipmentName', width: 200, align: 'center', },
  { title: '货件单号', dataIndex: 'shipmentId', width: 200, align: 'center', },
  { title: 'MSKU数量', dataIndex: 'mskuNum', width: 100, align: 'center', },
  { title: '商品总数', dataIndex: 'productNum', width: 100, align: 'center', },
  { title: '商品重量(kg)', dataIndex: 'plannumber', width: 100, align: 'center', },
  { title: '配送地址', dataIndex: 'ShipToAddress', width: 300, align: 'center', },
]
const purchasePlancolums = [
  { title: '补货计划号', dataIndex: 'replen_no', width: 100, align: 'center', },
  { title: '图片', dataIndex: 'cover', width: 100, align: 'center', },
  { title: 'SKU/品名', dataIndex: 'sku', width: 100, align: 'center', },
  { title: 'MSKU/标题', dataIndex: 'msku', width: 100, align: 'center', },
  { title: '备货仓库', dataIndex: 'deliveryware', width: 150, align: 'center', },
  { title: '计划申报量', dataIndex: 'quantity', width: 50, align: 'center', },
  { title: '计划采购量', dataIndex: 'purchase_quantity', width: 50, align: 'center', },
  { title: '采购备注', dataIndex: 'purchase_remark', width: 100, align: 'center', },
]
export default defineComponent({
  name: 'InventoryDeliverPlan',
  components: {
    DownOutlined,
    SearchOutlined,
    PlusCircleOutlined,
    BarsOutlined,
    QuestionCircleFilled,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const deliveryPlanParamRef = ref();
    const createShipmentRef = ref();
    const formRefAddress = ref();
    const addressRef = ref();
    const state = reactive({
      warehouses0: [],
      warehouses1: [],
      warehouseid: "",
      skus: "",
      dataLoading: false,
      page: 1,
      limit: 10,
      length: 0,
      count: 0,
      productPage: 1,
      productLimit: 10,
      productLength: 0,
      productCount: 0,
      data: [],
      createShipmentModal: false,
      createShipmentLoading: false,
      tongbuData: {},
      tongbuAddress: {},
      tongbuProduct: [],
      selectedRowKeys: [],
      deliveryPlanModal: false,
      deliveryPlanLoading: false,
      deliveryPlanParam: {},
      shops: [],
      selectedPlanRowKeys: [],
      selectedProductRowKeys: [],
      productLoading: false,
      productModal: false,
      productData: [],
      searchText: "",
      shopid: 0,
      status: "",
      warehouses: [],
      inventorystate: "1",
      placeModal: false,
      placeLoading: false,
      placeData: [],
      countrys: [],
      placeAddressModal: false,
      placeAddressLoading: false,
      placeAddress: {},
      placeDataLoading: false,
      FBAAddressModal: false,
      fbaAddressDataLoading: false,
      fbaAddressLoading: false,
      fbaaddressRowKeys: [],
      productlabelModal: false,
      productlabelLoading: false,
      labelLoading: false,
      labelType: "",
      label: {},
      printLabelData: [],
      productLabelRowKeys: [],
      pendingNum: 0,
      processedNum: 0,
      invalidatedNum: 0,
      confirmShipmentLoading: false,
      confirmShipmentModal: false,
      confirmShipmentParam: [],
      logisticsData: [
        { label: "港通-海运-美森限时达", value: "港通-海运-美森限时达" },
        { label: "港通-空运-香港DHL", value: "港通-空运-香港DHL" },
        { label: "港通-空运-大陆UPS", value: "港通-空运-大陆UPS" }
      ],
      removeProductId: [],
      createShipmentParam: {},
      toAddress: {},
      purchasePlanModal: false,
      purchasePlanLoading: false,
      purchasePlanData: [],
    });
    const width = computed(() => proxy.$utils.twidth(columnsf));
    const iwidth = computed(() => proxy.$utils.twidth(columns));
    const planwidth = computed(() => proxy.$utils.twidth(plancolumns));
    const productwidth = computed(() => proxy.$utils.twidth(productcolumns));
    const placewidth = computed(() => proxy.$utils.twidth(placecolumns));
    const fbaaddresswidth = computed(() => proxy.$utils.twidth(fbaaddresscolumns));
    const shipmentItemsColumnsWidth = computed(() => proxy.$utils.twidth(shipmentItemsColumns));
    const productLabelcolumnswidth = computed(() => proxy.$utils.twidth(productLabelcolumns));
    const verifycolumswidth = computed(() => proxy.$utils.twidth(verifycolums));
    const purchasePlancolumswidth = computed(() => proxy.$utils.twidth(purchasePlancolums));
    onMounted(() => {
    });
    onActivated(() => {
      getData();
    });
    watch(() => state.page, () => getData());
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    watch(() => state.productPage, () => getGoods());
    watch(() => state.productLimit, () => {
      if (state.productPage !== 1) state.productPage = 1;
      else getGoods();
    });
    const cancelDeliveryPlan = () => {
      console.log('cancelDeliveryPlan');
      deliveryPlanParamRef.value.clearValidate();
      state.deliveryPlanParam = {};
      state.removeProductId = [];
    }
    const cancelSaveProduct = () => {
      state.productData = [];
      state.selectedProductRowKeys = [];
    };
    const addDeliveryPlanParam = () => {
      state.deliveryPlanModal = true;
      state.deliveryPlanParam = {
        packag_type: "混装"
      };
    };
    const cancel = e => {
      console.log(e);
      state.deliveryPlanParam = {};
      message.warn('取消操作');
    };
    const updateInboundShipment = (data, type) => {
      console.log(data);
      state.deliveryPlanParam = _.pick(data, "shop", "_id", "shipmentId", "shopid", "packag_type", "deliver_time", "arrive_time", "mark", "ship_from_address", "fulfillmentCenterId", "label_type", "shipment_name", "source");
      let items = [];
      for (let i = 0; i < data.items.length; i++) {
        items.push(_.pick(data.items[i], "replen_no", "asin", "fbabatchid", "_id", "fnsku", "msku", "name", "packag_type", "parent_sku", "sku", "warehouse", "product", "quantity", "warehouseid", "create_time", "mark"));
      }
      state.deliveryPlanParam.items = items;
      state.deliveryPlanParam.removeProductId = [];
      state.deliveryPlanParam.addProduct = [];
      state.deliveryPlanParam.type = type;
      if (type === 'up') {
        state.deliveryPlanModal = true;
      } else {
        proxy.$api.doAPI("/fba/updateInbound", {
          data: state.deliveryPlanParam,
        }).then((res) => {
          if (res.code === 0) {
            getData();
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          console.error(err);
        });
      }
    };
    const addproduct = () => {
      if (!state.deliveryPlanParam.shopid) return message.error("请选择店铺");
      state.productModal = true;
      getGoods();
    };
    const getGoods = () => {
      proxy.$api.doAPI("/fba/searchgoods", {
        shopid: state.deliveryPlanParam.shopid,
        searchText: state.searchText,
        page: state.productPage,
        limit: state.productLimit,
      }).then((res) => {
        if (res.code === 0) {
          state.productData = res.data.list;
          state.productLength = res.data.length;
          state.productCount = res.data.count;
          if (state.productLength > 0 && state.productPage > state.productLength) state.productPage = 1;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
      });
    };
    const inboundproduct = () => {
      if (state.selectedProductRowKeys.length === 0) return message.warn("请选择商品信息");
      let list = _.filter(state.productData, n => state.selectedProductRowKeys.indexOf(n._id) > -1);
      if (list.length > 0) {
        let items = state.deliveryPlanParam.items || [];
        let additems = [];
        for (let i = 0; i < list.length; i++) {
          list[i].quantity = 0;
          items.push(list[i]);
          if (state.deliveryPlanParam._id !== undefined) {
            additems.push(list[i]);
          }
        }
        state.deliveryPlanParam.items = items;
        if (additems.length > 0) {
          state.deliveryPlanParam.addProduct = additems;
        }
      }
      state.selectedProductRowKeys = [];
      state.productData = [];
      state.productModal = false;
    };
    const okDeliveryPlan = async () => {
      await deliveryPlanParamRef.value.validate();
      let size = _.filter(state.deliveryPlanParam.items, n => n.warehouseid == undefined).length;
      if (size > 0) return message.warn("发货仓库必填");
      let numberSize = _.filter(state.deliveryPlanParam.items, n => n.quantity == 0 || n.quantity == undefined).length;
      if (numberSize > 0) return message.warn("计划申报量必填");
      if (JSON.stringify(state.deliveryPlanParam) !== "{}" && state.deliveryPlanParam.items.length > 0) {
        if (state.deliveryPlanParam._id !== undefined) {
          proxy.$api.doAPI("/fba/updateInbound", {
            data: state.deliveryPlanParam,
          }).then((res) => {
            if (res.code === 0) {
              deliveryPlanParamRef.value.clearValidate();
              state.deliveryPlanParam = {};
              state.deliveryPlanModal = false;
              getData();
            } else {
              message.error(res.msg);
            }
          }).catch((err) => {
            console.error(err);
          });
        } else {
          proxy.$api.doAPI("/fba/createPlan", {
            param: state.deliveryPlanParam,
          }).then((res) => {
            if (res.code === 0) {
              message.success("创建发货计划成功");
              deliveryPlanParamRef.value.clearValidate();
              state.deliveryPlanParam = {};
              state.deliveryPlanModal = false;
              getData();
            } else {
              message.error(res.msg);
            }
          }).catch((err) => {
            console.error(err);
          });
        }
      }
    };
    const delproduct = (record) => {
      if (state.deliveryPlanParam.items.length === 1) return message.warn("至少保留一项");
      state.deliveryPlanParam.items.forEach((item, index) => {
        if (record._id === item._id) {
          state.deliveryPlanParam.removeProductId.push(record._id);
          state.deliveryPlanParam.items.splice(index, 1);
          return;
        }
      });
      state.deliveryPlanParam.addProduct.forEach((item, index) => {
        if (record._id === item._id) {
          state.deliveryPlanParam.addProduct.splice(index, 1);
          return;
        }
      });
    };
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/fba/FBAPlan", {
        page: state.page,
        limit: state.limit,
        shopid: state.shopid,
        status: state.inventorystate,
        searchText: state.skus,
      }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.shops = [..._.map(res.data.shops || [], n => ({ ...n, label: n.name, value: n._id }))];
          state.warehouses = res.data.warehouses;
          state.length = res.data.length;
          state.count = res.data.count;
          state.pendingNum = res.data.pendingNum;
          state.processedNum = res.data.processedNum;
          state.invalidatedNum = res.data.invalidatedNum;
          state.data = res.data.list;
          if (state.length > 0 && state.page > state.length) state.page = 1;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
        state.dataLoading = false;
      });
    };
    const changeShop = (shopid) => {
      state.shopid = shopid;
      getData();
    }
    const changeInventorystate = (item) =>{
      state.inventorystate = item;
      getData();
    };
    //启运地管理开始
    const placemodel = () => {
      state.placeModal = true;
      placeList();
    };
    const cancelPlace = () => {
      state.placeModal = false;
    };
    const addplace = (item) => {
      state.placeAddressModal = true;
      if (!item) {
        state.placeAddress = {};
      } else {
        state.placeAddress = {
          _id: item?._id ,
          name: item?.name ,
          countrycode: item?.countrycode ,
          province: item?.province ,
          city: item?.city ,
          district: item?.district ,
          addressline1: item?.addressline1 ,
          addressline2: item?.addressline2 ,
          postalcode: item?.postalcode ,
          phone: item?.phone,
          shopids: item?.shopids,
        };
      }
    };
    const delAddress = (item) => {
      proxy.$api.doAPI("/place/delPlace", { _id: item._id }).then((res) => {
        if (res.code === 0) {
          message.success("删除成功");
          placeList();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
      });
    };
    const addOrUplAddress = () => {
      //请求添加起运地API
      proxy.$api.doAPI("/place/addOrUpPlace", { placeAddress: state.placeAddress }).then((res) => {
        if (res.code === 0) {
          message.success(res.msg);
          state.placeAddressModal = false;
          placeList();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
      });
    };
    const placeList = (item) => {
      proxy.$api.doAPI("/place/placeList", { shopid: item }).then((res) => {
        if (res.code === 0) {
          state.placeData = res.data.list;
          state.countrys = _.map(res.data.countrys, n => ({ ...n, label: `[${n.code}] ${n.name}`, value: n.code }));
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
      });
    };
    const canceAddress = () => {
      formRefAddress.value.clearValidate();
      state.placeAddressModal = false;
    };
    //创建货件
    const okCreateShipment = () => {
      if (JSON.stringify(state.createShipmentParam.ship_from_address) == '{}') return message.warn("未选择发货地址信息");
      proxy.$api.doAPI("/fba/purchase", { param: state.createShipmentParam }).then((res) => {
        if (res.code === 0) {
          state.createShipmentModal = false;
          state.confirmShipmentModal = true;
          //获取返回的shipmentId
          let shipmentId = res.data.shipmentId;//货件编号
          let ship_to_address = res.data.ShipToAddress;//配送地址
          let estimated_box_contents_fee = res.data.EstimatedBoxContentsFee;//总费用
          let fulfillmentCenterId = res.data.fulfillmentCenterId;//亚马逊配送中心标识
          let shopid = state.createShipmentParam.shopid;
          let shop = _.findWhere(state.shops, { value: shopid });
          state.createShipmentParam.shop = shop;
          state.createShipmentParam.ship_to_address = ship_to_address;
          state.createShipmentParam.mskuNum = _.uniq(_.pluck(state.createShipmentParam.items, "msku")).length;
          state.createShipmentParam.productNum = _.reduce(state.createShipmentParam.items, function (a, b) { return a + b.quantity; }, 0);
          state.createShipmentParam.shipmentId = shipmentId;
          state.createShipmentParam.fulfillmentCenterId = fulfillmentCenterId;
          state.createShipmentParam.label_type = res.data.LabelPrepType;//标签类型
          state.confirmShipmentParam = [];
          state.confirmShipmentParam.push(state.createShipmentParam);
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
      });
    };
    const okConfirmShipment = () => {
      state.confirmShipmentLoading = true;
      if (state.confirmShipmentParam[0].shipmentId == '') return message.warn("未货件单号");
      proxy.$api.doAPI("/fba/confirmpurchase", { verifyData: state.confirmShipmentParam[0] }).then((res) => {
        if (res.code === 0) {
          state.confirmShipmentModal = false;
          state.confirmShipmentLoading = false;
          state.selectedRowKeys = [];
          message.success("确认成功");
          getData();
        } else {
          message.error(res.msg);
          state.confirmShipmentLoading = false;
        }
      }).catch((err) => {
        console.error(err);
        state.confirmShipmentLoading = false;
      });
    };
    const cancelConfirmShipment = () => {
      state.confirmShipmentModal = false;
      getData();
    };
    const cancelCreateShipment = () => {
      createShipmentRef.value.clearValidate();
    };
    const fbaAddressModal = (shopid) => {
      placeList(shopid);
      state.FBAAddressModal = true;
    };
    const tongbuFbaAddress = () => {
      if (state.fbaaddressRowKeys.length === 0) return message.warn("未选择地址信息");
      let list = _.filter(state.placeData, n => state.fbaaddressRowKeys.indexOf(n._id) > -1);
      state.createShipmentParam.ship_from_address = list[0];
      state.FBAAddressModal = false;
      state.fbaaddressRowKeys = [];
    };
    const cancelFbaAddress = () => {
      state.FBAAddressModal = false;
      state.tongbuAddress = {};
      state.fbaaddressRowKeys = [];
    };
    const changeWarehouse = (e) => {
      console.log('changeWarehouse', e);
      if (state.page !== 1) state.page = 1;
      else getData();
    };
    const handleBulkClick = ({ key }) => {
      console.log('handleBulkClick', key);
      switch (key) {
        case "1":
          productlabel();
          break;
        case "2":
        case "3":
        case "4":
        case "5":
        case "6":
          break;
      }
    }
    const productlabel = () => {
      if (state.selectedRowKeys.length === 0) return message.warn("请选择需要打印的商品信息");
      if (state.selectedRowKeys.length > 1) return message.warn("一次只能选择一个");
      let list = _.filter(state.data, n => state.selectedRowKeys.indexOf(n._id) > -1);
      let { _id, items, } = list[0];
      state.printLabelData = _.map(items, n => {
        return {
          _id: n._id,
          asin: n.asin,
          fnsku: n.fnsku,
          msku: n.msku,
          name: n.name,
          parent_sku: n.parent_sku,
          product: n.product,
          quantity: n.quantity,
          seller_sku: n.seller_sku,
          printnum: n.quantity,
          condition: n.condition,
        }
      });
      state.productlabelModal = true;
      state.productLabelRowKeys = [];
    };
    const onSelectChange = (selectedRowKeys) => {
      console.log('productLabelRowKeys changed: ', selectedRowKeys);
      state.productLabelRowKeys = selectedRowKeys;
    };
    const onSelectedProductRowKeys = (selectedRowKeys) => {
      console.log('selectedProductRowKeys changed: ', selectedRowKeys);
      state.selectedProductRowKeys = selectedRowKeys;
    };
    const onSelectedPlanRowKeys = (selectedRowKeys) => {
      console.log('selectedPlanRowKeys changed: ', selectedRowKeys);
      state.selectedPlanRowKeys = selectedRowKeys;
    };
    const onFbaaddressRowKeys = (selectedRowKeys) => {
      console.log('fbaaddressRowKeys changed: ', selectedRowKeys);
      state.fbaaddressRowKeys = selectedRowKeys;
    };
    const onSelectedRowKeys = (selectedRowKeys) => {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      state.selectedRowKeys = selectedRowKeys;
    };
    const okPrint = async () => {
      if (state.productLabelRowKeys.length === 0) return message.warn("未选择需要打印的商品");
      let list = [];
      if (state.productLabelRowKeys.length === state.printLabelData.length) {
        list = state.printLabelData;
      } else {
        list = _.filter(state.printLabelData, n => state.productLabelRowKeys.indexOf(n._id) > -1);
      }
      //请求打印API
      await proxy.$api.doAPI("/fba/printTag", { list, type: 'FBA' }).then((res) => {
        if (res.code === 0) {
          printUrl(res.data.url);
          state.productlabelModal = false;
          state.productLabelRowKeys = [];
          state.selectedRowKeys = [];
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
      });
    };
    const cancelPrint = () => {
      state.productlabelModal = false;
      state.productLabelRowKeys = [];
      state.printLabelData = [];
      state.selectedRowKeys = [];
    };
    const processImportExport = ({ key, }) => {
      console.log('processImportExport', key);
    }
    const addToWarehouse = ({ key, }) => {
      console.log('addToWarehouse', key);
    }
    //创建货件
    const createShipment = async () => {
      if (state.selectedRowKeys.length === 0) return message.warn("请选择货件");
      if (state.selectedRowKeys.length > 1) return message.warn("一次只能选择一个");
      let list = _.filter(state.data, n => state.selectedRowKeys.indexOf(n._id) > -1);
      let { _id, shopid, shop, batchNo, items, shipment_name, label_type, packag_type, shipmentId, fulfillmentCenterId, ship_from_address, ship_to_address, remark } = list[0];
      if (shipmentId) {
        state.confirmShipmentModal = true;
        let shop = _.findWhere(state.shops, { value: shopid });
        state.confirmShipmentParam = [];
        state.confirmShipmentParam.push({
          ship_from_address,
          ship_to_address,
          shop,
          mskuNum: _.uniq(_.pluck(items, "msku")).length,
          productNum: _.reduce(items, function (a, b) { return a + b.quantity; }, 0),
          items,
          shipmentName: shipment_name,
          packag_type,
          labeltype: label_type,
          _id,
          shipmentId,
          fulfillmentCenterId,
        });
      } else {
        await proxy.$api.doAPI("/place/placeList", { shopid }).then((res) => {
          if (res.code === 0) {
            ship_from_address = res.data.list.length > 0 ? res.data.list[0] : {};
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          console.error(err);
        });
        state.createShipmentParam = {
          labeltype: "SELLER_LABEL",
          source: "FEED",
          shopid,
          targetmall: shop?.country,
          batchNo,
          packag_type,
          _id,
          shipmentName: shipment_name,
          items,
          remark,
          ship_from_address,
        };
        state.createShipmentModal = true;
      }
    };
    //修改物流信息
    const uplogistics = (data) => {
      proxy.$api.doAPI("/fba/uplogistics", data).then((res) => {
        if (res.code === 0) {
          message.success("物流方式已更改");
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
      });
    }
    ////同步到FBA，同时创建了FBA货件
    const syncFBA = ({ key, }) => {
      console.log('syncFBA', key);
    }
    //生成采购计划
    const makePurchasePlan = () => {
      if (state.selectedRowKeys.length === 0) return message.warn("请选择货件");
      if (state.selectedRowKeys.length > 1) return message.warn("一次只能选择一个");
      let list = _.filter(state.data, n => state.selectedRowKeys.indexOf(n._id) > -1);
      let { _id, items, } = list[0];
      state.purchasePlanModal = true;
      state.purchasePlanData = _.map(items, n => {
        return {
          ...n,
          purchase_quantity: n.quantity,
        }
      });
    }
    const okPurchasePlan = () => {
      proxy.$api.doAPI("/fba/genpurchaseplan", { purchase_list: state.purchasePlanData }).then((res) => {
        if (res.code === 0) {
          state.purchasePlanModal = false;
          state.purchasePlanData = [];
          state.selectedRowKeys = [];
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
      });
    };
    const cancelPurchasePlan = () => {
      state.purchasePlanModal = false;
      state.purchasePlanData = [];
      state.selectedRowKeys = [];
    };
    return {
      ...toRefs(state),
      deliveryPlanParamRef,
      createShipmentRef,
      formRefAddress,
      addressRef,
      columnsf,
      columns,
      width,
      iwidth,
      getData,
      changeWarehouse,
      handleBulkClick,
      createShipment,
      syncFBA,
      makePurchasePlan,
      processImportExport,
      addToWarehouse,
      image_placeholder: proxy.$constant.image_placeholder,
      addDeliveryPlanParam,//创建发货计划
      updateInboundShipment,
      cancel,
      plancolumns,
      planwidth,
      addproduct,
      productcolumns,
      productwidth,
      getGoods,
      inboundproduct,
      okDeliveryPlan,
      cancelDeliveryPlan,
      cancelSaveProduct,
      placemodel,
      placecolumns,
      placewidth,
      addplace,
      delAddress,
      addOrUplAddress,
      canceAddress,
      cancelPlace,
      fbaaddresswidth,
      fbaaddresscolumns,
      okCreateShipment,
      cancelCreateShipment,
      fbaAddressModal,
      tongbuFbaAddress,
      cancelFbaAddress,
      shipmentItemsColumnsWidth,
      shipmentItemsColumns,
      productlabel,
      productLabelcolumns,
      productLabelcolumnswidth,
      okPrint,
      cancelPrint,
      delproduct,
      verifycolums,
      verifycolumswidth,
      cancelConfirmShipment,
      okConfirmShipment,
      changeShop,
      changeInventorystate,
      onSelectChange,
      onSelectedProductRowKeys,
      onSelectedPlanRowKeys,
      onFbaaddressRowKeys,
      onSelectedRowKeys,
      purchasePlancolums,
      purchasePlancolumswidth,
      okPurchasePlan,
      cancelPurchasePlan,
      uplogistics,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}

.drop-down-Style {
  width: auto;
}

.sp {
  font-size: 15px;
  font-weight: bold
}
</style>

