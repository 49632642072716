<template>
  <div class="header">
    <a-form ref="formRef" name="formsearch" class="ant-advanced-search-form" :model="queryForm" @finish="onFinish">
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item name="shop" label="选择店铺">
            <a-select v-model:value="queryForm.shopid" :options="shops" style="width: 290px" show-search>
            </a-select>
          </a-form-item>
        </a-col>
        <!-- <a-col :span="6">
                    <a-form-item name="customer" label="选择店长">
                        <a-dropdown>
                            <template #overlay>
                                <a-menu @click="handleMenuClick">
                                    <a-menu-item key="1">店长1111111</a-menu-item>
                                    <a-menu-item key="2">店长2222222</a-menu-item>
                                </a-menu>
                            </template>
                            <a-button>
                                全部店长
                                <DownOutlined />
                            </a-button>
                        </a-dropdown>
                    </a-form-item>
                </a-col> -->
        <a-col :span="6">
          <a-form-item name="createtime" label="付款时间">
            <a-range-picker v-model:value="queryForm.daterange" value-format="YYYY-MM-DD" />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-button type="primary" @click="getData">查询</a-button>
        </a-col>
      </a-row>
    </a-form>
  </div>
  <div class="content">
    <a-table :columns="columns" :data-source="data" bordered :scroll="{ x: width }">
      <!-- <template #bodyCell="{ column, text }">
                <template v-if="column.dataIndex === 'ordercount'">
                    <a>{{ text }}</a>
                </template>
            </template> -->
      <template #title>
        <div class="theader">
          <span class="title">订单状态报表</span>
          <a-button type="primary">导出订单状态报表</a-button>
        </div>
      </template>
    </a-table>
  </div>
</template>
<script>
import { DownOutlined } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from 'vue';
import _ from "underscore";
const columns = [
  { title: '序号', dataIndex: 'index', width: 80, align: 'center' },
  { title: '付款时间', dataIndex: 'paydate', width: 150, align: 'center' },
  { title: '总订单数', dataIndex: 'count', width: 150, align: 'center' },
  { title: '未发货数', dataIndex: 'unshipped_count', width: 150, align: 'center' },
  { title: '已发货数', dataIndex: 'shipped_count', width: 150, align: 'center' },
  { title: '已完成数', dataIndex: 'finished_count', width: 150, align: 'center' },
  { title: '退款数', dataIndex: 'refunded_count', width: 150, align: 'center' },
  { title: '作废数', dataIndex: 'canceled_count', width: 150, align: 'center' }
];
export default defineComponent({
  name: 'FinanceOrderState',
  components: {
    DownOutlined
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      queryForm: {},
      data: [],
      shopid: '',
      shops: [],
      daterange: [],
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    onActivated(() => {
      getData();
    });
    const getData = () => {
      proxy.$api.doAPI("/report/orderstatusrepost", {
        daterange: state.daterange,
        shopid: state.shopid,
      }).then((res) => {
        if (res.code === 0) {
          state.data = res.data.list;
          state.shops = [{ label: "全部", value: "" }, ..._.map(res.data.own_shops || [], n => ({ ...n, label: n.name, value: n._id }))];
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
      });
    };
    return {
      ...toRefs(state),
      columns,
      width,
      getData,
    }
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

:deep(.ant-table-tbody > tr > td) {
  padding: 8px 8px !important;
}

.header {
  margin-bottom: 10px;
}

.theader {
  display: flex;
  justify-content: space-between;

  .title {
    font-weight: 600;
  }
}

a {
  text-decoration: underline;
}

p {
  margin: 0;
  padding: 0;
}
</style>