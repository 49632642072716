<template>
  <div class="d-flex align-center mb-2">
    <span style="width:80px;">查询搜索：</span>
    <a-input-search v-model:value="searchText" placeholder="输入侵权词查询" enter-button="搜索" allow-clear @search="getData" />
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-space>
      <a-button type="danger" @click="deleteTortWords(selectedRowKeys)">批量删除</a-button>
    </a-space>
    <a-space>
      <a-button type="primary" @click="addTortWords">
        <template #icon>
          <PlusCircleOutlined />
        </template>新增侵权词
      </a-button>
    </a-space>
  </div>
  <a-table :columns="columns" bordered :data-source="data" :loading="dataLoading" :scroll="{ x: width, y: 600 }"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
    :row-selection="{ selectedRowKeys, onChange: (keys) => { selectedRowKeys = keys; } }">
    <template #bodyCell="{ index, record, column }">
      <template v-if="column.dataIndex === 'index'">
        {{ index + 1 }}
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <a href="javascript:;" @click="editTortWord(record)">编辑</a>
          <a href="javascript:;" @click="deleteTortWords([record._id])" style="color: red;">删除</a>
        </a-space>
      </template>
    </template>
  </a-table>
  <a-modal v-model:visible="tortwordModal" :title="`${tortwordForm._id !== '' ? '编辑' : '新增'}侵权词`" @ok="saveTortWord"
    @cancel="cancelSave" ok-text="确认" cancel-text="取消" :confirm-loading="saving"
    :cancel-button-props="{ disabled: saving }" :maskClosable="false" :closable="!saving">
    <a-form ref="formRef" name="tortwordForm" layout="vertical" :model="tortwordForm" :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }" autocomplete="off">
      <a-input v-if="tortwordForm._id" v-model:value="tortwordForm.content" :disabled="saving" />
      <template v-else>
        <p>请输入侵权词，可一行一个地址批量添加 (支持Excel复制粘贴)</p>
        <a-textarea v-model:value="tortwordForm.content" :auto-size="{ minRows: 5, maxRows: 7 }" :disabled="saving" />
      </template>
    </a-form>
  </a-modal>
</template>
<script>
import { DownOutlined, SearchOutlined, PlusCircleOutlined, } from '@ant-design/icons-vue';
import { message } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import _ from "underscore";
const columns = [
  { title: '序号', dataIndex: 'index', width: 80, },
  { title: '侵权词', dataIndex: 'content', width: 300, },
  { title: '操作', dataIndex: 'operation', width: 150, fixed: 'right' },
];
export default defineComponent({
  name: 'TortWords',
  components: { DownOutlined, SearchOutlined, PlusCircleOutlined, },
  setup() {
    const { proxy } = getCurrentInstance();
    const formRef = ref();
    const state = reactive({
      searchText: "",
      dataLoading: false,
      page: 1,
      limit: 100,
      length: 0,
      count: 0,
      data: [],
      selectedRowKeys: [],
      saving: false,
      tortwordModal: false,
      tortwordForm: {},
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    watch(() => state.page, () => getData());
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    onActivated(() => {
      // console.log("onActivated");
      getData();
    });
    const getData = () => {
      state.selectedRowKeys = [];
      state.dataLoading = true;
      proxy.$api.doAPI("/tortword/list", { page: state.page, limit: state.limit, searchText: state.searchText }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.data = _.map(res.data.list, n => {
            return {
              ...n, create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
            }
          });
          state.length = res.data.length;
          state.count = res.data.count;
          if (state.length > 0 && state.page > state.length) state.page = 1;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const addTortWords = () => {
      state.tortwordForm = { _id: "", content: "", };
      state.tortwordModal = true;
    }
    const editTortWord = (item) => {
      state.tortwordForm = { _id: item._id, content: item.content };
      state.tortwordModal = true;
    }
    const saveTortWord = async () => {
      try {
        await formRef.value.validateFields();
        state.saving = true;
        proxy.$api.doAPI("/tortword/save", { tortwordForm: state.tortwordForm }).then((res) => {
          state.saving = false;
          if (res.code === 0) {
            state.tortwordModal = false;
            getData();
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.saving = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    }
    const cancelSave = () => {
      console.log('cancelSave');
      formRef.value.clearValidate();
    }
    ///批量删除
    const deleteTortWords = (ids) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/tortword/remove", { ids }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    return {
      ...toRefs(state),
      formRef,
      columns,
      width,
      getData,
      addTortWords,
      editTortWord,
      saveTortWord,
      cancelSave,
      deleteTortWords
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>

