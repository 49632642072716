<template>
  <div class="d-flex align-center mb-2">
    <span style="width:75px;">出库日期：</span>
    <a-range-picker v-model:value="daterange" :locale="locale" value-format="YYYY-MM-DD" />
  </div>
  <div class="d-flex align-center mb-2">
    <span style="width:80px;">查询搜索：</span>
    <a-input-search v-model:value="value" placeholder="名称或编号查询" enter-button="搜索" @search="onSearch" />
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-button type="default">
      <template #icon>
        <PlusCircleOutlined />
      </template>添加出库
    </a-button>
    <a-button type="primary">
      <template #icon>
        <UploadOutlined />
      </template>导出出库信息
    </a-button>
  </div>
  <a-table :columns="columns" :data-source="data" :scroll="{ x: width }">
    <template #bodyCell="{ column }">
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <a href="javascript:;">编辑</a>
          <a-popconfirm v-if="data.length" title="确定要删除?" @confirm="onDelete(record.key)">
            <a href="javascript:;" class="text-error">删除</a>
          </a-popconfirm>
        </a-space>
      </template>
    </template>
  </a-table>
</template>
<script>
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { DownOutlined, ArrowDownOutlined, SearchOutlined, PlusCircleOutlined, UploadOutlined, } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from 'vue';
import _ from "underscore";
const columns = [
  { title: '商品名称', dataIndex: 'name', width: 150, align: 'center' },
  { title: '商品编号', dataIndex: 'code', width: 150, align: 'center' },
  { title: '仓库', dataIndex: 'storage', width: 120, align: 'center' },
  { title: '规格', dataIndex: 'size', width: 150, align: 'center' },
  { title: '单位', dataIndex: 'unit', width: 150, align: 'center' },
  { title: '数量', dataIndex: 'usableinventory', width: 150, align: 'center' },
  { title: '单价', dataIndex: 'price', width: 100, align: 'center' },
  { title: '金额', dataIndex: 'amount', width: 100, align: 'center' },
  { title: '经办人', dataIndex: 'manager', width: 100, align: 'center' },
  { title: '备注', dataIndex: 'mark', width: 100, align: 'center' },
  { title: '创建时间', dataIndex: 'createtime', width: 150, align: 'center' },
  { title: '操作', dataIndex: 'operation', width: 100, fixed: 'right' },
];
const data = [];
for (let i = 0; i < 5; i++) {
  data.push({
    index: i + 1,
    storage: '二里岗仓库',
    name: '衣服' + i,
    code: '27327421',
    size: '',
    unit: '个',
    usableinventory: '0',
    sysinventory: '0',
    createtime: ''
  });
}
export default defineComponent({
  name: 'InventoryInandoutOutstock',
  components: {
    DownOutlined,
    SearchOutlined,
    PlusCircleOutlined,
    UploadOutlined,
    ArrowDownOutlined
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      data,
      daterange: ["", ""],
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    return {
      ...toRefs(state),
      locale,
      columns,
      width,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

:deep(.ant-table-tbody > tr > td) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>

