<template>
  <div class="d-flex align-center mb-2">
    <span style="width:75px;">查询搜索：</span>
    <a-input-search v-model:value="skus" placeholder="输入货件查询" enter-button="搜索" allow-clear @search="getData" />
  </div>
  <div class="d-flex align-center mb-2">
    <a-row>
      <a-col><span style="width:75px;">选择店铺：</span></a-col>
      <a-col :span="22">
        <div>
          <a-tag :color="shopid === 0 ? 'processing' : 'default'" @click="changeShop(0)" class="click">全部</a-tag>
          <a-tag v-for="item in shops" :key="item._id" :color="shopid === item._id ? 'processing' : 'default'"
          @click="changeShop(item._id)" class="click">{{ item.name }}</a-tag>
        </div>
      </a-col>
    </a-row>
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-button type="primary" @click="getData">同步FBA数据</a-button>
  </div>
  <a-table :columns="columns" bordered :data-source="data" :loading="dataLoading" :scroll="{ x: width, y: 600 }"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
    :row-selection="{ selectedRowKeys, onChange: (keys) => selectedRowKeys = keys }">
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'updatetime'">
        {{ record.lasttime }}
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <a href="javascript:;" class="text-error">删除</a>
        </a-space>
      </template>
    </template>
  </a-table>
</template>
<script>
import { DownOutlined, SearchOutlined, PlusCircleOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onActivated,
  getCurrentInstance,
} from "vue";
import _ from "underscore";
const columns = [
  { title: '货件编号', dataIndex: 'shipmentId', width: 150 },
  { title: '发货量', dataIndex: 'shipment', width: 100, },
  { title: '接收量', dataIndex: 'amountReceived', width: 100, },
  { title: '差异量', dataIndex: 'quantityDifference', width: 100, },
  { title: '发货时间', dataIndex: 'deliver_time', width: 150, },
  { title: '创建/更新时间', dataIndex: 'create_time', width: 150, },
  { title: '操作', dataIndex: 'operation', width: 100, fixed: 'right', },
];
const data = [
  {
    "shipmentId": "FBA17CGGL61Y",
    "shipment": "45",
    "amountReceived": "45",
    "quantityDifference": "0",
    "create_time": "2023-08-21 16:40:37",
    "deliver_time": "2023-09-01 15:35:25"
  },
  {
    "shipmentId": "FBA17C0CHMB7",
    "shipment": "446",
    "amountReceived": "446",
    "quantityDifference": "0",
    "create_time": "2023-08-18 09:37:43",
    "deliver_time": "2023-08-29 09:17:59"
  },
];
export default defineComponent({
  name: 'InventoryDeliverFBATrack',
  components: {
    DownOutlined,
    SearchOutlined,
    PlusCircleOutlined,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const formRef = ref();
    const state = reactive({
      warehouses0: [],
      warehouses1: [],
      warehouseid: "",
      skus: "",
      dataLoading: false,
      page: 1,
      limit: 10,
      length: 0,
      count: 0,
      data: [],
      selectedRowKeys: [],
      stores: 'all',
      shops: [],
      shopid: 0,
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    onMounted(() => {
    });
    onActivated(() => {
      getData();
    });
    watch(() => state.page, () => getData());
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    const changeShop = (shopid) => {
      state.shopid = shopid;
      getData();
    }
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/inventory/list", {
        page: state.page,
        limit: state.limit,
        skus: state.skus,
        warehouseid: state.warehouseid,
      }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.shops = [..._.map(res.data.shops || [], n => ({ ...n, label: n.name, value: n._id }))];
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const changeWarehouse = (e) => {
      console.log('changeWarehouse', e);
      if (state.page !== 1) state.page = 1;
      else getData();
    };
    const handleBulkClick = ({ key }) => {
      console.log('handleBulkClick', key);
      switch (key) {
        case "1":
        case "2":
        case "3":
        case "4":
          break;
      }
    }
    const processImportExport = ({ key, }) => {
      console.log('processImportExport', key);
    }
    const addToWarehouse = ({ key, }) => {
      console.log('addToWarehouse', key);
    }
    return {
      ...toRefs(state),
      formRef,
      columns,
      width,
      getData,
      changeWarehouse,
      handleBulkClick,
      processImportExport,
      addToWarehouse,
      image_placeholder: proxy.$constant.image_placeholder,
      data,
      changeShop,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>

