<template>
  <div class="d-flex align-center mb-2">
    <span style="width:75px;">采购日期：</span>
    <a-range-picker v-model:value="daterange" :locale="locale" value-format="YYYY-MM-DD" />
  </div>
  <div class="d-flex align-center mb-2">
    <span style="width:80px;">查询搜索：</span>
    <a-input-search v-model:value="searchText" placeholder="商品SKU/名称查询" enter-button="搜索" allow-clear @search="getData" />
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-space>
      <!-- <a-button type="primary" @click="addOrder" :loading="initing">添加采购单</a-button> -->
      <a-dropdown>
        <template #overlay>
          <a-menu @click="handleAddOrder">
            <a-menu-item key="1">添加采购单</a-menu-item>
            <a-menu-item key="2">1688采购单</a-menu-item>
          </a-menu>
        </template>
        <a-button type="primary" :loading="initing">
          添加采购单
          <DownOutlined />
        </a-button>
      </a-dropdown>
    </a-space>
    <a-space>
      <a-button type="primary" @click="showPurchaseImport">
        <template #icon>
          <UploadOutlined />
        </template>导入采购单
      </a-button>
      <a-button type="default">
        <template #icon>
          <DownloadOutlined />
        </template>导出采购单
      </a-button>
    </a-space>
  </div>
  <a-table :columns="columns" :data-source="data" :loading="dataLoading" :scroll="{ x: width }"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
    :row-selection="{ selectedRowKeys, onChange: (keys) => { selectedRowKeys = keys; } }"
    v-model:expandedRowKeys="expandedRowKeys">
    <template #headerCell="{ column }">
      <template v-if="column.dataIndex === 'number_createtime'">
        <p>采购平台</p>
        <p>采购单号</p>
        <p>下单时间</p>
      </template>
      <template v-if="column.dataIndex === 'warehouse_supplier'">
        <p>仓库</p>
        <p>供应商</p>
      </template>
      <template v-if="column.dataIndex === 'quantity_amount'">
        <p>总数量</p>
        <p>总金额</p>
      </template>
      <template v-if="column.dataIndex === 'orderinfo'">
        <p>采购账号</p>
        <p>交易方式</p>
        <p>订单号</p>
        <p>订单状态</p>
      </template>
      <template v-if="column.dataIndex === 'purchase_time_by'">
        <p>采购时间</p>
        <p>采购人</p>
      </template>
    </template>
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'number_createtime'">
        <p> {{ record.platform }} </p>
        <p> {{ record.number }} </p>
        <p> {{ record.create_time }} </p>
      </template>
      <template v-if="column.dataIndex === 'warehouse_supplier'">
        <p> {{ record.warehouse.name }} </p>
        <a v-if="record.product_supplier?.url" :href="record.product_supplier.url" target="_blank">
          {{ record.product_supplier?.name }}
          <LinkOutlined />
        </a>
        <p v-else>
          {{ record.product_supplier?.name }}
        </p>
      </template>
      <template v-if="column.dataIndex === 'quantity_amount'">
        <p> {{ record.quantity }} </p>
        <p> {{ record.amount }} </p>
      </template>
      <template v-if="column.dataIndex === 'orderinfo'">
        <p> {{ record.buyer || '-' }}</p>
        <p>{{ record.baseInfo?.tradeTypeDesc || '-' }}</p>
        <p v-if="record.orderId">
          <a :href="`https://trade.1688.com/order/order_detail.htm?orderId=${record.orderId}`" target="_blank">
            {{ record.orderId }}
          </a>
          <ReloadOutlined v-if="record.state !== 'canceled'" class="click text-warning ml-1"
            @click="refreshOrder(record._id)" />
        </p>
        <p v-else>-</p>
        <p :class="{
          'text-info': record.orderStatus === 'waitbuyerpay',
          'text-warning': record.orderStatus === 'waitsellersend',
          'text-purple': record.orderStatus === 'waitbuyerreceive',
          'text-success': record.orderStatus === 'confirm_goods' || record.orderStatus === 'success',
          'text-error': record.orderStatus === 'cancel' || record.orderStatus === 'terminated'
        }">
          {{ record.orderStatusStr }}
        </p>
      </template>
      <template v-if="column.dataIndex === 'express_list'">
        <template v-if="record.express_list?.length > 0">
          <p v-for="express_item in record.express_list" :key="express_item">
            <a :href="`https://m.kuaidi100.com/result.jsp?nu=${express_item.express_no}`" target="_blank">
              {{ express_item.express_supplier }}:{{ express_item.express_no }}
            </a>
            <a-tooltip>
              <template #title> 复制运单号 </template>
              <CopyOutlined class="click text-info ml-1" @click="doCopy(express_item.express_no)" />
            </a-tooltip>
            <a-tooltip>
              <template #title> 查看轨迹 </template>
              <EyeOutlined class="click text-info ml-1" @click="showLogisticsTrackInfo(express_item._id)" />
            </a-tooltip>
          </p>
        </template>
        <a v-if="record.state === 'pending' || record.state === 'purchase'" href="javascript:;"
          @click="addExpress(record)">
          添加物流单号
          <EditOutlined />
        </a>
        <a v-if="record.state === 'finished'" href="javascript:;" @click="showExpress(record)">
          查看物流详情
          <EyeOutlined />
        </a>
      </template>
      <template v-if="column.dataIndex === 'purchase_time_by'">
        <p> {{ record.purchase_time }} </p>
        <p> {{ record.purchase_by }} </p>
      </template>
      <template v-if="column.dataIndex === 'state'">
        <a-tag v-if="record.state === 'pending'" color="warning">待审核</a-tag>
        <a-tag v-if="record.state === 'purchase'" color="processing">采购中</a-tag>
        <a-tag v-if="record.state === 'finished'" color="success">已完成</a-tag>
        <a-tag v-if="record.state === 'canceled'" color="error">已作废</a-tag>
        <a-tag v-if="record.state === 'abnormal'" color="orange">异常</a-tag>
        <p v-if="record.pay_state === 'pending'">待申请付款</p>
        <p v-if="record.pay_state === 'unpaid'">已申请付款</p>
        <p v-if="record.pay_state === 'paid'">已完成付款</p>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <div class="d-flex flex-column">
          <a-space v-if="record.state === 'pending'">
            <!-- 待审核 可编辑 -->
            <a href="javascript:;" @click="createOrderCheck(record._id)">下单</a>
            <!-- 待审核 可标记作废 -->
            <a-popconfirm title="确定要作废采购单?" @confirm="cancelOrder(record._id)">
              <a href="javascript:;" class="text-error">作废</a>
            </a-popconfirm>
          </a-space>
          <template v-if="record.state === 'purchase'">
            <!-- 待申请付款 可申请付款 -->
            <div v-if="record.pay_state === 'pending'">
              <a href="javascript:;" @click="requestPayOrder(record._id)">申请付款</a>
            </div>
            <!-- 已申请付款 可完成付款 -->
            <div v-if="record.pay_state === 'unpaid'">
              <a href="javascript:;" @click="payOrder(record._id)">付款</a>
            </div>
            <!-- 标记完成 -->
            <!-- <div>
              <a href="javascript:;" @click="finishOrder(record._id)">标记完成</a>
            </div> -->
          </template>
          <a-popconfirm v-if="record.state === 'canceled'" title="确定要删除采购单?" @confirm="removeOrder(record._id)">
            <a href="javascript:;" class="text-error">删除</a>
          </a-popconfirm>
        </div>
      </template>
    </template>
    <template #expandedRowRender="{ record }">
      <a-table :columns="expandcolumns" :data-source="record.orderitems" :pagination="false">
        <template #bodyCell="{ column: icolumn, record: irecord, index: iindex }">
          <template v-if="icolumn.dataIndex === 'index'">
            {{ iindex + 1 }}
          </template>
          <template v-if="icolumn.dataIndex === 'cover'">
            <a-tooltip v-if="irecord.product?.cover" color="white" placement="rightBottom">
              <template #title>
                <a-image :src="irecord.product?.cover" :width="300" :height="300" style="object-fit: contain;height:100%;"
                  :preview="false" />
              </template>
              <a href="javascript:;">
                <a-image :src="irecord.product?.cover" :width="50" :height="50" style="object-fit: contain;height:100%;"
                  :preview="false" />
              </a>
            </a-tooltip>
            <a-image v-else :src="image_placeholder" :width="50" :height="50" style="object-fit: contain;height:100%;"
              :preview="false" />
          </template>
          <template v-if="icolumn.dataIndex === 'info'">
            <a-tooltip color="white">
              <template #title>
                <a href="javascript:;" @click="doCopy(irecord.product?.sku)">复制</a>
              </template>
              <span class="text-info">{{ irecord.product?.sku }}</span>
            </a-tooltip>
            <p>{{ irecord.product?.name_cn }}</p>
          </template>
          <template v-if="icolumn.dataIndex === 'product_specvalue'">
            <p>{{ irecord.product_specvalue?.join(' ') }}</p>
          </template>
          <template v-if="icolumn.dataIndex === 'purchase_quantity'">
            {{ irecord.purchase_quantity }}
          </template>
          <template v-if="icolumn.dataIndex === 'purchase_price'">
            {{ irecord.purchase_price }}
          </template>
          <template v-if="icolumn.dataIndex === 'operation'">

          </template>
        </template>
      </a-table>
    </template>
  </a-table>
  <a-modal v-model:visible="expressModal" :title="`修改物流信息 采购单号：${expressForm.number}`" @ok="saveExpress"
    @cancel="cancelSaveExpress" ok-text="确认" cancel-text="取消" :confirm-loading="expressSaving"
    :cancel-button-props="{ disabled: expressSaving }" :maskClosable="false" :closable="!expressSaving" :width="800">
    <a-table :columns="expresscolumns" :data-source="expressForm.express_list" :pagination="false">
      <template #bodyCell="{ column, record, index }">
        <template v-if="column.dataIndex === 'express_supplier'">
          <a-select v-model:value="record.express_supplier" :options="express_suppliers" :disabled="expressSaving"
            show-search placeholder="物流公司" style="width:100%;" />
        </template>
        <template v-if="column.dataIndex === 'sign_state'">
          <span v-if="record.sign_state" class="text-success"> 已签收 </span>
          <span v-else class="text-error"> 未签收 </span>
        </template>
        <template v-if="column.dataIndex === 'express_no'">
          <a-input v-model:value="record.express_no" placeholder="物流单号" :disabled="expressSaving" style="width:100%;" />
        </template>
        <template v-if="column.dataIndex === 'express_fee'">
          <a-input-group compact>
            <a-input v-model:value="record.express_fee" type="number" placeholder="物流费" suffix="RMB"
              :disabled="expressSaving" style="width:80%;" />
            <div style="width:20%;height:30px;display:inline-flex;align-items:center;">
              <PlusCircleOutlined v-if="index === 0" class="text-info click" style="width:100%;font-size:20px;"
                @click="pushExpress" />
              <MinusCircleOutlined v-else class="text-info click" style="width:100%;font-size:20px;"
                @click="popExpress(index)" />
            </div>
          </a-input-group>
        </template>
      </template>
    </a-table>
  </a-modal>
  <a-modal v-model:visible="expressModal2" :title="`查看物流详情 采购单号：${expressForm2.number}`" :width="800" :footer="null">
    <a-table :columns="expresscolumns" :data-source="expressForm2.express_list" :pagination="false">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'express_supplier'">
          <a-select v-model:value="record.express_supplier" :options="express_suppliers" disabled show-search
            placeholder="物流公司" style="width:100%;" />
        </template>
        <template v-if="column.dataIndex === 'sign_state'">
          <span v-if="record.sign_state" class="text-success"> 已签收 </span>
          <span v-else class="text-error"> 未签收 </span>
        </template>
        <template v-if="column.dataIndex === 'express_no'">
          <a-input v-model:value="record.express_no" placeholder="物流单号" disabled style="width:100%;" />
        </template>
        <template v-if="column.dataIndex === 'express_fee'">
          <a-input v-model:value="record.express_fee" type="number" placeholder="物流费" suffix="RMB" disabled
            style="width:100%;" />
        </template>
      </template>
    </a-table>
  </a-modal>
  <a-drawer :title="`${purchaseForm._id !== '' ? '编辑' : '新增'}采购单`" placement="right" :visible="purchaseDrawer"
    @close="cancelSavePurchase" width="70%" :closable="!saving" :maskClosable="!saving">
    <template #extra>
      <a-button class="mr-2" :disabled="saving" @click="cancelSavePurchase">取消</a-button>
      <a-button type="primary" :loading="saving" @click="savePurchase">保存</a-button>
    </template>
    <a-form ref="purchaseRef" name="purchaseForm" :model="purchaseForm" :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }" autocomplete="off">
      <a-row>
        <a-col :span="24">
          <a-card title="采购单信息" size="small">
            <a-form-item label="采购平台" name="platform" :rules="[{ required: true, message: '选择采购平台' }]">
              <a-select v-model:value="purchaseForm.platform" :options="platforms" :disabled="saving" show-search
                placeholder="选择采购平台" style="width:100%;" />
            </a-form-item>
            <a-form-item label="采购入库仓" name="warehouseid" :rules="[{ required: true, message: '选择采购入库仓' }]">
              <a-select v-model:value="purchaseForm.warehouseid" :options="warehouses" :disabled="saving" show-search
                placeholder="选择采购入库仓" style="width:100%;" />
            </a-form-item>
            <a-form-item label="供应商" name="product_supplier_id" :rules="[{ required: true, message: '选择供应商' }]">
              <a-select v-model:value="purchaseForm.product_supplier_id"
                :disabled="saving || purchaseForm.items.length > 0" show-search placeholder="搜索供应商" style="width: 100%"
                :default-active-first-option="false" :show-arrow="false" :filter-option="false"
                :not-found-content="fetching ? undefined : null" :options="suppliers" @search="searchSupplier">
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
              </a-select>
            </a-form-item>
            <!-- <a-form-item label="物流公司" name="express_supplier">
              <a-select v-model:value="purchaseForm.express_supplier" :options="express_suppliers" :disabled="saving"
                show-search placeholder="物流公司" style="width:100%;" />
            </a-form-item>
            <a-form-item label="物流单号" name="express_no">
              <a-input v-model:value="purchaseForm.express_no" placeholder="物流单号" :disabled="saving" />
            </a-form-item>
            <a-form-item label="物流费" name="express_fee">
              <a-input v-model:value="purchaseForm.express_fee" placeholder="物流费" :disabled="saving" />
            </a-form-item> -->
          </a-card>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-card size="small">
            <template #title>
              <div class="d-flex justify-space-between align-center">
                <span>商品信息</span>
                <div>
                  <a href="javascript:;" @click="findsku">
                    <PlusCircleOutlined />
                    <span class="underline ml-1">添加商品</span>
                  </a>
                </div>
              </div>
            </template>
            <!-- <a-row :gutter="16">
              <a-col :span="6">
                <a-form-item label="库存SKU">
                  <a-input v-model:value="purchaseItem.sku" placeholder="库存SKU" :disabled="saving" />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="数量">
                  <a-input v-model:value="purchaseItem.quantity" type="number" :disabled="saving" placeholder="数量" />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="采购价">
                  <a-input v-model:value="purchaseItem.price" type="number" :disabled="saving" placeholder="单价"
                    suffix="RMB" />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-button type="primary" :loading="skuloading" :disabled="saving" @click="addSku">
                  添加
                </a-button>
              </a-col>
            </a-row> -->
            <a-table :columns="purchaseitemcolumns" :data-source="purchaseForm.items" bordered>
              <template #bodyCell="{ record, column, index }">
                <template v-if="column.dataIndex === 'cover'">
                  <a-tooltip v-if="record.cover" color="white" placement="rightBottom">
                    <template #title>
                      <a-image :src="record.cover" :width="300" :height="300" style="object-fit: contain;height:100%;"
                        :preview="false" />
                    </template>
                    <a href="javascript:;">
                      <a-image :src="record.cover" :width="50" :height="50" style="object-fit: contain;height:100%;"
                        :preview="false" />
                    </a>
                  </a-tooltip>
                  <a-image v-else :src="image_placeholder" :width="50" :height="50"
                    style="object-fit: contain;height:100%;" :preview="false" />
                </template>
                <template v-if="column.dataIndex === 'info'">
                  <a-tooltip color="white">
                    <template #title>
                      <a href="javascript:;" @click="doCopy(record.sku)">复制</a>
                    </template>
                    <span class="text-info">{{ record.sku }}</span>
                  </a-tooltip>
                  <p>{{ record.name_cn }}</p>
                </template>
                <template v-if="column.dataIndex === 'product_spec'">
                  <p>{{ record.product_specvalue.join(' ') }}</p>
                </template>
                <template v-if="column.dataIndex === 'quantity'">
                  <a-input v-model:value="record.quantity" type="number" :disabled="saving" placeholder="数量" />
                </template>
                <template v-if="column.dataIndex === 'price'">
                  <a-input v-model:value="record.price" type="number" :disabled="saving" placeholder="单价" />
                </template>
                <template v-if="column.dataIndex === 'operation'">
                  <a href="javascript:;" class="text-error" @click="removeSku(index)">移除</a>
                </template>
              </template>
            </a-table>
          </a-card>
        </a-col>
      </a-row>
    </a-form>
  </a-drawer>
  <a-modal v-model:visible="purchaseModal" title="1688采购单" :maskClosable="false" :closable="!saving"
    @cancel="cancelSavePurchase2">
    <template #footer>
      <div class="d-flex justify-end align-center">
        <a-space>
          <a-button @click="cancelSavePurchase2" :disabled="saving">关闭</a-button>
          <a-button @click="savePurchase2" type="danger" :loading="saving">获取1688订单</a-button>
        </a-space>
      </div>
    </template>
    <a-form ref="purchaseRef2" name="purchaseForm2" :model="purchaseForm2" :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }" autocomplete="off">
      <a-row>
        <a-col :span="24">
          <a-form-item label="入库仓" name="warehouseid" :rules="[{ required: true, message: '选择入库仓' }]">
            <a-select v-model:value="purchaseForm2.warehouseid" :options="warehouses" :disabled="saving" show-search
              placeholder="选择入库仓" style="width:100%;" />
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="1688账号" name="buyerid" :rules="[{ required: true, message: '选择1688账号' }]">
            <a-select v-model:value="purchaseForm2.buyerid" :options="buyerlist" :disabled="saving" show-search
              placeholder="选择1688账号" style="width:100%;" />
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="1688订单" name="orderIds" :rules="[{ required: true, message: '选择收货地址' }]">
            <a-textarea v-model:value="purchaseForm2.orderIds" placeholder="请填写1688订单号，多个单号换行"
              :auto-size="{ minRows: 3, maxRows: 3 }" :disabled="saving" />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="skuModal" title="查找SKU" :maskClosable="false" :closable="!skuloading" width="960px"
    @cancel="() => skuModal = false">
    <template #footer>
      <div class="d-flex justify-end align-center">
        <div>
          <a-checkbox v-model:checked="skuClose">
            添加后直接关闭窗口
          </a-checkbox>
          <a-button @click="addSkus" type="primary" :loading="skuloading">添加</a-button>
          <a-button @click="() => skuModal = false" :disabled="skuloading">关闭</a-button>
        </div>
      </div>
    </template>
    <div class="d-flex align-center mb-2">
      <div style="width:85px;">搜索类型：</div>
      <a-radio-group v-model:value="skuForm.type" size="small">
        <a-radio-button value="sku">库存SKU</a-radio-button>
        <a-radio-button value="name_cn">库存SKU名称</a-radio-button>
        <a-radio-button value="name_en">库存SKU英文名称</a-radio-button>
        <a-radio-button value="vsku">库存虚拟SKU</a-radio-button>
      </a-radio-group>
    </div>
    <div class="d-flex align-center mb-2">
      <div style="width:85px;">搜索内容：</div>
      <a-input-search v-if="skuForm.type === 'sku'" v-model:value="skuForm.text" placeholder="请输入库存SKU编码"
        enter-button="搜索" @search="searchSku" />
      <a-input-search v-if="skuForm.type === 'name_cn'" v-model:value="skuForm.text" placeholder="请输入库存SKU名称"
        enter-button="搜索" @search="searchSku" />
      <a-input-search v-if="skuForm.type === 'name_en'" v-model:value="skuForm.text" placeholder="请输入库存SKU英文名称"
        enter-button="搜索" @search="searchSku" />
      <a-input-search v-if="skuForm.type === 'vsku'" v-model:value="skuForm.text" placeholder="请输入库存虚拟SKU编码"
        enter-button="搜索" @search="searchSku" />
    </div>
    <a-table :columns="skucolumns" bordered :data-source="skudata" :loading="skuloading" :scroll="{ y: 400 }"
      :pagination="{ position: ['bottomLeft'], current: skupage, pageSize: skulimit, total: skucount, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
      @change="(pagination) => { skupage = pagination.current; skulimit = pagination.pageSize; }" row-key="sku"
      :row-selection="{ selectedRowKeys: selectedSkus, onChange: (keys) => { selectedSkus = keys; } }">
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'cover'">
          <a-tooltip v-if="record.cover" color="white" placement="rightBottom">
            <template #title>
              <a-image :src="record.cover" :width="300" :height="300" style="object-fit: contain;height:100%;"
                :preview="false" />
            </template>
            <a href="javascript:;">
              <a-image :src="record.cover" :width="50" :height="50" style="object-fit: contain;height:100%;"
                :preview="false" />
            </a>
          </a-tooltip>
          <a-image v-else :src="image_placeholder" :width="50" :height="50" style="object-fit: contain;height:100%;"
            :preview="false" />
        </template>
        <template v-if="column.dataIndex === 'info'">
          <a-tooltip color="white">
            <template #title>
              <a href="javascript:;" @click="doCopy(record.sku)">复制</a>
            </template>
            <span class="text-info">{{ record.sku }}</span>
          </a-tooltip>
          <p>{{ record.name_cn }}</p>
        </template>
        <template v-if="column.dataIndex === 'product_spec'">
          <p>{{ record.product_specvalue.join(' ') }}</p>
        </template>
        <template v-if="column.dataIndex === 'quantity'">
          <a-input v-model:value="record.quantity" type="number" :disabled="skuloading" placeholder="数量" />
        </template>
        <template v-if="column.dataIndex === 'price'">
          <a-input v-model:value="record.price" type="number" :disabled="skuloading" placeholder="单价" />
        </template>
      </template>
    </a-table>
  </a-modal>
  <a-modal v-model:visible="buyerModal" title="1688采购下单" :maskClosable="false" :closable="!creating" width="800px"
    @cancel="() => buyerModal = false">
    <template #footer>
      <div class="d-flex justify-space-between align-center">
        <div>
          <p v-if="preview_result[0]">
            预估金额：<span class="text-h6 text-error">{{ preview_result[0].amount }}</span>
          </p>
        </div>
        <a-space>
          <a-button @click="createOrderPreview" type="danger" :disabled="!buyerForm.buyerid || !buyerForm.addressid"
            :loading="previewing">检查优惠</a-button>
          <a-button @click="createOrderConfirm" type="primary" :loading="creating">提交</a-button>
          <a-button @click="() => buyerModal = false" :disabled="creating">关闭</a-button>
        </a-space>
      </div>
    </template>
    <a-form ref="buyerRef" name="buyerForm" :model="buyerForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }"
      autocomplete="off">
      <a-row>
        <a-col :span="24">
          <a-form-item label="1688账号" name="buyerid" :rules="[{ required: true, message: '选择1688账号' }]">
            <a-select v-model:value="buyerForm.buyerid" :options="buyerlist" :disabled="creating" show-search
              placeholder="选择1688账号" style="width:100%;" @change="changebuyer" />
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="收货地址" name="addressid" :rules="[{ required: true, message: '选择收货地址' }]">
            <a-select v-model:value="buyerForm.addressid" :options="buyeraddresslist" :disabled="creating" show-search
              placeholder="选择收货地址" style="width:100%;" />
          </a-form-item>
        </a-col>
        <template v-if="preview_result.length > 0">
          <a-col :span="12">
            <a-form-item label="交易方式" name="tradeType" :rules="[{ required: true, message: '选择交易方式' }]">
              <a-select v-model:value="buyerForm.tradeType" :disabled="creating" show-search placeholder="选择交易方式"
                style="width:100%;">
                <a-select-option v-for="item in preview_result[0].tradeModelList" :key="item.tradeType"
                  :value="item.tradeType">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="优惠券" name="shopPromotion"
              :rules="preview_result[0].shopPromotionList.length > 0 ? [{ required: true, message: '选择优惠券' }] : []">
              <a-select v-model:value="buyerForm.shopPromotionId" :disabled="creating" show-search placeholder="选择优惠券"
                style="width:100%;">
                <a-select-option v-for="item in preview_result[0].shopPromotionList" :key="item.promotionId"
                  :value="item.promotionId">
                  {{ item.text }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </template>
      </a-row>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="importModal" title="导入采购单" :maskClosable="false" :closable="!uploading" width="500px"
    @cancel="cancelPurchaseImport">
    <template #footer>
      <div class="d-flex justify-space-between align-center">
        <a :href="`${publicPath}template/采购单上传模板.xlsx`" target="_blank">
          采购单上传模板.xlsx
        </a>
        <a-space>
          <a-button @click="cancelPurchaseImport" :disabled="uploading">关闭</a-button>
          <a-button @click="savePurchaseImport" type="primary" :loading="uploading">上传</a-button>
        </a-space>
      </div>
    </template>
    <div class="d-flex align-center mb-2" style="justify-content:center;">
      <a-upload :before-upload="beforeUpload" :disabled="uploading" :showUploadList="false">
        <a-button type="danger" :disabled="uploading">
          <UploadOutlined />
          选择文件
        </a-button>
      </a-upload>
    </div>
  </a-modal>
  <a-modal v-model:visible="payModal" :maskClosable="false" closable centered width="400px">
    <template #title>
      <question-circle-outlined style="color:#FFA505;font-size:1.3rem;" />
      <span class="text-h6 ml-2">支付</span>
    </template>
    <div class="pl-6">
      <p class="font-weight-bold text-h7">请在新打开的页面进行支付。</p>
      <p class="text-gray text-h9">支付完成后，根据您的情况点击下面按钮。</p>
    </div>
    <template #footer>
      <a-space>
        <a-button class="btn-orange" @click="refreshPayResult('success')">支付成功</a-button>
        <a-button class="text-gray" @click="refreshPayResult('fail')">支付失败</a-button>
      </a-space>
    </template>
  </a-modal>
  <a-drawer title="物流轨迹" width="50%" placement="right" :visible="logisticsDrawer" @close="() => logisticsDrawer = false">
    <a-list item-layout="horizontal" :data-source="logisticsSteps">
      <template #renderItem="{ item }">
        <a-list-item>
          <a-list-item-meta :description="item.acceptTime">
            <template #title>
              {{ item.remark }}
            </template>
          </a-list-item-meta>
        </a-list-item>
      </template>
    </a-list>
  </a-drawer>
</template>
<script>
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { DownOutlined, SearchOutlined, PlusCircleOutlined, MinusCircleOutlined, UploadOutlined, DownloadOutlined, EditOutlined, CopyOutlined, LinkOutlined, PlusOutlined, MinusOutlined, ReloadOutlined, QuestionCircleOutlined, EyeOutlined } from '@ant-design/icons-vue';
import { message } from "ant-design-vue";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onActivated,
  getCurrentInstance,
} from "vue";
import _ from "underscore";

const columns = [
  { title: '采购单号/下单时间', dataIndex: 'number_createtime', width: 150, },
  { title: '仓库/供应商', dataIndex: 'warehouse_supplier', width: 200, },
  { title: '总数量/总金额', dataIndex: 'quantity_amount', width: 100, },
  { title: '订单信息', dataIndex: 'orderinfo', width: 220, },
  { title: '物流信息', dataIndex: 'express_list', width: 220, },
  { title: '采购时间/采购人', dataIndex: 'purchase_time_by', width: 150, },
  { title: '状态', dataIndex: 'state', width: 120, },
  // { title: '备注', dataIndex: 'purchase_mark', width: 100, },
  { title: '操作', dataIndex: 'operation', width: 120, fixed: 'right', },
];
const expandcolumns = [
  { title: '序号', dataIndex: 'index', width: 60, },
  { title: '图片', dataIndex: 'cover', width: 150, },
  { title: '商品信息', dataIndex: 'info', width: 150, },
  { title: '商品属性', dataIndex: 'product_specvalue', width: 120, },
  { title: '数量', dataIndex: 'purchase_quantity', width: 100, },
  { title: '采购价', dataIndex: 'purchase_price', width: 100, },
  { title: '操作', dataIndex: 'operation', width: 100, },
];
const expresscolumns = [
  { title: '物流公司', dataIndex: 'express_supplier', width: 100, },
  { title: '签收状态', dataIndex: 'sign_state', width: 80, },
  { title: '物流单号', dataIndex: 'express_no', width: 150, },
  { title: '物流费', dataIndex: 'express_fee', width: 120, },
]
const purchaseitemcolumns = [
  { title: '图片', dataIndex: 'cover', width: 80, },
  { title: '商品名称', dataIndex: 'info', width: 120, },
  { title: '商品属性', dataIndex: 'product_spec', width: 120, },
  { title: '采购数量', dataIndex: 'quantity', width: 100, },
  { title: '采购价', dataIndex: 'price', width: 100, },
  { title: '操作', dataIndex: 'operation', width: 100, },
];
const skucolumns = [
  { title: '图片', dataIndex: 'cover', width: 80 },
  { title: '商品信息', dataIndex: 'info', width: 120, },
  { title: '商品属性', dataIndex: 'product_spec', width: 120, },
  { title: "采购数量", dataIndex: "quantity", width: 100 },
  { title: "采购价", dataIndex: "price", width: 100 },
];
export default defineComponent({
  name: 'PurchaseManageOrder',
  components: {
    DownOutlined,
    SearchOutlined,
    PlusCircleOutlined,
    MinusCircleOutlined,
    UploadOutlined,
    DownloadOutlined,
    EditOutlined,
    CopyOutlined,
    LinkOutlined, PlusOutlined, MinusOutlined, ReloadOutlined, QuestionCircleOutlined, EyeOutlined
  },
  setup() {
    let timeout;
    let currentValue = '';
    const { proxy } = getCurrentInstance();
    const purchaseRef = ref();
    const purchaseRef2 = ref();
    const buyerRef = ref();
    const publicPath = reactive(process.env.BASE_URL);
    const state = reactive({
      daterange: ["", ""],
      searchText: "",
      dataLoading: false,
      saving: false,
      page: 1,
      limit: 10,
      length: 0,
      count: 0,
      data: [],
      selectedRowKeys: [],
      expandedRowKeys: [],
      expressForm: {},
      expressModal: false,
      expressSaving: false,
      express_suppliers: [],
      expressForm2: {},
      expressModal2: false,
      uploading: false,
      platforms: [],
      warehouses: [],
      suppliers: [],
      purchaseForm: {},
      purchaseDrawer: false,
      purchaseForm2: {},
      purchaseModal: false,
      importModal: false,
      fetching: false,
      initing: false,
      purchaseItem: {
        sku: "", quantity: "", price: "",
      },
      skuModal: false,
      skuForm: { type: "sku", text: "", },
      skupage: 1,
      skulimit: 100,
      skudata: [],
      skulength: 0,
      skucount: 0,
      skuloading: false,
      selectedSkus: [],
      skuClose: true,
      buyerlist: [],
      addresslist: [],
      buyeraddresslist: [],
      buyerModal: false,
      buyerForm: {},
      previewing: false,
      preview_result: [],
      creating: false,
      payModal: false,
      payForm: {},
      logisticsSteps: [],
      logisticsDrawer: false,
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    watch(() => state.page, () => {
      getData();
    });
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    watch(() => state.skupage, () => {
      searchSku();
    });
    watch(() => state.skulimit, () => {
      if (state.skulimit !== 1) state.skulimit = 1;
      else searchSku();
    });
    onActivated(() => {
      // console.log("onActivated");
      getData();
    });
    const getData = () => {
      state.expandedRowKeys = [];
      state.dataLoading = true;
      proxy.$api.doAPI("/purchaseorder/list", { page: state.page, limit: state.limit, daterange: state.daterange, searchText: state.searchText, }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.express_suppliers = res.data.express_suppliers;
          state.data = _.map(res.data.list, n => {
            return {
              ...n,
              create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
              purchase_time: n.purchase_time ? proxy.$utils.dateFormat(n.purchase_time, 'YYYY-MM-DD HH:mm:ss') : "",
            }
          });
          state.length = res.data.length;
          state.count = res.data.count;
          if (state.length > 0 && state.page > state.length) state.page = 1;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const addExpress = (item) => {
      let expressForm = {
        _id: item._id,
        number: item.number,
        express_list: _.map(item.express_list, n => _.extend({}, { ...n })),
      }
      if (expressForm.express_list.length === 0) {
        expressForm.express_list.push({
          key: proxy.$utils.uuid(),
          express_supplier: "",
          express_no: "",
          express_fee: item.postFee || "",
          sign_state: false,
        });
      } else {
        expressForm.express_list = _.map(expressForm.express_list, n => {
          return {
            key: proxy.$utils.uuid(),
            express_supplier: n.express_supplier,
            express_no: n.express_no,
            express_fee: n.express_fee,
            sign_state: n.sign_state,
          }
        });
      }
      state.expressForm = expressForm;
      state.expressModal = true;
    }
    const showExpress = (item) => {
      let expressForm2 = {
        _id: item._id,
        number: item.number,
        express_list: _.map(item.express_list, n => _.extend({}, { ...n })),
      }
      if (expressForm2.express_list.length > 0) {
        expressForm2.express_list = _.map(expressForm2.express_list, n => {
          return {
            key: proxy.$utils.uuid(),
            express_supplier: n.express_supplier,
            express_no: n.express_no,
            express_fee: n.express_fee,
            sign_state: n.sign_state,
          }
        });
      }
      state.expressForm2 = expressForm2;
      state.expressModal2 = true;
    }
    const pushExpress = () => {
      state.expressForm.express_list.push({
        key: proxy.$utils.uuid(),
        express_supplier: "",
        express_no: "",
        express_fee: "",
        sign_state: false,
      });
    }
    const popExpress = (index) => {
      state.expressForm.express_list.splice(index, 1);
    }
    const saveExpress = () => {
      if (state.expressForm.express_list.length === 0) return message.error('物流信息不能为空');
      for (let i = 0; i < state.expressForm.express_list.length; i++) {
        let { express_supplier, express_no, express_fee } = state.expressForm.express_list[i];
        if (!express_supplier) return message.error('物流公司不能为空');
        if (!express_no) return message.error('物流单号不能为空');
      }
      state.expressSaving = true;
      proxy.$api.doAPI("/purchaseorder/saveexpress", { expressForm: state.expressForm, }).then((res) => {
        state.expressSaving = false;
        if (res.code === 0) {
          message.success("保存成功");
          state.expressModal = false;
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.expressSaving = false;
        console.error(err);
      });
    }
    const cancelSaveExpress = () => {
      console.log('cancelSaveExpress');

    }
    const showLogisticsTrackInfo = (expressid) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/purchaseorder/trackinfo", { expressid }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.logisticsSteps = res.data.logisticsSteps.reverse();
          state.logisticsDrawer = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const handleAddOrder = ({ key }) => {
      console.log('handleAddOrder', key);
      switch (key) {
        case "1": //添加采购单
          addOrder();
          break;
        case "2": //1688采购单
          add1688Order();
          break;
      }
    }
    const addOrder = () => {
      state.initing = true;
      proxy.$api.doAPI("/purchaseorder/init").then((res) => {
        state.initing = false;
        if (res.code === 0) {
          state.purchaseForm = res.data.purchaseForm;
          state.platforms = res.data.platforms;
          state.warehouses = res.data.warehouses;
          state.suppliers = [];
          state.purchaseItem = {
            sku: "", quantity: "", price: "",
          };
          state.purchaseDrawer = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.initing = false;
        console.error(err);
      });
    }
    const add1688Order = () => {
      state.initing = true;
      proxy.$api.doAPI("/purchaseorder/init2").then((res) => {
        state.initing = false;
        if (res.code === 0) {
          state.purchaseForm2 = res.data.purchaseForm;
          state.warehouses = res.data.warehouses;
          state.buyerlist = res.data.buyerlist;
          state.purchaseModal = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.initing = false;
        console.error(err);
      });
    }
    const editOrder = (item) => {
      state.initing = true;
      proxy.$api.doAPI("/purchaseorder/init", { _id: item._id }).then((res) => {
        state.initing = false;
        if (res.code === 0) {
          state.purchaseForm = res.data.purchaseForm;
          state.platforms = res.data.platforms;
          state.warehouses = res.data.warehouses;
          state.suppliers = res.data.suppliers;
          state.purchaseItem = {
            sku: "", quantity: "", price: "",
          };
          state.purchaseDrawer = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.initing = false;
        console.error(err);
      });
    }
    //新增采购单-保存
    const savePurchase = async () => {
      try {
        await purchaseRef.value.validateFields();
        state.saving = true;
        proxy.$api.doAPI("/purchaseorder/save", { purchaseForm: state.purchaseForm, }).then((res) => {
          state.saving = false;
          if (res.code === 0) {
            message.success("提交成功");
            state.purchaseDrawer = false;
            getData();
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.saving = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    };
    const cancelSavePurchase = () => {
      console.log('cancelSavePurchase');
      purchaseRef.value.clearValidate();
      state.purchaseDrawer = false;
    }
    const savePurchase2 = async () => {
      try {
        await purchaseRef2.value.validateFields();
        state.saving = true;
        proxy.$api.doAPI("/purchaseorder/save2", { purchaseForm: state.purchaseForm2, }).then((res) => {
          state.saving = false;
          if (res.code === 0) {
            message.success("提交成功");
            state.purchaseModal = false;
            getData();
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.saving = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    };
    const cancelSavePurchase2 = () => {
      console.log('cancelSavePurchase2');
      purchaseRef2.value.clearValidate();
      state.purchaseModal = false;
    }
    const addSku = () => {
      if (!state.purchaseItem.sku) return message.error("库存SKU不能为空");
      if (!state.purchaseItem.quantity) return message.error("数量不能为空");
      if (state.purchaseItem.quantity < 1) return message.error("数量不能小于1");
      if (!state.purchaseItem.price) return message.error("采购价不能为空");
      if (_.findWhere(state.purchaseForm.items, { sku: state.purchaseItem.sku })) return message.error("SKU已存在，请勿重复添加");
      findonesku();
    }
    const findonesku = () => {
      state.skuloading = true;
      proxy.$api.doAPI("/product/findonesku", { sku: state.purchaseItem.sku }).then((res) => {
        state.skuloading = false;
        if (res.code === 0) {
          combineSkus([_.extend({ quantity: state.purchaseItem.quantity, price: res.data.product.purchase_price || "" }, res.data.product)]);
          state.purchaseItem = {
            sku: "", quantity: "", price: "",
          };
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.skuloading = false;
        console.error(err);
      });
    }
    const findsku = () => {
      console.log('findsku');
      if (!state.purchaseForm.product_supplier_id) return message.error("请选择供应商");
      state.skuForm = { type: "sku", text: "", };
      state.skupage = 1;
      state.skucount = 0;
      state.skudata = [];
      state.skuModal = true;
    }
    const searchSku = () => {
      // if (!state.skuForm.text) return message.error("请输入搜索内容");
      state.selectedSkus = [];
      state.skuloading = true;
      proxy.$api.doAPI("/product/searchsku", { page: state.skupage, limit: state.skulimit, skuForm: state.skuForm, product_supplier_id: state.purchaseForm.product_supplier_id, }).then((res) => {
        state.skuloading = false;
        if (res.code === 0) {
          state.skudata = _.map(res.data.list, n => ({ ...n, quantity: 1, price: n.purchase_price || "" }));
          state.skulength = res.data.length;
          state.skucount = res.data.count;
          if (state.skulength > 0 && state.skupage > state.skulength) state.skupage = 1;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.skuloading = false;
        console.error(err);
      });
    }
    const addSkus = () => {
      if (state.selectedSkus.length === 0) return message.error("请选择要添加的数据");
      let skus = _.filter(state.skudata, n => state.selectedSkus.indexOf(n.sku) > -1);
      console.log(skus);
      for (let i = 0; i < skus.length; i++) {
        let { sku, quantity, price } = skus[i];
        if (!quantity) return message.error(`${sku}采购数量不能为空`);
        if (quantity < 1) return message.error(`${sku}采购数量不能小于1`);
        // if (!price) return message.error(`${sku}采购价不能为空`);
      }
      combineSkus(skus);
      state.selectedSkus = [];
      if (state.skuClose) state.skuModal = false;
    }
    const combineSkus = (skus) => {
      let items = [], combine_skus = {};
      for (let i = 0; i < state.purchaseForm.items.length; i++) {
        let { sku } = state.purchaseForm.items[i];
        if (!combine_skus[sku]) {
          items.push(_.extend({}, state.purchaseForm.items[i]));
          combine_skus[sku] = true;
        }
      }
      for (let i = 0; i < skus.length; i++) {
        let { sku, quantity, price, product_specid, product_specvalue } = skus[i];
        if (!combine_skus[sku]) {
          items.push(_.extend({ quantity, price, product_specid, product_specvalue }, skus[i]));
          combine_skus[sku] = true;
        }
      }
      state.purchaseForm.items = items;
    }
    const removeSku = (index) => {
      state.purchaseForm.items.splice(index, 1);
    }
    const createOrderCheck = (_id) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/purchaseorder/create_check", { _id }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.buyerlist = res.data.buyerlist;
          state.addresslist = res.data.addresslist;
          state.buyeraddresslist = [];
          state.buyerForm = {
            purchaseorderid: _id,
            buyerid: undefined,
            addressid: undefined,
          };
          state.preview_result = [];
          state.buyerModal = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const changebuyer = (buyerid) => {
      console.log('changebuyer', buyerid);
      let buyeraddresslist = _.where(state.addresslist, { buyerid });
      state.buyerForm.addressid = undefined;
      state.buyeraddresslist = _.map(buyeraddresslist, n => _.extend({}, { ...n }));
    }
    const createOrderPreview = () => {
      state.previewing = true;
      proxy.$api.doAPI("/purchaseorder/create_preview", { buyerForm: state.buyerForm, }).then((res) => {
        state.previewing = false;
        if (res.code === 0) {
          state.preview_result = res.data.preview_result;
          if (res.data.preview_result.length === 0) return message.info("无优惠");
          state.buyerForm.flow = res.data.preview_result[0].flow;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.previewing = false;
        console.error(err);
      });
    }
    const createOrderConfirm = async () => {
      try {
        await buyerRef.value.validateFields();
        state.creating = true;
        proxy.$api.doAPI("/purchaseorder/create_confirm", { buyerForm: state.buyerForm, }).then((res) => {
          state.creating = false;
          if (res.code === 0) {
            message.success("提交成功");
            state.buyerModal = false;
            getData();
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.creating = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    };
    const refreshOrder = (_id) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/purchaseorder/refresh", { _id }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const cancelOrder = (_id) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/purchaseorder/cancel", { _id }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const removeOrder = (_id) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/purchaseorder/remove", { _id }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const requestPayOrder = (_id) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/purchaseorder/requestpay", { _id }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const payOrder = (_id) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/purchaseorder/pay", { _id }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.payForm = {
            purchaseorderid: _id,
            payUrl: res.data.payUrl,
          };
          proxy.$utils.downloadBrowser({ href: res.data.payUrl });
          state.payModal = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const refreshPayResult = () => {
      state.payModal = false;
      refreshOrder(state.payForm.purchaseorderid);
    }
    const finishOrder = (_id) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/purchaseorder/finish", { _id }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    ///采购单上传-保存
    const showPurchaseImport = () => {
      state.importModal = true;
    }
    ///采购单上传-保存
    const savePurchaseImport = () => {

    }
    ///采购单上传-取消
    const cancelPurchaseImport = () => {
      state.importModal = false;
    }
    const FetchSupplier = (value, callback) => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      currentValue = value;
      function search() {
        state.fetching = true;
        proxy.$api.doAPI("/productsupplier/search", { value, platform: state.purchaseForm.platform }).then((res) => {
          if (currentValue === value) {
            state.fetching = false;
            callback(res.data.suppliers);
          }
        }).catch((err) => {
          state.fetching = false;
          callback([]);
          console.error(err);
        });
      }
      timeout = setTimeout(search, 300);
    }
    const searchSupplier = val => {
      console.log('searchSupplier', val);
      FetchSupplier(val, suppliers => state.suppliers = suppliers);
    };
    return {
      ...toRefs(state),
      publicPath,
      purchaseRef,
      purchaseRef2,
      buyerRef,
      locale,
      columns,
      expandcolumns,
      expresscolumns,
      purchaseitemcolumns,
      skucolumns,
      width,
      getData,
      addExpress,
      showExpress,
      pushExpress,
      popExpress,
      saveExpress,
      cancelSaveExpress,
      showLogisticsTrackInfo,
      handleAddOrder,
      editOrder,
      savePurchase,
      savePurchase2,
      cancelSavePurchase,
      cancelSavePurchase2,
      createOrderCheck,
      changebuyer,
      createOrderPreview,
      createOrderConfirm,
      refreshOrder,
      cancelOrder,
      removeOrder,
      requestPayOrder,
      payOrder,
      refreshPayResult,
      finishOrder,
      doCopy: proxy.$utils.doCopy,
      image_placeholder: proxy.$constant.image_placeholder,
      addSku,
      findsku,
      searchSku,
      addSkus,
      removeSku,
      showPurchaseImport,
      savePurchaseImport,
      cancelPurchaseImport,
      searchSupplier,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>