<template>
  <a-spin :spinning="loading">
    <div>
      <div class="mainform">
        <a-form ref="listingformRef" name="listingForm" :model="listingForm" autocomplete="off">
          <a-card title="产品信息">
            <div class="form-item">
              <div class="label">
                <span class="cn">来源URL</span>
              </div>
              <a-form-item name="url" style="width:100%;">
                <a-input v-model:value="listingForm.url" placeholder="用于记录供货渠道URL，方便采购。" disabled style="width:600px;" />
                <a-button>访问链接</a-button>
              </a-form-item>
            </div>
            <div class="form-item">
              <div class="label ">
                <span class="cn">SKU</span>
              </div>
              <a-form-item name="item_sku" style="width:80%;max-width:400px;">
                <a-input v-model:value="listingForm.item_sku" />
              </a-form-item>
            </div>
            <div class="form-item">
              <div class="label ">
                <span class="cn">产品标题</span>
              </div>
              <a-form-item name="item_name" style="width:80%;min-width:600px;">
                <a-input v-model:value="listingForm.item_name" placeholder="请输入产品标题" show-count :maxlength="200" />
              </a-form-item>
            </div>
          </a-card>
          <a-card title="产品图片">
            <div class="form-item">
              <div class="label ">
                <span class="cn">产品图片</span>
              </div>
              <a-form-item style="width:80%;min-width:600px;">
                <div class="d-flex justify-space-between align-center" style="width:100%;">

                </div>
                <a-row :gutter="16" style="overflow-y:auto;">
                  <a-col :span="2" v-for="(image, index) in listingForm.images" :key="image">
                    <div style="width:105px;height:125px;border:1px solid #e8e8e8;">
                      <div>
                        <a-image width="105px" height="105px" :src="image" style="object-fit: contain;height:100%;">
                          <template #placeholder>
                            <a-image :src="image_placeholder" width="105px" height="105px" :preview="false" />
                          </template>
                        </a-image>
                        <div style="width:105px;height:20px;" class="d-flex justify-end">
                          <a-tooltip>
                            <template #title>删除图片</template>
                            <DeleteOutlined @click="removeImage({ index })" style="font-size:20px;color:#ff4d4f;" />
                          </a-tooltip>
                        </div>
                      </div>
                      <svg-icon v-if="index === 0" iconClass="zhutu"
                        :style="{ 'position': 'absolute', 'top': 0, 'left': 0, 'height': '17px', 'width': '40px' }" />
                    </div>
                  </a-col>
                  <a-col :span="2" v-if="listingForm.images?.length < MAX_IMAGE_COUNT">
                    <a-dropdown placement="right">
                      <div class="d-flex flex-column justify-center align-center click"
                        style="width:105px;height:125px;border:1px solid #e8e8e8;">
                        <svg-icon iconClass="tianjia" :style="{ 'width': '24px', 'height': '24px' }" />
                        <div class="text-gray mt-2">选择图片</div>
                      </div>
                      <template #overlay>
                        <ImagePicker :max_count="MAX_IMAGE_COUNT" :images="listingForm.images"
                          @local-upload-success="(data) => onLocalUploadSuccess({ data })"
                          @network-input-success="(data) => onNetworkInputSuccess({ data })"
                          @space-pick-success="(data) => onSpacePickSuccess({ data })" />
                      </template>
                    </a-dropdown>
                  </a-col>
                </a-row>
              </a-form-item>
            </div>
          </a-card>
          <a-card title="描述信息">
            <div class="form-item">
              <div class="label">
                <span class="cn">简易描述</span>
              </div>
              <div class="d-flex flex-column" style="width:80%;min-width:600px;">
                <p class="text-info">
                  简易描述认领后填充在Wish、亚马逊、Shopee、Joom的产品描述部分，不支持字体、颜色、图片等编辑。
                </p>
                <a-form-item>
                  <a-textarea v-model:value="listingForm.bullet_points" :auto-size="{ minRows: 10, maxRows: 10 }" />
                </a-form-item>
              </div>
            </div>
            <div class="form-item">
              <div class="label">
                <span class="cn ">详细描述</span>
              </div>
              <div class="d-flex flex-column" style="width:80%;min-width:600px;">
                <p class="text-info">
                  详细描述认领后填充在速卖通、eBay、Lazada、Shopify的产品描述部分，一般包含产品功能属性、产品细节图片、售后服务、公司实力等内容。
                </p>
                <a-form-item>
                  <div style="border: 1px solid #ccc">
                    <Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef" :defaultConfig="toolbarConfig"
                      :mode="editorMode" />
                    <Editor style="height: 500px; overflow-y: hidden;" v-model="listingForm.product_description"
                      :defaultConfig="editorConfig" :mode="editorMode" @onCreated="handleCreated" />
                  </div>
                </a-form-item>
              </div>
            </div>
          </a-card>
        </a-form>
      </div>
      <div class="footer pr-4">
        <a-button class="btn-warning" @click="doSave" :loading="saving">
          <span>保 存</span>
        </a-button>
      </div>
    </div>
  </a-spin>
</template>
<script>
import { InfoCircleFilled, DownOutlined, HomeOutlined, DeleteOutlined, EditOutlined, SaveOutlined, RollbackOutlined, } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, shallowRef, reactive, toRefs, watch, computed, onMounted, onBeforeUnmount, onActivated, getCurrentInstance, toRaw, } from "vue";
import { useRoute, useRouter } from "vue-router";
import _ from "underscore";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import ImagePicker from '@/components/ImagePicker.vue';
const MAX_IMAGE_COUNT = 9;
import '@wangeditor/editor/dist/css/style.css'
import { DomEditor } from '@wangeditor/editor'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default defineComponent({
  name: 'GatherForm',
  components: {
    InfoCircleFilled, DownOutlined, HomeOutlined, DeleteOutlined, EditOutlined, SaveOutlined, RollbackOutlined, ImagePicker, Editor, Toolbar,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const listingformRef = ref();
    const editorRef = shallowRef();
    const toolbarConfig = {
      toolbarKeys: ["bold", "italic", "bulletedList", "numberedList", "undo", "redo", "fullScreen"]
    };
    const editorConfig = {

    };
    const state = reactive({
      loading: false,
      saving: false,
      listingForm: {},
    });
    onActivated(() => {
      getData();
    });
    const getData = () => {
      console.log('getData');
      state.loading = true;
      state.loading = true;
      proxy.$api.doAPI("/listingcollect/info", { _id: route.query.id }).then((res) => {
        state.loading = false;
        if (res.code === 0) {
          state.listingForm = res.data.listingForm;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.loading = false;
        console.error(err);
      });
    }
    const onLocalUploadSuccess = ({ data }) => {
      console.log('onLocalUploadSuccess', data);
      let images = toRaw(state.listingForm.images);
      images.push(data.url);
      state.listingForm.images = _.uniq(images);
    }
    const onNetworkInputSuccess = ({ data }) => {
      console.log('onNetworkInputSuccess', data);
      let images = toRaw(state.listingForm.images);
      images.push(...data.images);
      state.listingForm.images = _.uniq(images);
    }
    const onSpacePickSuccess = ({ data }) => {
      console.log('onSpacePickSuccess', data);
      let images = toRaw(state.listingForm.images);
      images.push(...data.images);
      state.listingForm.images = _.uniq(images);
    }
    const removeImage = ({ index }) => {
      state.listingForm.images.splice(index, 1);
    }
    const handleCreated = (editor) => {
      editorRef.value = editor // 记录 editor 实例，重要！
    }
    const doSave = async () => {
      try {
        state.saving = true;
        await listingformRef.value.validateFields();
        proxy.$api.doAPI("/listingcollect/save", { listingForm: state.listingForm }).then((res) => {
          state.saving = false;
          if (res.code === 0) {
            Modal.success({
              title: "提示",
              content: "保存成功",
              centered: true,
              okText: "关闭",
              okType: "default",
              onOk() {
                window.close();
              },
            });
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.saving = false;
          console.error(err);
        });
      } catch (errorInfo) {
        state.saving = false;
        console.log("Failed:", errorInfo);
        message.error(_.reduce(errorInfo.errorFields, (a, b) => a.concat(b.errors), []).join('；'));
      }
    }
    return {
      ...toRefs(state),
      locale,
      MAX_IMAGE_COUNT,
      listingformRef,
      editorRef,
      editorMode: 'default',
      toolbarConfig,
      editorConfig,
      onLocalUploadSuccess,
      onNetworkInputSuccess,
      onSpacePickSuccess,
      removeImage,
      handleCreated,
      doSave,
      image_placeholder: proxy.$constant.image_placeholder,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-form-item-extra) {
  min-height: 0;
}

p {
  margin: 0;
  padding: 0;
}

.form-item {
  display: flex;
}

.label {
  min-width: 220px;
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 3px;
  padding-right: 10px;
  text-align: right;
}

.label>span:first-child::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin-left: 2px;
}

.label>.en {
  word-break: break-word;
}

.required>span:first-child::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.mainform {
  overflow: auto;
  height: 100vh;
  padding-bottom: 80px;
}

.footer {
  box-shadow: 0 0 6px 0 #d9d9d9;
  height: 64px !important;
  line-height: 64px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  text-align: right;
  z-index: 9;
  background-color: #fff;
  border-radius: 2px;
}
</style>