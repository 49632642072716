<template>
  <a-tabs v-model:activeKey="activeKey" type="card">
    <a-tab-pane key="1" tab="MSKU">
      <div class="header">
        <a-form :model="formState" name="form_query" layout="inline" autocomplete="off">
          <a-form-item>
            <a-dropdown>
              <template #overlay>
                <a-menu @click="handleMenuClick">
                  <a-menu-item key="1">店长1111111</a-menu-item>
                  <a-menu-item key="2">店长2222222</a-menu-item>
                </a-menu>
              </template>
              <a-button>
                全部店铺
                <DownOutlined />
              </a-button>
            </a-dropdown>
          </a-form-item>
          <a-form-item>
            <a-dropdown>
              <template #overlay>
                <a-menu @click="handleMenuClick">
                  <a-menu-item key="1">店长1111111</a-menu-item>
                  <a-menu-item key="2">店长2222222</a-menu-item>
                </a-menu>
              </template>
              <a-button>
                全部店长
                <DownOutlined />
              </a-button>
            </a-dropdown>
          </a-form-item>
          <a-form-item>
            <a-dropdown>
              <template #overlay>
                <a-menu @click="handleMenuClick">
                  <a-menu-item key="1">MSKU</a-menu-item>
                  <a-menu-item key="2">ASIN</a-menu-item>
                  <a-menu-item key="3">SKU</a-menu-item>
                </a-menu>
              </template>
              <a-button>
                MSKU
                <DownOutlined />
              </a-button>
            </a-dropdown>
          </a-form-item>
          <a-form-item>
            <a-range-picker v-model:value="daterange" value-format="YYYY-MM-DD" />
          </a-form-item>
          <a-form-item>
            <a-input v-model:value="sku" placeholder="输入msku/asin/sku查询"></a-input>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit">查询</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="content">
        <a-table :columns="columns" :data-source="data" bordered :scroll="{ x: width }">
          <template #bodyCell="{ column, text }">
            <template v-if="column.dataIndex === 'ordercount'">
              <a>{{ text }}</a>
            </template>
            <template v-if="column.dataIndex === 'cover'">
              <a-image :src="text" :width="50" :height="50" style="object-fit: contain;height:100%;" />
            </template>
          </template>
          <template #title>
            <div class="theader">
              <span class="title">利润报表</span>
              <a-button type="primary">导出利润报表</a-button>
            </div>
          </template>
          <template #footer>合计</template>
        </a-table>
      </div>
    </a-tab-pane>
    <a-tab-pane key="2" tab="ASIN">
      <div class="header">
        <a-form :model="formState" name="form_query" layout="inline" autocomplete="off">
          <a-form-item>
            <a-select v-model:value="datetype">
              <a-select-option value="paytime">支付时间</a-select-option>
              <a-select-option value="sendtime">发货时间</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-range-picker v-model:value="daterange" value-format="YYYY-MM-DD" />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit">查询</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="content">
        <a-table :columns="columns1" :data-source="data1" bordered :scroll="{ x: width1 }">
          <template #bodyCell="{ column, text }">
            <template v-if="column.dataIndex === 'ordercount'">
              <a>{{ text }}</a>
            </template>
            <template v-if="column.dataIndex === 'operation'">
              <a-space>
                <a href="javascript:;">查看详情</a>
                <a href="javascript:;">收支明细</a>
              </a-space>
            </template>
          </template>
          <template #title>
            <div class="theader">
              <span class="title">订单收支报告</span>
              <a-button type="primary">导出订单收支明细</a-button>
            </div>
          </template>
          <template #footer>合计</template>
        </a-table>
      </div>
    </a-tab-pane>
    <a-tab-pane key="3" tab="SKU">
      <div class="header">
        <a-form :model="formState" name="form_query" layout="inline" autocomplete="off">
          <a-form-item>
            <a-select v-model:value="datetype">
              <a-select-option value="paytime">支付时间</a-select-option>
              <a-select-option value="sendtime">发货时间</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-range-picker v-model:value="daterange" value-format="YYYY-MM-DD" />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit">查询</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="content">
        <a-table :columns="columns1" :data-source="data1" bordered :scroll="{ x: width1 }">
          <template #bodyCell="{ column, text }">
            <template v-if="column.dataIndex === 'ordercount'">
              <a>{{ text }}</a>
            </template>
            <template v-if="column.dataIndex === 'operation'">
              <a-space>
                <a href="javascript:;">查看详情</a>
                <a href="javascript:;">收支明细</a>
              </a-space>
            </template>
          </template>
          <template #title>
            <div class="theader">
              <span class="title">订单收支报告</span>
              <a-button type="primary">导出订单收支明细</a-button>
            </div>
          </template>
          <template #footer>合计</template>
        </a-table>
      </div>
    </a-tab-pane>
    <a-tab-pane key="4" tab="店铺">
      <div class="header">
        <a-form :model="formState" name="form_query" layout="inline" autocomplete="off">
          <a-form-item>
            <a-select v-model:value="datetype">
              <a-select-option value="paytime">支付时间</a-select-option>
              <a-select-option value="sendtime">发货时间</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-range-picker v-model:value="daterange" value-format="YYYY-MM-DD" />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit">查询</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="content">
        <a-table :columns="columns2" :data-source="data2" bordered :scroll="{ x: width2 }">
          <template #bodyCell="{ column, text }">
            <template v-if="column.dataIndex === 'ordercount'">
              <a>{{ text }}</a>
            </template>
            <template v-if="column.dataIndex === 'operation'">
              <a-space>
                <a href="javascript:;">查看详情</a>
                <a href="javascript:;">收支明细</a>
              </a-space>
            </template>
          </template>
          <template #title>
            <div class="theader">
              <span class="title">订单收支报告</span>
              <a-button type="primary">导出订单收支明细</a-button>
            </div>
          </template>
          <template #footer>合计</template>
        </a-table>
      </div>
    </a-tab-pane>
  </a-tabs>
</template>
<script>
import { DownOutlined } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from 'vue';
import _ from "underscore";
const columns = [
  { title: '图片', dataIndex: 'cover', width: 100, align: 'center' },
  { title: '店铺', dataIndex: 'shop', width: 100, align: 'center' },
  { title: '国家', dataIndex: 'country', width: 100, align: 'center' },
  {
    title: '商品信息',
    children: [
      { title: 'MSKU', dataIndex: 'msku', key: 'msku', width: 100, align: 'center' },
      { title: 'ASIN', dataIndex: 'asin', key: 'asin', width: 100, align: 'center' },
      { title: 'SKU/平名', dataIndex: 'sku', key: 'sku', width: 120, align: 'center' }
    ]
  },
  {
    title: '数量统计',
    children: [
      { title: '销量', dataIndex: 'msku', key: 'msku', width: 100, align: 'center' },
      { title: '退款量', dataIndex: 'asin', key: 'asin', width: 100, align: 'center' },
      { title: '退货量', dataIndex: 'asin', key: 'asin', width: 100, align: 'center' }
    ]
  },
  {
    title: '收入',
    children: [
      { title: '销售额', dataIndex: 'receivables', key: 'receivables', width: 100, align: 'center' },
      { title: '运费', dataIndex: 'freight', key: 'freight', width: 100, align: 'center' },
      { title: '其他收入', dataIndex: 'otherin', key: 'otherin', width: 120, align: 'center' },
      { title: '小计', dataIndex: 'totalin', key: 'totalin', width: 100, align: 'center' }
    ]
  },
  {
    title: '支出',
    children: [
      { title: '成本', dataIndex: 'costofgoods', key: 'costofgoods', width: 100, align: 'center' },
      { title: '平台费', dataIndex: 'platformfee', key: 'platformfee', width: 100, align: 'center' },
      { title: '其他费用', dataIndex: 'otherout', key: 'otherout', width: 120, align: 'center' },
      { title: '小计', dataIndex: 'totalout', key: 'totalout', width: 100, align: 'center' }
    ]
  },
  { title: '毛利润', dataIndex: 'grossprofit', width: 100, align: 'center' },
  { title: '毛利率%', dataIndex: 'grossprofitpersent', width: 100, align: 'center' }
];
const columns1 = [
  { title: '序号', dataIndex: 'index', width: 70, align: 'center' },
  { title: '店铺', dataIndex: 'shop', width: 150, align: 'center' },
  { title: '订单数', dataIndex: 'ordercount', width: 100, align: 'center' },
  { title: '商品数', dataIndex: 'goods', width: 100, align: 'center' },
  { title: '平台SKU数', dataIndex: 'SKU', width: 120, align: 'center' },
  {
    title: '收入',
    children: [
      { title: '应收货款', dataIndex: 'receivables', key: 'receivables', width: 100, align: 'center' },
      { title: '运费', dataIndex: 'freight', key: 'freight', width: 100, align: 'center' },
      { title: '其他收入', dataIndex: 'otherin', key: 'otherin', width: 100, align: 'center' },
      { title: '合计', dataIndex: 'totalin', key: 'totalin', width: 100, align: 'center' }
    ]
  },
  {
    title: '支出',
    children: [
      { title: '商品成本', dataIndex: 'costofgoods', key: 'costofgoods', width: 100, align: 'center' },
      { title: '平台费', dataIndex: 'platformfee', key: 'platformfee', width: 100, align: 'center' },
      { title: 'FBA费用', dataIndex: 'FBAfee', key: 'FBAfee', width: 100, align: 'center' },
      { title: '其他费用', dataIndex: 'otherout', key: 'otherout', width: 100, align: 'center' },
      { title: '合计', dataIndex: 'totalout', key: 'totalout', width: 100, align: 'center' }
    ]
  },
  { title: '退款金额', dataIndex: 'refundamount', width: 100, align: 'center' },
  { title: '退款率%', dataIndex: 'refundpersent', width: 100, align: 'center' },
  { title: '毛利', dataIndex: 'grossprofit', width: 100, align: 'center' },
  { title: '毛利率%', dataIndex: 'grossprofitpersent', width: 100, align: 'center' },
  { title: '操作', dataIndex: 'operation', width: 180, fixed: 'right', align: 'center' },
];
const columns2 = [
  { title: '序号', dataIndex: 'index', width: 70, align: 'center' },
  { title: '国家', dataIndex: 'country', width: 150, align: 'center' },
  { title: '订单数', dataIndex: 'ordercount', width: 100, align: 'center' },
  { title: '商品数', dataIndex: 'goods', width: 100, align: 'center' },
  { title: '平台SKU数', dataIndex: 'SKU', width: 120, align: 'center' },
  {
    title: '收入',
    children: [
      { title: '应收货款', dataIndex: 'receivables', key: 'receivables', width: 100, align: 'center' },
      { title: '运费', dataIndex: 'freight', key: 'freight', width: 100, align: 'center' },
      { title: '其他收入', dataIndex: 'otherin', key: 'otherin', width: 100, align: 'center' },
      { title: '合计', dataIndex: 'totalin', key: 'totalin', width: 100, align: 'center' }
    ]
  },
  {
    title: '支出',
    children: [
      { title: '商品成本', dataIndex: 'costofgoods', key: 'costofgoods', width: 100, align: 'center' },
      { title: '平台费', dataIndex: 'platformfee', key: 'platformfee', width: 100, align: 'center' },
      { title: 'FBA费用', dataIndex: 'FBAfee', key: 'FBAfee', width: 100, align: 'center' },
      { title: '其他费用', dataIndex: 'otherout', key: 'otherout', width: 100, align: 'center' },
      { title: '合计', dataIndex: 'totalout', key: 'totalout', width: 100, align: 'center' }
    ]
  },
  { title: '退款金额', dataIndex: 'refundamount', width: 100, align: 'center' },
  { title: '退款率%', dataIndex: 'refundpersent', width: 100, align: 'center' },
  { title: '毛利', dataIndex: 'grossprofit', width: 100, align: 'center' },
  { title: '毛利率%', dataIndex: 'grossprofitpersent', width: 100, align: 'center' },
  { title: '操作', dataIndex: 'operation', width: 180, fixed: 'right', align: 'center' },
];
const data = [];
for (let i = 0; i < 5; i++) {
  data.push({
    index: i + 1,
    platform: '亚马逊',
    ordercount: '433',
    goods: '685',
    SKU: '466',

    refundamount: '6808.25',
    refundpersent: '9.42',
    grossprofit: '40560.15',
    grossprofitpersent: '56.13',
  });
}
const data1 = [];
for (let i = 0; i < 5; i++) {
  data1.push({
    index: i + 1,
    shop: '侯亚磊-美国',
    ordercount: '433',
    goods: '685',
    SKU: '466',

    refundamount: '6808.25',
    refundpersent: '9.42',
    grossprofit: '40560.15',
    grossprofitpersent: '56.13',
  });
}
const data2 = [];
for (let i = 0; i < 5; i++) {
  data2.push({
    index: i + 1,
    country: '美国（US）',
    ordercount: '433',
    goods: '685',
    SKU: '466',

    refundamount: '6808.25',
    refundpersent: '9.42',
    grossprofit: '40560.15',
    grossprofitpersent: '56.13',
  });
}
export default defineComponent({
  name: 'FinanceProfit',
  components: {
    DownOutlined
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      data,
      data1,
      data2,
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    const width1 = computed(() => proxy.$utils.twidth(columns1));
    const width2 = computed(() => proxy.$utils.twidth(columns2));
    return {
      ...toRefs(state),
      columns,
      columns1,
      columns2,
      width,
      width1,
      width2,
    }
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

.header {
  margin-bottom: 10px;
}

.theader {
  display: flex;
  justify-content: space-between;

  .title {
    font-weight: 600;
  }
}

a {
  text-decoration: underline;
}

p {
  margin: 0;
  padding: 0;
}
</style>