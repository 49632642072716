<template>
  <div class="platform">
    <a-card title="授权平台">
      <a-card-grid v-for="item in platforms" :key="item.value"
        :class="`click ${platform === item.value ? 'pactive' : ''}`" style="width:25%;text-align:center;" hoverable
        @click="() => platform = item.value">
        {{ item.label }}
      </a-card-grid>
    </a-card>
  </div>
  <div class="authorizelist">
    <a-card title="授权店铺">
      <div class="d-flex justify-space-between align-center mb-2">
        <a-button type="primary" @click="getData" :loading="dataLoading">
          刷新数据
        </a-button>
        <a-button type="primary" @click="addAuth" :loading="addAuthLoading">
          <template #icon>
            <PlusCircleOutlined />
          </template>添加授权
        </a-button>
      </div>
      <a-table bordered :columns="columns" :data-source="data" :loading="dataLoading" :scroll="{ x: width, y: height }"
        :pagination="false">
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'shop_selling_partner_id'">
            <span v-if="record.shop_selling_partner_id">{{ record.shop_selling_partner_id }}</span>
            <span v-else class="text-error">未授权</span>
          </template>
          <template v-if="column.dataIndex === 'account'">
            <span v-if="record.account">{{ record.account }}</span>
            <span v-else class="text-error">未授权</span>
          </template>
          <template v-if="column.dataIndex === 'enabled'">
            <a-switch v-model:checked="record.enabled" checked-children="启用" un-checked-children="停用"
              @change="(checked) => changeState(checked, record)" :loading="switching[record._id]" />
          </template>
          <template v-if="column.dataIndex === 'ad_authtime'">
            <span v-if="record.ad_authtime">
              {{ record.ad_authtime }}
              <a href="javascript:;" class="text-error" @click="bindAuthAD(record._id)">重新授权</a>
            </span>
            <span v-else>
              未授权，无法使用广告功能
              <a href="javascript:;" @click="bindAuthAD(record._id)">立即授权</a>
            </span>
          </template>
          <template v-if="column.dataIndex === 'operation'">
            <a-space v-if="record.enabled">
              <a v-if="record.selling_partner_id || record.account" href="javascript:;" class="text-error"
                @click="bindAuth(record._id)">重新授权</a>
              <a v-else href="javascript:;" @click="bindAuth(record._id)">立即授权</a>
            </a-space>
          </template>
        </template>
      </a-table>
    </a-card>
  </div>
  <a-modal v-model:visible="preAuthModal" title="添加授权" @ok="preAuth" @cancel="cancelSave" ok-text="确认" cancel-text="取消"
    :confirm-loading="preAuthLoading" :cancel-button-props="{ disabled: preAuthLoading }" :maskClosable="false"
    :closable="!preAuthLoading">
    <a-form ref="formRef" name="preAuthForm" :model="preAuthForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }"
      autocomplete="off">
      <a-form-item label="授权平台" name="platform">
        {{ getplatformname(preAuthForm.platform) }}
      </a-form-item>
      <a-form-item label="店铺名称" name="name" :rules="[{ required: true, message: '请输入店铺名称' }]">
        <a-input v-model:value="preAuthForm.name" placeholder="卖家自己定义，以做区分" :disabled="preAuthLoading" />
      </a-form-item>
      <a-form-item label="平台账号" name="account_username" :rules="[{ required: true, message: '请输入平台账号' }]">
        <a-input v-model:value="preAuthForm.account_username" placeholder="卖家在平台上的账号" :disabled="preAuthLoading" />
      </a-form-item>
      <a-form-item v-if="preAuthForm.platform === 'amz'" label="区域" name="region"
        :rules="[{ required: true, message: '请选择区域' }]">
        <a-select v-model:value="preAuthForm.region" :options="seller_regions" show-search :filterOption="filterOption"
          :disabled="preAuthLoading" @change="onChangeRegion" />
      </a-form-item>
      <a-form-item v-if="preAuthForm.platform === 'amz'" label="站点" name="sellercenterids"
        :rules="[{ required: true, message: '请选择站点' }]">
        <div class="d-flex flex-column">
          <a-checkbox v-model:checked="preAuthForm.checkAll" :disabled="preAuthLoading" @change="onCheckAllChange">
            全选
          </a-checkbox>
          <a-checkbox-group v-model:value="preAuthForm.sellercenterids" :options="region_seller_centers"
            :disabled="preAuthLoading" @change="onCheckChange" />
        </div>
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="authModal" title="授权绑定店铺" @ok="confirmAuth" ok-text="确认" cancel-text="取消"
    :confirm-loading="authing" :cancel-button-props="{ disabled: authing }" :maskClosable="false" :closable="!authing">
    <a-form name="authForm" :model="authForm" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" autocomplete="off">
      <a-form-item label="授权平台" name="platform">
        {{ getplatformname(authForm.platform) }}
      </a-form-item>
      <a-form-item label="授权店铺" name="shopname">
        {{ authForm.shopname }}
      </a-form-item>
      <a-form-item label="平台账号" name="account_username">
        {{ authForm.account_username }}
      </a-form-item>
      <a-form-item v-if="authForm.platform === 'amz'" label="区域" name="region">
        {{ authForm.region }}
      </a-form-item>
      <a-form-item v-if="authForm.platform === 'amz'" label="站点" name="sellercenter">
        {{ authForm.sellercenter }}
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="authADModal" title="授权店铺广告" @ok="confirmAuthAD" ok-text="确认" cancel-text="取消"
    :confirm-loading="authing" :cancel-button-props="{ disabled: authing }" :maskClosable="false" :closable="!authing">
    <a-form name="authADForm" :model="authADForm" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" autocomplete="off">
      <a-form-item label="授权平台" name="platform">
        {{ getplatformname(authADForm.platform) }}
      </a-form-item>
      <a-form-item label="授权店铺" name="shopname">
        {{ authADForm.shopname }}
      </a-form-item>
      <a-form-item label="平台账号" name="account_username">
        {{ authADForm.account_username }}
      </a-form-item>
      <a-form-item label="区域" name="region">
        {{ authADForm.region }}
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { DownOutlined, SearchOutlined, PlusCircleOutlined } from "@ant-design/icons-vue";
import { message, Modal } from "ant-design-vue";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onActivated,
  getCurrentInstance,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import _ from "underscore";
export default defineComponent({
  name: "ShopsManageAuthorize",
  components: { DownOutlined, SearchOutlined, PlusCircleOutlined, },
  setup() {
    const { proxy } = getCurrentInstance();
    const formRef = ref();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      platform: "amz",
      platforms: [],
      dataLoading: false,
      data: [],
      addAuthLoading: false,
      seller_regions: [],
      seller_centers: [],
      region_seller_centers: [],
      preAuthModal: false,
      preAuthForm: {},
      preAuthLoading: false,
      authModal: false,
      authForm: {},
      authing: false,
      authADModal: false,
      authADForm: {},
      switching: {},
      columns: [],
      height: 600,
    });
    const width = computed(() => proxy.$utils.twidth(state.columns));
    watch(() => state.platform, () => getData());
    onActivated(() => {
      // console.log("onActivated");
      if (route.query.state) {
        let _state = route.query.state.split('_');
        state.platform = _state[1];
      } else if (route.query.code) {
        let zdterp_adauth_shopid = localStorage.getItem("zdterp_adauth_shopid");
        if (zdterp_adauth_shopid) {
          state.platform = 'amz';
        }
      }
      getData();
      // console.log("route.query", route);
      if (route.query.state) {
        showAuth(route.query);
      } else if (route.query.code) {
        let zdterp_adauth_shopid = localStorage.getItem("zdterp_adauth_shopid");
        if (zdterp_adauth_shopid) {
          showAuthAD(zdterp_adauth_shopid)
        }
      }
    });
    const makeTableData = (data) => {
      let columns = [];
      let list = [];
      if (state.platform === 'amz') {
        for (let i = 0; i < data.length; i++) {
          let { name, account_username, shop_selling_partner_id, ad_authtime, shops } = data[i];
          for (let j = 0; j < shops.length; j++) {
            list.push({
              name, account_username, shop_selling_partner_id,
              ad_authtime, rowSpan: j == 0 ? shops.length : 0,
              ...shops[j],
            });
          }
        }
        console.log(list);
        columns.push(
          {
            title: "店铺名称", dataIndex: "name", width: 120,
            customCell: (record, rowIndex, column) => ({ rowSpan: record.rowSpan || 0 }),
          },
          {
            title: "平台账号", dataIndex: "account_username", width: 180,
            customCell: (record, rowIndex, column) => ({ rowSpan: record.rowSpan || 0 }),
          },
          {
            title: "店铺ID", dataIndex: "shop_selling_partner_id", width: 120,
            customCell: (record, rowIndex, column) => ({ rowSpan: record.rowSpan || 0 }),
          },
          { title: "授权地区", dataIndex: "authregion", width: 100, },
          { title: "授权时间(基础API)", dataIndex: "authtime", width: 120, },
          { title: "状态(基础API)", dataIndex: "enabled", width: 120, },
          { title: "操作", dataIndex: "operation", width: 100, },
          {
            title: "授权时间(广告API)", dataIndex: "ad_authtime", width: 200,
            customCell: (record, rowIndex, column) => ({ rowSpan: record.rowSpan || 0 }),
          },
        )
      } else if (state.platform === 'smt') {
        list = data;
        columns.push(
          { title: "店铺名称", dataIndex: "name", width: 120, },
          { title: "平台账号", dataIndex: "account_username", width: 150, },
          { title: "授权账号", dataIndex: "account", width: 150, },
          { title: "授权时间", dataIndex: "authtime", width: 120, },
          { title: "授权状态", dataIndex: "enabled", width: 120, },
          { title: "操作", dataIndex: "operation", width: 100, fixed: "right", }
        )
      }
      state.columns = columns;
      state.data = list;
    }
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/shop/list", { platform: state.platform, }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          makeTableData(res.data.list);
          state.platforms = res.data.platforms;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const addAuth = () => {
      state.addAuthLoading = true;
      proxy.$api.doAPI("/shop/addauth", { platform: state.platform }).then((res) => {
        state.addAuthLoading = false;
        if (res.code === 0) {
          state.seller_regions = res.data.seller_regions;
          state.seller_centers = res.data.seller_centers;
          state.region_seller_centers = _.where(state.seller_centers, { region: res.data.seller_regions[0]?.value });
          state.preAuthForm = {
            platform: state.platform,
            name: '',
            account_username: '',
            region: res.data.seller_regions[0]?.value,
            checkAll: true,
            sellercenterids: _.pluck(state.region_seller_centers, "value")
          };
          state.preAuthModal = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.addAuthLoading = false;
        console.error(err);
      });
    };
    const onChangeRegion = () => {
      state.region_seller_centers = _.where(state.seller_centers, { region: state.preAuthForm.region });
      state.preAuthForm.sellercenterids = _.pluck(state.region_seller_centers, "value");
      state.preAuthForm.checkAll = true;
    }
    const onCheckAllChange = () => {
      if (state.preAuthForm.checkAll) {
        state.preAuthForm.sellercenterids = _.pluck(state.region_seller_centers, "value");
      } else {
        state.preAuthForm.sellercenterids = [];
      }
    }
    const onCheckChange = () => {
      state.preAuthForm.checkAll = state.preAuthForm.sellercenterids.length === _.pluck(state.region_seller_centers, "value").length;
    }
    const preAuth = async () => {
      try {
        await formRef.value.validateFields();
        state.preAuthLoading = true;
        proxy.$api.doAPI("/shop/preauth", { preAuthForm: state.preAuthForm }).then((res) => {
          state.preAuthLoading = false;
          if (res.code === 0) {
            state.preAuthModal = false;
            getData();
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.preAuthLoading = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    };
    const cancelSave = () => {
      console.log('cancelSave');
      formRef.value.clearValidate();
    }
    const bindAuth = (_id) => {
      state.addAuthLoading = true;
      proxy.$api.doAPI("/shop/bindauth", { _id, host: window.location.host }).then((res) => {
        state.addAuthLoading = false;
        if (res.code === 0) {
          if (res.data.auth_url) {
            proxy.$utils.downloadBrowser({ href: res.data.auth_url });
          }
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.addAuthLoading = false;
        console.error(err);
      });
    };
    const bindAuthAD = (_id) => {
      state.addAuthLoading = true;
      proxy.$api.doAPI("/shop/bindauthad", { _id, host: window.location.host }).then((res) => {
        state.addAuthLoading = false;
        if (res.code === 0) {
          if (res.data.auth_url) {
            localStorage.setItem("zdterp_adauth_shopid", _id);
            proxy.$utils.downloadBrowser({ href: res.data.auth_url });
          }
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.addAuthLoading = false;
        console.error(err);
      });
    }
    const showAuth = (data) => {
      proxy.$api.doAPI("/shop/showauth", data).then((res) => {
        if (res.code === 0) {
          if (res.data.shop) {
            state.platform = res.data.shop.platform;
            if (state.platform === "amz") {
              state.authForm = {
                platform: res.data.shop.platform,
                shopid: res.data.shop._id,
                shopname: res.data.shop.name,
                account_username: res.data.shop.account_username,
                region: res.data.seller_center.region,
                sellercenter: res.data.seller_center.MarketPlace_CN,
                ...route.query,
              };
            } else if (state.platform === "smt") {
              state.authForm = {
                platform: res.data.shop.platform,
                shopid: res.data.shop._id,
                shopname: res.data.shop.name,
                account_username: res.data.shop.account_username,
                ...route.query,
              };
            }
            state.authModal = true;
          } else {
            message.error("授权失败");
          }
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
      });
    };
    const showAuthAD = (shopid) => {
      proxy.$api.doAPI("/shop/showauthad", { shopid }).then((res) => {
        if (res.code === 0) {
          if (res.data.shop) {
            state.platform = res.data.shop.platform;
            if (state.platform === "amz") {
              state.authADForm = {
                platform: res.data.shop.platform,
                shopid: res.data.shop._id,
                shopname: res.data.shop.name,
                account_username: res.data.shop.account_username,
                region: res.data.seller_center.region,
                ...route.query,
              };
            }
            state.authADModal = true;
          } else {
            message.error("授权失败");
          }
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
      });
    };
    const confirmAuth = () => {
      state.authing = true;
      proxy.$api.doAPI("/shop/confirmauth", { authForm: state.authForm }).then((res) => {
        state.authing = false;
        if (res.code === 0) {
          Modal.success({
            title: "提示", content: "授权成功",
            onOk() {
              state.authModal = false;
              router.replace({ path: route.path }).catch(console.error);
              getData();
            },
          });
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.authing = false;
        console.error(err);
      });
    };
    const confirmAuthAD = () => {
      state.authing = true;
      proxy.$api.doAPI("/shop/confirmauthad", { authADForm: state.authADForm, host: window.location.host }).then((res) => {
        state.authing = false;
        if (res.code === 0) {
          Modal.success({
            title: "提示", content: "授权成功",
            onOk() {
              state.authADModal = false;
              router.replace({ path: route.path }).catch(console.error);
              getData();
            },
          });
          localStorage.removeItem("zdterp_adauth_shopid");
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.authing = false;
        console.error(err);
      });
    };
    const changeState = (enabled, item) => {
      state.switching[item._id] = true;
      proxy.$api.doAPI("/shop/changestate", { enabled, _id: item._id }).then((res) => {
        state.switching[item._id] = false;
        if (res.code === 0) {
        } else {
          message.error(res.msg);
        }
        getData();
      }).catch((err) => {
        state.switching[item._id] = false;
        console.error(err);
        getData();
      });
    }
    const removeAuth = (_id) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/shop/removeauth", { _id }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const getplatformname = (platform) => {
      return _.findWhere(state.platforms, { value: platform })?.label;
    }
    const filterOption = (inputValue, option) => {
      return option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
    };
    return {
      ...toRefs(state),
      formRef,
      width,
      getData,
      addAuth,
      onChangeRegion,
      onCheckAllChange,
      onCheckChange,
      preAuth,
      cancelSave,
      bindAuth,
      bindAuthAD,
      confirmAuth,
      confirmAuthAD,
      changeState,
      removeAuth,
      getplatformname,
      filterOption,
    };
  },
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

.pactive {
  background: #e6f7ff;
  color: #1890ff;
}

p {
  margin: 0;
  padding: 0;
}
</style>
