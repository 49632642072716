<template>
  <div class="d-flex align-center mb-2">
    <span>平台：</span>
      <a-tag v-for="item in platforms" :key="item.value" :color="platform === item.value ? 'processing' : 'default'"
      @click="changePlatform(item.value)" class="click">{{ item.label }}</a-tag>
  </div>
  <div class="d-flex align-center mb-2">
    <a-row>
      <a-col><span style="width:75px;">店铺：</span></a-col>
      <a-col :span="23">
        <div>
          <a-tag :color="shopid === 0 ? 'processing' : 'default'" @click="changeShop(0)" class="click">全部</a-tag>
          <a-tag v-for="item in shops" :key="item.value" :color="shopid === item.value ? 'processing' : 'default'"
            @click="changeShop(item.value)" class="click">{{ item.label }}
          </a-tag>
        </div>
      </a-col>
    </a-row>
  </div>
  <div class="d-flex align-center mb-2">
    <span>状态：</span>
    <a-tag :color="matchstate === 0 ? 'processing' : 'default'" @click="changeState(0)" class="click">全部</a-tag>
    <a-tag :color="matchstate === 1 ? 'success' : 'default'" @click="changeState(1)" class="click">已配对</a-tag>
    <a-tag :color="matchstate === 2 ? 'error' : 'default'" @click="changeState(2)" class="click">未配对</a-tag>
  </div>
  <div class="d-flex align-center mb-2">
    <span>搜索内容：</span>
    <a-radio-group v-model:value="searchtype">
      <a-radio :value="1">平台SKU</a-radio>
      <a-radio :value="2">ASIN</a-radio>
    </a-radio-group>
    <a-input-search v-model:value="searchtext" enter-button="搜索" style="width: 20%;min-width:300px;" allow-clear
      @search="getData" />
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-button type="primary" @click="getData"> 刷新列表 </a-button>
    <a-button type="danger" @click="showPull"> 拉取商品 </a-button>
  </div>
  <a-table :columns="columns" bordered :data-source="data" :loading="dataLoading" :scroll="{ x: width, y: 600 }"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
    :row-selection="{ selectedRowKeys, onChange: (keys) => selectedRowKeys = keys }">
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'image_url'">
        <a-tooltip v-if="record.image_url" color="white" placement="rightBottom">
          <template #title>
            <a-image :src="record.image_url" width="480px" height="480px" style="object-fit: contain;height:100%;"
              :preview="false">
              <template #placeholder>
                <a-image :src="image_placeholder" width="480px" height="480px" :preview="false" />
              </template>
            </a-image>
          </template>
          <a href="javascript:;">
            <a-image :src="record.image_url" width="80px" height="80px" style="object-fit: contain;height:100%;"
              :preview="false">
              <template #placeholder>
                <a-image :src="image_placeholder" width="80px" height="80px" :preview="false" />
              </template>
            </a-image>
          </a>
        </a-tooltip>
        <a-image v-else :src="image_placeholder" width="80px" height="80px" style="object-fit: contain;height:100%;"
          :preview="false">
          <template #placeholder>
            <a-image :src="image_placeholder" width="80px" height="80px" :preview="false" />
          </template>
        </a-image>
      </template>
      <template v-if="column.dataIndex === 'shop'">
        <div class="d-flex flex-column justify-center align-center">
          <span v-if="record.shop?.platform === 'amz'"
            style="background-color: #0563d4;color: #fff;border-radius: 0.25em;padding: 0 5px;">Amazon</span>
          <span>{{ record.shop.name }}</span>
        </div>
      </template>
      <template v-if="column.dataIndex === 'info'">
        <div class="d-flex flex-column">
          <a-tooltip color="white" placement="topLeft">
            <template #title>
              <a href="javascript:;" @click="doCopy(record.seller_sku)">复制</a>
            </template>
            <a href="javascript:;">{{ record.seller_sku }}</a>
          </a-tooltip>
          <a-tooltip color="white" placement="topLeft">
            <template #title>
              <a href="javascript:;" @click="doCopy(record.asin)">复制</a>
            </template>
            <span class="click">{{ record.asin }}</span>
          </a-tooltip>
          <a-tooltip placement="topLeft">
            <template #title> {{ record.name }} </template>
            <p class="in1line">{{ record.name }}</p>
          </a-tooltip>
        </div>
      </template>
      <template v-if="column.dataIndex === 'cover'">
        <a-tooltip v-if="record.product?.cover" color="white" placement="rightBottom">
          <template #title>
            <a-image :src="record.product.cover" width="480px" height="480px" style="object-fit: contain;height:100%;"
              :preview="false">
              <template #placeholder>
                <a-image :src="image_placeholder" width="480px" height="480px" :preview="false" />
              </template>
            </a-image>
          </template>
          <a href="javascript:;">
            <a-image :src="record.product.cover" width="80px" height="80px" style="object-fit: contain;height:100%;"
              :preview="false">
              <template #placeholder>
                <a-image :src="image_placeholder" width="80px" height="80px" :preview="false" />
              </template>
            </a-image>
          </a>
        </a-tooltip>
        <a-image v-else :src="image_placeholder" width="80px" height="80px" style="object-fit: contain;height:100%;"
          :preview="false">
          <template #placeholder>
            <a-image :src="image_placeholder" width="80px" height="80px" :preview="false" />
          </template>
        </a-image>
      </template>
      <template v-if="column.dataIndex === 'info2'">
        <div class="d-flex flex-column">
          <a-tooltip v-if="record.product" color="white" placement="topLeft">
            <template #title>
              <a href="javascript:;" @click="doCopy(record.product.sku)">复制</a>
            </template>
            <a href="javascript:;">{{ record.product.sku }}</a>
          </a-tooltip>
          <span v-else>-</span>
          <a-tooltip v-if="record.product" placement="topLeft">
            <template #title> {{ record.product.name_cn }} </template>
            <span>{{ record.product.name_cn }}</span>
          </a-tooltip>
          <span v-else>-</span>
        </div>
      </template>
      <template v-if="column.dataIndex === 'matched'">
        <span v-if="record.matched" class="text-success text-h9">已配对</span>
        <span v-else class="text-error text-h9">未配对</span>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <template v-if="record.matched">
            <a href="javascript:;" @click="matchsku(record)">更换配对</a>
            <!-- <a href="javascript:;" class="text-error">解除关系</a> -->
          </template>
          <a v-else href="javascript:;" @click="matchsku(record)">手动配对</a>
        </a-space>
      </template>
    </template>
  </a-table>
  <a-modal v-model:visible="pullModal" title="拉取在线商品" :maskClosable="false" :closable="!pulling" width="750px"
    @cancel="() => pullModal = false">
    <template #footer>
      <div class="d-flex justify-space-between">
        <div class="btn-group">
          <button class="click btn btn-sm btn-teal" @click="selectall">全选</button>
          <button class="click btn btn-sm btn-default" @click="selectnone">全不选</button>
        </div>
        <a-space>
          <a-button type="primary" @click="pullShopListing" :disabled="selectedShops.length === 0"
            :loading="pulling">立即下载</a-button>
          <a-button type="default" @click="() => pullModal = false" :disabled="pulling">取消</a-button>
        </a-space>
      </div>
    </template>
    <div class="d-flex mb-2">
      <span>平台：</span>
      <a-tag v-for="item in platforms" :key="item.value"
        :color="pullForm.platform === item.value ? 'processing' : 'default'" @click="changePullPlatform(item.value)"
        class="click">{{ item.label }}</a-tag>
    </div>
    <a-table :columns="pullShopColumns" :data-source="pullShops" :loading="pulling" :scroll="{ x: pullshopwidth, y: 600 }"
      :pagination="false" row-key="_id"
      :row-selection="{ selectedRowKeys: selectedShops, onChange: (keys) => selectedShops = keys }">
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'pull_state'">
          <span v-if="record.pull_state === 'SUCCESS'" class="text-success">下载成功</span>
          <span v-else-if="record.pull_state === 'ERROR'" class="text-error">下载失败：{{ record.pull_error }}</span>
          <span v-else-if="record.pull_state" class="text-warning">正在下载</span>
          <span v-else class="text-info">店铺可以下载</span>
        </template>
      </template>
    </a-table>
  </a-modal>
  <a-modal v-model:visible="skuModal" title="手动配对SKU" :maskClosable="false" closable width="800px" :footer="false">
    <div class="d-flex align-center mb-2">
      <div style="width:85px;">搜索类型：</div>
      <a-radio-group v-model:value="skuForm.type" size="small">
        <a-radio-button value="sku">库存SKU</a-radio-button>
        <a-radio-button value="name_cn">库存SKU名称</a-radio-button>
        <a-radio-button value="name_en">库存SKU英文名称</a-radio-button>
        <a-radio-button value="csku">组合SKU</a-radio-button>
        <a-radio-button value="cname_cn">组合SKU名称</a-radio-button>
        <a-radio-button value="vsku">库存虚拟SKU</a-radio-button>
        <a-radio-button value="cvsku">组合虚拟SKU</a-radio-button>
      </a-radio-group>
    </div>
    <div class="d-flex align-center mb-2">
      <div style="width:85px;">搜索内容：</div>
      <a-input-search v-if="skuForm.type === 'sku'" v-model:value="skuForm.text" placeholder="请输入库存SKU编码"
        enter-button="搜索" @search="searchSku" />
      <a-input-search v-if="skuForm.type === 'name_cn'" v-model:value="skuForm.text" placeholder="请输入库存SKU名称"
        enter-button="搜索" @search="searchSku" />
      <a-input-search v-if="skuForm.type === 'name_en'" v-model:value="skuForm.text" placeholder="请输入库存SKU英文名称"
        enter-button="搜索" @search="searchSku" />
      <a-input-search v-if="skuForm.type === 'csku'" v-model:value="skuForm.text" placeholder="请输入组合SKU编码"
        enter-button="搜索" @search="searchSku" />
      <a-input-search v-if="skuForm.type === 'cname_cn'" v-model:value="skuForm.text" placeholder="请输入组合SKU名称"
        enter-button="搜索" @search="searchSku" />
      <a-input-search v-if="skuForm.type === 'vsku'" v-model:value="skuForm.text" placeholder="请输入库存虚拟SKU编码"
        enter-button="搜索" @search="searchSku" />
      <a-input-search v-if="skuForm.type === 'cvsku'" v-model:value="skuForm.text" placeholder="请输入组合虚拟SKU编码"
        enter-button="搜索" @search="searchSku" />
    </div>
    <a-table :columns="skucolumns" bordered :data-source="skudata" :loading="skuloading" :scroll="{ y: 400 }"
      :pagination="{ current: skupage, pageSize: skulimit, total: skucount, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
      @change="(pagination) => { skupage = pagination.current; skulimit = pagination.pageSize; }">
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'cover'">
          <a-image :src="record.cover || image_placeholder" :width="50" :height="50"
            style="object-fit: contain;height:100%;" :preview="false" />
        </template>
        <template v-if="column.dataIndex === 'info'">
          <p>{{ record.sku }}<span v-if="record.is_combine" class="text-error">(组合)</span></p>
          <p>{{ record.name_cn }}</p>
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <a href="javascript:;" @click="confirmMatch(record)">选择</a>
        </template>
      </template>
    </a-table>
  </a-modal>
</template>
<script>
import { DownOutlined } from '@ant-design/icons-vue';
import { message } from "ant-design-vue";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onActivated,
  getCurrentInstance,
} from "vue";
import _ from "underscore";
const columns = [
  { title: '缩略图', dataIndex: 'image_url', width: 100 },
  { title: '平台/店铺', dataIndex: 'shop', width: 120 },
  { title: '平台sku/ASIN/名称', dataIndex: 'info', width: 300 },
  { title: '缩略图(本地SKU)', dataIndex: 'cover', width: 120 },
  { title: '匹配本地SKU/商品中文名', dataIndex: 'info2', width: 200 },
  { title: '状态', dataIndex: 'matched', width: 120 },
  { title: '操作', dataIndex: 'operation', width: 180, align: 'center', fixed: 'right' },
];
const pullShopColumns = [
  { title: '店铺名称', dataIndex: 'name', width: 150, },
  { title: '最近拉取时间', dataIndex: 'pull_time', width: 180, },
  { title: '店铺拉取状态', dataIndex: 'pull_state', width: 150, },
];
const skucolumns = [
  { title: '图片', dataIndex: 'cover', width: 80 },
  { title: '商品信息', dataIndex: 'info', width: 400 },
  { title: "操作", dataIndex: "operation", width: 100, fixed: "right" },
];
export default defineComponent({
  name: 'OnlineProductPairs',
  components: { DownOutlined },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      platform: "amz",
      platforms: [
        { label: "Amazon", value: "amz" },
      ],
      shopid: 0,
      shops: [],
      matchstate: 0,
      searchtype: 1,
      searchtext: '',
      dataLoading: false,
      page: 1,
      limit: 20,
      length: 0,
      count: 0,
      data: [],
      selectedRowKeys: [],
      pullModal: false,
      pullForm: {},
      pullShops: [],
      pulling: false,
      selectedShops: [],
      skuModal: false,
      skuForm: { type: "sku", text: "", },
      skupage: 1,
      skulimit: 100,
      skudata: [],
      skulength: 0,
      skucount: 0,
      skuloading: false,
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    const pullshopwidth = computed(() => proxy.$utils.twidth(pullShopColumns));
    watch(() => state.page, () => getData());
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    watch(() => state.skupage, () => searchSku());
    watch(() => state.skulimit, () => {
      if (state.skupage !== 1) state.skupage = 1;
      else searchSku();
    });
    onMounted(() => {
    });
    onActivated(() => {
      getShops();
    });
    const getShops = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/product/shops", { platform: state.platform, }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.shops = res.data.shops;
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const getData = () => {
      state.selectedRowKeys = [];
      state.dataLoading = true;
      proxy.$api.doAPI("/product/pairs", { platform: state.platform, shopid: state.shopid, matchstate: state.matchstate, searchtype: state.searchtype, searchtext: state.searchtext, page: state.page, limit: state.limit, }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.data = _.map(res.data.list, n => {
            return {
              ...n,
              time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
            }
          });
          state.length = res.data.length;
          state.count = res.data.count;
          if (state.length > 0 && state.page > state.length) state.page = 1;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const showPull = () => {
      state.pullForm = {
        platform: "amz",
      };
      getPullShops();
    };
    const getPullShops = () => {
      state.selectedShops = [];
      state.dataLoading = true;
      proxy.$api.doAPI("/product/pullshops", state.pullForm).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.pullShops = res.data.shops;
          state.pullModal = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const pullShopListing = () => {
      if (state.selectedShops.length === 0) return;
      state.dataLoading = true;
      proxy.$api.doAPI("/product/pullshoplistings", { shopids: state.selectedShops }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          getPullShops();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const matchsku = (shoplisting) => {
      console.log('matchsku', shoplisting);
      state.skuForm = { type: "sku", text: "", shoplistingid: shoplisting._id };
      if (shoplisting.product) {
        state.skuForm.text = shoplisting.product.sku;
        if (shoplisting.product.is_combine) {
          state.skuForm.type = "c" + state.skuForm.type;
        }
      } else {
        state.skuForm.type = "vsku";
        state.skuForm.text = shoplisting.seller_sku;
      }
      state.skupage = 1;
      state.skudata = [];
      state.skuModal = true;
    }
    const confirmMatch = (item) => {
      state.skuloading = true;
      proxy.$api.doAPI("/product/matchshoplisting", { skuForm: state.skuForm, productid: item._id, }).then((res) => {
        state.skuloading = false;
        if (res.code === 0) {
          state.skuModal = false;
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.skuloading = false;
        console.error(err);
      });
    }
    const searchSku = () => {
      state.skuloading = true;
      proxy.$api.doAPI("/product/searchsku", { page: state.skupage, limit: state.skulimit, skuForm: state.skuForm, }).then((res) => {
        state.skuloading = false;
        if (res.code === 0) {
          state.skudata = res.data.list;
          state.skulength = res.data.length;
          state.skucount = res.data.count;
          if (state.skulength > 0 && state.skupage > state.skulength) state.skupage = 1;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.skuloading = false;
        console.error(err);
      });
    }
    const selectall = () => {
      state.selectedShops = _.pluck(state.pullShops, "_id");
    }
    const selectnone = () => {
      state.selectedShops = [];
    }
    const changePlatform = (platform) => {
      state.platform = platform;
      getShops();
    }
    const changeShop = (shopid) => {
      state.shopid = shopid;
      getData();
    }
    const changeState = (matchstate) => {
      state.matchstate = matchstate;
      getData();
    }
    const changePullPlatform = (platform) => {
      state.pullForm.platform = platform;
      getPullShops();
    }
    return {
      ...toRefs(state),
      columns,
      pullShopColumns,
      skucolumns,
      width,
      pullshopwidth,
      getData,
      showPull,
      pullShopListing,
      matchsku,
      confirmMatch,
      searchSku,
      selectall,
      selectnone,
      changePlatform,
      changeShop,
      changeState,
      changePullPlatform,
      doCopy: proxy.$utils.doCopy,
      image_placeholder: proxy.$constant.image_placeholder,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-teal {
  color: #fff;
  background-color: #6bccb4;
  border-color: #60c8ae;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, .1);
}

.btn-default {
  color: #6a6a6a;
  background-color: #f5f5f5;
  border-color: #cfd9db;
  text-shadow: 0 1px 0 rgba(255, 255, 255, .8);
}

.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.btn-group>.btn:first-child {
  margin-left: 0;
}

.btn-group .btn+.btn {
  margin-left: -1px;
}

.btn-group>.btn:last-child:not(:first-child),
.btn-group>.dropdown-toggle:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

p {
  margin: 0;
  padding: 0;
}
</style>