<template>
  <a-spin :spinning="loading">
    <div>
      <div class="mainform">
        <a-form
          ref="listingformRef"
          name="listingForm"
          :model="listingForm"
          autocomplete="off"
        >
          <a-card title="基本信息">
            <div class="form-item">
              <div class="label">
                <span class="cn">ASIN</span>
              </div>
              <a-form-item name="asin" style="width: 80%; max-width: 400px">
                <a-input v-model:value="listingForm.asin" disabled />
              </a-form-item>
            </div>
            <div class="form-item">
              <div class="label">
                <span class="cn">售价</span>
              </div>
              <a-form-item name="asin" style="width: 80%; max-width: 400px">
                <a-input v-model:value="listingForm.unit_price" />
              </a-form-item>
            </div>
            <div class="form-item">
              <div class="label">
                <span class="cn">来源URL</span>
              </div>
              <a-form-item name="url" style="width: 100%">
                <a-input
                  v-model:value="listingForm.url"
                  disabled
                  style="width: 800px"
                />
                <a-button @click="open(listingForm.url)">访问链接</a-button>
              </a-form-item>
            </div>
            <div class="form-item">
              <div class="label">
                <span class="cn">产品标题</span>
              </div>
              <a-form-item name="title" style="width: 80%; min-width: 600px">
                <a-input
                  v-model:value="listingForm.title"
                  placeholder="请输入产品标题"
                  show-count
                  :maxlength="200"
                  style="width: 1350px"
                />
              </a-form-item>
            </div>
          </a-card>
          <a-card title="图片">
            <div class="form-item">
              <div class="label">
                <span class="cn">产品图片</span>
              </div>
              <a-form-item style="width: 80%; min-width: 600px">
                <div
                  class="d-flex justify-space-between align-center"
                  style="width: 100%"
                ></div>
                <a-row :gutter="16" style="overflow-y: auto">
                  <a-col
                    :span="2"
                    v-for="(image, index) in listingForm.img_url"
                    :key="image"
                  >
                    <div
                      style="
                        width: 105px;
                        height: 125px;
                        border: 1px solid #e8e8e8;
                      "
                    >
                      <div>
                        <a-image
                          width="105px"
                          height="105px"
                          :src="image"
                          style="object-fit: contain; height: 100%"
                        >
                          <template #placeholder>
                            <a-image
                              :src="image_placeholder"
                              width="105px"
                              height="105px"
                              :preview="false"
                            />
                          </template>
                        </a-image>
                        <div
                          style="width: 105px; height: 20px"
                          class="d-flex justify-end"
                        >
                          <a-tooltip>
                            <template #title>删除图片</template>
                            <DeleteOutlined
                              @click="removeImage({ index })"
                              style="font-size: 20px; color: #ff4d4f"
                            />
                          </a-tooltip>
                        </div>
                      </div>
                      <svg-icon
                        v-if="index === 0"
                        iconClass="zhutu"
                        :style="{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          height: '17px',
                          width: '40px',
                        }"
                      />
                    </div>
                  </a-col>
                </a-row>
              </a-form-item>
            </div>
          </a-card>
          <a-card title="关于">
            <div class="form-item">
              <div class="label">
                <span class="cn">关于竞品</span>
              </div>
              <div
                class="d-flex flex-column"
                style="width: 80%; min-width: 600px"
              >
                <p class="text-info">简易描述关于竞品信息</p>
                <a-form-item>
                  <a-textarea
                    v-model:value="listingForm.about_product"
                    :auto-size="{ minRows: 10, maxRows: 10 }"
                  />
                </a-form-item>
              </div>
            </div>
            <div class="form-item">
              <div class="label">
                <span class="cn">详细竞品</span>
              </div>
              <div
                class="d-flex flex-column"
                style="width: 80%; min-width: 600px"
              >
                <p class="text-info">详细描述一般包含产品属性、评分、上架时间等内容。</p>
                <div style="border: 1px solid #ccc; padding-top: 20px">
                  <a-form
                    ref="listingformRef"
                    name="listingForm"
                    :model="listingForm"
                    autocomplete="off"
                    labelAlign="right"
                    :labelCol="{ span: 3 }"
                    :wrapperCol="{ span: 12 }"
                  >
                    <a-col
                      :xs="24"
                      :sm="24"
                      :md="24"
                      :lg="32"
                      v-for="item in listingForm.keys"
                      :key="item"
                    >
                      <a-form-item :label="item" :name="item">
                        <a-input
                          v-if="item !== 'best_sellers_rank' && item !== 'asin'"
                          v-model:value="listingForm.product_description[item]"
                        />
                        <a-input
                          v-else-if="item === 'asin'"
                          v-model:value="listingForm.product_description[item]"
                          disabled
                        />
                        <a-textarea
                          v-else
                          v-model:value="listingForm.product_description[item]"
                          :auto-size="{ minRows: 2, maxRows: 2 }"
                        />
                      </a-form-item>
                    </a-col>
                  </a-form>
                </div>
              </div>
            </div>
          </a-card>
        </a-form>
      </div>
      <div class="footer pr-4">
        <a-button class="btn-warning" @click="doSave" :loading="saving">
          <span>保 存</span>
        </a-button>
      </div>
    </div>
  </a-spin>
</template>
  <script>
import {
  InfoCircleFilled,
  DownOutlined,
  HomeOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
  RollbackOutlined,
} from "@ant-design/icons-vue";
import { message, Modal } from "ant-design-vue";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onBeforeUnmount,
  onActivated,
  getCurrentInstance,
  toRaw,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import _ from "underscore";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import ImagePicker from "@/components/ImagePicker.vue";
const MAX_IMAGE_COUNT = 9;
import "@wangeditor/editor/dist/css/style.css";
import { DomEditor } from "@wangeditor/editor";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
export default defineComponent({
  name: "CompetitorsForm",
  components: {
    InfoCircleFilled,
    DownOutlined,
    HomeOutlined,
    DeleteOutlined,
    EditOutlined,
    SaveOutlined,
    RollbackOutlined,
    ImagePicker,
    Editor,
    Toolbar,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const listingformRef = ref();
    const state = reactive({
      loading: false,
      saving: false,
      listingForm: {},
      keys: [],
    });
    onActivated(() => {
      getData();
    });
    const getData = () => {
      state.loading = true;
      state.loading = true;
      proxy.$api
        .doAPI("/advertising/competitive_info", { _id: route.query.id })
        .then((res) => {
          state.loading = false;
          if (res.code === 0) {
            let keys = _.keys(res.data.listingForm.product_description);
            state.listingForm = res.data.listingForm;
            state.listingForm.keys = keys;
          } else {
            message.error(res.msg);
          }
        })
        .catch((err) => {
          state.loading = false;
          console.error(err);
        });
    };
    const removeImage = ({ index }) => {
      state.listingForm.img_url.splice(index, 1);
    };
    const open = (url) => {
      proxy.$utils.downloadBrowser({
        href: url,
      });
    };
    const doSave = async () => {
      try {
          state.saving = true;
        console.log(state.listingForm);
        await listingformRef.value.validateFields();
          proxy.$api.doAPI("/advertising/save", { listingForm: state.listingForm }).then((res) => {
            state.saving = false;
            if (res.code === 0) {
              Modal.success({
                title: "提示",
                content: "保存成功",
                centered: true,
                okText: "关闭",
                okType: "default",
                onOk() {
                  window.close();
                },
              });
            } else {
              message.error(res.msg);
            }
          }).catch((err) => {
            state.saving = false;
            console.error(err);
          });
      } catch (errorInfo) {
        state.saving = false;
        console.log("Failed:", errorInfo);
        message.error(
          _.reduce(
            errorInfo.errorFields,
            (a, b) => a.concat(b.errors),
            []
          ).join("；")
        );
      }
    };
    return {
      ...toRefs(state),
      locale,
      MAX_IMAGE_COUNT,
      listingformRef,
      removeImage,
      doSave,
      open,
      image_placeholder: proxy.$constant.image_placeholder,
    };
  },
});
</script>
  <style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-form-item-extra) {
  min-height: 0;
}

p {
  margin: 0;
  padding: 0;
}

.form-item {
  display: flex;
}

.label {
  min-width: 220px;
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 3px;
  padding-right: 10px;
  text-align: right;
}

.label > span:first-child::after {
  content: ":";
  position: relative;
  top: -0.5px;
  margin-left: 2px;
}

.label > .en {
  word-break: break-word;
}

.required > span:first-child::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.mainform {
  overflow: auto;
  height: 100vh;
  padding-bottom: 80px;
}

.footer {
  box-shadow: 0 0 6px 0 #d9d9d9;
  height: 64px !important;
  line-height: 64px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  text-align: right;
  z-index: 9;
  background-color: #fff;
  border-radius: 2px;
}
</style>