<template>
  <div class="header">
    <div class="search">
      <span style="width:80px;">查询搜索：</span>
      <a-input-search v-model:value="value" placeholder="多个单号用逗号隔开" enter-button="搜索" @search="onSearch" />
    </div>
    <div class="search">
      <span style="width:80px;">国家区域：</span>
      <template v-for="tag in tags" :key="tag">
        <a-checkable-tag :checked="selectedTags.indexOf(tag) > -1" @change="checked => handleChange(tag, checked)">
          {{ tag }}
        </a-checkable-tag>
      </template>
    </div>
    <div class="search">
      <span style="width:80px;">发货时间：</span>
      <a-radio-group v-model:value="delivertime">
        <a-radio-button value="all">全部</a-radio-button>
        <a-radio-button value="-1">昨天</a-radio-button>
        <a-radio-button value="0">今天</a-radio-button>
        <a-radio-button value="7">7天内</a-radio-button>
        <a-radio-button value="30">30天内</a-radio-button>
        <a-radio-button value="90">90天内</a-radio-button>
      </a-radio-group>
    </div>
    <div class="search">
      <span style="width:80px;">运输天数：</span>
      <a-radio-group v-model:value="transportdays">
        <a-radio-button value="all">全部</a-radio-button>
        <a-radio-button value="3">3天内</a-radio-button>
        <a-radio-button value="7">7天内</a-radio-button>
        <a-radio-button value="15">15天内</a-radio-button>
        <a-radio-button value="30">30天内</a-radio-button>
        <a-radio-button value="60">60天内</a-radio-button>
      </a-radio-group>
    </div>
    <div class="search">
      <span style="width:80px;">包裹状态：</span>
      <a-radio-group v-model:value="orderstate">
        <a-radio-button value="all">全部</a-radio-button>
        <a-radio-button value="-1">待入库</a-radio-button>
        <a-radio-button value="0">已入库</a-radio-button>
        <a-radio-button value="1">待出库</a-radio-button>
        <a-radio-button value="2">已出库</a-radio-button>
        <a-radio-button value="3">已签收</a-radio-button>
      </a-radio-group>
    </div>
    <div class="op">
      <div class="lt">
        <a-space>
          <a-dropdown>
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu-item key="1">批量标记</a-menu-item>
                <a-menu-item key="2">批量删除</a-menu-item>
              </a-menu>
            </template>
            <a-button type="primary">
              批量操作
              <DownOutlined />
            </a-button>
          </a-dropdown>
        </a-space>
      </div>
      <div class="rt">
        <a-space>
          <a-button type="primary">刷新数据</a-button>
          <a-dropdown>
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu-item-group title="导出订单">
                  <a-menu-item key="1">导出勾选订单</a-menu-item>
                  <a-menu-item key="2">导出全部页订单</a-menu-item>
                </a-menu-item-group>
              </a-menu>
            </template>
            <a-button type="primary">
              导出订单
              <DownOutlined />
            </a-button>
          </a-dropdown>
        </a-space>
      </div>
    </div>
  </div>
  <a-table :columns="columns" :data-source="data" :scroll="{ x: width }" />
</template>
<script>
import { DownOutlined } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from 'vue';
import _ from "underscore";
const columns = [
  { title: '包裹号/订单号', dataIndex: 'orderId', width: 200, align: 'center', },
  { title: '收件人', dataIndex: 'receiver', width: 150, align: 'center', },
  { title: '国家', dataIndex: 'country', width: 100, align: 'center', },
  { title: '物流方式', dataIndex: 'transport', width: 120, align: 'center', },
  { title: '店铺', dataIndex: 'name', width: 150, align: 'center', },
  { title: '下单时间', dataIndex: 'sendtime', width: 150, align: 'center', },
  { title: '运输天数', dataIndex: 'transportdays', width: 100, align: 'center', },
  { title: '最新状态', dataIndex: 'state', width: 100, align: 'center' },
  { title: '备注', dataIndex: 'mark', width: 150, align: 'center' },
  { title: '操作', dataIndex: 'operation', fixed: 'right', width: 90, align: 'center' },
];
const data = [];
for (let i = 0; i < 46; i++) {
  data.push({
    key: i,
    name: `正博店铺 ${i}`,
    country: '美国',
    orderId: '249-2507735-1865435',
    receiver: '陈志武',
    transport: '正博EUB',
    sendtime: '2022-10-10 12:23:34',
    transportdays: '9',
    state: '运输中',
    mark: ''
  });
}
export default defineComponent({
  name: 'OrdersToolsTrack',
  components: {
    DownOutlined
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      tags: ['全部', '美国', '法国', '英国'],
      selectedTags: [],
      data,
      delivertime: 'all',
      transportdays: "all",
      orderstate: 'all',
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    const handleChange = (tag, checked) => {
      const { selectedTags } = state;
      const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
      console.log('You are interested in: ', nextSelectedTags);
      state.selectedTags = nextSelectedTags;
    };
    return {
      ...toRefs(state),
      columns,
      width,
      handleChange,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

.header {
  .search {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  .op {
    display: flex;
    justify-content: space-between;
  }
}

p {
  margin: 0;
  padding: 0;
}
</style>
