<template>
  <div class="d-flex align-center mb-2">
    <span style="width: 80px;">查询搜索：</span>
    <a-input-search v-model:value="name" placeholder="图片标题查询" enter-button="搜索" allow-clear @search="getData" />
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-button type="primary" @click="showUpload">
      <template #icon>
        <PlusCircleOutlined />
      </template> 上传图片
    </a-button>
    <p>
      <span class="ml-2">总容量：<span class="text-h6 text-info">{{ totalSize }} M</span></span>
      <span class="ml-2">已使用：<span class="text-h6 text-error">{{ usedSize }}M</span></span>
      <span class="ml-2">剩余：<span class="text-h6 text-success">{{ leftSize }}M</span></span>
    </p>
  </div>
  <a-pagination v-if="count > 0" v-model:current="page" v-model:page-size="limit" show-quick-jumper
    :show-size-changer="false" :total="count" :show-total="total => `共 ${total} 条`"
    :pageSizeOptions="['12', '18', '24', '30']" />
  <div class="list mt-2">
    <a-row :gutter="16">
      <a-col :span="4" v-for="image in data" :key="image._id">
        <a-card hoverable style="width:100%;">
          <template #cover>
            <a-image height="200px" :src="image.remoteurl" style="object-fit:contain;height:200px;">
              <template #placeholder>
                <a-image :src="image_placeholder" height="200px" :preview="false" />
              </template>
            </a-image>
          </template>
          <template #actions>
            <a class="text-center" style="flex:1" href="javascript:;" @click="doCopy(image.remoteurl)">复制地址</a>
            <a-popconfirm title="确定要删除?" @confirm="removeImage(image._id)">
              <a class="text-center text-error" style="flex:1" href="javascript:;">删除</a>
            </a-popconfirm>
            <a-dropdown style="flex:1">
              <a class="ant-dropdown-link" @click.prevent>
                更多
                <DownOutlined />
              </a>
              <template #overlay>
                <a-menu>
                  <a-menu-item>
                    <a class="text-center text-info" href="javascript:;" @click="updateDimension(image._id)">更新尺寸</a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </template>
          <a-card-meta>
            <template #description>
              <p class="text-h7 in1line text-black"> {{ image.originalname }} </p>
            </template>
          </a-card-meta>
          <a-card-meta>
            <template #description>
              <p class="d-flex justify-space-between text-gray">
                <span>{{ image.width }}x{{ image.height }}</span>
                <span>{{ image.size_ }}</span>
              </p>
            </template>
          </a-card-meta>
        </a-card>
      </a-col>
    </a-row>
  </div>
  <a-drawer title="上传图片" placement="right" :visible="uploadDrawer" @close="cancelUpload" width="70%"
    :closable="!uploading" :maskClosable="!uploading">
    <template #extra>
      <a-button class="mr-2" @click="cancelUpload" :disabled="uploading">取消</a-button>
      <a-button type="primary" @click="doUpload" :loading="uploading"
        :disabled="adding || uploading || files.length === 0">
        <template #icon>
          <UploadOutlined />
        </template>
        上传
      </a-button>
    </template>
    <a-space>
      <a-button type="primary" size="large" :loading="adding" :disabled="uploading" @click="addFiles">
        <template #icon>
          <PlusOutlined />
        </template>
        点击添加图片
      </a-button>
      <span class="text-error">只能上传 jpg/tiff/gif 文件，且不超过2MB</span>
    </a-space>
    <input ref="uploadRef" type="file" accept="image/jpeg,image/jpg,image/tiff,image/gif" multiple="multiple"
      style="display: none" @change="selectImage" />
    <a-table :columns="columns" :data-source="files" :pagination="false">
      <template #bodyCell="{ record, column, index }">
        <template v-if="column.dataIndex === 'index'">
          {{ index + 1 }}
        </template>
        <template v-if="column.dataIndex === 'name'">
          {{ record.file.name }}
        </template>
        <template v-if="column.dataIndex === 'type'">
          {{ record.file.type }}
        </template>
        <template v-if="column.dataIndex === 'size'">
          {{ sizeToText(record.file.size) }}
        </template>
        <template v-if="column.dataIndex === 'url'">
          <a-image v-if="record.url" :src="record.url" width="80px" height="80px"
            style="object-fit: contain;height:100%;">
            <template #placeholder>
              <a-image :src="image_placeholder" width="80px" height="80px" :preview="false" />
            </template>
          </a-image>
          <a-image v-else :src="image_placeholder" width="80px" height="80px" style="object-fit: contain;height:100%;"
            :preview="false">
            <template #placeholder>
              <a-image :src="image_placeholder" width="80px" height="80px" :preview="false" />
            </template>
          </a-image>
        </template>
        <template v-if="column.dataIndex === 'status'">
          <span v-if="record.status === 'pending'" class="text-info text-h9">等待上传</span>
          <span v-if="record.status === 'uploading'" class="text-orange text-h9">
            正在上传 {{ record.upload_rate }}
          </span>
          <span v-if="record.status === 'success'" class="text-success text-h9">
            上传成功{{ record.upload_msg }}
          </span>
          <a-tooltip v-if="record.status === 'fail'" placement="topLeft">
            <template #title> {{ record.msg }} </template>
            <span class="text-error text-h9 underline">上传失败{{ record.upload_msg }} </span>
          </a-tooltip>
          <a-tooltip v-if="record.status === 'error'" placement="topLeft">
            <template #title> {{ record.msg }} </template>
            <span class="text-error text-h9 underline">无法上传</span>
          </a-tooltip>
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <a-button v-if="record.status === 'fail'" danger type="link" :disabled="uploading"
            @click="uploadFile(index)">重试</a-button>
          <a-button v-if="record.status === 'error'" danger type="link" :disabled="adding"
            @click="removeFile(index)">移除</a-button>
        </template>
      </template>
    </a-table>
  </a-drawer>
</template>
<script>
import { DownOutlined, UploadOutlined, SearchOutlined, PlusOutlined, PlusCircleOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onActivated,
  getCurrentInstance,
} from "vue";
import _ from "underscore";
const columns = [
  { title: "序号", dataIndex: "index", width: 50, },
  { title: "预览", dataIndex: "url", width: 120, },
  { title: "文件名", dataIndex: "name", width: 150, },
  { title: "类型", dataIndex: "type", width: 120, },
  { title: "大小", dataIndex: "size", width: 120, },
  { title: "状态", dataIndex: "status", width: 120, },
  { title: "操作", dataIndex: "operation", width: 150, },
];
export default defineComponent({
  name: "PictureList",
  components: { DownOutlined, UploadOutlined, SearchOutlined, PlusOutlined, PlusCircleOutlined, },
  setup() {
    const { proxy } = getCurrentInstance();
    const uploadRef = ref();
    const envOS = reactive(process.env.OS);
    const state = reactive({
      uploadDrawer: false,
      name: "",
      data: [],
      page: 1,
      limit: 12,
      length: 0,
      count: 0,
      adding: false,
      uploading: false,
      files: [],
      total_size: '',
      used_size: '',
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    const totalSize = computed(() => proxy.$utils.d((state.total_size) / 1024 / 1024).toFixed(2));
    const usedSize = computed(() => proxy.$utils.d((state.used_size) / 1024 / 1024).toFixed(2));
    const leftSize = computed(() => proxy.$utils.d(totalSize.value).minus(usedSize.value).toFixed(2));
    watch(() => state.page, () => {
      getData();
    });
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    onActivated(() => {
      getData();
    });
    const getData = () => {
      const hide = message.loading('正在加载', 0);
      proxy.$api.doAPI("/assets/space_images", { name: state.name, page: state.page, limit: state.limit }).then((res) => {
        hide();
        if (res.code === 0) {
          state.data = _.map(res.data.list, n => {
            return {
              ...n,
              size_: proxy.$utils.sizeToText(n.size),
            };
          });
          state.length = res.data.length;
          state.count = res.data.count;
          if (state.length > 0 && state.page > state.length) state.page = 1;
          state.total_size = res.data.total;
          state.used_size = res.data.used;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        hide();
        console.error(err);
      });
    };
    const removeImage = (_id) => {
      const hide = message.loading('正在删除', 0);
      proxy.$api.doAPI("/assets/remove_image", { _id }).then((res) => {
        hide();
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        hide();
        console.error(err);
      });
    }
    const updateDimension = (_id) => {
      const hide = message.loading('正在处理', 0);
      proxy.$api.doAPI("/assets/update_dimension", { _id }).then((res) => {
        hide();
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        hide();
        console.error(err);
      });
    }
    const showUpload = () => {
      state.uploadDrawer = true;
      state.files = [];
    }
    const addFiles = () => {
      uploadRef.value.click();
    }
    const removeFile = (index) => {
      state.files.splice(index, 1);
    }
    const checkfile = (file) => {
      let allow_types = ['image/jpeg', 'image/jpg', 'image/tiff', 'image/gif'];
      const validType = allow_types.indexOf(file.type) > -1;
      const validSize = file.size / 1024 / 1024 < 2;
      if (!validType) {
        return { code: 1, msg: '只能上传jpg/tiff/gif 文件' };
      } else if (!validSize) {
        return { code: 1, msg: '图片不能超过2MB' };
      }
      return { code: 0 };
    }
    const selectImage = async (e) => {
      let files = e.target.files;
      if (state.files.length + files.length > 100) {
        uploadRef.value.value = null;
        return message.error(`不能超过100张图片`);
      }
      state.adding = true;
      for (let i = 0; i < files.length; i++) {
        let { code, msg } = checkfile(files[i]);
        let status = "pending";
        if (code === 1) status = "error";
        let url = await new Promise((resolve) => {
          let reader = new FileReader();
          reader.addEventListener('load', function () {
            resolve(reader.result);
          }, false);
          reader.readAsDataURL(files[i]);
        });
        await proxy.$utils.sleep(17);
        state.files.unshift({
          _id: proxy.$utils.uuid(), url,
          file: files[i], status, msg,
        });
      }
      uploadRef.value.value = null;
      state.adding = false;
    }
    const doUpload = async () => {
      if (state.files.length === 0) return;
      for (let i = 0; i < state.files.length; i++) {
        await _doUpload(i);
      }
      console.log('doUpload-finished');
      state.uploading = false;
    }
    const _doUpload = async (i) => {
      let { status, file } = state.files[i];
      if (status !== "pending" && status !== "fail") return;
      state.uploading = true;
      const slicefiles = proxy.$utils.sliceFile(file.size);
      const faid = proxy.$utils.uuid();
      let loaded = 0, total = file.size;
      let timestamp = Date.now();
      for (let j = 0; j < slicefiles.length; j++) {
        let slicefile = slicefiles[j];
        const formData = new FormData();
        if (slicefile.chunks > 1) {
          let _file = file.slice(slicefile.from, slicefile.to);
          formData.set("file", _file);
        } else {
          formData.set("file", file);
        }
        formData.set("faid", faid);
        formData.set("chunk", slicefile.chunk);
        formData.set("chunks", slicefile.chunks);
        formData.set("originalname", file.name);
        state.files[i].status = 'uploading';
        try {
          let res = await proxy.$api.doUpload("/assets/plupload", formData, function onUploadProgress(e) {
            console.log('onUploadProgress', e);
            if (e.lengthComputable) {
              loaded += e.loaded;
              let upload_rate = Math.round((loaded * 100) / total);
              if (upload_rate > 100) upload_rate = 100;
              state.files[i].upload_rate = upload_rate + '%';
              if (loaded >= total) {
                timestamp = Date.now() - timestamp;
                state.files[i].upload_msg = `，用时：${timestamp.toFixed(0)}ms`;
              }
            }
          });
          if (res.code === 0) {
            if (slicefile.chunk + 1 === slicefile.chunks) {
              if (res.data?.url) {
                state.files[i].status = 'success';
                state.files[i].url = res.data.url;
              } else {
                state.files[i].status = 'fail';
                state.files[i].msg = '系统异常';
              }
            }
          } else {
            message.error(res.msg);
            state.files[i].status = 'fail';
            state.files[i].msg = res.msg;
            break;
          }
        } catch (e) {
          console.error(e);
          message.error(e.message);
          state.files[i].status = 'fail';
          state.files[i].msg = e.message;
          break;
        }
      }
    }
    const uploadFile = async (i) => {
      await _doUpload(i);
      state.uploading = false;
    }
    const cancelUpload = () => {
      state.uploadDrawer = false;
      state.files = [];
    }
    return {
      ...toRefs(state),
      envOS,
      uploadRef,
      columns,
      width,
      totalSize,
      usedSize,
      leftSize,
      getData,
      removeImage,
      updateDimension,
      showUpload,
      addFiles,
      removeFile,
      selectImage,
      doUpload,
      uploadFile,
      cancelUpload,
      sizeToText: proxy.$utils.sizeToText,
      doCopy: proxy.$utils.doCopy,
      image_placeholder: proxy.$constant.image_placeholder,
    };
  },
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

:deep(.ant-card-body) {
  padding: 8px;
}

p {
  margin-bottom: 0.5em !important;
}
</style>
