<template>
  <a-tabs v-model:activeKey="activeTab">
    <a-tab-pane key="1" tab="单品采集">
      <a-form ref="formRef1" name="form1" :model="form1">
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item name="urls" :rules="[{ required: true, message: '请输入产品网址' }]">
              <a-textarea v-model:value="form1.urls" placeholder="请填写在线商品链接，可一行一个商品链接批量添加，一次添加的商品链接不要超过100个" :rows="6"
                :disabled="saving" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item>
          <a-space>
            <!-- <a href="javascript:;" @click="showsupportsite">查看支持的站点</a> -->
            <a-button type="primary" :loading="saving" @click="submit1">开始采集</a-button>
            <a-button :disabled="saving" @click="clear1">清空</a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </a-tab-pane>
    <a-tab-pane key="2" tab="关键词采集" force-render>
      <a-form ref="formRef2" name="form2" :model="form2">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="关键词" name="keywords" :rules="[{ required: true, message: '请输入关键词' }]">
              <a-textarea v-model:value="form2.keywords" :rows="6" :disabled="saving" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="采集数" name="count" :rules="[{ required: true, message: '请输入采集条数' }]">
              <a-input-number v-model:value="form2.count" :disabled="saving" style="width:100%;" />
            </a-form-item>
            <a-form-item label="价格区间" name="sale_price">
              <div style="display:flex; align-items: center;">
                <a-input v-model:value="form2.price1" :disabled="saving" style="margin-right: 5px;" /> -
                <a-input v-model:value="form2.price2" :disabled="saving" style="margin-left: 5px;" />
              </div>
            </a-form-item>
            <a-form-item label="销量区间" name="sale_quantity">
              <div style="display:flex;align-items: center;">
                <a-input v-model:value="form2.quantity1" :disabled="saving" style="margin-right: 5px;" /> —
                <a-input v-model:value="form2.quantity2" :disabled="saving" style="margin-left: 5px;" />
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item>
          <a-space>
            <a-button type="primary" :loading="saving" @click="submit2">开始采集</a-button>
            <a-button :disabled="saving" @click="clear2">清空</a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </a-tab-pane>
    <template #rightExtra>
      <a-steps>
        <a-step title="采集数据" />
        <a-step title="认领产品" />
        <a-step title="编辑产品" />
        <a-step title="发布产品" />
      </a-steps>
    </template>
  </a-tabs>
  <div class="list">
    <div class="d-flex justify-space-between mb-2">
      <a-space>
        <a-button type="primary" @click="getData">刷新数据</a-button>
        <a href="javascript:;" @click="showtask">查看采集日志</a>
      </a-space>
      <a-space>
        <!-- <a-button type="primary">批量认领</a-button> -->
        <a-button type="danger">批量删除</a-button>
      </a-space>
    </div>
    <a-tabs v-model:activeKey="claimTab" @change="getData">
      <a-tab-pane key="1" :tab="`全部(${count})`"></a-tab-pane>
      <a-tab-pane key="2" :tab="`未认领(${unclaim_count})`"></a-tab-pane>
      <a-tab-pane key="3" :tab="`已认领(${claimed_count})`"></a-tab-pane>
    </a-tabs>
    <a-table bordered :columns="columns" :data-source="data" :scroll="{ x: width }" :loading="dataLoading"
      :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
      @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
      :row-selection="{ selectedRowKeys, onChange: (keys) => { selectedRowKeys = keys; } }">
      <template #bodyCell="{ record, column }">
        <div v-if="column.dataIndex === 'main_image_url'" class="text-center" style="width:85px;">
          <a-tooltip v-if="record.images[0]" color="white" placement="rightBottom">
            <template #title>
              <a-image :src="record.images[0]" width="480px" height="480px" style="object-fit: contain;height:100%;">
                <template #placeholder>
                  <a-image :src="image_placeholder" width="480px" height="480px" :preview="false" />
                </template>
              </a-image>
            </template>
            <a href="javascript:;">
              <a-image :src="record.images[0]" width="60px" height="60px" style="object-fit: contain;height:100%;">
                <template #placeholder>
                  <a-image :src="image_placeholder" width="60px" height="60px" :preview="false" />
                </template>
              </a-image>
            </a>
          </a-tooltip>
          <a-image v-else :src="image_placeholder" width="60px" height="60px" style="object-fit: contain;height:100%;"
            :preview="false">
            <template #placeholder>
              <a-image :src="image_placeholder" width="60px" height="60px" :preview="false" />
            </template>
          </a-image>
          <p>来源：<a target="_blank" :href="record.url">{{ record.source }}</a></p>
        </div>
        <template v-if="column.dataIndex === 'item_name'">
          <a-tooltip>
            <template #title>
              {{ record.item_name }}
            </template>
            <p class="in3line">
              {{ record.item_name }}
            </p>
          </a-tooltip>
        </template>
        <template v-if="column.dataIndex === 'product_details'">
          <a-tooltip>
            <template #title>
              <p v-for="item in record.product_details" :key="item.label">
                <span class="text-info">{{ item.label }}: </span>
                <span>{{ item.value }}</span>
              </p>
            </template>
            <p class="in3line">
              {{ record._product_details }}
            </p>
          </a-tooltip>
        </template>
        <template v-if="column.dataIndex === 'claimed'">
          <a v-if="record.claimed" href="javascript:;" @click="showclaimhis(record._id)" class="text-success underline">
            已认领
          </a>
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <div class="d-flex flex-column">
            <!-- <a-dropdown>
              <a class="ant-dropdown-link" @click.prevent>
                认领到
                <DownOutlined />
              </a>
              <template #overlay>
                <a-menu @click="({ key }) => handleMenuClick(key, record._id)">
                  <a-menu-item key="claim_amz">
                    <a href="javascript:;" class="underline text-info">[Amazon刊登]草稿箱</a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown> -->
            <a href="javascript:;" @click="edit(record._id)"> 编辑 </a>
            <a-dropdown>
              <a class="ant-dropdown-link" @click.prevent>
                更多
                <DownOutlined />
              </a>
              <template #overlay>
                <a-menu @click="({ key }) => handleMenuClick(key, record._id)">
                  <a-menu-item key="remark">
                    <a href="javascript:;" class="underline text-info">添加备注</a>
                  </a-menu-item>
                  <a-menu-item key="delete">
                    <a href="javascript:;" class="underline text-error">删除</a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </template>
      </template>
    </a-table>
  </div>
  <a-modal v-model:visible="taskModal" title="数据采集日志" width="1200px" centered @cancel="hidetask" :footer="null">
    <a-table bordered :columns="tcolumns" :data-source="tasks" :scroll="{ x: twidth }" :loading="taskLoading"
      :pagination="{ current: tpage, pageSize: tlimit, total: tcount, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
      @change="(pagination) => { tpage = pagination.current; tlimit = pagination.pageSize; }" row-key="_id">
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'platform'">
          {{ getplatformname(record.platform) }}
        </template>
        <template v-if="column.dataIndex === 'main_image_url'">
          <a-tooltip v-if="record.images[0]" color="white" placement="rightBottom">
            <template #title>
              <a-image :src="record.images[0]" width="480px" height="480px" style="object-fit: contain;height:100%;">
                <template #placeholder>
                  <a-image :src="image_placeholder" width="480px" height="480px" :preview="false" />
                </template>
              </a-image>
            </template>
            <a href="javascript:;">
              <a-image :src="record.images[0]" width="80px" height="80px" style="object-fit: contain;height:100%;">
                <template #placeholder>
                  <a-image :src="image_placeholder" width="80px" height="80px" :preview="false" />
                </template>
              </a-image>
            </a>
          </a-tooltip>
          <a-image v-else :src="image_placeholder" width="80px" height="80px" style="object-fit: contain;height:100%;"
            :preview="false">
            <template #placeholder>
              <a-image :src="image_placeholder" width="80px" height="80px" :preview="false" />
            </template>
          </a-image>
        </template>
        <template v-if="column.dataIndex === 'state'">
          <span v-if="record.state === 'success'" class="text-success">
            采集成功
          </span>
          <span v-if="record.state === 'fail'" class="text-error">
            采集失败
          </span>
          <span v-if="record.state === 'pending'" class="text-warning">
            采集中 <loading-outlined />
          </span>
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <a-space>
            <a v-if="record.state === 'fail'" href="javascript:;" class="text-error" @click="reset(record._id, 0)">重试</a>
            <template v-if="envOS === 'darwin'">
              <a v-if="record.state === 'pending'" href="javascript:;" class="text-error"
                @click="reset(record._id, 1)">手动采集</a>
              <a v-if="record.state === 'success'" href="javascript:;" class="text-error"
                @click="reset(record._id, 1)">重新采集</a>
            </template>
          </a-space>
        </template>
      </template>
    </a-table>
  </a-modal>
  <a-modal v-model:visible="claimModal" title="选择店铺" width="600px" @cancel="() => claimModal = false"
    :maskClosable="false">
    <template #footer>
      <a-button type="primary" @click="confirmclaim" :loading="claiming"
        :disabled="checkedClaimShopIds.length === 0">确定</a-button>
    </template>
    <a-checkbox v-model:checked="allClaimShopChecked" @change="onAllClaimShopCheckedChange" :disabled="claiming"> 全选
    </a-checkbox>
    <a-divider style="margin: 10px 0;" />
    <a-row>
      <a-col :span="8" v-for="(item, index) in claimShops" :key="item._id">
        <div class="d-flex flex-column pa-1 ma-1" style="border:1px solid #ddd;">
          <a-checkbox v-model:checked="item.checked" @change="(e) => onClaimShopCheckedChange(e, index)"
            :disabled="claiming">
            {{ item.gname }}-{{ item.area }}
          </a-checkbox>
          <a-divider style="margin: 10px 0;" />
          <div class="d-flex flex-wrap">
            <a-checkbox v-for="(shop, sindex) in item.shops" :key="shop._id" v-model:checked="shop.checked"
              @change="(e) => onShopCheckedChange(e, index, sindex)" :disabled="claiming">
              {{ shop.region }}
            </a-checkbox>
          </div>
        </div>
      </a-col>
    </a-row>
  </a-modal>
  <a-drawer title="支持站点" placement="right" v-model:visible="supportsitedrawer" width="30%">
    <a-row :gutter="16">
      <a-col :span="6" v-for="item in supportsites" :key="item.id">
        <a :href="item.url" target="_blank" :title="item.title">
          <img :src="item.icon" :alt="item.title" style="width:100%;object-fit:cover;">
        </a>
      </a-col>
    </a-row>
  </a-drawer>
  <a-drawer title="认领记录" placement="right" v-model:visible="claimHisDrawer" width="30%">
    <a-list size="small" bordered :data-source="claimHis"
      :pagination="{ pageSize: 20, hideOnSinglePage: true, showSizeChanger: false, }">
      <template #renderItem="{ item }">
        <a-list-item>
          {{ item.opuser }} 于 {{ item.optime }} 认领到 {{ item.shopname }}
          <!-- <MdPreview :modelValue="'`' + item.opuser + '` 于 `' + item.optime + '` 认领到 `' + item.shopname + '`'" /> -->
        </a-list-item>
      </template>
    </a-list>
  </a-drawer>
</template>
<script>
import { LoadingOutlined, DownOutlined, } from '@ant-design/icons-vue';
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onActivated,
  getCurrentInstance,
} from "vue";
import _ from "underscore";
import { message, Modal } from "ant-design-vue";
const columns = [
  { title: '缩略图', dataIndex: 'main_image_url', width: 100, },
  { title: '标题', dataIndex: 'item_name', width: 400, },
  { title: '描述', dataIndex: 'product_details', width: 300, },
  { title: '售价', dataIndex: 'standard_price', width: 80, },
  { title: '属性', dataIndex: 'variation', width: 80, },
  { title: '认领记录', dataIndex: 'claimed', width: 80, },
  { title: '创建时间', dataIndex: 'create_time', width: 150, },
  { title: '操作', dataIndex: 'operation', width: 150, fixed: 'right' },
];
const tcolumns = [
  { title: '平台', dataIndex: 'platform', width: 80, },
  { title: '缩略图', dataIndex: 'main_image_url', width: 100, },
  { title: '链接', dataIndex: 'url', width: 300, },
  { title: '状态', dataIndex: 'state', width: 100, },
  { title: '时间', dataIndex: 'create_time', width: 150, },
  { title: '操作', dataIndex: 'operation', width: 150, fixed: 'right' },
];
export default defineComponent({
  name: "ShopsPublishGather",
  components: { LoadingOutlined, DownOutlined },
  setup() {
    const { proxy } = getCurrentInstance();
    const formRef1 = ref();
    const formRef2 = ref();
    const envOS = reactive(process.env.OS);
    const state = reactive({
      activeTab: "1",
      form1: { urls: "", },
      form2: {
        keywords: "",
        count: "",
        price1: "",
        price2: "",
        quantity1: "",
        quantity2: "",
      },
      saving: false,
      selectedRowKeys: [],
      claimTab: "2",
      page: 1,
      limit: 10,
      length: 0,
      count: 0,
      unclaim_count: 0,
      claimed_count: 0,
      dataLoading: false,
      data: [],
      supportsites: [],
      supportsitedrawer: false,
      tpage: 1,
      tlimit: 10,
      tlength: 0,
      tcount: 0,
      taskLoading: false,
      tasks: [],
      taskModal: false,
      claimid: '',
      claimShops: [],
      claimModal: false,
      allClaimShopChecked: false,
      claiming: false,
      claimHis: [],
      claimHisDrawer: false,
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    const twidth = computed(() => proxy.$utils.twidth(tcolumns));
    const checkedClaimShopIds = computed(() => {
      let shopids = [];
      state.claimShops.forEach(n => {
        n.shops.forEach(m => {
          if (m.checked) shopids.push(m._id);
        });
      });
      return shopids;
    });
    watch(() => state.page, () => getData());
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    watch(() => state.tpage, () => getTasks());
    watch(() => state.tlimit, () => {
      if (state.tpage !== 1) state.tpage = 1;
      else getTasks();
    });
    onActivated(() => {
      getData();
    })
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/listingcollect/list", { page: state.page, limit: state.limit, claimTab: state.claimTab, }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.data = _.map(res.data.list, n => {
            return {
              ...n,
              _product_details: _.reduce(n.product_details || [], (a, b) => a + ` ${b.label}:${b.value}`, ''),
              create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
            }
          });
          state.length = res.data.length;
          state.count = res.data.count;
          if (state.length > 0 && state.page > state.length) state.page = 1;
          state.supportsites = res.data.supportsites;
          state.unclaim_count = res.data.unclaim_count;
          state.claimed_count = res.data.claimed_count;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const showtask = () => {
      state.taskModal = true;
      getTasks();
    }
    const hidetask = () => {
      state.taskModal = false;
    }
    const getTasks = () => {
      state.taskLoading = true;
      proxy.$api.doAPI("/listingcollect/tasks", { page: state.tpage, limit: state.tlimit }).then((res) => {
        state.taskLoading = false;
        if (res.code === 0) {
          state.tasks = _.map(res.data.list, n => {
            return {
              ...n, create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
            }
          });
          state.tlength = res.data.tlength;
          state.tcount = res.data.tcount;
          if (state.tlength > 0 && state.tpage > state.tlength) state.tpage = 1;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.taskLoading = false;
        console.error(err);
      });
    }
    const submit1 = async () => {
      try {
        await formRef1.value.validateFields();
        state.saving = true;
        proxy.$api.doAPI("/listingcollect/save1", { form: state.form1 }).then((res) => {
          state.saving = false;
          if (res.code === 0) {
            if (res.data.unsupporturls.length > 0) {
              message.error(`不支持的商品链接：\n${res.data.unsupporturls.join('\n')}`);
              state.form1 = {
                urls: res.data.unsupporturls.join('\n'),
              };
            } else {
              message.success("提交成功");
              clear1();
            }
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.saving = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    }
    const clear1 = () => {
      state.form1 = {
        urls: "",
      };
      formRef1.value.clearValidate();
    }
    const submit2 = async () => {
      try {
        await formRef2.value.validateFields();
        state.saving = true;
        proxy.$api.doAPI("/listingcollect/save2", { form: state.form2 }).then((res) => {
          state.saving = false;
          if (res.code === 0) {
            message.success("提交成功");
            clear2();
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.saving = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    }
    const clear2 = () => {
      state.form2 = {
        keywords: "",
        count: "",
        price1: "",
        price2: "",
        quantity1: "",
        quantity2: "",
      };
      formRef2.value.clearValidate();
    }
    const reset = (_id, type) => {
      state.taskLoading = true;
      proxy.$api.doAPI("/listingcollect/reset", { _id, type }).then((res) => {
        state.taskLoading = false;
        getTasks();
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.taskLoading = false;
        console.error(err);
      });
    }
    const remove = (_id) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/listingcollect/remove", { _id }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const edit = (id) => {
      proxy.$utils.downloadBrowser({ href: `/shops/published/gatherform?id=${id}` });
    }
    const handleMenuClick = (key, id) => {
      console.log('handleMenuClick', key, id);
      switch (key) {
        case "claim_amz": //认领到亚马逊采集箱
          showclaim(id, 'amz');
          break;
        case "remark": //添加备注

          break;
        case "delete": //删除
          Modal.confirm({
            title: '确认删除',
            content: `确认要采集商品数据吗？`,
            onOk() {
              remove(id)
            },
            onCancel() {
            },
          });
          break;
      }
    }
    const showclaimhis = (_id) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/listingcollect/showclaimhis", { _id }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.claimHis = res.data.claim_his;
          state.claimHisDrawer = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const showclaim = (_id, platform) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/listingcollect/showclaim", { platform }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.claimid = _id;
          state.claimShops = res.data.shops;
          state.allClaimShopChecked = false;
          state.claimModal = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const confirmclaim = () => {
      if (checkedClaimShopIds.value.length > 0) {
        state.claiming = true;
        proxy.$api.doAPI("/listingcollect/confirmclaim", { _id: state.claimid, shopids: checkedClaimShopIds.value }).then((res) => {
          state.claiming = false;
          if (res.code === 0) {
            state.claimModal = false;
            getData();
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.claiming = false;
          console.error(err);
        });
      }
    }
    const onAllClaimShopCheckedChange = (e) => {
      console.log('onAllClaimShopCheckedChange', e);
      let checked = e.target.checked;
      state.claimShops.forEach(n => {
        n.checked = checked;
        n.shops.forEach(m => m.checked = checked);
      });
    }
    const onClaimShopCheckedChange = (e, index) => {
      console.log('onClaimShopCheckedChange', e, index);
      let checked = e.target.checked;
      state.claimShops[index].shops.forEach(n => n.checked = checked);
      checkAllClaimShop();
    }
    const onShopCheckedChange = (e, index, sindex) => {
      console.log('onShopCheckedChange', e, index, sindex);
      let checked = e.target.checked;
      state.claimShops[index].shops[sindex].checked = checked;
      checkClaimShop(index);
      checkAllClaimShop();
    }
    const checkAllClaimShop = () => {
      let allClaimShopChecked = true;
      state.claimShops.forEach(n => {
        if (!n.checked) allClaimShopChecked = false;
      });
      state.allClaimShopChecked = allClaimShopChecked;
    }
    const checkClaimShop = (index) => {
      let allShopChecked = true;
      state.claimShops[index].shops.forEach(n => {
        if (!n.checked) allShopChecked = false;
      });
      state.claimShops[index].checked = allShopChecked;
    }
    const showsupportsite = () => {
      state.supportsitedrawer = true;
    }
    const getplatformname = (platform) => {
      return _.findWhere(state.supportsites, { id: platform })?.title;
    }
    return {
      ...toRefs(state),
      envOS,
      formRef1,
      formRef2,
      columns,
      tcolumns,
      width,
      twidth,
      checkedClaimShopIds,
      getData,
      showtask,
      hidetask,
      submit1,
      clear1,
      submit2,
      clear2,
      reset,
      edit,
      handleMenuClick,
      showclaimhis,
      confirmclaim,
      onAllClaimShopCheckedChange,
      onClaimShopCheckedChange,
      onShopCheckedChange,
      showsupportsite,
      getplatformname,
      image_placeholder: proxy.$constant.image_placeholder,
    }
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-row.ant-form-item) {
  text-align: right;
}

p {
  margin: 0;
  padding: 0;
}
</style>
