<template>
  <div class="d-flex align-center mb-2">
    <span style="width:75px;">所在仓库：</span>
    <a-dropdown>
      <template #overlay>
        <a-menu @click="handleMenuClick">
          <a-menu-item key="1">默认仓库</a-menu-item>
          <a-menu-item key="2">二里岗仓库</a-menu-item>
        </a-menu>
      </template>
      <a-button>
        默认仓库
        <DownOutlined />
      </a-button>
    </a-dropdown>
  </div>
  <div class="d-flex align-center mb-2">
    <span style="width:80px;">查询搜索：</span>
    <a-input-search v-model:value="value" placeholder="存库名称查询" enter-button="搜索" @search="onSearch" />
  </div>
  <div class="d-flex align-center mb-2">
    <a-button type="primary">导出库龄信息</a-button>
  </div>
  <a-table :columns="columns" :data-source="data" :scroll="{ x: width }">
    <template #bodyCell="{ column }">
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <a href="javascript:;">编辑</a>
          <a-popconfirm v-if="data.length" title="确定要删除?" @confirm="onDelete(record.key)">
            <a href="javascript:;" class="text-error">删除</a>
          </a-popconfirm>
        </a-space>
      </template>
    </template>
  </a-table>
</template>
<script>
import { DownOutlined, SearchOutlined, PlusCircleOutlined } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from 'vue';
import _ from "underscore";
const columns = [
  { title: '商品名称', dataIndex: 'shopname', width: 100, align: 'center' },
  { title: '商品编码', dataIndex: 'shopcode', width: 100, align: 'center' },
  { title: '仓库', dataIndex: 'store', width: 100, align: 'center' },
  { title: '商品规格', dataIndex: 'shopsize', width: 100, align: 'center' },
  { title: '现存数量', dataIndex: 'num', width: 100, align: 'center' },
  { title: '入库日期', dataIndex: 'indate', width: 100, align: 'center' },
  { title: '最新出库日期', dataIndex: 'outdate', width: 100, align: 'center' },
  {
    title: '0-30天',
    children: [
      { title: '数量', dataIndex: 'age', key: 'age', width: 80, align: 'center' },
      { title: '金额', dataIndex: 'age', key: 'age', width: 80, align: 'center' }
    ]
  },
  {
    title: '31-60天',
    children: [
      { title: '数量', dataIndex: 'age', key: 'age', width: 80, align: 'center' },
      { title: '金额', dataIndex: 'age', key: 'age', width: 80, align: 'center' }
    ]
  },
  {
    title: '61-90天',
    children: [
      { title: '数量', dataIndex: 'age', key: 'age', width: 80, align: 'center' },
      { title: '金额', dataIndex: 'age', key: 'age', width: 80, align: 'center' }
    ]
  },
  {
    title: '91-180天',
    children: [
      { title: '数量', dataIndex: 'age', key: 'age', width: 80, align: 'center' },
      { title: '金额', dataIndex: 'age', key: 'age', width: 80, align: 'center' }
    ]
  },
  {
    title: '180天以上',
    children: [
      { title: '数量', dataIndex: 'age', key: 'age', width: 80, align: 'center' },
      { title: '金额', dataIndex: 'age', key: 'age', width: 80, align: 'center' }
    ]
  },
  { title: '创建时间', dataIndex: 'createtime', width: 150, align: 'center' },
  { title: '操作', dataIndex: 'operation', width: 100, fixed: 'right', align: 'center' },
];
const data = [];
for (let i = 0; i < 5; i++) {
  data.push({
    index: i + 1,
    parent: '',
    name: '衣服' + i,
    createtime: ''
  });
}
export default defineComponent({
  name: 'InventoryManageInventoryage',
  components: {
    DownOutlined,
    SearchOutlined,
    PlusCircleOutlined
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      data,
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    return {
      ...toRefs(state),
      columns,
      width,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>
