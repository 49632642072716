<template>
  <div class="d-flex align-center mb-2">
    <span style="width:80px;">查询搜索：</span>
    <a-input-search v-model:value="searchText" placeholder="输入账号名称查询" enter-button="搜索" allow-clear @search="getData" />
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-space>
      <a-button type="primary" @click="getData">刷新数据</a-button>
    </a-space>
    <a-space>
      <a-button type="danger" @click="bindAuthPre">
        <template #icon>
          <PlusCircleOutlined />
        </template>新增授权
      </a-button>
    </a-space>
  </div>
  <a-table :columns="columns" bordered :data-source="data" :loading="dataLoading" :scroll="{ x: width, y: 600 }"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
    :row-selection="{ selectedRowKeys, onChange: (keys) => { selectedRowKeys = keys; } }">
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'auth_state'">
        <template v-if="record.auth_state">
          <span v-if="record.auth_timeout" class="text-error">
            授权过期
          </span>
          <span v-else-if="record.auth_state === 'success'" class="text-success">
            授权有效
          </span>
          <span v-else class="text-error">
            授权无效
          </span>
        </template>
        <span v-else class="text-error">
          未授权
        </span>
      </template>
      <template v-if="column.dataIndex === 'state'">
        <span v-if="record.enabled" class="text-success">
          启用
        </span>
        <span v-else class="text-error">
          停用
        </span>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <a v-if="record.auth_state === 'success'" href="javascript:;" @click="showAddress(record)">收货地址设置</a>
          <a href="javascript:;" @click="editBuyer(record)">编辑</a>
          <a v-if="record.enabled" href="javascript:;" class="text-error" @click="disable(record)">停用</a>
          <a v-else href="javascript:;" class="text-success" @click="enable(record)">启用</a>
          <template v-if="record.auth_state === 'success'">
            <a href="javascript:;" class="text-error" @click="reAuth">重新授权</a>
          </template>
        </a-space>
      </template>
    </template>
  </a-table>
  <a-modal v-model:visible="buyerModal" :title="`${buyerForm._id !== '' ? '编辑1688账号信息' : '新增授权账号'}`" @ok="editBuyer"
    @cancel="cancelSaveBuyer" ok-text="确认" cancel-text="取消" :confirm-loading="buyerSaving"
    :cancel-button-props="{ disabled: buyerSaving }" :maskClosable="false" :closable="!buyerSaving">
    <a-form ref="formRef" name="buyerForm" :model="buyerForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }"
      autocomplete="off">
      <a-form-item label="账号名称" name="name" :rules="[{ required: true, message: '账号名称不能为空' }]">
        <a-input v-model:value="buyerForm.name" placeholder="请输入账号名称" :disabled="buyerSaving" />
      </a-form-item>
      <a-form-item label="备注" name="remark">
        <a-textarea v-model:value="buyerForm.remark" placeholder="备注" :auto-size="{ minRows: 2, maxRows: 2 }"
          :disabled="buyerSaving" />
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="addressModal" title="收货地址设置" width="1000px" :maskClosable="false" @cancel="cancelSaveAddress">
    <a-alert message="首次使用可以【同步1688收货地址】！如果配置了1688后台收货地址与仓库关联之后，针对该仓库的采购单在进行1688下单时会自动选择关联的地址~" type="info" show-icon
      class="mb-2" />
    <a-table :columns="acolumns" bordered :data-source="addressdata" :scroll="{ x: 600, y: 600 }" :pagination="false">
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'receiveaddress'">
          {{ record.addressCodeText }} {{ record.address }} （{{ record.fullName }}收）{{ record.mobilePhone }}
        </template>
        <template v-if="column.dataIndex === 'warehouseid'">
          <a-select v-model:value="record.warehouseid" :options="warehouses" placeholder="请选择仓库" show-search>
          </a-select>
        </template>
      </template>
    </a-table>
    <template #footer>
      <a-button type="primary" style="color: #fff;background-color: #2db7f5;border-color: #2db7f5;" @click="syncAddress"
        :loading="syncing">同步1688收货地址</a-button>
      <a-button type="default" @click="cancelSaveAddress">关闭</a-button>
    </template>
  </a-modal>
  <a-modal v-model:visible="bindAuthModal" :maskClosable="false" closable width="600px" @cancel="authSuccess">
    <template #title>
      <span class="text-h6">1688账号授权</span>
    </template>
    <a-row :gutter="16">
      <a-col :span="3"></a-col>
      <a-col :span="18">
        <a-steps :current="bindAuthForm.step">
          <a-step title="开始授权" disabled />
          <a-step title="获取授权" disabled />
        </a-steps>
      </a-col>
      <a-col :span="3"></a-col>
    </a-row>
    <a-row v-if="bindAuthForm.step === 0" :gutter="16" class="mt-4 alert-info">
      <a-col :span="4"></a-col>
      <a-col :span="16">
        <div class="text-center">
          <p class="font-weight-bold text-h7 mt-2">第一步：开始授权</p>
          <p class="text-h10 mt-1">提醒：点击申请后，用账号获取验证码，进入第二步。</p>
          <p class="text-h10 mt-1">注：如未订购服务，可点击<a
              href="https://pc.1688.com/product/detail.htm?productCode=cXH%2BQplvcuJnUZYtw7wXlUXvJhEJVP811LsPp56xgvY%3D&productType=GROUP&spm=null"
              target="_blank">去订购</a>,订购完毕之后再点击申请</p>
        </div>
      </a-col>
      <a-col :span="4"></a-col>
      <a-col :span="8"></a-col>
      <a-col :span="8">
        <div class="text-center">
          <a-button class="mt-2 mb-2" block type="primary" @click="bindAuth" :loading="authBinding">点击申请</a-button>
        </div>
      </a-col>
      <a-col :span="8"></a-col>
    </a-row>
    <a-row v-if="bindAuthForm.step === 1" :gutter="16" class="mt-4 alert-info">
      <a-col :span="2"></a-col>
      <a-col :span="18">
        <div class="text-center">
          <p class="font-weight-bold text-h7 mt-2">第二步：获取授权</p>
          <p class="text-h10 mt-1 mb-2">提醒：您授权的1688帐号，是否已经授权成功？确定授权成功后将刷新获取1688帐号授权结果</p>
        </div>
      </a-col>
      <a-col :span="2"></a-col>
    </a-row>
    <template #footer>
      <a-space v-if="bindAuthForm.step === 1">
        <a-button class="btn-success" @click="authSuccess">已授权成功</a-button>
        <a-button class="text-gray" @click="authFail">遇到问题，重新授权</a-button>
      </a-space>
    </template>
  </a-modal>
  <a-modal v-model:visible="confirmAuthModal" title="添加授权" @ok="confirmAuth" @cancel="cancelAuth" ok-text="确认"
    cancel-text="取消" :confirm-loading="authConfirming" :cancel-button-props="{ disabled: authConfirming }"
    :maskClosable="false" :closable="!authConfirming">
    <a-form ref="confirmAuthRef" name="confirmAuthForm" :model="confirmAuthForm" :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }" autocomplete="off">
      <a-form-item label="账号名称" name="name" :rules="[{ required: true, message: '账号名称不能为空' }]">
        <a-input v-model:value="confirmAuthForm.name" placeholder="请输入账号名称" :disabled="authConfirming" />
      </a-form-item>
      <a-form-item label="备注" name="remark">
        <a-textarea v-model:value="confirmAuthForm.remark" placeholder="备注" :auto-size="{ minRows: 2, maxRows: 2 }"
          :disabled="authConfirming" />
      </a-form-item>
    </a-form>
    <p class="text-error"> {{ authError }} </p>
  </a-modal>
</template>
<script>
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { DownOutlined, SearchOutlined, PlusCircleOutlined, UploadOutlined, LinkOutlined, EditOutlined, } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import { useRoute, useRouter } from "vue-router";
import _ from "underscore";
import Cookies from 'js-cookie';
const columns = [
  { title: '账号名称', dataIndex: 'name', width: 150, },
  { title: '1688账号名称', dataIndex: 'resource_owner', width: 150, },
  // { title: '账号类型', dataIndex: 'type', width: 150, },
  { title: '过期时间', dataIndex: 'expire_time', width: 150, },
  { title: '授权状态', dataIndex: 'auth_state', width: 150, },
  { title: '状态', dataIndex: 'state', width: 100, },
  { title: '创建时间', dataIndex: 'create_time', width: 150, },
  { title: '操作', dataIndex: 'operation', width: 280, fixed: 'right', align: 'center' },
];
const acolumns = [
  { title: '1688收货地址', dataIndex: 'receiveaddress', width: 200, },
  { title: '下单仓库', dataIndex: 'warehouse', width: 100, },
];
export default defineComponent({
  name: 'PurchaseSupplier16688',
  components: { DownOutlined, SearchOutlined, PlusCircleOutlined, UploadOutlined, LinkOutlined, EditOutlined },
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const formRef = ref();
    const confirmAuthRef = ref();
    const publicPath = reactive(process.env.BASE_URL);
    const state = reactive({
      searchText: "",
      dataLoading: false,
      page: 1,
      limit: 10,
      length: 0,
      count: 0,
      data: [],
      buyerSaving: false,
      buyerModal: false,
      buyerForm: {},
      addressdata: [],
      warehouses: [],
      addressModal: false,
      syncing: false,
      bindAuthModal: false,
      authBinding: false,
      bindAuthForm: {},
      confirmAuthForm: {},
      confirmAuthModal: false,
      authConfirming: false,
      authError: "",
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    watch(() => state.page, () => getData());
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1; else getData();
    });
    onActivated(() => {
      // console.log("onActivated");
      getData();
      console.log("route.query", route);
      if (route.query.code) {
        showAuth(route.query);
      }
    });
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/ae/buyerlist", { page: state.page, limit: state.limit, searchText: state.searchText }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.data = _.map(res.data.list, n => {
            return {
              ...n, create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
            }
          });
          state.length = res.data.length;
          state.count = res.data.count;
          if (state.length > 0 && state.page > state.length) state.page = 1;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const editBuyer = (item) => {
      state.buyerForm = { _id: item._id, name: item.name, remark: item.remark, };
      state.buyerModal = true;
    };
    const _editBuyer = async () => {
      try {
        await formRef.value.validateFields();
        state.buyerSaving = true;
        proxy.$api.doAPI("/ae/editbuyer", { buyerForm: state.buyerForm, }).then((res) => {
          state.buyerSaving = false;
          if (res.code === 0) {
            message.success("保存成功");
            state.buyerModal = false;
            getData();
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.buyerSaving = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    }
    const cancelSaveBuyer = () => {
      console.log('cancelSaveBuyer');
      formRef.value.clearValidate();
    }
    const showAddress = (item) => {
      state.dataLoading = true;
      state.curr_buyerid = item._id;
      proxy.$api.doAPI("/ae/addresslist", { buyerid: item._id }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.addressdata = res.data.list;
          state.addressModal = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const syncAddress = () => {
      state.syncing = true;
      proxy.$api.doAPI("/ae/syncaddress", { buyerid: state.curr_buyerid }).then((res) => {
        state.syncing = false;
        if (res.code === 0) {
          state.addressdata = res.data.list;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.syncing = false;
        console.error(err);
      });
    };
    const cancelSaveAddress = () => {
      console.log('cancelSaveAddress');
      state.addressModal = false;
    }
    const enable = (item) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/ae/enablebuyer", { _id: item._id, }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const disable = (item) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/ae/disablebuyer", { _id: item._id, }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const bindAuthPre = (item) => {
      state.bindAuthForm = {
        step: 0,
      };
      state.bindAuthModal = true;
    };
    const bindAuth = () => {
      state.authBinding = true;
      proxy.$api.doAPI("/ae/bindauth").then((res) => {
        state.authBinding = false;
        if (res.code === 0) {
          if (res.data.auth_url) {
            proxy.$utils.downloadBrowser({ href: res.data.auth_url });
            state.bindAuthForm.step = 1;
          }
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.authBinding = false;
        console.error(err);
      });
    };
    const reAuth = () => {
      proxy.$api.doAPI("/ae/reauth").then((res) => {
        if (res.code === 0) {
          if (res.data.auth_url) {
            proxy.$utils.downloadBrowser({ href: res.data.auth_url });
          }
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
      });
    };
    const authSuccess = () => {
      state.bindAuthModal = false;
      getData();
    };
    const authFail = () => {
      state.bindAuthModal = false;
      getData();
    };
    const showAuth = (query) => {
      if (query.reauth === "true") {
        proxy.$api.doAPI("/ae/confirmauth", query).then((res) => {
          state.authConfirming = false;
          if (res.code === 0) {
            Modal.success({
              title: "提示", content: "授权成功",
              onOk() {
                router.replace({ path: route.path }).catch(console.error);
                Cookies.remove('ae1688authcode');
                Cookies.remove('ae1688reauth');
                state.data = [];
                getData();
              },
            });
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.authConfirming = false;
          console.error(err);
        });
      } else {
        state.confirmAuthForm = {
          code: query.code,
          name: "", remark: "",
        };
        state.confirmAuthModal = true;
      }
    };
    const confirmAuth = async () => {
      try {
        const values = await confirmAuthRef.value.validateFields();
        console.log("Success:", values);
        console.log(state.confirmAuthForm);
        state.authConfirming = true;
        proxy.$api.doAPI("/ae/confirmauth", state.confirmAuthForm).then((res) => {
          state.authConfirming = false;
          if (res.code === 0) {
            Modal.success({
              title: "提示", content: "授权成功",
              onOk() {
                state.confirmAuthModal = false;
                router.replace({ path: route.path }).catch(console.error);
                Cookies.remove('ae1688authcode');
                Cookies.remove('ae1688reauth');
                state.data = [];
                getData();
              },
            });
          } else {
            // message.error(res.msg);
            state.authError = `操作失败：${res.msg}`;
          }
        }).catch((err) => {
          state.authConfirming = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    };
    const cancelAuth = () => {
      confirmAuthRef.value.clearValidate();
      if (state.authError) {
        router.replace({ path: route.path }).catch(console.error);
        Cookies.remove('ae1688authcode');
        Cookies.remove('ae1688reauth');
        state.data = [];
        getData();
      }
      state.authError = "";
    }
    return {
      ...toRefs(state),
      locale,
      publicPath,
      formRef,
      confirmAuthRef,
      columns,
      acolumns,
      width,
      getData,
      showAddress,
      editBuyer,
      _editBuyer,
      cancelSaveBuyer,
      enable,
      disable,
      bindAuthPre,
      bindAuth,
      reAuth,
      confirmAuth,
      cancelAuth,
      authSuccess,
      authFail,
      syncAddress,
      cancelSaveAddress,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

.alert-info {
  color: #16879d !important;
  background-color: #e9f9fc;
  border-color: #d3f3f9;
}

p {
  margin: 0;
  padding: 0;
}
</style>

