<template>
  <div class="d-flex align-center mb-2">
    <span style="width:75px;">查询搜索：</span>
    <a-input-search v-model:value="orderids" placeholder="多个单号用逗号隔开" enter-button="搜索" @search="getData" />
  </div>
  <div class="d-flex align-center mb-2">
    <a-row>
      <a-col><span style="width:75px;">所在仓库：</span></a-col>
      <a-col>
        <div>
          <a-tag :color="warehouseid === 0 ? 'processing' : 'default'" @click="changeWarehouse(0)" class="click">全部</a-tag>
          <a-tag v-for="item in warehouses" :key="item._id" :color="warehouseid === item._id ? 'processing' : 'default'"
            @click="changeWarehouse(item._id)" class="click">{{ item.name }}
          </a-tag>
        </div>
      </a-col>
    </a-row>
  </div>
  <div class="d-flex align-center mb-2">
    <a-row>
      <a-col><span style="width:75px;">所在店铺：</span></a-col>
      <a-col :span="22">
        <div>
          <a-tag :color="shopid === 0 ? 'processing' : 'default'" @click="changeShop(0)" class="click">全部</a-tag>
          <a-tag v-for="item in shops" :key="item._id" :color="shopid === item._id ? 'processing' : 'default'"
            @click="changeShop(item._id)" class="click">{{ item.name }}
          </a-tag>
        </div>
      </a-col>
    </a-row>
  </div>
  <div class="d-flex align-center mb-2">
    <a-row>
      <a-col>
        <span style="width:75px;">库存状态：</span>
      </a-col>
      <a-col>
        <div>
          <a-tag :color="inventorystate === 0 ? 'processing' : 'default'" @click="changeInventorystate(0)" class="click">全部</a-tag>
          <a-tag :color="inventorystate === 1 ? 'processing' : 'default'" @click="changeInventorystate(1)" class="click">有货</a-tag>
          <a-tag :color="inventorystate === 2 ? 'processing' : 'default'" @click="changeInventorystate(2)" class="click">缺货</a-tag>
        </div>
      </a-col>
    </a-row>
  </div>
  <div class="d-flex align-center mb-2">
    <a-row>
      <a-col>
        <span style="width:75px;">订单类型：</span>
      </a-col>
      <a-col>
        <div>
          <a-tag :color="ordertype === 0 ? 'processing' : 'default'" @click="changeOrdertype(0)" class="click">自发货</a-tag>
          <a-tag :color="ordertype === 1 ? 'processing' : 'default'" @click="changeOrdertype(1)" class="click">FBA</a-tag>
        </div>
      </a-col>
    </a-row>
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-space>
      <a-button type="primary" @click="bulkchoosewarehouse" :loading="warehouse_choosing">
        批量选择发货仓库
      </a-button>
      <a-button type="primary" @click="bulkchooselogistics" :loading="logistics_choosing">
        批量选择物流方式
      </a-button>
      <a-button type="primary" @click="bulkconfirmorder" :loading="orderconfirming">
        批量确认订单
      </a-button>
      <a-button type="primary" @click="bulkprintorder" :loading="orderprinting">
        批量打印面单
      </a-button>
      <ZhengBoAssistant />
      <a-dropdown>
        <template #overlay>
          <a-menu @click="handleBulkClick">
            <a-menu-item key="shipped">标记已发货</a-menu-item>
            <a-menu-item key="canceled" class="text-error">批量作废</a-menu-item>
            <a-menu-item-group title="批量标记自定义分类">
              <a-menu-item key="selectcustomtag">标记分类</a-menu-item>
              <a-menu-item key="removecustomtag">取消分类</a-menu-item>
            </a-menu-item-group>
            <a-menu-item key="virtualship">批量虚假发货</a-menu-item>
          </a-menu>
        </template>
        <a-button>
          批量操作
          <DownOutlined />
        </a-button>
      </a-dropdown>
    </a-space>
    <a-space>
      <a-button type="primary" @click="getData">同步订单</a-button>
      <a-dropdown>
        <template #overlay>
          <a-menu @click="handleExportClick">
            <a-menu-item-group title="导出订单">
              <a-menu-item key="1">导出勾选订单</a-menu-item>
              <a-menu-item key="2">导出全部页订单</a-menu-item>
            </a-menu-item-group>
            <a-menu-item-group title="导出给货代">
              <a-menu-item key="3">E邮宝模版</a-menu-item>
              <a-menu-item key="4">中邮模版</a-menu-item>
            </a-menu-item-group>
          </a-menu>
        </template>
        <a-button type="primary">
          导出订单
          <DownOutlined />
        </a-button>
      </a-dropdown>
    </a-space>
  </div>
  <a-table :columns="columns" bordered :data-source="data" :loading="dataLoading" :scroll="{ x: width, y: 600 }"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
    :row-selection="{ selectedRowKeys, onChange: (keys) => selectedRowKeys = keys }"
    v-model:expandedRowKeys="expandedRowKeys">
    <template #title>
      <MinusOutlined v-if="expanded" class="click" style="border:1px solid #ddd;" @click="foldall" />
      <PlusOutlined v-else class="click" style="border:1px solid #ddd;" @click="expandall" />
    </template>
    <template #headerCell="{ column }">
      <template v-if="column.dataIndex === 'shop_orderid'">
        <p>店铺</p>
        <p>订单号</p>
        <p>SKU</p>
      </template>
      <template v-if="column.dataIndex === 'buyer'">
        <p>买家</p>
      </template>
      <template v-if="column.dataIndex === 'order_amount'">
        <p>订单金额(原始货币)</p>
        <p>收入运费(原始货币)</p>
      </template>
      <template v-if="column.dataIndex === 'time'">
        <p>创建时间</p>
        <p>付款时间</p>
        <p>剩余发货</p>
        <p>交运时间</p>
      </template>
    </template>
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'shop_orderid'">
        <p>{{ record.shop?.name }}</p>
        <a-tooltip color="white">
          <template #title>
            <a href="javascript:;" @click="doCopy(record.orderid)">复制</a>
          </template>
          <p>{{ record.orderid }}</p>
        </a-tooltip>
        <p class="text-info in1line"> {{ showorderitems(record) }} </p>
        <p>
          <svg-icon v-if="record.has_battery" iconClass="dianchi1" :style="{ 'width': '20px', 'height': '20px' }"
            title="带电池" />
          <svg-icon v-else iconClass="dianchi" :style="{ 'width': '20px', 'height': '20px' }" title="不带电池" />
          <svg-icon v-if="record.has_magnet" iconClass="citie1" :style="{ 'width': '20px', 'height': '20px' }"
            title="带磁" />
          <svg-icon v-else iconClass="citie" :style="{ 'width': '20px', 'height': '20px' }" title="不带磁" />
          <svg-icon v-if="record.is_liquid" iconClass="yeti1" :style="{ 'width': '20px', 'height': '20px' }" title="液体" />
          <svg-icon v-else iconClass="yeti" :style="{ 'width': '20px', 'height': '20px' }" title="非液体" />
          <svg-icon v-if="record.is_powder" iconClass="fenchen1" :style="{ 'width': '20px', 'height': '20px' }"
            title="粉末" />
          <svg-icon v-else iconClass="fenchen" :style="{ 'width': '20px', 'height': '20px' }" title="非粉末" />
          <svg-icon v-if="record.items[0]?.product?.is_combine" iconClass="zuhesku1"
            :style="{ 'width': '20px', 'height': '20px' }" :title="record.items[0]?.seller_sku" />
        </p>
        <a v-if="record.skumatch_status === 'fullmatch'" href="javascript:;" @click="showDeclare(record)">
          申报
        </a>
      </template>
      <template v-if="column.dataIndex === 'warehouse'">
        <template v-if="record.warehouse">
          <a v-if="record.inventory_status" href="javascript:;" class="underline" @click="choosewarehouse(record)">
            {{ record.warehouse.name }}
          </a>
          <span v-else class="text-info">
            {{ record.warehouse.name }}
          </span>
          <a-tag v-if="record.inventory_status === 'success'" color="green">有货</a-tag>
          <a-tag v-else-if="record.inventory_status === 'fail'" color="red">缺货</a-tag>
          <a-tag v-else color="orange">正在匹配库存</a-tag>
        </template>
        <template v-else>
          <a v-if="record.skumatch_status === 'fullmatch'" href="javascript:;" class="text-success underline"
            @click="choosewarehouse(record)">
            选择发货仓库
          </a>
          <template v-else>
            <a-popconfirm v-if="record.skumatch_time" title="重新匹配SKU" @confirm="rematch(record)">
              <a href="javascript:;" class="text-error underline">重新匹配SKU</a>
            </a-popconfirm>
            <span v-else>正在匹配SKU</span>
          </template>
        </template>
      </template>
      <template v-if="column.dataIndex === 'logistics_channel'">
        <template v-if="record.channelbind">
          <a href="javascript:;" class="underline" @click="chooselogistics(record)">
            【{{ record.supplierbind.name }}】{{ record.channelbind.name }}
          </a>
          <div class="d-flex align-center">
            <a-tag v-if="record.logistics_status === 'pending'" color="orange">正在获取物流单号</a-tag>
            <div v-else-if="record.logistics_status === 'success'" class="d-flex align-center">
              <a-tooltip color="white">
                <template #title>
                  <a href="javascript:;" @click="doCopy(record.logistics_tracking_number)">复制</a>
                </template>
                <a-tag color="green">
                  {{ record.logistics_tracking_number }}
                </a-tag>
              </a-tooltip>
              <a-tooltip title="打印面单">
                <a href="javascript:;" @click="printorder(record)">
                  <svg-icon iconClass="printer" :style="{ 'width': '20px', 'height': '20px' }" />
                </a>
              </a-tooltip>
              <a-tooltip title="物流追踪">
                <a :href="`https://t.17track.net/zh-cn#nums=${record.logistics_tracking_number}`" target="_blank">
                  <svg-icon iconClass="track" :style="{ 'width': '20px', 'height': '20px' }" />
                </a>
              </a-tooltip>
            </div>
            <div v-else>
              <a-tag color="red">物流单号获取失败</a-tag>
              <span class="text-error">{{ record.logistics_reason }}</span>
            </div>
          </div>
        </template>
        <a href="javascript:;" v-else class="text-success underline" @click="chooselogistics(record)">选择物流渠道</a>
      </template>
      <template v-if="column.dataIndex === 'buyer'">
        <p>{{ record.shipping_country }}</p>
        <p>{{ record.shipping_state }}</p>
        <p>{{ record.shipping_postalcode }}</p>
        <p>{{ record._shipping_name || record.shipping_name }}</p>
        <a href="javascript:;" @click="showShipping(record)">修改</a>
      </template>
      <template v-if="column.dataIndex === 'order_amount'">
        <p>{{ record.amount }} {{ record.order_currency }}</p>
        <p>{{ record.freight }} {{ record.order_currency }}</p>
      </template>
      <template v-if="column.dataIndex === 'time'">
        <p :title="record.create_time">{{ record.create_time }}</p>
        <p title="付款时间">{{ record.purchase_time }}</p>
        <template v-if="record.order_status === 'Unshipped'">
          <p v-if="record.ship_timeout" style="font-size:16px;font-weight:bold;color:#ed5466">
            过期：{{ record.ship_time }}
          </p>
          <p v-else style="font-size:16px;font-weight:bold;color:#91c854">
            剩余发货：{{ record.ship_time }}
          </p>
        </template>
        <template v-if="record.order_status === 'Shipped'">
          <p>-</p>
        </template>
        <p title="交运时间">{{ record.delivery_time }}</p>
      </template>
      <template v-if="column.dataIndex === 'remark'">
        <a-tag v-if="record.mark_status === 'shipped'" class="text-error">标记发货</a-tag>
        <a-tag v-if="record.mark_status === 'canceled'" class="text-error">作废</a-tag>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <a-button type="primary" size="small" danger>拆分</a-button>
          <a-button type="primary" size="small">备注</a-button>
        </a-space>
      </template>
    </template>
    <template #expandedRowRender="{ record }">
      <a-table :columns="itemcolumns" :data-source="record.items" :scroll="{ x: iwidth }" :pagination="false">
        <template #bodyCell="{ record: irecord, column: icolumn }">
          <template v-if="icolumn.dataIndex === 'cover'">
            <a-tooltip v-if="irecord.product?.cover || irecord.shoplisting?.image_url" color="white"
              placement="rightBottom">
              <template #title>
                <a-image :src="irecord.product?.cover || irecord.shoplisting?.image_url" :width="300" :height="300"
                  style="object-fit: contain;height:100%;" :preview="false" />
              </template>
              <a href="javascript:;">
                <a-image :src="irecord.product?.cover || irecord.shoplisting?.image_url" :width="50" :height="50"
                  style="object-fit: contain;height:100%;" :preview="false" />
              </a>
            </a-tooltip>
            <a-image v-else :src="nosku" :width="50" :height="50" style="object-fit: contain;height:100%;"
              :preview="false" />
          </template>
          <template v-if="icolumn.dataIndex === 'info'">
            <div>
              <a-tooltip color="white">
                <template #title>
                  <a href="javascript:;" @click="doCopy(irecord.product?.sku || irecord.seller_sku)">复制</a>
                </template>
                <a href="javascript:;">
                  <span v-if="irecord.product?.is_combine" class="text-error">(组合)</span>
                  {{ irecord.product?.sku || irecord.seller_sku }}
                </a>
              </a-tooltip>
              <span class="text-error text-h6">×{{ irecord.quantity_ordered }}</span>
              <a-tooltip color="white">
                <template #title>
                  <a href="javascript:;" @click="doCopy(irecord.asin)">复制</a>
                </template>
                <a v-if="record.amazon_product_url" :href="`${record.amazon_product_url}${irecord.asin}`" target="_blank"
                  class="underline ml-1">{{ irecord.asin }}</a>
                <a v-else href="javascript:;" class="underline ml-1">{{ irecord.asin }}</a>
              </a-tooltip>
            </div>
            <p>{{ irecord.product?.name_cn || irecord.title }}</p>
            <a v-if="irecord.skumatch_status === 'success'" href="javascript:;" class="text-info underline"
              @click="matchsku(record, irecord)">
              更换
            </a>
            <a v-else href="javascript:;" class="text-error underline" @click="matchsku(record, irecord)">
              配对
            </a>
          </template>
          <template v-if="icolumn.dataIndex === 'cinfo'">
            <div v-for="item in irecord.product?.combine_list" :key="item.sku" class="d-flex justify-space-between"
              style="border-bottom:1px solid #ececec;">
              <div style="width:80px;">
                <a-tooltip v-if="item.cover" color="white" placement="rightBottom">
                  <template #title>
                    <a-image :src="item.cover" width="300px" height="300px" style="object-fit: contain;height:100%;"
                      :preview="false" />
                  </template>
                  <a href="javascript:;">
                    <a-image :src="item.cover" width="50px" height="50px" style="object-fit: contain;height:100%;"
                      :preview="false" />
                  </a>
                </a-tooltip>
                <a-image v-else :src="image_placeholder" width="50px" height="50px"
                  style="object-fit: contain;height:100%;" :preview="false" />
              </div>
              <div style="flex:1;">
                <div>
                  <a href="javascript:;">{{ item.sku }}</a>
                  <span class="text-error text-h6">×{{ item.quantity * irecord.quantity_ordered }}</span>
                </div>
                <p>{{ item.name_cn }}</p>
              </div>
            </div>
          </template>
          <template v-if="icolumn.dataIndex === 'warehouse'">
            <p>{{ irecord.warehouse?.name || '-' }}</p>
          </template>
          <template v-if="icolumn.dataIndex === 'inventory'">
            <p>{{ irecord.inventory?.quantity || '-' }}</p>
          </template>
          <template v-if="icolumn.dataIndex === 'skumatch_status'">
            <span v-if="irecord.skumatch_status === 'success'" class="text-success">成功</span>
            <span v-else class="text-error">{{ irecord.skumatch_reason || '-' }}</span>
          </template>
        </template>
      </a-table>
    </template>
  </a-table>
  <a-modal v-model:visible="warehouseModal" title="选择仓库" @ok="confirmWarehouse" ok-text="确认" cancel-text="取消"
    :confirm-loading="warehouse_choosing" :cancel-button-props="{ disabled: warehouse_choosing }" :maskClosable="false"
    :closable="!warehouse_choosing">
    <a-form ref="warehouseRef" name="warehouseForm" :model="warehouseForm" :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }" autocomplete="off">
      <a-form-item label="仓库" name="warehouseid" :rules="[{ required: true, message: '请选择仓库' }]">
        <a-select v-model:value="warehouseForm.warehouseid" style="width: 100%" :disabled="warehouse_choosing"
          show-search>
          <a-select-opt-group label="一件代发仓" v-if="warehouses0.length > 0">
            <a-select-option v-for="warehouse in warehouses0" :key="warehouse.warehouseid" :value="warehouse.warehouseid">
              {{ warehouse.name }}
            </a-select-option>
          </a-select-opt-group>
          <a-select-opt-group label="自建本地仓" v-if="warehouses1.length > 0">
            <a-select-option v-for="warehouse in warehouses1" :key="warehouse._id" :value="warehouse._id">
              {{ warehouse.name }}
            </a-select-option>
          </a-select-opt-group>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="logisticsModal" title="选择物流渠道" @ok="confirmLogistics" ok-text="确认" cancel-text="取消"
    :confirm-loading="logistics_choosing" :cancel-button-props="{ disabled: logistics_choosing }" :maskClosable="false"
    :closable="!logistics_choosing">
    <a-form ref="logisticsRef" name="logisticsForm" :model="logisticsForm" :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }" autocomplete="off">
      <a-form-item label="物流渠道" name="logistics_channel_bind_id" :rules="[{ required: true, message: '请选择物流渠道' }]">
        <a-select v-model:value="logisticsForm.logistics_channel_bind_id" :options="channelbinds" style="width: 100%"
          :disabled="logistics_choosing" show-search>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="skuModal" title="配对SKU" :maskClosable="false" closable width="800px" :footer="false">
    <div class="d-flex align-center mb-2">
      <div style="width:85px;">搜索类型：</div>
      <a-radio-group v-model:value="skuForm.type" size="small">
        <a-radio-button value="sku">库存SKU</a-radio-button>
        <a-radio-button value="name_cn">库存SKU名称</a-radio-button>
        <a-radio-button value="name_en">库存SKU英文名称</a-radio-button>
        <a-radio-button value="csku">组合SKU</a-radio-button>
        <a-radio-button value="cname_cn">组合SKU名称</a-radio-button>
        <a-radio-button value="vsku">库存虚拟SKU</a-radio-button>
        <a-radio-button value="cvsku">组合虚拟SKU</a-radio-button>
      </a-radio-group>
    </div>
    <div class="d-flex align-center mb-2">
      <div style="width:85px;">搜索内容：</div>
      <a-input-search v-if="skuForm.type === 'sku'" v-model:value="skuForm.text" placeholder="请输入库存SKU编码"
        enter-button="搜索" @search="searchSku" />
      <a-input-search v-if="skuForm.type === 'name_cn'" v-model:value="skuForm.text" placeholder="请输入库存SKU名称"
        enter-button="搜索" @search="searchSku" />
      <a-input-search v-if="skuForm.type === 'name_en'" v-model:value="skuForm.text" placeholder="请输入库存SKU英文名称"
        enter-button="搜索" @search="searchSku" />
      <a-input-search v-if="skuForm.type === 'csku'" v-model:value="skuForm.text" placeholder="请输入组合SKU编码"
        enter-button="搜索" @search="searchSku" />
      <a-input-search v-if="skuForm.type === 'cname_cn'" v-model:value="skuForm.text" placeholder="请输入组合SKU名称"
        enter-button="搜索" @search="searchSku" />
      <a-input-search v-if="skuForm.type === 'vsku'" v-model:value="skuForm.text" placeholder="请输入库存虚拟SKU编码"
        enter-button="搜索" @search="searchSku" />
      <a-input-search v-if="skuForm.type === 'cvsku'" v-model:value="skuForm.text" placeholder="请输入组合虚拟SKU编码"
        enter-button="搜索" @search="searchSku" />
    </div>
    <a-table :columns="skucolumns" bordered :data-source="skudata" :loading="skuloading" :scroll="{ y: 400 }"
      :pagination="{ current: skupage, pageSize: skulimit, total: skucount, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
      @change="(pagination) => { skupage = pagination.current; skulimit = pagination.pageSize; }">
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'cover'">
          <a-image :src="record.cover || image_placeholder" :width="50" :height="50"
            style="object-fit: contain;height:100%;" :preview="false" />
        </template>
        <template v-if="column.dataIndex === 'info'">
          <p>{{ record.sku }}<span v-if="record.is_combine" class="text-error">(组合)</span></p>
          <p>{{ record.name_cn }}</p>
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <a href="javascript:;" @click="confirmMatch(record)">选择</a>
        </template>
      </template>
    </a-table>
  </a-modal>
  <a-modal v-model:visible="declareListModal" :title="`申报信息【${declareOrderNo}】`" :maskClosable="false"
    :closable="!declareListLoading" width="1000px" :footer="false">
    <a-table :columns="declarecolumns" bordered :data-source="declareList" :scroll="{ y: 600 }"
      :loading="declareListLoading" :pagination="false">
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'operation'">
          <a href="javascript:;" @click="editDeclare(record)">编辑</a>
        </template>
      </template>
    </a-table>
  </a-modal>
  <a-modal v-model:visible="declareFormModal" :title="`申报信息【${declareForm.sku}】`" :maskClosable="false"
    :closable="!declareFormLoading" width="600px" @cancel="() => declareFormModal = false">
    <template #footer>
      <div class="d-flex justify-space-between align-center">
        <div>
          总价：
          <span style="color:#ed5466;font-weight:700;">
            {{ declareForm.declare_quantity * declareForm.declare_amount }}
          </span> USD
          总重量：
          <span style="color:#ed5466;font-weight:700;">
            {{ declareForm.declare_quantity * declareForm.declare_weight }}
          </span> g
        </div>
        <div>
          <a-checkbox v-model:checked="declareForm.update_product" :disabled="declareFormLoading"
            style="color:#3f56a1;font-weight:700;">
            同时修改商品申报信息
          </a-checkbox>
          <a-button @click="saveDeclare" type="primary" :loading="declareFormLoading">确认</a-button>
          <a-button @click="() => declareFormModal = false" :disabled="declareFormLoading">关闭</a-button>
        </div>
      </div>
    </template>
    <a-form ref="declareRef" name="declareForm" :model="declareForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }"
      autocomplete="off">
      <a-form-item label="申报中文名" name="declare_name_cn" :rules="[{ required: true, message: '请输入申报中文名' }]">
        <a-input v-model:value="declareForm.declare_name_cn" :disabled="declareFormLoading" />
      </a-form-item>
      <a-form-item label="申报英文名" name="declare_name_en" :rules="[{ required: true, message: '请输入申报英文名' }]">
        <a-input v-model:value="declareForm.declare_name_en" :disabled="declareFormLoading" />
      </a-form-item>
      <a-form-item label="申报价值" name="declare_amount" :rules="[{ required: true, message: '请输入申报价值' }]">
        <a-input v-model:value="declareForm.declare_amount" type="number" addon-after="USD"
          :disabled="declareFormLoading" />
      </a-form-item>
      <a-form-item label="申报重量" name="declare_weight" :rules="[{ required: true, message: '请输入申报重量' }]">
        <a-input v-model:value="declareForm.declare_weight" type="number" addon-after="克"
          :disabled="declareFormLoading" />
      </a-form-item>
      <a-form-item label="申报数量" name="declare_quantity" :rules="[{ required: true, message: '请输入申报数量' }]">
        <a-input v-model:value="declareForm.declare_quantity" type="number" :disabled="declareFormLoading" />
      </a-form-item>
      <a-form-item label="报关编码" name="hs_code">
        <a-input v-model:value="declareForm.hs_code" type="number" placeholder="非必填" :disabled="declareFormLoading" />
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="shippingModal" title="修改收件信息" @ok="saveShipping" ok-text="确认" cancel-text="取消"
    :confirm-loading="shippingLoading" :cancel-button-props="{ disabled: shippingLoading }" :maskClosable="false"
    :closable="!shippingLoading" width="800px">
    <a-form ref="shippingRef" name="shippingForm" :model="shippingForm" :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }" autocomplete="off">
      <a-row :gutter="24">
        <a-col :span="12">
          <a-form-item label="收件人" name="shipping_name" :rules="[{ required: true, message: '请输入收件人' }]">
            <a-input v-model:value="shippingForm.shipping_name" :disabled="shippingLoading" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="联系电话" name="shipping_phone" :rules="[{ required: true, message: '请输入联系电话' }]">
            <a-input v-model:value="shippingForm.shipping_phone" :disabled="shippingLoading" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="联系邮箱" name="shipping_email">
            <a-input v-model:value="shippingForm.shipping_email" :disabled="shippingLoading" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="国家/地区" name="shipping_countrycode">
            <a-select v-model:value="shippingForm.shipping_countrycode" :options="shippingCountrys" style="width: 100%"
              :disabled="shippingLoading" show-search>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="州/省" name="shipping_state">
            <a-input v-model:value="shippingForm.shipping_state" :disabled="shippingLoading" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="城市" name="shipping_city">
            <a-input v-model:value="shippingForm.shipping_city" :disabled="shippingLoading" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="地区" name="shipping_district">
            <a-input v-model:value="shippingForm.shipping_district" :disabled="shippingLoading" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="邮编" name="shipping_postalcode">
            <a-input v-model:value="shippingForm.shipping_postalcode" :disabled="shippingLoading" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label="地址" name="shipping_address"
        :rules="[{ required: true, message: '请输入地址' }]">
        <a-input v-model:value="shippingForm.shipping_address" :disabled="shippingLoading" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { DownOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onActivated,
  getCurrentInstance,
} from "vue";
import _ from "underscore";
import ZhengBoAssistant from "../../../components/ZhengBoAssistant.vue";
import { printBlob } from "../../../zhengbo";
const columns = [
  { title: "店铺/店长/订单号", dataIndex: "shop_orderid", width: 200 },
  { title: "仓库", dataIndex: "warehouse", width: 150 },
  { title: "物流渠道", dataIndex: "logistics_channel", width: 180 },
  { title: "买家", dataIndex: "buyer", width: 200 },
  { title: "订单金额", dataIndex: "order_amount", width: 120 },
  { title: "时间", dataIndex: "time", width: 200 },
  { title: "操作", dataIndex: "operation", width: 150, fixed: "right" },
];
const itemcolumns = [
  { title: '图片', dataIndex: 'cover', width: 50 },
  { title: 'SKU编号/数量/中文名称', dataIndex: 'info', width: 200 },
  { title: 'SKU编号/数量/中文名称', dataIndex: 'cinfo', width: 200 },
  { title: '仓库', dataIndex: 'warehouse', width: 100 },
  { title: "库存", dataIndex: "inventory", width: 100 },
  { title: "匹配状态", dataIndex: "skumatch_status", width: 100 },
];
const skucolumns = [
  { title: '图片', dataIndex: 'cover', width: 80 },
  { title: '商品信息', dataIndex: 'info', width: 400 },
  { title: "操作", dataIndex: "operation", width: 100, fixed: "right" },
];
const declarecolumns = [
  { title: 'SKU', dataIndex: 'sku', width: 180 },
  { title: '申报信息', dataIndex: 'info' },
  { title: "操作", dataIndex: "operation", width: 100, fixed: "right" },
]
export default defineComponent({
  name: "OrderPending",
  components: { DownOutlined, PlusOutlined, MinusOutlined, ZhengBoAssistant, },
  setup() {
    const { proxy } = getCurrentInstance();
    const warehouseRef = ref();
    const logisticsRef = ref();
    const declareRef = ref();
    const shippingRef = ref();
    const state = reactive({
      orderids: "",
      warehouseid: 0,
      warehouses: [],
      orderstate: 1,
      inventorystate: 0,
      ordertype: 0,
      page: 1,
      limit: 10,
      data: [],
      length: 0,
      count: 0,
      dataLoading: false,
      selectedRowKeys: [],
      warehouse_choosing: false,
      warehouseModal: false,
      warehouses0: [],
      warehouses1: [],
      warehouseForm: {},
      logistics_choosing: false,
      logisticsModal: false,
      channelbinds: [],
      logisticsForm: {},
      orderconfirming: false,
      skuModal: false,
      skuForm: { type: "sku", text: "", },
      skupage: 1,
      skulimit: 100,
      skudata: [],
      skulength: 0,
      skucount: 0,
      skuloading: false,
      order_id: "",
      declareOrderNo: "",
      declareList: [],
      declareListModal: false,
      declareListLoading: false,
      declareForm: {},
      declareFormModal: false,
      declareFormLoading: false,
      shippingForm: {},
      shippingModal: false,
      shippingLoading: false,
      shippingCountrys: [],
      orderprinting: false,
      expandedRowKeys: [],
      expanded: false,
      shopid: 0,
      shops: [],
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    const iwidth = computed(() => proxy.$utils.twidth(itemcolumns));
    watch(() => state.page, () => getData());
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    watch(() => state.warehouseid, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    watch(() => state.shopid, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    watch(() => state.inventorystate, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    watch(() => state.ordertype, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    watch(() => state.skupage, () => searchSku());
    watch(() => state.skulimit, () => {
      if (state.skupage !== 1) state.skupage = 1;
      else searchSku();
    });
    onActivated(() => {
      getData();
    });
    const changeWarehouse = (item) =>{
      state.warehouseid = item;
    };
    const changeShop = (item) =>{
      state.shopid = item;
    };
    const changeInventorystate = (item) =>{
      state.inventorystate = item;
    };
    const changeOrdertype = (item) =>{
      state.ordertype = item;
    };
    const getData = () => {
      state.selectedRowKeys = [];
      state.expandedRowKeys = [];
      state.expanded = false;
      state.dataLoading = true;
      proxy.$api.doAPI("/order/list", {
        page: state.page,
        limit: state.limit,
        orderids: state.orderids,
        orderstate: state.orderstate,
        inventorystate: state.inventorystate,
        warehouseid: state.warehouseid,
        ordertype: state.ordertype,
        shopid: state.shopid,
      }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.data = res.data.list;
          state.warehouses = res.data.warehouses;
          state.shops = res.data.shops;
          state.length = res.data.length;
          state.count = res.data.count;
          if (state.length > 0 && state.page > state.length) state.page = 1;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const choosewarehouse = (order) => {
      _choosewarehouse([order._id], order);
    }
    const bulkchoosewarehouse = () => {
      if (state.selectedRowKeys.length === 0) return message.warn("未选择订单");
      _choosewarehouse(state.selectedRowKeys);
    }
    const _choosewarehouse = (order_ids, order) => {
      state.warehouse_choosing = true;
      proxy.$api.doAPI("/order/choosewarehouse").then((res) => {
        state.warehouse_choosing = false;
        if (res.code === 0) {
          state.warehouses0 = res.data.warehouses0;
          state.warehouses1 = res.data.warehouses1;
          state.warehouseForm = {
            order_ids,
            warehouseid: order?.warehouseid || res.data.defaultwarehouseid
          };
          state.warehouseModal = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.warehouse_choosing = false;
        console.error(err);
      });
    };
    const confirmWarehouse = async () => {
      try {
        await warehouseRef.value.validateFields();
        state.warehouse_choosing = true;
        proxy.$api.doAPI("/order/confirmwarehouse", { warehouseForm: state.warehouseForm }).then((res) => {
          state.warehouse_choosing = false;
          getData();
          if (res.code === 0) {
            state.warehouseModal = false;
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.warehouse_choosing = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    }
    const chooselogistics = (order) => {
      _chooselogistics([order._id], order);
    }
    const bulkchooselogistics = () => {
      if (state.selectedRowKeys.length === 0) return message.warn("未选择订单");
      _chooselogistics(state.selectedRowKeys);
    }
    const _chooselogistics = (order_ids, order) => {
      state.logistics_choosing = true;
      proxy.$api.doAPI("/order/chooselogistics").then((res) => {
        state.logistics_choosing = false;
        if (res.code === 0) {
          state.channelbinds = res.data.channelbinds;
          state.logisticsForm = {
            order_ids,
            logistics_channel_bind_id: order?.logistics_channel_bind_id,
          };
          state.logisticsModal = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.logistics_choosing = false;
        console.error(err);
      });
    };
    const confirmLogistics = async () => {
      try {
        await logisticsRef.value.validateFields();
        state.logistics_choosing = true;
        proxy.$api.doAPI("/order/confirmlogistics", { logisticsForm: state.logisticsForm }).then((res) => {
          state.logistics_choosing = false;
          getData();
          if (res.code === 0) {
            state.logisticsModal = false;
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.logistics_choosing = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    }
    const bulkconfirmorder = () => {
      if (state.selectedRowKeys.length === 0) return message.warn("未选择订单");
      state.orderconfirming = true;
      proxy.$api.doAPI("/order/confirmorder", { order_ids: state.selectedRowKeys }).then((res) => {
        state.orderconfirming = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.orderconfirming = false;
        console.error(err);
      });
    }
    const rematch = (order) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/order/rematchsku", { order_id: order._id }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const matchsku = (order, orderitem) => {
      console.log('matchsku', order, orderitem);
      state.skuForm = { type: "sku", text: "", order_id: order._id, orderitem_id: orderitem._id };
      if (orderitem.skumatch_status === 'success') {
        state.skuForm.text = orderitem.product?.sku;
      } else {
        state.skuForm.type = "vsku";
        state.skuForm.text = orderitem.seller_sku;
      }
      if (orderitem.product?.is_combine) {
        state.skuForm.type = "c" + state.skuForm.type;
      }
      state.skupage = 1;
      state.skudata = [];
      state.skuModal = true;
    }
    const confirmMatch = (item) => {
      state.skuloading = true;
      proxy.$api.doAPI("/product/matchsku", { skuForm: state.skuForm, productid: item._id, }).then((res) => {
        state.skuloading = false;
        if (res.code === 0) {
          state.skuModal = false;
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.skuloading = false;
        console.error(err);
      });
    }
    const searchSku = () => {
      // if (!state.skuForm.text) return message.error("请输入搜索内容");
      state.skuloading = true;
      proxy.$api.doAPI("/product/searchsku", { page: state.skupage, limit: state.skulimit, skuForm: state.skuForm, }).then((res) => {
        state.skuloading = false;
        if (res.code === 0) {
          state.skudata = res.data.list;
          state.skulength = res.data.length;
          state.skucount = res.data.count;
          if (state.skulength > 0 && state.skupage > state.skulength) state.skupage = 1;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.skuloading = false;
        console.error(err);
      });
    }
    const showDeclare = (item) => {
      state.order_id = item._id;
      state.declareOrderNo = item.orderid;
      _showDeclare();
    }
    const _showDeclare = () => {
      state.declareListModal = true;
      state.declareListLoading = true;
      proxy.$api.doAPI("/order/showdeclare", { _id: state.order_id }).then((res) => {
        state.declareListLoading = false;
        if (res.code === 0) {
          state.declareList = _.map(res.data.list, n => {
            return {
              ...n,
              info: [
                `${n.declare_name_cn || '--'}`,
                `${n.declare_name_en || '--'}`,
                `$${n.declare_amount || '--'}`,
                `${n.declare_weight || '--'}g`,
                `${n.declare_quantity || '--'}`,
                `${n.hs_code || '--'}`
              ].join('/'),
            }
          });
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.declareListLoading = false;
        console.error(err);
      });
    }
    const editDeclare = (item) => {
      state.declareForm = _.extend({ update_product: false }, item);
      state.declareFormModal = true;
    }
    const saveDeclare = async () => {
      try {
        await declareRef.value.validateFields();
        state.declareFormLoading = true;
        proxy.$api.doAPI("/order/savedeclare", { declareForm: state.declareForm }).then((res) => {
          state.declareFormLoading = false;
          if (res.code === 0) {
            state.declareFormModal = false;
            _showDeclare();
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.declareFormLoading = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    }
    const showShipping = (item) => {
      state.order_id = item._id;
      _showShipping();
    }
    const _showShipping = () => {
      state.shippingModal = true;
      state.shippingLoading = true;
      proxy.$api.doAPI("/order/showshipping", { _id: state.order_id }).then((res) => {
        state.shippingLoading = false;
        if (res.code === 0) {
          state.shippingForm = res.data.shippingForm;
          state.shippingCountrys = _.map(res.data.countrys, n => ({ ...n, label: `[${n.code}] ${n.name} ${n.name_en}`, value: n.code }));
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.shippingLoading = false;
        console.error(err);
      });
    };
    const saveShipping = async () => {
      try {
        await shippingRef.value.validateFields();
        state.shippingLoading = true;
        proxy.$api.doAPI("/order/saveshipping", { _id: state.order_id, shippingForm: state.shippingForm }).then((res) => {
          state.shippingLoading = false;
          if (res.code === 0) {
            state.shippingModal = false;
            getData();
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.shippingLoading = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    }
    const handleBulkClick = ({ key }) => {
      if (state.selectedRowKeys.length === 0) return message.warn("未选择订单");
      console.log('handleBulkClick', key);
      switch (key) {
        case "shipped": //标记已发货
        case "canceled": //作废
        case "virtualship": //虚假发货
          markorder(state.selectedRowKeys, key);
          break;
        case "selectcustomtag": //标记自定义分类

          break;
        case "removecustomtag": //取消自定义分类

          break;
      }
    }
    const markorder = (order_ids, status) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/order/markorder", { order_ids, status }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const handleExportClick = ({ key }) => {
      console.log('handleExportClick', key);
    }
    const showorderitems = ({ items }) => {
      return _.reduce(items, (a, b) => a.concat(`${b.seller_sku}*${b.quantity_ordered}`), []).join(';')
    }
    const printorder = (item) => {
      _printorder([item._id]);
    }
    const bulkprintorder = () => {
      if (state.selectedRowKeys.length === 0) return message.error("未选择订单");
      _printorder(state.selectedRowKeys);
    }
    const _printorder = (order_ids) => {
      state.orderprinting = true;
      proxy.$api.doAPI("/order/printorder", { order_ids }).then((res) => {
        state.orderprinting = false;
        if (res.code === 0) {
          let urls = res.data.urls;
          proxy.$utils.mergePDF(urls).then((blob) => {
            printBlob(blob);
          });
          state.selectedRowKeys = [];
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.orderprinting = false;
        console.error(err);
      });
    }
    const expandall = () => {
      state.expandedRowKeys = _.pluck(state.data, "_id");
      state.expanded = true;
    }
    const foldall = () => {
      state.expandedRowKeys = [];
      state.expanded = false;
    }
    return {
      ...toRefs(state),
      warehouseRef,
      logisticsRef,
      declareRef,
      shippingRef,
      columns,
      itemcolumns,
      skucolumns,
      declarecolumns,
      width,
      iwidth,
      getData,
      choosewarehouse,
      bulkchoosewarehouse,
      confirmWarehouse,
      chooselogistics,
      bulkchooselogistics,
      confirmLogistics,
      bulkconfirmorder,
      rematch,
      matchsku,
      confirmMatch,
      searchSku,
      showDeclare,
      editDeclare,
      saveDeclare,
      showShipping,
      saveShipping,
      handleBulkClick,
      handleExportClick,
      showorderitems,
      printorder,
      bulkprintorder,
      expandall,
      foldall,
      doCopy: proxy.$utils.doCopy,
      image_placeholder: proxy.$constant.image_placeholder,
      nosku: proxy.$constant.nosku,
      changeWarehouse,
      changeShop,
      changeInventorystate,
      changeOrdertype,
    };
  },
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>
