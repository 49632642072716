<template>
  <div class="op-btn">
    <a-button type="primary">提交保存</a-button>
  </div>
  <a-card title="订单信息" style="width: 100%;">
    <a-form :model="formState" name="orderForm" class="ant-advanced-search-form">
      <a-row :gutter="24">
        <a-col :span="8">
          <a-form-item label="订单号" name="orderId" :rules="[{ required: true, message: '请输入订单号' }]">
            <a-input v-model:value="formState.orderId" placeholder="请输入订单号">
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="Email" name="email">
            <a-input v-model:value="formState.email">
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="卖家税号(IOSS)" name="IOSS">
            <a-input v-model:value="formState.ioss">
            </a-input>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-card>
  <a-card title="收货地址" style="width: 100%;">
    <a-form name="orderForm" class="ant-advanced-search-form" :model="formState">
      <a-row :gutter="24">
        <a-col :span="8">
          <a-form-item name="receiver" label="收件人" :rules="[{ required: true, message: '收件人不能为空' }]">
            <a-input v-model:value="formState.receiver" placeholder="收件人"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item name="telphone" label="电话">
            <a-input v-model:value="formState.telphone" placeholder="电话"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item name="mobile" label="手机">
            <a-input v-model:value="formState.mobile" placeholder="手机"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item name="company" label="公司">
            <a-input v-model:value="formState.company" placeholder="公司"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item name="taxnumber" label="税号">
            <a-input v-model:value="formState.taxnumber" placeholder="税号"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col :span="8">
          <a-form-item name="country" label="国家/地区" :rules="[{ required: true, message: '国家/地区不能为空' }]">
            <a-select ref="select" v-model:value="sltCountry" style="width: 100%；" @focus="focus" @change="handleChange"
              show-search>
              <a-select-option value="lucy">阿富汗</a-select-option>
              <a-select-option value="Yiminghe">阿联酋</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item name="province" label="省/州">
            <a-input v-model:value="formState.province" placeholder="省/州"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item name="city" label="地市">
            <a-input v-model:value="formState.city" placeholder="地市"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col :span="24">
          <a-form-item name="address1" label="地址1" :rules="[{ required: true, message: '地址1不能为空' }]">
            <a-input v-model:value="formState.address1" placeholder="地址1"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item name="address2" label="地址2">
            <a-input v-model:value="formState.address2" placeholder="地址2"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item name="housenumber" label="门牌号">
            <a-input v-model:value="formState.housenumber" placeholder="门牌号"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item name="postcode" label="邮编">
            <a-input v-model:value="formState.postcode" placeholder="邮编"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-card>
  <a-card title="产品信息" style="width: 100%;">
    <template #extra><a href="javascript:;" @click="handleAdd">增加一行</a></template>
    <a-table bordered :data-source="dataSource" :columns="columns" :pagination="false">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'sku'">
          <div class="editable-cell">
            <a-input v-model:value="record.sku" />
          </div>
        </template>
        <template v-if="column.dataIndex === 'type'">
          <div class="editable-cell">
            <a-input v-model:value="record.type" placeholder="输入属性" />
          </div>
        </template>
        <template v-if="column.dataIndex === 'url'">
          <div class="editable-cell">
            <a-input v-model:value="record.url" placeholder="输入销售链接" />
          </div>
        </template>
        <template v-if="column.dataIndex === 'num'">
          <div class="editable-cell">
            <a-input v-model:value="record.num" placeholder="输入数量" />
          </div>
        </template>
        <template v-if="column.dataIndex === 'price'">
          <div class="editable-cell">
            <a-input v-model:value="record.price" placeholder="输入单价" />
          </div>
        </template>
        <template v-else-if="column.dataIndex === 'operation'">
          <a-popconfirm v-if="dataSource.length" title="确认要删除?" @confirm="onDelete(record.key)">
            <a>删除</a>
          </a-popconfirm>
        </template>
      </template>
    </a-table>
  </a-card>
  <a-card title="备注" style="width: 100%;">
    <a-textarea v-model:value="value2" placeholder="备注信息" allow-clear />
  </a-card>
  <a-card title="费用信息" style="width: 100%;">
    <a-descriptions size="small" :column="3">
      <a-descriptions-item label="产品金额">0.00元</a-descriptions-item>
      <a-descriptions-item label="运费">0.00元</a-descriptions-item>
      <a-descriptions-item label="总费用">0.00元</a-descriptions-item>
    </a-descriptions>
  </a-card>
  <div class="op-btn">
    <a-button type="primary">提交保存</a-button>
  </div>
</template>
<script>
import { defineComponent, ref, toRefs, reactive } from 'vue';
import _ from "underscore";
export default defineComponent({
  name: 'OrdersManageManualrecord',
  components: {

  },
  setup() {
    const columns = [
      {
        title: 'SKU',
        dataIndex: 'sku'
      },
      {
        title: '属性',
        dataIndex: 'type'
      },
      {
        title: '销售链接',
        dataIndex: 'url'
      },
      {
        title: '数量',
        dataIndex: 'num'
      },
      {
        title: '单价',
        dataIndex: 'price'
      },
      {
        title: '操作',
        dataIndex: 'operation',
        width: 120
      }
    ];
    const state = reactive({
      dataSource: [
        { key: 0, SKU: '', type: '', url: '', num: '', price: '' }
      ]
    });
    const handleAdd = () => {
      const newData = { key: 1, SKU: '', type: '', url: '', num: '', price: '' };
      state.dataSource.push(newData);
    };
    const focus = () => {
      console.log('focus');
    };
    const handleChange = (value) => {
      console.log(`selected ${value}`);
    };
    const onDelete = (key) => {
      state.dataSource.splice(_.findIndex(state.dataSource, { key: key }));
    };
    return {
      ...toRefs(state),
      formState: {
        orderId: '',
        email: '',
        ioss: ''
      },
      focus,
      handleChange,
      handleAdd,
      onDelete,
      columns,
      sltCountry: '请选择'
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

.op-btn {
  display: flex;
  justify-content: flex-end;
  margin: 5px 0;
}
p {
  margin: 0;
  padding: 0;
}
</style>