<template>
  <div class="d-flex align-center mb-2">
    <span style="width:80px;">查询搜索：</span>
    <a-input-search v-model:value="searchText" placeholder="名称或编号查询" enter-button="搜索" allow-clear @search="getData" />
  </div>
  <div class="d-flex align-center mb-2">
    <a-space>
      <a-button type="primary">
        导出缺货商品
      </a-button>
      <a-button type="danger" @click="bulk_requestPurchase" :loading="request_purchaseing"
        :disabled="selectedRowKeys.length === 0">
        批量申请采购
      </a-button>
    </a-space>
  </div>
  <a-table :columns="columns" :data-source="data" :loading="dataLoading" :scroll="{ x: width }"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
    :row-selection="{ selectedRowKeys, onChange: (keys) => selectedRowKeys = keys }">
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'cover'">
        <a-image :src="record.product?.cover || image_placeholder" :width="50" :height="50"
          style="object-fit: contain;height:100%;" :preview="false" />
      </template>
      <template v-if="column.dataIndex === 'product'">
        <a href="javascript:;">{{ record.product.sku }}</a>
        <p>{{ record.product.name_cn }}</p>
      </template>
      <template v-if="column.dataIndex === 'warehouse'">
        {{ record.warehouse.name }}
      </template>
      <template v-if="column.dataIndex === 'default_supplier'">
        {{ record.default_supplier?.name || '无' }}
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <a href="javascript:;" @click="requestPurchase(record)">申请采购</a>
        </a-space>
      </template>
    </template>
  </a-table>
  <a-modal v-model:visible="purchaseModal" title="申请采购" @ok="confirmPurchase" @cancel="cancelPurchase" ok-text="确认"
    cancel-text="取消" :confirm-loading="purchasing" :cancel-button-props="{ disabled: purchasing }" :maskClosable="false"
    :closable="!purchasing" width="1000px" :bodyStyle="{ padding: 0 }">
    <a-table :columns="pcolumns" :data-source="purchase_list" :scroll="{ x: pwidth }" :pagination="false">
      <template #headerCell="{ column }">
        <template v-if="column.dataIndex === 'sku'">
          <p>sku</p>
          <p>商品中文名</p>
        </template>
      </template>
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'cover'">
          <a-image :src="record.product?.cover || image_placeholder" :width="50" :height="50"
            style="object-fit: contain;height:100%;" :preview="false" />
        </template>
        <template v-if="column.dataIndex === 'sku'">
          <a href="javascript:;">{{ record.product.sku }}</a>
          <p>{{ record.product.name_cn }}</p>
        </template>
        <template v-if="column.dataIndex === 'warehouse'">
          {{ record.warehouse.name }}
        </template>
        <template v-if="column.dataIndex === 'default_supplier'">
          <a-select v-model:value="record.product_supplier_id" :options="record.suppliers" :disabled="purchasing"
            show-search />
        </template>
        <template v-if="column.dataIndex === 'purchase_quantity'">
          <a-input v-model:value="record.purchase_quantity" type="number" :disabled="purchasing" />
        </template>
        <template v-if="column.dataIndex === 'purchase_price'">
          <a-input v-model:value="record.purchase_price" type="number" :disabled="purchasing" />
        </template>
        <template v-if="column.dataIndex === 'purchase_remark'">
          <a-textarea v-model:value="record.purchase_remark" :auto-size="{ minRows: 2, maxRows: 2 }"
            :disabled="purchasing" />
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <a-space>
            <a v-if="purchase_list.length > 1" href="javascript:;" class="text-error"
              @click="removePurchase(record)">删除</a>
          </a-space>
        </template>
      </template>
    </a-table>
  </a-modal>
</template>
<script>
import { DownOutlined, SearchOutlined, PlusCircleOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onActivated,
  getCurrentInstance,
} from "vue";
import _ from "underscore";
const columns = [
  { title: '图片', dataIndex: 'cover', width: 100, },
  { title: '商品信息', dataIndex: 'product', width: 150 },
  { title: '仓库', dataIndex: 'warehouse', width: 150, },
  { title: '默认供应商', dataIndex: 'default_supplier', width: 100, },
  { title: '可用库存', dataIndex: 'quantity', width: 100, },
  { title: '订单量', dataIndex: 'order_quantity', width: 100, },
  { title: '建议采购', dataIndex: 'suggestion', width: 100, },
  { title: '已申请', dataIndex: 'pending', width: 100, },
  { title: '采购中', dataIndex: 'purchase', width: 100, },
  { title: '操作', dataIndex: 'operation', width: 180, fixed: 'right' },
];
const pcolumns = [
  { title: '缩略图', dataIndex: 'cover', width: 80, },
  { title: 'sku/商品中文名', dataIndex: 'sku', width: 150, },
  { title: '仓库', dataIndex: 'warehouse', width: 100, },
  { title: '供应商', dataIndex: 'default_supplier', width: 100, },
  { title: '库存量', dataIndex: 'quantity', width: 80, },
  { title: '采购数量', dataIndex: 'purchase_quantity', width: 100, },
  { title: '价格', dataIndex: 'purchase_price', width: 100, },
  { title: '备注', dataIndex: 'purchase_remark', width: 100, },
  { title: '操作', dataIndex: 'operation', width: 80, },
]
export default defineComponent({
  name: 'InventoryManageStockout',
  components: {
    DownOutlined,
    SearchOutlined,
    PlusCircleOutlined
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      searchText: "",
      dataLoading: false,
      page: 1,
      limit: 10,
      length: 0,
      count: 0,
      data: [],
      selectedRowKeys: [],
      request_purchaseing: false,
      purchase_list: [],
      purchaseModal: false,
      purchasing: false,
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    const pwidth = computed(() => proxy.$utils.twidth(pcolumns));
    onMounted(() => {
    });
    onActivated(() => {
      getData();
    });
    watch(() => state.page, () => getData());
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    const getData = () => {
      state.selectedRowKeys = [];
      state.dataLoading = true;
      proxy.$api.doAPI("/inventory/lacklist", { page: state.page, limit: state.limit, searchText: state.searchText, }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.data = _.map(res.data.list, n => {
            return {
              ...n,
              create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
            }
          });
          state.length = res.data.length;
          state.count = res.data.count;
          if (state.length > 0 && state.page > state.length) state.page = 1;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const requestPurchase = (item) => {
      _requestPurchase([item._id]);
    }
    const bulk_requestPurchase = () => {
      if (state.selectedRowKeys.length === 0) return;
      _requestPurchase(state.selectedRowKeys);
    }
    const _requestPurchase = (inventoryids) => {
      let inventorys = _.filter(state.list, n => inventoryids.indexOf(n._id) > -1);
      if (_.find(inventorys, n => !n.product_supplier_id)) return message.error("商品没有关联供应商");
      state.request_purchaseing = true;
      proxy.$api.doAPI("/inventory/requestpurchase", { inventoryids }).then((res) => {
        state.request_purchaseing = false;
        if (res.code === 0) {
          state.purchase_list = res.data.list;
          state.purchaseModal = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.request_purchaseing = false;
        console.error(err);
      });
    }
    const confirmPurchase = () => {
      let purchase_list = _.map(state.purchase_list, n => {
        return {
          _id: n._id,
          product_supplier_id: n.product_supplier_id,
          purchase_quantity: n.purchase_quantity,
          purchase_price: n.purchase_price,
          purchase_remark: n.purchase_remark,
        }
      });
      if (_.find(purchase_list, n => !n.product_supplier_id)) return message.error("商品没有关联供应商");
      state.purchasing = true;
      proxy.$api.doAPI("/inventory/confirmpurchase", { purchase_list }).then((res) => {
        state.purchasing = false;
        if (res.code === 0) {
          state.purchaseModal = false;
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.purchasing = false;
        console.error(err);
      });
    }
    const removePurchase = (item) => {
      state.purchase_list = _.filter(state.purchase_list, n => n._id !== item._id);
      state.selectedRowKeys = _.filter(state.selectedRowKeys, n => n !== item._id);
    }
    const cancelPurchase = () => {
      state.purchaseModal = false;
      state.selectedRowKeys = [];
    }
    return {
      ...toRefs(state),
      columns,
      width,
      pcolumns,
      pwidth,
      getData,
      requestPurchase,
      bulk_requestPurchase,
      confirmPurchase,
      removePurchase,
      cancelPurchase,
      image_placeholder: proxy.$constant.image_placeholder,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>