<template>
  <a-tabs v-model:activeKey="activeKey" type="card" @change="handclick">
    <a-tab-pane :key="1" tab="按国家统计">
      <div class="header">
        <a-form :model="formState" name="form_query" layout="inline" autocomplete="off">
          <a-form-item>
            <a-range-picker v-model:value="daterange" value-format="YYYY-MM-DD" />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="getData">查询</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="content">
        <a-table :columns="columns" :data-source="data" bordered :scroll="{ x: width }">
          <template #bodyCell="{ column, text }">
            <template v-if="column.dataIndex === 'order_count'">
              <a>{{ text }}</a>
            </template>
          </template>
          <template #title>
            <div class="theader">
              <span class="title">物流统计</span>
              <a-button type="primary">导出物流统计报表</a-button>
            </div>
          </template>
        </a-table>
      </div>
    </a-tab-pane>
    <a-tab-pane :key="2" tab="按渠道统计" @click="getData">
      <div class="header">
        <a-form :model="formState" name="form_query" layout="inline" autocomplete="off">
          <a-form-item>
            <a-range-picker v-model:value="daterange" value-format="YYYY-MM-DD" />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="getData">查询</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="content">
        <a-table :columns="columns1" :data-source="data" bordered :scroll="{ x: width1 }">
          <template #bodyCell="{ column, text }">
            <template v-if="column.dataIndex === 'supplier'">
              <a>{{ text }}</a>
            </template>
          </template>
          <template #title>
            <div class="theader">
              <span class="title">物流统计</span>
              <a-button type="primary">导出物流统计报表</a-button>
            </div>
          </template>
        </a-table>
      </div>
    </a-tab-pane>
  </a-tabs>
</template>
<script>
import { DownOutlined } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from 'vue';
import _ from "underscore";
const columns = [
  { title: '国家', dataIndex: 'country', width: 120, align: 'center' },
  { title: '订单数量', dataIndex: 'order_count', width: 120, align: 'center' },
  { title: '总重量', dataIndex: 'order_weight', width: 120, align: 'center' },
  { title: '运费总额(CNY)', dataIndex: 'total_fee', width: 150, align: 'center' },
  { title: '平均时效', dataIndex: 'aging', width: 150, align: 'center' }
];
const columns1 = [
  { title: '物流商', dataIndex: 'supplier', width: 150, align: 'center' },
  { title: '物流渠道', dataIndex: 'channel', width: 150, align: 'center' },
  { title: '总重量', dataIndex: 'weight', width: 120, align: 'center' },
  { title: '运费总额(CNY)', dataIndex: 'total_fee', width: 150, align: 'center' },
  { title: '备注', dataIndex: 'mark', width: 150, align: 'center' }
];

export default defineComponent({
  name: 'LogisticsOrderstatics',
  components: {
    DownOutlined
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      activeKey: 1,
      daterange: [],
      data: [],
    });
    const width = computed(() => proxy.$utils.twidth(columns))
    const width1 = computed(() => proxy.$utils.twidth(columns1))
    onActivated(() => {
      getData();
    });
    const handclick = () => {
      state.daterange = [];
      state.data = [];
      getData();
    };
    const getData = () => {
      proxy.$api.doAPI("/logistics/orderStatistics", { type: state.activeKey, daterange: state.daterange }).then((res) => {
        if (res.code === 0) {
          state.data = res.data.list;
          if (state.activeKey === 1) {
            state.data.sort((a, b) => {
              return (b.order_count - a.order_count)
            });
          }
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
      });
    };
    return {
      ...toRefs(state),
      columns,
      columns1,
      width,
      width1,
      getData,
      handclick,
    }
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

.header {
  margin-bottom: 10px;
}

.theader {
  display: flex;
  justify-content: space-between;

  .title {
    font-weight: 600;
  }
}

a {
  text-decoration: underline;
}

p {
  margin: 0;
  padding: 0;
}
</style>
