<template>
  <a-menu v-if="direction === 'vertical'" @click="handleAddImageClick">
    <a-menu-item v-for="menu in pre_menus" :key="menu.value">
      {{ menu.label }}
    </a-menu-item>
    <a-menu-item key="local">
      <DesktopOutlined v-if="show_icon" />
      本地上传
    </a-menu-item>
    <a-menu-item key="network">
      <IeOutlined v-if="show_icon" />
      在线图片
    </a-menu-item>
    <a-menu-item key="space">
      <CloudServerOutlined v-if="show_icon" />
      图片空间
    </a-menu-item>
    <a-menu-item v-for="menu in suf_menus" :key="menu.value">
      {{ menu.label }}
    </a-menu-item>
  </a-menu>
  <a-space v-if="direction === 'horizontal'">
    <a-tooltip>
      <template #title>
        只能上传 jpg/tiff/gif 文件，且不超过2MB
      </template>
      <a-button :loading="uploading" @click="handleAddImageClick({ key: 'local' })" class="btn-primary">
        本地上传
      </a-button>
    </a-tooltip>
    <a-button :loading="uploading" @click="handleAddImageClick({ key: 'network' })">
      在线图片
    </a-button>
    <a-button :loading="uploading" @click="handleAddImageClick({ key: 'space' })">
      图片空间
    </a-button>
  </a-space>
  <input ref="uploadRef" type="file" accept="image/jpeg,image/jpg,image/tiff,image/gif" multiple="multiple"
    style="display: none" @change="uploadLocalImage" />
  <a-modal v-model:visible="networkmodal" title="从网络地址(URL)选择图片" @ok="addNetworkImage" @cancel="cancelAddNetworkImage"
    ok-text="添加" cancel-text="取消" :maskClosable="false" width="600px">
    <a-textarea v-model:value="image_urls" placeholder="请填写图片URL地址，多个地址换行" :auto-size="{ minRows: 10, maxRows: 10 }" />
  </a-modal>
  <a-modal v-model:visible="spacemodal" title="从图片空间选择" @ok="addSpaceImage" @cancel="cancelAddSpaceImage" ok-text="确定"
    cancel-text="取消" :maskClosable="false" width="1000px">
    <a-spin :spinning="loading">
      <a-row>
        <a-col :span="6">
          <a-table :columns="columns" :data-source="space_data" bordered :pagination="false">
            <template #bodyCell="{ column, text }">
              <template v-if="column.dataIndex === 'name'">
                <a href="javascript:;">{{ text }}</a>
              </template>
            </template>
          </a-table>
        </a-col>
        <a-col :span="18" style="padding-left:10px;">
          <a-card size="small" class="space-card">
            <template #title>
              <div class="d-flex justify-space-between align-center">
                <span>空间图片</span>
                <span>已选中{{ selected_images.length }}张图片</span>
              </div>
            </template>
            <div style="height:500px;overflow-x:hidden;overflow-y:auto;">
              <a-row :gutter="16">
                <a-col :span="4" v-for="image in spaceimages" :key="image._id">
                  <div style="width:100%;height:100px;position:relative;border:1px solid #e8e8e8;">
                    <a-image width="100%" height="100px" :src="image.remoteurl" style="object-fit: contain;height:100%;"
                      :preview="false">
                      <template #placeholder>
                        <a-image :src="image_placeholder" width="100%" height="100px" :preview="false" />
                      </template>
                    </a-image>
                    <div style="width:100%;height:100px;position:absolute;top:0;left:0;cursor: pointer;"
                      class="d-flex justify-center align-center select-image" @click="toggleSelect(image)">
                      <template v-if="image.selected">点击取消</template>
                      <template v-else>点击选中</template>
                    </div>
                    <CheckOutlined v-if="image.selected" class="text-success font-weight-bold"
                      style="font-size:25px;position:absolute;top:0;right:0;" />
                    <div
                      style="width:100%;height:24px;position:absolute;bottom:0;left:0;background-color: rgba(125,125,125,0.6);color: #fff;padding: 0 3px;text-align:right;">
                      {{ image.size_ }}
                    </div>
                  </div>
                  <!-- <div class="in1line">{{ image.originalname }}</div> -->
                </a-col>
              </a-row>
            </div>
          </a-card>
          <div class="d-flex justify-end">
            <a-pagination v-model:current="page" v-model:page-size="limit" show-quick-jumper show-size-changer
              :total="count" :show-total="total => `共 ${total} 条`" :pageSizeOptions="['30', '60', '90', '120']" />
          </div>
          <div style="height:100px;overflow-x:hidden;overflow-y:auto;">
            <a-row>
              <a-col :span="2" v-for="image in selected_images" :key="image">
                <div style="width:62px;height:62px;position:relative;border:1px solid #e8e8e8;">
                  <a-image width="62px" height="62px" :src="image" style="object-fit: contain;height:100%;"
                    :preview="false">
                    <template #placeholder>
                      <a-image :src="image_placeholder" width="62px" height="62px" :preview="false" />
                    </template>
                  </a-image>
                  <CloseCircleFilled class="text-error font-weight-bold"
                    style="font-size:20px;position:absolute;top:0;right:0;" @click="removeSelectedImage(image)" />
                </div>
              </a-col>
            </a-row>
          </div>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>
<script>
import { DownOutlined, CloseCircleFilled, CheckOutlined, CloudServerOutlined, IeOutlined, DesktopOutlined, } from '@ant-design/icons-vue';
import { message, } from "ant-design-vue";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onActivated,
  getCurrentInstance,
  toRaw,
} from "vue";
import { useStore } from "vuex";
import _ from "underscore";
const columns = [
  { title: '空间使用情况', colSpan: 2, dataIndex: 'title' },
  { title: '', colSpan: 0, dataIndex: 'value' }
];
export default defineComponent({
  name: 'ImagePicker',
  components: { DownOutlined, CloseCircleFilled, CheckOutlined, CloudServerOutlined, IeOutlined, DesktopOutlined, },
  props: ['direction', 'show_icon', 'max_count', 'images', 'pre_menus', 'suf_menus'],
  setup(props, ctx) {
    console.log('imagepicker', toRaw(props));
    const { proxy } = getCurrentInstance();
    const uploadRef = ref();
    const state = reactive({
      direction: props.direction || 'vertical',
      show_icon: props.show_icon || false,
      max_count: props.max_count || 1,
      images: props.images || [],
      pre_menus: props.pre_menus || [],
      suf_menus: props.suf_menus || [],
      uploading: false,
      networkmodal: false,
      image_urls: '',
      page: 1,
      limit: 30,
      length: 0,
      count: 0,
      spaceimages: [],
      spacemodal: false,
      loading: false,
      selected_images: [],
      space_data: [
        { title: '总容量', value: `0M` },
        { title: '已使用', value: `0M` },
        { title: '剩余空间', value: `0M` },
        { title: '存储张数', value: `0` }
      ]
    });
    watch(() => props.direction, (direction) => {
      state.direction = direction;
    });
    watch(() => props.show_icon, (show_icon) => {
      state.show_icon = show_icon;
    });
    watch(() => props.max_count, (max_count) => {
      state.max_count = max_count;
    });
    watch(() => props.images, (images) => {
      state.images = images;
    });
    watch(() => props.pre_menus, (pre_menus) => {
      state.pre_menus = pre_menus;
    });
    watch(() => props.suf_menus, (suf_menus) => {
      state.suf_menus = suf_menus;
    });
    watch(() => state.page, () => {
      getSpaceImages();
    });
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getSpaceImages();
    });
    const store = useStore();
    const totalSize = computed(() => proxy.$utils.d((store.state.imagepicker.total_size) / 1024 / 1024).toFixed(2));
    const usedSize = computed(() => proxy.$utils.d((store.state.imagepicker.used_size) / 1024 / 1024).toFixed(2));
    const leftSize = computed(() => proxy.$utils.d(totalSize.value).minus(usedSize.value).toFixed(2));

    const handleAddImageClick = (e) => {
      switch (e.key) {
        case 'local': //本地图片
          if (state.uploading) return;
          uploadRef.value.click();
          break;
        case 'network': //网络图片
          state.image_urls = '';
          state.networkmodal = true;
          break;
        case 'space': //空间图片
          state.page = 1;
          state.limit = 30;
          state.length = 0;
          state.count = 0;
          state.spaceimages = [];
          state.spacemodal = true;
          state.selected_images = [];
          getSpaceImages();
          break;
        default:
          ctx.emit(`${e.key}-clicked`);
          break;
      }
    }
    //#region LocalImageUploader
    const checkfiles = (files) => {
      let allow_types = ['image/jpeg', 'image/jpg', 'image/tiff', 'image/gif'];
      for (let i = 0; i < files.length; i++) {
        console.log(files[i].type);
        const validType = allow_types.indexOf(files[i].type) > -1;
        const validSize = files[i].size / 1024 / 1024 < 2;
        if (!validType) {
          return { code: 1, msg: '只能上传jpg/tiff/gif 文件' };
        } else if (!validSize) {
          return { code: 1, msg: '图片不能超过2MB' };
        }
      }
      return { code: 0 };
    }
    const uploadLocalImage = async (e) => {
      let files = e.target.files;
      if ((files.length + state.images.length) > state.max_count) return message.error(`不能超过${state.max_count}张图片`);
      let checkresult = checkfiles(files);
      if (checkresult.code != 0) return message.error(checkresult.msg);
      await doUploadImage(files);
      uploadRef.value.value = null;
    }
    const doUploadImage = async (files) => {
      if (files.length === 0) return;
      state.uploading = true;
      for (let i = 0; i < files.length; i++) {
        if (state.images.length >= state.max_count) continue;
        let file = files[i];
        const slicefiles = proxy.$utils.sliceFile(file.size);
        const faid = proxy.$utils.uuid();
        for (let j = 0; j < slicefiles.length; j++) {
          let slicefile = slicefiles[j];
          const formData = new FormData();
          if (slicefile.chunks > 1) {
            let _file = file.slice(slicefile.from, slicefile.to);
            formData.set("file", _file);
          } else {
            formData.set("file", file);
          }
          formData.set("faid", faid);
          formData.set("chunk", slicefile.chunk);
          formData.set("chunks", slicefile.chunks);
          formData.set("originalname", file.name);
          try {
            let res = await proxy.$api.doUpload("/assets/plupload", formData);
            if (res.code === 0) {
              if (slicefile.chunk + 1 === slicefile.chunks) {
                if (res.data.url) {
                  state.images.push(res.data.url);
                  ctx.emit('local-upload-success', { url: res.data.url });
                }
                await proxy.$utils.sleep(100);
              }
            } else {
              message.error(res.msg);
              break;
            }
          } catch (e) {
            console.error(e);
            message.error(e.message);
            break;
          }
        }
      }
      console.log('doUploadImage-finished');
      state.uploading = false;
    }
    //#endregion
    //#region NetworkImageInputer
    const addNetworkImage = () => {
      if (state.image_urls && state.image_urls.trim()) {
        let network_images = _.uniq(state.image_urls.trim().split('\n'));
        if ((network_images.length + state.images.length) > state.max_count) return message.error(`不能超过${state.max_count}张图片`);
        state.images = [...state.images, ...network_images];
        ctx.emit('network-input-success', { images: network_images });
        state.networkmodal = false;
      }
    }
    const cancelAddNetworkImage = () => {
      state.networkmodal = false;
    }
    //#endregion
    //#region SpaceImagePicker
    const getSpaceImages = () => {
      state.loading = true;
      proxy.$api.doAPI("/assets/space_images", { page: state.page, limit: state.limit }).then((res) => {
        state.loading = false;
        if (res.code === 0) {
          store.dispatch("imagepicker/setData", {
            total_size: res.data.total,
            used_size: res.data.used,
          });
          state.spaceimages = _.map(res.data.list, n => {
            let selected = state.selected_images.indexOf(n.remoteurl) > -1;
            return {
              ...n,
              size_: proxy.$utils.sizeToText(n.size),
              selected,
            };
          });
          state.length = res.data.length;
          state.count = res.data.count;
          if (state.length > 0 && state.page > state.length) state.page = 1;
          state.space_data = [
            { title: '总容量', value: `${totalSize.value}M` },
            { title: '已使用', value: `${usedSize.value}M` },
            { title: '剩余空间', value: `${leftSize.value}M` },
            { title: '存储张数', value: `${state.count}` }
          ];
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.loading = false;
        console.error(err);
      });
    }
    const addSpaceImage = () => {
      if (state.selected_images?.length > 0) {
        if ((state.images.length + state.selected_images.length) > state.max_count) return message.error(`不能超过${state.max_count}张图片`);
        let selected_images = _.uniq(state.selected_images);
        state.images = [...state.images, ...selected_images];
        ctx.emit('space-pick-success', { images: selected_images });
        state.spacemodal = false;
        state.selected_images = [];
      }
    }
    const cancelAddSpaceImage = () => {
      state.spacemodal = false;
    }
    const toggleSelect = (image) => {
      console.log('toggleSelect', image);
      image.selected = !image.selected;
      let selected_images = _.map(state.selected_images, n => n);
      if (image.selected) {
        let selectedImage = selected_images.indexOf(image.remoteurl) > -1;
        if (!selectedImage) selected_images.push(image.remoteurl);
      } else {
        selected_images = _.filter(selected_images, n => n !== image.remoteurl);
      }
      state.selected_images = _.map(selected_images, n => n);
    }
    const removeSelectedImage = (image) => {
      console.log('removeSelectedImage', image);
      state.selected_images = _.filter(state.selected_images, n => n !== image);
      state.spaceimages = _.map(state.spaceimages, n => {
        let selected = state.selected_images.indexOf(n) > -1;
        return { ...n, selected };
      });
    }
    //#endregion
    return {
      ...toRefs(state),
      uploadRef,
      uploadLocalImage,

      addNetworkImage,
      cancelAddNetworkImage,

      columns,
      getSpaceImages,
      addSpaceImage,
      cancelAddSpaceImage,
      toggleSelect,
      removeSelectedImage,
      image_placeholder: proxy.$constant.image_placeholder,

      handleAddImageClick,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.space-card>.ant-card-head) {
  background-color: #e8e8e8;
}

.select-image {
  color: transparent;
}

.select-image::hover {
  color: #fff;
  background-color: rgba(125, 125, 125, 0.3);
}
</style>