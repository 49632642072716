<template>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-button type="primary" @click="getData">Refresh List</a-button>
    <a-button type="primary" @click="constructReport">
      <template #icon>
        <PlusCircleOutlined />
      </template>Construct a report
    </a-button>
  </div>
  <a-table :columns="columns" :data-source="data" :scroll="{ x: width }" :loading="dataLoading"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }">
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'type'">
        <a-tooltip>
          <template #title> {{ record.type }} </template>
          {{ gettypename(record) }}
        </a-tooltip>
      </template>
      <template v-if="column.dataIndex === 'marketplaceIds'">
        <a-tooltip>
          <template #title> {{ record.marketplaceIds.join(',') }} </template>
          <p class="in1line">{{ record.marketplaceIds.join(',') }}</p>
        </a-tooltip>
      </template>
      <template v-if="column.dataIndex === 'documentId'">
        <a-tooltip>
          <template #title> {{ record.documentId }} </template>
          <p class="in1line">{{ record.documentId }}</p>
        </a-tooltip>
      </template>
      <template v-if="column.dataIndex === 'download_url'">
        <a-tooltip>
          <template #title> {{ record.download_url }} </template>
          <p class="in1line">{{ record.download_url }}</p>
        </a-tooltip>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <div class="d-flex flex-column">
          <a v-if="record.step === 'getReports'" href="javascript:;" @click="getReports(record._id)">
            Get reports
          </a>
          <a v-if="record.step === 'createReport'" href="javascript:;" @click="createReport(record._id)">
            1. Create report
          </a>
          <a v-if="record.step === 'getReport'" href="javascript:;" @click="getReport(record._id)">
            2. Get report
          </a>
          <a v-if="record.status === 'DONE' && record.step !== 'SUCCESS'" href="javascript:;"
            @click="getDocument(record._id)">
            3. Get document
          </a>
          <a v-if="record.step === 'downloadReportDocument'" href="javascript:;" @click="downloadDocument(record._id)">
            4. Download document
          </a>
          <a v-if="record.step === 'SUCCESS'" href="javascript:;" @click="showDocument(record._id, 1)">
            Show document
          </a>
          <!-- <a v-if="record.step === 'SUCCESS' && !record.saved" href="javascript:;" @click="showDocument(record._id, 2)">
            Save document
          </a> -->
        </div>
      </template>
    </template>
  </a-table>
  <a-modal v-model:visible="reportModal" title="Construct report" @ok="submitReport" @cancel="cancelSubmitReport"
    ok-text="Submit" cancel-text="Cancel" :confirm-loading="reportSubmiting"
    :cancel-button-props="{ disabled: reportSubmiting }" :maskClosable="false" :closable="!reportSubmiting" width="750px">
    <a-form ref="reportFormRef" name="reportForm" :model="reportForm" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }"
      autocomplete="off">
      <a-form-item label="Shop" name="shopid" :rules="[{ required: true, message: 'Shop can\'t be empty' }]">
        <a-select v-model:value="reportForm.shopid" :options="shops" placeholder="shop" :disabled="reportSubmiting"
          show-search />
      </a-form-item>
      <a-form-item label="ReportType" name="type" :rules="[{ required: true, message: 'ReportType can\'t be empty' }]">
        <a-select v-model:value="reportForm.type" :options="reportTypes" placeholder="report type"
          :disabled="reportSubmiting" show-search />
      </a-form-item>
      <template
        v-if="reportForm.type === 'GET_XML_ALL_ORDERS_DATA_BY_LAST_UPDATE_GENERAL' || reportForm.type === 'GET_FLAT_FILE_ALL_ORDERS_DATA_BY_LAST_UPDATE_GENERAL'">
        <a-form-item label="DateRange" name="daterange"
          :rules="[{ required: true, message: 'DateRange can\'t be empty' }, { validator: validateDateRange, trigger: 'change' },]">
          <a-range-picker v-model:value="reportForm.daterange" :locale="locale" value-format="YYYY-MM-DD" />
        </a-form-item>
      </template>
    </a-form>
  </a-modal>
  <a-drawer v-model:visible="documentDrawer" title="Report Documents" placement="right" width="50%">
    <a-table :columns="dcolumns" :data-source="documents" :scroll="{ y: 600 }"
      :pagination="{ current: dpage, pageSize: dlimit, total: dcount, showQuickJumper: false, showSizeChanger: false, }"
      @change="(pagination) => { dpage = pagination.current; dlimit = pagination.pageSize; }">
    </a-table>
  </a-drawer>
  <a-modal v-model:visible="documentModal" title="Report Documents" @ok="saveDocument"
    @cancel="() => { documentModal = false }" ok-text="Save" cancel-text="Cancel" :confirm-loading="documentSaving"
    :cancel-button-props="{ disabled: documentSaving }" :maskClosable="false" :closable="!documentSaving" width="1000px">
    <a-table :columns="dcolumns" :data-source="documents" :loading="documentSaving" :scroll="{ y: 600 }"
      :pagination="{ current: dpage, pageSize: dlimit, total: dcount, showQuickJumper: false, showSizeChanger: false, }"
      @change="(pagination) => { dpage = pagination.current; dlimit = pagination.pageSize; }">
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'status'">
          <span v-if="documentSaveStatus[record._id] === 'pending'" class="text-info">Pending</span>
          <span v-if="documentSaveStatus[record._id] === 'saving'" class="text-warning">Saving</span>
          <span v-if="documentSaveStatus[record._id] === 'saved'" class="text-success">Saved</span>
          <span v-if="documentSaveStatus[record._id] === 'error'" class="text-error">Error</span>
        </template>
      </template>
    </a-table>
  </a-modal>
</template>
<script>
import { DownOutlined, SearchOutlined, PlusCircleOutlined, EyeOutlined } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import _ from "underscore";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
const columns = [
  { title: 'Title', dataIndex: 'title', width: 120, },
  { title: 'Type', dataIndex: 'type', width: 120, },
  { title: 'MarketplaceIds', dataIndex: 'marketplaceIds', width: 120, },
  { title: 'ReportId', dataIndex: 'reportId', width: 120, },
  { title: 'DocumentId', dataIndex: 'documentId', width: 120, },
  { title: 'DownloadUrl', dataIndex: 'download_url', width: 120, },
  { title: 'Step', dataIndex: 'step', width: 120, },
  { title: 'Status', dataIndex: 'status', width: 120, },
  { title: 'CreateTime', dataIndex: 'report_create_time', width: 120, },
  { title: 'Operation', dataIndex: 'operation', width: 150, fixed: 'right' },
];
export default defineComponent({
  name: 'AmazonReportsApi',
  components: { DownOutlined, SearchOutlined, PlusCircleOutlined, EyeOutlined },
  setup() {
    const { proxy } = getCurrentInstance();
    const reportFormRef = ref();
    const state = reactive({
      page: 1,
      limit: 10,
      data: [],
      length: 0,
      count: 0,
      dataLoading: false,
      reportTypes: [],
      shops: [],
      reportForm: {},
      reportModal: false,
      reportSubmiting: false,
      reportid: null,
      documents: [],
      documentDrawer: false,
      documentModal: false,
      dcolumns: [],
      documentSaving: false,
      documentSaveStatus: {},
      dpage: 1,
      dlimit: 10,
      dlength: 0,
      dcount: 0,
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    watch(() => state.page, () => {
      getData();
    });
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    onActivated(() => {
      getData();
    });
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI2("/dev/amazon/report/list", { page: state.page, limit: state.limit }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.data = _.map(res.data.list, n => {
            return {
              ...n,
              create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
              report_create_time: n.report_create_time ? proxy.$utils.dateFormat(n.report_create_time, 'YYYY-MM-DD HH:mm:ss') : '',
            };
          });
          state.length = res.data.length;
          state.count = res.data.count;
          if (state.length > 0 && state.page > state.length) state.page = 1;
          state.reportTypes = res.data.reportTypes;
          state.shops = res.data.shops;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const constructReport = () => {
      state.reportForm = {
        type: undefined,
        shopid: null,
        daterange: ["", ""]
      };
      state.reportModal = true;
    }
    const submitReport = async () => {
      try {
        await reportFormRef.value.validateFields();
        state.dataLoading = true;
        proxy.$api.doAPI2("/dev/amazon/report/submit", { reportForm: state.reportForm }).then((res) => {
          state.dataLoading = false;
          if (res.code === 0) {
            state.reportModal = false;
            getData();
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.dataLoading = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    }
    const cancelSubmitReport = () => {
      reportFormRef.value.clearValidate();
      state.reportModal = false;
    }
    const getReports = (_id) => {
      state.dataLoading = true;
      proxy.$api.doAPI2("/dev/amazon/report/getlist", { _id }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const createReport = (_id) => {
      state.dataLoading = true;
      proxy.$api.doAPI2("/dev/amazon/report/create", { _id }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const getReport = (_id) => {
      state.dataLoading = true;
      proxy.$api.doAPI2("/dev/amazon/report/get", { _id }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const getDocument = (_id) => {
      state.dataLoading = true;
      proxy.$api.doAPI2("/dev/amazon/report/get_document", { _id }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const downloadDocument = (_id) => {
      state.dataLoading = true;
      proxy.$api.doAPI2("/dev/amazon/report/download", { _id }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const showDocument = (_id, type) => {
      state.reportid = _id;
      state.dataLoading = true;
      proxy.$api.doAPI2("/dev/amazon/report/show", { _id, type }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          let headers = {};
          for (let i = 0; i < res.data.report_json.length; i++) {
            let keys = _.keys(res.data.report_json[i]);
            for (let j = 0; j < keys.length; j++) {
              let key = keys[j];
              if (!headers[key]) headers[key] = [];
              if (res.data.report_json[i][key]) headers[key].push(res.data.report_json[i][key]);
            }
          }
          console.log(headers);
          let dcolumns = _.filter(_.map(_.keys(headers), n => {
            if (headers[n].length > 0) return { title: n, dataIndex: n, width: 150 };
            return null;
          }), n => !!n);
          if (type === 2) {
            dcolumns.unshift({ title: "Status", dataIndex: "status", width: 100 });
          }
          state.dcolumns = dcolumns;
          state.documents = _.map(res.data.report_json, n => {
            return {
              ...n,
              _id: proxy.$utils.uuid(),
            };
          });
          _.extend(state.documentSaveStatus, _.reduce(state.documents, (a, b) => _.extend(a, { [b._id]: 'pending' }), {}));
          state.dcount = res.data.report_json.length;
          state.dlength = Math.ceil(res.data.report_json.length / state.dlimit);
          if (type === 1) state.documentDrawer = true;
          if (type === 2) state.documentModal = true;
          state.dpage = 1;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const saveDocument = async () => {
      let list = proxy.$utils.sublist(state.documents, state.dlimit);
      state.documentSaving = true;
      for (let i = 0; i < list.length; i++) {
        let documents = list[i];
        _.extend(state.documentSaveStatus, _.reduce(documents, (a, b) => _.extend(a, { [b._id]: 'saving' }), {}));
        try {
          let res = await proxy.$api.doAPI2("/dev/amazon/report/save", { _id: state.reportid, documents, is_last: i === list.length - 1 });
          if (res.code === 0) {
            _.extend(state.documentSaveStatus, _.reduce(documents, (a, b) => _.extend(a, { [b._id]: 'saved' }), {}));
          } else {
            _.extend(state.documentSaveStatus, _.reduce(documents, (a, b) => _.extend(a, { [b._id]: 'error' }), {}));
          }
        } catch (e) {
          _.extend(state.documentSaveStatus, _.reduce(documents, (a, b) => _.extend(a, { [b._id]: 'error' }), {}));
        }
        state.dpage++;
        await proxy.$utils.sleep(100);
      }
      state.documentSaving = false;
      Modal.success({
        title: "Info", content: "Saved",
        onOk() {
          state.documentModal = false;
          getData();
        },
      });
    }
    const showXML = (item) => {
      state.xml = item.xml;
      state.xmlDrawer = true;
    }
    const gettypename = (item) => {
      return _.findWhere(state.reportTypes, { value: item.type })?.label;
    }
    const validateDateRange = (_rule, value) => {
      if (!value[0] || !value[1]) {
        return Promise.reject("DateRange can't be empty");
      } else {
        return Promise.resolve();
      }
    };
    return {
      ...toRefs(state),
      locale,
      reportFormRef,
      columns,
      width,
      getData,
      constructReport,
      submitReport,
      cancelSubmitReport,
      getReports,
      createReport,
      getReport,
      getDocument,
      downloadDocument,
      showDocument,
      saveDocument,
      showXML,
      gettypename,
      validateDateRange,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>

