<template>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-button type="primary" @click="getData" :loading="dataLoading">刷新数据</a-button>
    <a-button type="primary" @click="addRole" :loading="loading">
      <template #icon>
        <PlusCircleOutlined />
      </template>新增角色
    </a-button>
  </div>
  <a-table :columns="columns" :data-source="data" :loading="dataLoading">
    <template #bodyCell="{ record, column, index }">
      <template v-if="column.dataIndex === 'index'">
        {{ index + 1 }}
      </template>
      <template v-if="column.dataIndex === 'permissions'">
        <div class="d-flex flex-column">
          <span v-for="p in record.permissions" :key="p._id">
            {{ p.title }}（{{ p.list.map(n => n.title).join('，') }}）
          </span>
        </div>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <a href="javascript:;" @click="editRole(record)">编辑</a>
        </a-space>
      </template>
    </template>
  </a-table>
  <a-modal v-model:visible="roleModal" :title="`${roleForm._id !== '' ? '编辑' : '新增'}角色`" @ok="saveRole" ok-text="确认"
    cancel-text="取消" :confirm-loading="saving" :cancel-button-props="{ disabled: saving }" :maskClosable="false"
    :closable="!saving" width="600px">
    <a-form ref="roleRef" name="roleForm" :model="roleForm" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }"
      autocomplete="off">
      <a-form-item label="角色名" name="name" :rules="[{ required: true, message: '请输入角色名' }]">
        <a-input v-model:value="roleForm.name" :disabled="saving" />
      </a-form-item>
      <a-form-item label="权限" name="permissions" :rules="[{ required: true, message: '请选择权限' }]">
        <a-checkbox-group v-model:value="roleForm.permissions" style="width:100%;max-height:60vh;overflow-y:scroll;">
          <a-card :title="item.label" v-for="item in permissions" :key="item.label"
            :headStyle="{ lineHeight: 0.5, minHeight: 0, paddingLeft: '12px', paddingRight: '12px' }"
            :bodyStyle="{ padding: '12px' }">
            <template #title>
              <span>
                {{ item.label }}
                ({{ selectedCount(item.options) }}/{{ item.options.length }})
              </span>
            </template>
            <template #extra>
              <div>
                <a href="javascript:;" @click.stop="selectAll(item.options)" class="ml-1">全选</a>
                <a href="javascript:;" @click.stop="clearAll(item.options)" class="ml-1">全不选</a>
              </div>
            </template>
            <a-checkbox v-for="option in item.options" :key="option.value" :value="option.value">
              {{ option.label }}
            </a-checkbox>
          </a-card>
        </a-checkbox-group>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { DownOutlined, SearchOutlined, PlusCircleOutlined } from '@ant-design/icons-vue';
import { message } from "ant-design-vue";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onActivated,
  getCurrentInstance,
} from "vue";
import _ from "underscore";
const columns = [
  { title: '序号', dataIndex: 'index', width: 80, },
  { title: '角色名称', dataIndex: 'name', width: 100, },
  { title: '开通权限', dataIndex: 'permissions', },
  { title: '创建时间', dataIndex: 'create_time', width: 120, },
  { title: '操作', dataIndex: 'operation', width: 120, fixed: 'right' },
];
export default defineComponent({
  name: 'AccountSettingRoles',
  components: {
    DownOutlined,
    SearchOutlined,
    PlusCircleOutlined
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const roleRef = ref();
    const state = reactive({
      data: [],
      dataLoading: false,
      roles: [],
      saving: false,
      roleForm: {},
      roleModal: false,
      loading: false,
      permissions: [],
      permission_groups: [],
    });
    onActivated(() => {
      getData();
    });
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/account/rolelist").then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.data = _.map(res.data.list, n => {
            return {
              ...n,
              create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
            };
          });
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const addRole = () => {
      getRole();
    }
    const editRole = (item) => {
      getRole(item._id);
    }
    const getRole = (_id = "") => {
      state.loading = true;
      proxy.$api.doAPI("/account/getrole", { _id }).then((res) => {
        state.loading = false;
        if (res.code === 0) {
          state.roleForm = res.data.roleForm;
          state.permissions = res.data.permissions;
          state.roleModal = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.loading = false;
        console.error(err);
      });
    }
    const saveRole = async () => {
      try {
        await roleRef.value.validateFields();
        state.saving = true;
        proxy.$api.doAPI("/account/saverole", { roleForm: state.roleForm, }).then((res) => {
          state.saving = false;
          if (res.code === 0) {
            message.success("保存成功");
            state.roleModal = false;
            getData();
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.saving = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    }
    const selectAll = (options) => {
      let values = _.pluck(options, "value");
      console.log('selectAll', values);
      state.roleForm.permissions = _.uniq([
        ...state.roleForm.permissions,
        ...values,
      ]);
    }
    const clearAll = (options) => {
      let values = _.pluck(options, "value");
      console.log('clearAll', values);
      state.roleForm.permissions = _.filter(state.roleForm.permissions, n => values.indexOf(n) === -1);
    }
    const selectedCount = (options) => {
      let values = _.pluck(options, "value");
      return _.filter(values, n => state.roleForm.permissions.indexOf(n) > -1).length;
    }
    return {
      ...toRefs(state),
      roleRef,
      columns,
      getData,
      addRole,
      editRole,
      saveRole,
      selectAll,
      clearAll,
      selectedCount,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

:deep(.ant-checkbox-wrapper + .ant-checkbox-wrapper) {
  margin-left: 0 !important;
}

:deep(.ant-collapse-content > .ant-collapse-content-box) {
  padding: 8px !important;
}
p {
  margin: 0;
  padding: 0;
}
</style>

