<template>
  <div class="header">
    <div class="search">
      <span style="width:80px;">收款平台：</span>
      <a-radio-group v-model:value="platforms">
        <a-radio-button value="all">全部</a-radio-button>
        <a-radio-button value="1">pingpong收款</a-radio-button>
        <a-radio-button value="2">艾贝盈收款</a-radio-button>
        <a-radio-button value="3">万里汇收款</a-radio-button>
        <a-radio-button value="4">连连收款</a-radio-button>
      </a-radio-group>
    </div>
    <div class="d-flex justify-space-between align-center mb-2">
      <a-space>
        <a-button type="primary" @click="getData">刷新数据</a-button>
      </a-space>
      <a-space>
        <a-button type="danger" @click="showModal">绑定授权</a-button>
      </a-space>
    </div>
  </div>
  <a-table :columns="columns" :data-source="data" bordered :scroll="{ x: width }">
    <template #bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'platform'">
        <p> pingpong </p>
      </template>
      <template v-if="column.dataIndex === 'owner'">
        <p>{{ record.store?.x_storeName || "-" }}</p>
      </template>
      <template v-if="column.dataIndex === 'blance'">
        <p>52808.96
          <!-- {{ record.accountbanace?.x_availableBalance || "0.00" }} -->
          ({{ record.accountbanace?.x_currency || "" }})
        </p>
      </template>
      <template v-if="column.dataIndex === 'state'">
        <a-tag v-if="record.client?.x_state === 1" color="green">已绑定</a-tag>
        <a-tag v-else color="red">已解绑</a-tag>
      </template>
    </template>
  </a-table>
  <a-modal v-model:visible="visible" title="绑定第三方收款账号" @ok="handleBindAccount">
    <a-form :model="accountForm" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="账号">
        <a-input v-model:value="accountForm.account" />
      </a-form-item>
      <a-form-item label="密码">
        <a-input-password v-model:value="accountForm.password" />
      </a-form-item>
      <a-form-item label="收款平台">
        <a-radio-group v-model:value="accountForm.platform">
          <a-radio-button :value="1">pingpong收款</a-radio-button>
          <a-radio-button :value="2">连连收款</a-radio-button>
          <a-radio-button :value="3">艾贝盈收款</a-radio-button>
          <a-radio-button :value="4">万里汇收款</a-radio-button>
        </a-radio-group>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { DownOutlined } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from 'vue';
import _ from "underscore";
const columns = [
  { title: '收款平台', dataIndex: 'platform', width: 150, align: 'center', },
  { title: '账号ID', dataIndex: 'x_accountId', width: 150, align: 'center', },
  { title: '持有人', dataIndex: 'owner', width: 150, align: 'center', },
  { title: '余额', dataIndex: 'blance', width: 100, align: 'center', },
  { title: '状态', dataIndex: 'state', width: 100, align: 'center', },
  { title: '操作', dataIndex: 'action', width: 100, align: 'center', },
];
export default defineComponent({
  name: 'OperationPayeeAccount',
  components: {
    DownOutlined
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      data: [],
      platforms: "all",
      visible: false,
      accountForm: {
        account: '',
        password: '',
        platform: '',
      }
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    onActivated(() => {
      getData();
    });
    const getData = () => {
      proxy.$api.doAPI("/report/receivableaccount", {
      }).then((res) => {
        if (res.code === 0) {
          state.data = res.data.list;
          console.log(state.data);
        } else {
          message.error(res.msg);
          state.data = [];
        }
      }).catch((err) => {
        console.error(err);
        state.data = [];
      });
    };

    const handleChange = (tag, checked) => {
      const { selectedTags } = state;
      const nextSelectedTags = checked
        ? [...selectedTags, tag]
        : selectedTags.filter(t => t !== tag);
      console.log('You are interested in: ', nextSelectedTags);
      state.selectedTags = nextSelectedTags;
    };
    const showModal = () => {
      state.visible = true;
    };
    ////绑定第三方收款账号
    const handleBindAccount = (e) => {

    };
    return {
      ...toRefs(state),
      columns,
      width,
      getData,
      handleChange,
      showModal,
      handleBindAccount,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

.header {
  .search {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  .op {
    display: flex;
    justify-content: space-between;
  }
}

p {
  margin: 0;
  padding: 0;
}
</style>
