<template>
  <div class="header">
    <div class="search">
      <span style="width:80px;">查询条件：</span>
      <a-radio-group v-model:value="day" @change="getData">
        <a-radio-button :value="1">昨天</a-radio-button>
        <a-radio-button :value="7">近7天</a-radio-button>
        <a-radio-button :value="15">近15天</a-radio-button>
        <a-radio-button :value="30">近30天</a-radio-button>
      </a-radio-group>
    </div>
    <div class="search">
      <span style="width:80px;">选择店铺：</span>
      <a-select style="width:273px;" v-model:value="shopid" :options="shops" show-search>
      </a-select>
    </div>
    <div class="search">
      <span style="width:80px;">时间范围：</span>
      <a-range-picker v-model:value="daterange" value-format="YYYY-MM-DD" />
      <a-button class="ma-2" type="primary" @click="getData">查询</a-button>
    </div>

    <div class="review-header">
      <a-row>
        <a-col :span="4">
          <a-statistic title="客户数" :value="client_num" />
        </a-col>
        <a-col :span="4">
          <a-statistic title="订单数" :value="order_num" />
        </a-col>
        <a-col :span="4">
          <a-statistic title="付款金额" :precision="2" :value="pay_amount" />
        </a-col>
        <a-col :span="4">
          <a-statistic title="订单总金额" :precision="2" :value="total_amount" />
        </a-col>
        <a-col :span="4">
          <a-statistic title="退款金额" :precision="2" :value="refund_amount" />
        </a-col>
        <a-col :span="4">
          <a-statistic title="客单价" :precision="2" :value="per_customer" />
        </a-col>
      </a-row>
    </div>
  </div>
  <a-table :columns="columns" :data-source="data" bordered :scroll="{ x: width }">
    <template #bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'shop'">
        <p>{{ record.shop?.name || '-' }}</p>
      </template>
    </template>
  </a-table>
</template>
<script>
import { DownOutlined, DownloadOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from 'vue';
import _ from "underscore";
const columns = [
  { title: '店铺', dataIndex: 'shop', width: 120, align: 'center', },
  { title: '客户数', dataIndex: 'clientnum', width: 120, align: 'center', },
  { title: '订单数', dataIndex: 'ordernum', width: 120, align: 'center', },
  { title: '订单总金额', dataIndex: 'ordertotalamount', width: 150, align: 'center', },
  { title: '付款金额', dataIndex: 'payamount', width: 150, align: 'center', },
  { title: '付款订单数', dataIndex: 'payordernumber', width: 150, align: 'center', },
  { title: '退款金额', dataIndex: 'refundamount', width: 150, align: 'center', },
  { title: '退款订单数', dataIndex: 'refundordernum', width: 150, align: 'center', },
  { title: '客单价', dataIndex: 'unitprice', width: 120, align: 'center', },
  { title: '操作', dataIndex: 'action', width: 100, align: 'center', },
];
const data = [];
export default defineComponent({
  name: 'OperationAnalysisShops',//店铺表现
  components: {
    DownOutlined,
    DownloadOutlined
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      day: 7,
      days: [],
      data: [],
      firdaterange: [],
      daterange: [],
      shopid: '',
      shops: [],
      client_num: 0,
      order_num: 0,
      pay_amount: 0,
      total_amount: 0,
      refund_amount: 0,
      per_customer: 0
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    onActivated(() => {
      getData();
    });
    const getDay = (day) => {
      state.days = [];
      for (let i = 0; i < day; i++) {
        const time = new Date(new Date().setDate(new Date().getDate() + i - day));
        const year = time.getFullYear();
        const month = `0${time.getMonth() + 1}`.slice(-2);
        const strDate = `0${time.getDate()}`.slice(-2);
        if (i === 0) {
          state.days.push(`${year}-${month}-${strDate}`);
        }
        if (i === day - 1) {
          state.days.push(`${year}-${month}-${strDate}`);
        }
      }
    };
    const getData = () => {
      getDay(state.day);
      proxy.$api.doAPI("/report/storeper", {
        firdaterange: state.days,
        daterange: state.daterange,
        shopid: state.shopid,
      }).then((res) => {
        if (res.code === 0) {
          state.data = res.data.list;
          state.client_num = res.data.client_num,
            state.order_num = res.data.order_num,
            state.pay_amount = res.data.pay_amount,
            state.total_amount = res.data.total_amount,
            state.refund_amount = res.data.refund_amount,
            state.per_customer = res.data.per_customer,
            state.shops = [{ label: "全部店铺", value: "" }, ..._.map(res.data.own_shops || [], n => ({ ...n, label: n.name, value: n._id }))];
          state.firdaterange = [];
        } else {
          message.error(res.msg);
          state.firdaterange = [];
        }
      }).catch((err) => {
        console.error(err);
        state.firdaterange = [];
      });
    };

    const handleChange = (tag, checked) => {
      const { selectedTags } = state;
      const nextSelectedTags = checked
        ? [...selectedTags, tag]
        : selectedTags.filter(t => t !== tag);
      console.log('You are interested in: ', nextSelectedTags);
      state.selectedTags = nextSelectedTags;
    };
    return {
      ...toRefs(state),
      columns,
      width,
      getData,
      handleChange,
    };
  }
});
</script>
<style lang="less" scoped>
.review-header {
  padding: 24px;
}

:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

.header {
  .search {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  .op {
    display: flex;
    justify-content: space-between;
  }
}

p {
  margin: 0;
  padding: 0;
}
</style>

