<template>
  <div class="header">
    <div class="search">
      <span style="width:80px;">收款平台：</span>
      <a-radio-group v-model:value="platforms">
        <a-radio-button value="all">全部</a-radio-button>
        <a-radio-button value="1">pingpong收款</a-radio-button>
        <a-radio-button value="2">连连收款</a-radio-button>
        <a-radio-button value="3">艾贝盈收款</a-radio-button>
        <a-radio-button value="4">万里汇收款</a-radio-button>
      </a-radio-group>
    </div>
    <div class="search">
      <span style="width:80px;">查询时间：</span>
      <a-range-picker v-model:value="daterange" value-format="YYYY-MM-DD" />
    </div>
    <div class="d-flex justify-space-between align-center mb-2">
      <a-space>
        <a-button type="primary" @click="getData">同步数据</a-button>
      </a-space>
      <a-button type="default">
        <template #icon>
          <DownloadOutlined />
        </template>导出明细
      </a-button>
    </div>
  </div>
  <a-table :columns="columns" :data-source="data" :scroll="{ x: width }" :loading="dataLoading"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }">
    <template #bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'platform'">
        <p> pingpong </p>
      </template>
      <template v-if="column.dataIndex === 'counry'">
        <p>{{ record.storeaccount?.x_nation || "-" }}</p>
      </template>
      <template v-if="column.dataIndex === 'amount_account'">
        <p>{{ record.storeaccount?.x_cardNo || "-" }}</p>
      </template>
      <template v-if="column.dataIndex === 'BoundShop'">
        <p>{{ record.store?.x_storeName || "-" }}</p>
      </template>
      <template v-if="column.dataIndex === 'nickname'">
        <p>{{ record.store?.x_storeName || "-" }}</p>
      </template>
      <template v-if="column.dataIndex === 'remitter'">
        <p>{{ "-" }}</p>
      </template>
      <template v-if="column.dataIndex === 'x_amount'">
        <p>{{ record.x_amount }}({{ record.x_currency }})</p>
      </template>
    </template>
  </a-table>
</template>
<script>
import { DownOutlined, DownloadOutlined } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from 'vue';
import _ from "underscore";
const columns = [
  { title: '收款平台', dataIndex: 'platform', width: 150, align: 'center', },
  { title: '到账日期', dataIndex: 'x_transferTime', width: 150, align: 'center', },
  { title: '交易号', dataIndex: 'x_txid', width: 150, align: 'center', },
  { title: '账户昵称', dataIndex: 'nickname', width: 100, align: 'center', },
  { title: '账户所在国家', dataIndex: 'counry', width: 150, align: 'center', },
  { title: '收款账号', dataIndex: 'amount_account', width: 150, align: 'center', },
  { title: '收款金额', dataIndex: 'x_amount', width: 150, align: 'center', },
  { title: '汇款人', dataIndex: 'remitter', width: 150, align: 'center', },
  { title: '店铺名称', dataIndex: 'BoundShop', width: 100, align: 'center', },
  { title: '状态', dataIndex: 'state', width: 100, align: 'center', },
  { title: '操作', dataIndex: 'action', width: 100, align: 'center', },
];
export default defineComponent({
  name: 'OperationPayeeList',
  components: {
    DownOutlined,
    DownloadOutlined
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      platforms: 'all',
      data: [],
      daterange: [],
      page: 1,
      limit: 10,
      length: 0,
      count: 0,
      dataLoading: false,
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    watch(() => state.page, () => getData());
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    onActivated(() => {
      getData();
    });
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/report/transactiondetail", {
        daterange: state.daterange,
        page: state.page,
        limit: state.limit
      }).then((res) => {
        if (res.code === 0) {
          state.data = res.data.list;
          console.log(state.data);
          state.count = res.data.count;
          state.length = res.data.length;
          state.daterange = [];
          state.dataLoading = false;
          if (state.length > 0 && state.page > state.length) state.page = 1;
        } else {
          message.error(res.msg);
          state.daterange = [];
          state.data = [];
          state.dataLoading = false;
        }
      }).catch((err) => {
        console.error(err);
        state.daterange = [];
        state.data = [];
        state.dataLoading = false;
      });
    };

    const handleChange = (tag, checked) => {
      const { selectedTags } = state;
      const nextSelectedTags = checked
        ? [...selectedTags, tag]
        : selectedTags.filter(t => t !== tag);
      console.log('You are interested in: ', nextSelectedTags);
      state.selectedTags = nextSelectedTags;
    };
    return {
      ...toRefs(state),
      columns,
      width,
      getData,
      handleChange,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

.header {
  .search {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  .op {
    display: flex;
    justify-content: space-between;
  }
}

p {
  margin: 0;
  padding: 0;
}
</style>
