<template>
  <div class="d-flex align-center mb-2">
    <span style="width:75px;">所在仓库：</span>
    <a-select v-model:value="warehouseid" style="width: 200px" @change="changeWarehouse" show-search>
      <a-select-opt-group label="一件代发仓" v-if="warehouses0.length > 0">
        <a-select-option v-for="warehouse in warehouses0" :key="warehouse.warehouseid" :value="warehouse.warehouseid">
          {{ warehouse.name }}
        </a-select-option>
      </a-select-opt-group>
      <a-select-opt-group label="自建本地仓" v-if="warehouses1.length > 0">
        <a-select-option v-for="warehouse in warehouses1" :key="warehouse._id" :value="warehouse._id">
          {{ warehouse.name }}
        </a-select-option>
      </a-select-opt-group>
    </a-select>
  </div>
  <div class="d-flex align-center mb-2">
    <span style="width:80px;">查询搜索：</span>
    <a-input-search v-model:value="skus" placeholder="多个SKU用逗号隔开" enter-button="搜索" allow-clear @search="getData" />
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-space>
      <a-dropdown>
        <template #overlay>
          <a-menu @click="handleBulkClick">
            <a-menu-item key="1" :disabled="selectedRowKeys.length === 0">打印标签</a-menu-item>
            <a-menu-item key="2" :disabled="selectedRowKeys.length === 0">打印清单</a-menu-item>
            <a-menu-item-group title="批量编辑">
              <a-menu-item key="3" :disabled="selectedRowKeys.length === 0">批量编辑</a-menu-item>
              <a-menu-item key="4" :disabled="selectedRowKeys.length === 0">批量删除</a-menu-item>
            </a-menu-item-group>
          </a-menu>
        </template>
        <a-button type="primary">
          批量操作
          <DownOutlined />
        </a-button>
      </a-dropdown>
    </a-space>
    <a-space>
      <a-button type="primary" @click="getData">刷新数据</a-button>
      <a-dropdown>
        <template #overlay>
          <a-menu @click="processImportExport">
            <a-menu-item-group title="导入">
              <a-menu-item key="1">导入商品</a-menu-item>
            </a-menu-item-group>
            <a-menu-item-group title="导出">
              <a-menu-item key="2" :disabled="selectedRowKeys.length === 0">导出勾选</a-menu-item>
              <a-menu-item key="3">导出所有页</a-menu-item>
            </a-menu-item-group>
          </a-menu>
        </template>
        <a-button type="primary">
          导出/导入
          <DownOutlined />
        </a-button>
      </a-dropdown>
      <a-dropdown>
        <template #overlay>
          <a-menu @click="addToWarehouse">
            <a-menu-item v-for="warehouse in [...warehouses0, ...warehouses1]" :key="warehouse._id"
              :value="warehouse._id">
              {{ warehouse.name }}
            </a-menu-item>
          </a-menu>
        </template>
        <a-button type="primary" danger>
          添加商品至
          <DownOutlined />
        </a-button>
      </a-dropdown>
    </a-space>
  </div>
  <a-table :columns="columns" bordered :data-source="data" :loading="dataLoading" :scroll="{ x: width, y: 600 }"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
    :row-selection="{ selectedRowKeys, onChange: (keys) => selectedRowKeys = keys }">
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'cover'">
        <a-image :src="record.cover || image_placeholder" width="80px" height="80px"
          style="object-fit: contain;height:100%;" :preview="!!record.cover">
          <template #placeholder>
            <a-image :src="image_placeholder" width="80px" height="80px" :preview="false" />
          </template>
        </a-image>
      </template>
      <template v-if="column.dataIndex === 'info'">
        <a href="javascript:;">{{ record.sku }}</a>
        <p>{{ record.name_cn }}</p>
        <p style="color:#a0a3a6;">{{ record.number }}</p>
      </template>
      <template v-if="column.dataIndex === 'inventory'">
        <div v-for="inventory in record.inventorys" :key="inventory.slotid" class="d-flex">
          <span>{{ inventory.slot }}</span>
          (<a href="javascript:;">{{ inventory.quantity }}</a>)
        </div>
      </template>
      <template v-if="column.dataIndex === 'create_time'">
        <p>更新时间：{{ record.lasttime }}</p>
        <p>创建时间：{{ record.create_time }}</p>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <a href="javascript:;" class="text-error">删除</a>
        </a-space>
      </template>
    </template>
  </a-table>
</template>
<script>
import { DownOutlined, SearchOutlined, PlusCircleOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onActivated,
  getCurrentInstance,
} from "vue";
import _ from "underscore";
const columns = [
  { title: '图片', dataIndex: 'cover', width: 80 },
  { title: '商品信息', dataIndex: 'info', width: 200, },
  { title: '库存信息', dataIndex: 'inventory', width: 200, },
  { title: '单价(CNY)', dataIndex: 'purchase_price', width: 100, },
  { title: '总价(CNY)', dataIndex: 'total_amount', width: 100, },
  { title: '时间', dataIndex: 'create_time', width: 200, },
  { title: '操作', dataIndex: 'operation', width: 150, fixed: 'right', },
];
export default defineComponent({
  name: 'InventoryView',
  components: {
    DownOutlined,
    SearchOutlined,
    PlusCircleOutlined,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const formRef = ref();
    const state = reactive({
      warehouses0: [],
      warehouses1: [],
      warehouseid: "",
      skus: "",
      dataLoading: false,
      page: 1,
      limit: 10,
      length: 0,
      count: 0,
      data: [],
      selectedRowKeys: [],
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    onMounted(() => {
    });
    onActivated(() => {
      getData();
    });
    watch(() => state.page, () => getData());
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/inventory/list", {
        page: state.page,
        limit: state.limit,
        skus: state.skus,
        warehouseid: state.warehouseid,
      }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.warehouses0 = res.data.warehouses0;
          state.warehouses1 = res.data.warehouses1;
          if (!state.warehouseid && res.data.warehouseid) {
            state.warehouseid = res.data.warehouseid;
          }
          state.data = _.map(res.data.list, n => {
            let total_amount = "";
            if (n.purchase_price && n.inventorys?.length > 0) {
              let quantity = _.reduce(n.inventorys, (a, b) => a + b.quantity, 0);
              total_amount = proxy.$d(n.purchase_price).times(quantity).toString();
            }
            return {
              ...n,
              total_amount,
              create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
            }
          });
          state.length = res.data.length;
          state.count = res.data.count;
          if (state.length > 0 && state.page > state.length) state.page = 1;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const changeWarehouse = (e) => {
      console.log('changeWarehouse', e);
      if (state.page !== 1) state.page = 1;
      else getData();
    };
    const handleBulkClick = ({ key }) => {
      console.log('handleBulkClick', key);
      switch (key) {
        case "1":
        case "2":
        case "3":
        case "4":
          break;
      }
    }
    const processImportExport = ({ key, }) => {
      console.log('processImportExport', key);
    }
    const addToWarehouse = ({ key, }) => {
      console.log('addToWarehouse', key);
    }
    return {
      ...toRefs(state),
      formRef,
      columns,
      width,
      getData,
      changeWarehouse,
      handleBulkClick,
      processImportExport,
      addToWarehouse,
      image_placeholder: proxy.$constant.image_placeholder,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>

