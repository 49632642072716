<template>
  <a-spin :spinning="uistate.loading" style="overflow-x: hidden;">
    <a-row>
      <a-col :span="4">
        <div class="d-flex justify-center">
          <a-menu v-model:selectedKeys="activeMenuKeys" @click="clickMenu">
            <a-menu-item key="card1">账号与站点</a-menu-item>
            <a-menu-item key="card2" v-show="listingForm.account_username">站点差异化信息</a-menu-item>
            <a-menu-item key="card3">产品描述</a-menu-item>
            <a-menu-item key="card4">产品信息</a-menu-item>
            <a-menu-item key="card5">产品图片</a-menu-item>
            <a-menu-item key="card6">售价和多属性</a-menu-item>
          </a-menu>
        </div>
      </a-col>
      <a-col :span="20">
        <div style="background:#f0f2f5;">
          <div id="mainform" class="mainform">
            <a-form ref="listingformRef" name="listingForm" :model="listingForm" autocomplete="off">
              <a-card title="账号与站点" id="card1" class="mb-2 code-box">
                <div class="form-item">
                  <div class="label ">
                    <span class="cn">账号</span>
                  </div>
                  <a-form-item name="account_username">
                    <div class="d-flex">
                      <div class="el-alert el-alert--info" style="width:600px;">
                        <span :title="listingForm.account_username" class="el-alert__title">
                          {{ listingForm.account_username || '--' }}
                        </span>
                      </div>
                      <a-button v-if="listingForm.state === 'draft'" class="btn-primary ml-4" @click="showShopAccounts">
                        <FormOutlined />重选账号和站点
                      </a-button>
                    </div>
                  </a-form-item>
                </div>
                <div class="form-item">
                  <div class="label ">
                    <span class="cn">站点</span>
                  </div>
                  <a-form-item name="region_sites">
                    <div class="el-alert el-alert--info" style="width:600px;">
                      <span v-if="listingForm.state === 'fail'" class="el-alert__title text-danger">
                        {{ fail_region_sites }} <span>( 刊登失败 )</span>
                      </span>
                      <span v-else class="el-alert__title">
                        {{ region_sites || '--' }}
                      </span>
                    </div>
                  </a-form-item>
                </div>
              </a-card>
              <a-card title="站点差异化信息" id="card2" class="mb-2 code-box">
                <a-tabs v-if="listingForm.regions?.length > 0" v-model:activeKey="curr_region">
                  <a-tab-pane v-for="(region, index) in listingForm.regions" :key="region"
                    :tab="listingForm.region_names[index]">
                  </a-tab-pane>
                </a-tabs>
                <template v-if="listingForm.account_username">
                  <div class="form-item">
                    <div class="label required">
                      <span class="cn">产品标题</span>
                    </div>
                    <a-form-item name="item_name" style="width:100%;">
                      <div class="d-flex align-center">
                        <a-input v-model:value="listingForm[curr_region].item_name" :maxlength="200"
                          placeholder="最多200字符，建议120字符" style="width:100%;"
                          :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'"></a-input>
                        <p class="text-gray text-right ml-1" style="min-width:50px;">
                          {{ listingForm[curr_region]?.item_name?.length || 0 }}/200
                        </p>
                      </div>
                    </a-form-item>
                  </div>
                  <div class="form-item">
                    <div class="label required">
                      <span class="cn">Search Terms</span>
                    </div>
                    <a-form-item name="generic_keywords" style="width:100%;">
                      <div class="d-flex align-center">
                        <a-input v-model:value="listingForm[curr_region].generic_keywords" :maxlength="250"
                          placeholder="最多250字符" style="width:100%;"
                          :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'"></a-input>
                        <p class="text-gray text-right ml-1" style="min-width:50px;">
                          {{ listingForm[curr_region]?.generic_keywords?.length || 0 }}/250
                        </p>
                      </div>
                    </a-form-item>
                  </div>
                  <div class="form-item">
                    <div class="label required">
                      <span class="cn">Bullet Point</span>
                    </div>
                    <a-form-item name="bullet_points" style="width:100%;">
                      <template #extra>
                        <div class="text-gray">
                          每行一条Bullet Point，最多<span class="text-error"> 5 </span>行，每行最多
                          <span class="text-error"> 500 </span>个字符（按回车键换行）
                        </div>
                      </template>
                      <a-textarea v-model:value="listingForm[curr_region].bullet_points"
                        :auto-size="{ minRows: 7, maxRows: 7 }" style="width:100%;"
                        :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                    </a-form-item>
                  </div>
                  <div class="form-item">
                    <div class="label required">
                      <span class="cn">产品分类</span>
                    </div>
                    <a-form-item name="browsenodename">
                      <template #extra>
                        <div v-if="browsetreeID1" class="d-flex flex-wrap">
                          <span>{{ browsetreeNAME1 }}</span>
                          <span v-if="browsetreeNAME2">
                            》 {{ browsetreeNAME2 }}
                          </span>
                          <span v-if="browsetreeNAME3">
                            》 {{ browsetreeNAME3 }}
                          </span>
                          <span v-if="browsetreeNAME4">
                            》 {{ browsetreeNAME4 }}
                          </span>
                          <span v-if="browsetreeNAME5">
                            》 {{ browsetreeNAME5 }}
                          </span>
                          <span v-if="browsetreeNAME6">
                            》 {{ browsetreeNAME6 }}
                          </span>
                        </div>
                      </template>
                      <a-input v-model:value="listingForm[curr_region].browsenodename" placeholder="请选择" readonly
                        style="width:800px;"></a-input>
                      <a-button class="btn-primary ml-4"
                        :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'"
                        @click="selectBrowseTree">
                        <FormOutlined /> 浏览及搜索Amazon产品分类
                      </a-button>
                    </a-form-item>
                  </div>
                  <div class="form-item">
                    <div class="label required">
                      <span class="cn">Product Type</span>
                    </div>
                    <a-form-item name="feed_product_type">
                      <a-select v-model:value="listingForm[curr_region].feed_product_type"
                        :options="browsetreeProductTypes" placeholder="请选择" show-search
                        @change="(v) => changeProductType(v, curr_region)" style="width:400px;"
                        :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                    </a-form-item>
                  </div>
                  <div class="form-item">
                    <div class="label required">
                      <span class="cn">分类属性</span>
                    </div>
                    <a-form-item style="width:100%;">
                      <a-card v-if="listingForm[curr_region].feed_product_type">
                        <template #extra>
                          <a-input-search v-model:value="attribute_search_key[curr_region]" placeholder="请输入属性名称"
                            enter-button="属性搜索" allow-clear @search="(v) => doSearchAttribute(v, curr_region)"
                            style="width:400px;"
                            :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                        </template>
                        <a href="javascript:;" v-if="showAllAttributes[curr_region]"
                          @click="() => showAllAttributes[curr_region] = !showAllAttributes[curr_region]">
                          隐藏其他信息
                          <UpOutlined />
                        </a>
                        <a href="javascript:;" v-else
                          @click="() => showAllAttributes[curr_region] = !showAllAttributes[curr_region]">
                          显示其他信息
                          <DownOutlined />
                        </a>
                        <div class="d-flex flex-column align-center">
                          <div v-for="(ot, oi) in browsetreeFilterAttributes" :key="ot.field_key">
                            <div class="form-item" v-show="showAllAttributes[curr_region] || oi < 15">
                              <div class="label" style="width:300px;">
                                <span class="cn " v-if="ot.label_cn">{{ ot.label_cn }}</span>
                                <span class="en text-gray">{{ ot.label }}</span>
                              </div>
                              <a-form-item :name="ot.field_key">
                                <template #extra>
                                  {{ ot.field_key }}
                                </template>
                                <a-auto-complete v-if="ot?.options?.length > 0"
                                  v-model:value="listingForm[curr_region][ot.field_key]" :options="ot.options"
                                  :placeholder="`例如：${ot.example}`" style="width:400px;"
                                  @change="(v) => { if (!v) listingForm[curr_region][ot.field_key] = null }"
                                  :filter-option="filterOption"
                                  :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                                <a-input v-else v-model:value="listingForm[curr_region][ot.field_key]"
                                  :placeholder="`例如：${ot.example}`" style="width:400px;"
                                  :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                              </a-form-item>
                            </div>
                          </div>
                        </div>
                      </a-card>
                    </a-form-item>
                  </div>
                </template>
              </a-card>
              <a-card title="产品描述" id="card3" class="mb-2 code-box">
                <a-tabs v-if="listingForm.regions?.length > 0" v-model:activeKey="curr_region">
                  <a-tab-pane v-for="(region, index) in listingForm.regions" :key="region"
                    :tab="listingForm.region_names[index]">
                  </a-tab-pane>
                </a-tabs>
                <div v-show="product_description_edit_type === 'editor'">
                  <div v-show="listingForm.state === 'draft' || listingForm.state === 'fail'"
                    style="border: 1px solid #ccc">
                    <Toolbar style="border-bottom: 1px solid #ccc" :editor="EditorRef" :defaultConfig="toolbarConfig"
                      :mode="editorMode" />
                    <Editor style="height: 300px; overflow-y: hidden;" v-model="product_description"
                      :defaultConfig="editorConfig" :mode="editorMode" @onCreated="(editor) => handleCreated(editor)" />
                  </div>
                  <div v-show="listingForm.state !== 'draft' && listingForm.state !== 'fail'"
                    style="height:300px;overflow:auto;border: 1px solid #ccc;" v-html="product_description">
                  </div>
                </div>
                <div v-show="product_description_edit_type === 'textarea'" style="border: 1px solid #ccc">
                  <a-textarea v-model:value="product_description2" :auto-size="{ minRows: 15, maxRows: 15 }"
                    style="width:100%;" :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                </div>
                <a href="javascript:;" class="underline text-h10" v-if="product_description_edit_type === 'editor'"
                  @click="() => {
                    product_description_edit_type = 'textarea';
                    product_description2 = product_description;
                  }">
                  使用纯文本编辑器
                </a>
                <a href="javascript:;" class="underline text-h10" v-if="product_description_edit_type === 'textarea'"
                  @click="() => {
                    product_description_edit_type = 'editor';
                    product_description = product_description2;
                  }">
                  使用富文本编辑器
                </a>
              </a-card>
              <a-card title="产品信息" id="card4" class="mb-2 code-box">
                <a-row>
                  <a-col :span="12">
                    <div class="form-item">
                      <div class="label required">
                        <span class="cn">主SKU编号</span>
                      </div>
                      <a-form-item name="item_sku" :rules="[{ required: true, message: '请输入主SKU编号' }]"
                        style="width:100%;">
                        <div class="d-flex align-center">
                          <a-input v-model:value="listingForm.item_sku" :maxlength="40" style="width:100%;"
                            :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'"></a-input>
                          <p class="text-gray text-right ml-1" style="min-width:40px;">
                            {{ listingForm.item_sku?.length || 0 }}/40
                          </p>
                        </div>
                      </a-form-item>
                    </div>
                  </a-col>
                  <a-col :span="12">
                    <div class="form-item">
                      <div class="label required">
                        <span class="cn">品牌</span>
                      </div>
                      <a-form-item name="brand_name" :rules="[{ required: true, message: '请输入品牌' }]">
                        <a-auto-complete v-model:value="listingForm.brand_name" :options="brands" placeholder="请输入品牌"
                          style="width:400px;" :filter-option="filterOption"
                          :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                      </a-form-item>
                    </div>
                  </a-col>
                  <a-col :span="12">
                    <div class="form-item">
                      <div class="label required">
                        <span class="cn">制造商</span>
                      </div>
                      <a-form-item name="manufacturer" :rules="[{ required: true, message: '请输入制造商' }]">
                        <a-auto-complete v-model:value="listingForm.manufacturer" :options="manufacturers"
                          placeholder="请输入制造商" style="width:400px;" :filter-option="filterOption"
                          :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                      </a-form-item>
                    </div>
                  </a-col>
                  <a-col :span="12">
                    <div class="form-item">
                      <div class="label">
                        <span class="cn">制造商零件编号</span>
                      </div>
                      <a-form-item name="part_number">
                        <div class="d-flex align-center">
                          <a-input v-model:value="listingForm.part_number" placeholder="最多40个字符" :maxlength="40"
                            style="width:400px;"
                            :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                          <a-tooltip title="品牌-主SKU编号来生成"
                            v-if="listingForm.state === 'draft' || listingForm.state === 'fail'">
                            <a-button
                              @click="() => { listingForm.part_number = (listingForm.brand_name || '') + '-' + (listingForm.item_sku || '') }">
                              一键生成
                            </a-button>
                          </a-tooltip>
                          <p class="text-gray text-right ml-1" style="min-width:40px;">
                            {{ listingForm.part_number?.length || 0 }}/40
                          </p>
                        </div>
                      </a-form-item>
                    </div>
                  </a-col>
                  <a-col :span="12">
                    <div class="form-item">
                      <div class="label required">
                        <span class="cn">物品状况</span>
                      </div>
                      <a-form-item name="condition_type" :rules="[{ required: true, message: '请选择物品状况' }]"
                        style="width:100%;">
                        <a-select v-model:value="listingForm.condition_type"
                          :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'">
                          <a-select-option v-for="item in condition_types" :key="item" :value="item">
                            {{ item }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </div>
                  </a-col>
                  <a-col :span="24">
                    <div class="form-item">
                      <div class="label">
                        <span class="cn">物品状况描述</span>
                      </div>
                      <a-form-item name="condition_note" style="width:100%;">
                        <a-textarea v-model:value="listingForm.condition_note" :auto-size="{ minRows: 4, maxRows: 4 }"
                          :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                      </a-form-item>
                    </div>
                  </a-col>
                  <a-col :span="24">
                    <div class="form-item">
                      <div class="label">
                        <span class="cn">Platinum Keywords</span>
                      </div>
                      <a-form-item name="platinum_keywords" style="width:100%;">
                        <a-row :gutter="16">
                          <a-col :span="12">
                            <a-form-item>
                              <div class="d-flex align-center">
                                <a-input v-model:value="listingForm.platinum_keywords1" placeholder="最多100字符"
                                  style="width:100%;" :maxlength="100"
                                  :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                                <p class="text-gray text-right ml-1" style="min-width:50px;">
                                  {{ listingForm.platinum_keywords1?.length || 0 }}/100
                                </p>
                              </div>
                            </a-form-item>
                          </a-col>
                          <a-col :span="12">
                            <a-form-item>
                              <div class="d-flex align-center">
                                <a-input v-model:value="listingForm.platinum_keywords2" placeholder="最多100字符"
                                  style="width:100%;" :maxlength="100"
                                  :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                                <p class="text-gray text-right ml-1" style="min-width:50px;">
                                  {{ listingForm.platinum_keywords1?.length || 0 }}/100
                                </p>
                              </div>
                            </a-form-item>
                          </a-col>
                          <a-col :span="12">
                            <a-form-item>
                              <div class="d-flex align-center">
                                <a-input v-model:value="listingForm.platinum_keywords4" placeholder="最多100字符"
                                  style="width:100%;" :maxlength="100"
                                  :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                                <p class="text-gray text-right ml-1" style="min-width:50px;">
                                  {{ listingForm.platinum_keywords1?.length || 0 }}/100
                                </p>
                              </div>
                            </a-form-item>
                          </a-col>
                          <a-col :span="12">
                            <a-form-item>
                              <div class="d-flex align-center">
                                <a-input v-model:value="listingForm.platinum_keywords4" placeholder="最多100字符"
                                  style="width:100%;" :maxlength="100"
                                  :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                                <p class="text-gray text-right ml-1" style="min-width:50px;">
                                  {{ listingForm.platinum_keywords1?.length || 0 }}/100
                                </p>
                              </div>
                            </a-form-item>
                          </a-col>
                          <a-col :span="12">
                            <a-form-item>
                              <div class="d-flex align-center">
                                <a-input v-model:value="listingForm.platinum_keywords5" placeholder="最多100字符"
                                  style="width:100%;" :maxlength="100"
                                  :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                                <p class="text-gray text-right ml-1" style="min-width:50px;">
                                  {{ listingForm.platinum_keywords1?.length || 0 }}/100
                                </p>
                              </div>
                            </a-form-item>
                          </a-col>
                        </a-row>
                      </a-form-item>
                    </div>
                  </a-col>
                </a-row>
              </a-card>
              <a-card title="产品图片" id="card5" class="mb-2 code-box">
                <template #extra>
                  <div style="background-color:#f4f4f5;color:#909399;padding:8px 16px;">
                    <InfoCircleFilled style="font-size:16px;width:16px;" />
                    图片最多支持<span class="text-error">9</span>张
                  </div>
                </template>
                <div class="el-alert el-alert--warning is-light mb15">
                  <div class="el-alert__content">
                    <span class="el-alert__title is-bold">图像必须符合以下要求：</span>
                    <div class="el-alert__description">
                      <ul style="list-style: disc; line-height: 200%;margin:0;">
                        <li>
                          主图的背景<span class="text-danger bold">必须是纯白色</span>，
                          只能是照片，不能包含实际不在订单内的配件和道具；主图不能带LOGO和水印（产品本身的LOGO是允许的）主图中的产品最好是占据图片大约85%左右的空间；
                        </li>
                        <li>图片像素建议大于1000*1000像素，可有'zoom function'图片放大功能，图片不能小于500*500像素；</li>
                        <li>推荐上传JPEG格式 , 也可上传TIFF, GIF 格式的图片；</li>
                        <li>图片中如有模特，模特不能是坐姿，最好站立，只能用真人模特，不能使用模型模特，不能包含裸体信息；</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <a-row v-if="listingForm.state === 'draft' || listingForm.state === 'fail'">
                  <a-col :span="14">
                    <p class="text-h8">添加图片</p>
                    <ImagePicker0 :direction="'horizontal'" :max_count="MAX_IMAGE_COUNT" :images="listingForm.images"
                      @local-upload-success="(data) => onLocalUploadSuccess({ type: 'listingform', data })"
                      @network-input-success="(data) => onNetworkInputSuccess({ type: 'listingform', data })"
                      @space-pick-success="(data) => onSpacePickSuccess({ type: 'listingform', data })" />
                  </a-col>
                  <a-col :span="10">

                  </a-col>
                </a-row>
                <draggable v-if="listingForm.images?.length > 0" v-model="listingForm.images" class="d-flex flex-wrap"
                  style="min-height:125px;" :move="() => (listingForm.state === 'draft' || listingForm.state === 'fail')">
                  <template #item="{ index, element: image }">
                    <div style="width:105px;height:125px;border:1px solid #e8e8e8;" class="mr-1">
                      <a-image width="105px" height="105px" :src="image" style="object-fit: contain;height:100%;">
                        <template #placeholder>
                          <a-image :src="image_placeholder" width="105px" height="105px" :preview="false" />
                        </template>
                      </a-image>
                      <div style="width:105px;height:20px;" class="d-flex justify-space-between"
                        v-if="listingForm.state === 'draft' || listingForm.state === 'fail'">
                        <a-dropdown>
                          <template #overlay>
                            <ImagePicker1 :max_count="1" :images="[]"
                              @local-upload-success="(data) => onLocalUploadSuccess({ type: 'listingform2', index, data })"
                              @network-input-success="(data) => onNetworkInputSuccess({ type: 'listingform2', index, data })"
                              @space-pick-success="(data) => onSpacePickSuccess({ type: 'listingform2', index, data })" />
                          </template>
                          <a-tooltip>
                            <template #title>更换图片</template>
                            <svg-icon iconClass="huantu"
                              :style="{ 'height': '20px', 'width': '20px', cursor: 'pointer' }" />
                          </a-tooltip>
                        </a-dropdown>
                        <a-tooltip>
                          <template #title>删除图片</template>
                          <DeleteOutlined @click="removeImage({ type: 'listingform', index })"
                            style="font-size:20px;color:#ff4d4f;" />
                        </a-tooltip>
                      </div>
                    </div>
                  </template>
                </draggable>
                <div v-else class="el-alert text-center no-data mt15 el-alert--info is-center is-light">
                  <div class="el-alert__content"><span class="el-alert__title">暂无图片，请上传产品图片</span>
                  </div>
                </div>
              </a-card>
              <a-card title="售价和多属性" id="card6" class="mb-2 code-box">
                <div class="form-item">
                  <div class="label ">
                    <span class="cn">售卖形式</span>
                  </div>
                  <a-form-item name="sale_type">
                    <a-radio-group v-model:value="listingForm.sale_type" name="sale_type"
                      :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'">
                      <a-radio value="1">单品</a-radio>
                      <a-radio value="2">变种</a-radio>
                    </a-radio-group>
                    <p class="loadtext pb0 text-warning">
                      {{
                        listingForm.external_product_id_type === 'GCID' ?
                        '注意：如果您申请过亚马逊品牌备案，并且针对您刊登的分类也申请过GTIN豁免（UPC豁免)，在GCID选项下填写对应的MPN(制造商零件编号）即可，建议不超过40个字符，且每个子SKU的MPN编号不同。'
                        :
                        '注意：如果您申请过亚马逊品牌备案，并且针对您刊登的分类也申请过GTIN豁免（UPC豁免)，则无需填写UPC。产品ID类型选择GTIN后，保持产品ID为空即可。'
                      }}
                    </p>
                  </a-form-item>
                </div>
                <a-row>
                  <a-col :span="12">
                    <div class="form-item">
                      <div class="label ">
                        <span class="cn">税务编码</span>
                      </div>
                      <a-form-item name="product_tax_code">
                        <a-input v-model:value="listingForm.product_tax_code" style="width:400px;"
                          :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                      </a-form-item>
                    </div>
                  </a-col>
                  <a-col :span="12">
                    <div class="form-item">
                      <div class="label ">
                        <span class="cn">处理天数</span>
                      </div>
                      <a-form-item name="fulfillment_latency">
                        <a-input v-model:value="listingForm.fulfillment_latency" type="number" suffix="天"
                          style="width:400px;"
                          :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                      </a-form-item>
                    </div>
                  </a-col>
                  <template v-if="listingForm.sale_type === '1'">
                    <a-col :span="24">
                      <div class="form-item">
                        <div class="label required">
                          <span class="cn">产品ID</span>
                        </div>
                        <a-form-item name="external_product_id"
                          :rules="listingForm.external_product_id_type === 'GTIN' ? [] : [{ required: true, message: '请输入产品ID' }]">
                          <div class="d-flex align-center">
                            <a-select v-model:value="listingForm.external_product_id_type" style="width:80px;"
                              :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'">
                              <a-select-option v-for="item in external_product_id_types" :key="item" :value="item">
                                {{ item }}
                              </a-select-option>
                            </a-select>
                            <a-input v-model:value="listingForm.external_product_id" placeholder="最多16个字符" :maxlength="16"
                              style="width:400px;"
                              :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                            <p class="text-gray text-right ml-1" style="min-width:40px;">
                              {{ listingForm.external_product_id?.length || 0 }}/16
                            </p>
                            <a-button class="btn-primary ml-2" @click="getExternalProductID">
                              自动获取
                            </a-button>
                          </div>
                        </a-form-item>
                      </div>
                    </a-col>
                    <a-col :span="12">
                      <div class="form-item">
                        <div class="label required">
                          <span class="cn">价格</span>
                        </div>
                        <a-form-item name="standard_price"
                          :rules="[{ required: true, message: '价格不能为空或0' }, { validator: validatePrice, trigger: 'change' }]">
                          <div class="d-flex align-center" style="width:100%">
                            <a-input v-model:value="listingForm.standard_price" type="number" disabled
                              style="width:400px;" />
                            <a-button @click="editListingPrice"
                              :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'">
                              <template #icon>
                                <FormOutlined />
                              </template>
                            </a-button>
                          </div>
                        </a-form-item>
                      </div>
                    </a-col>
                    <a-col :span="12">
                      <div class="form-item">
                        <div class="label required">
                          <span class="cn">数量</span>
                        </div>
                        <a-form-item name="quantity"
                          :rules="[{ required: true, message: '数量不能为空' }, { validator: validateQuantity, trigger: 'change' }]">
                          <a-input v-model:value="listingForm.quantity" type="number" style="width:400px;"
                            :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                        </a-form-item>
                      </div>
                    </a-col>
                  </template>
                  <template v-if="listingForm.sale_type === '2'">
                    <a-col :span="12">
                      <div class="form-item">
                        <div class="label required">
                          <span class="cn">商品属性</span>
                        </div>
                        <a-form-item name="variation_theme" :rules="[{ required: true, message: `请选择商品属性` }]">
                          <a-select v-model:value="listingForm.variation_theme" :options="variation_themes"
                            placeholder="请选择" show-search style="width:400px;" @change="changeVariationTheme"
                            :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'">
                          </a-select>
                        </a-form-item>
                      </div>
                    </a-col>
                    <a-col :span="24">
                      <div class="d-flex justify-space-between">
                        <a-button danger @click="removeVariationItems"
                          :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'">
                          <DeleteOutlined />批量删除多属性
                        </a-button>
                        <a-space>
                          <a-button class="btn-success" @click="addVariationItem">
                            <PlusOutlined />添加一行多属性
                          </a-button>
                          <a-button class="btn-primary" @click="editVariationTheme"
                            :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'">
                            <FormOutlined />批量修改多属性
                          </a-button>
                        </a-space>
                      </div>
                      <template v-if="variation_list.length > 0">
                        <a-table :columns="variation_headers" :data-source="variation_list" bordered :pagination="false"
                          row-key="_id"
                          :row-selection="{ selectedRowKeys: variation_ids, onChange: (keys) => variation_ids = keys }">
                          <template #headerCell="{ column }">
                            <template v-if="column.dataIndex === 'color_name'">
                              <span class="header required">color_name</span>
                            </template>
                            <template v-if="column.dataIndex === 'size_name'">
                              <span class="header required">size_name</span>
                            </template>
                            <template v-if="column.dataIndex === 'material_type'">
                              <span class="header required">material</span>
                            </template>
                            <template v-if="column.dataIndex === 'item_sku'">
                              <div class="d-flex flex-column justify-center align-center">
                                <span class="header required">SKU编号</span>
                                <a v-if="listingForm.state === 'draft' || listingForm.state === 'fail'"
                                  href="javascript:;" class="text-primary font-weight-bold text-h10 underline"
                                  @click="oneClickGenerate('item_sku')">一键生成</a>
                              </div>
                            </template>
                            <template v-if="column.dataIndex === 'external_product_id'">
                              <div class="d-flex justify-center align-center">
                                <a-select v-model:value="variation_list[0].external_product_id_type"
                                  @change="changeVariationListProductIDType"
                                  :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'">
                                  <a-select-option v-for="item in external_product_id_types" :key="item" :value="item">
                                    {{ item }}
                                  </a-select-option>
                                </a-select>
                                <div v-if="listingForm.state === 'draft' || listingForm.state === 'fail'"
                                  class="d-flex flex-column ml-2">
                                  <a v-if="variation_list[0].external_product_id_type === 'GCID'" href="javascript:;"
                                    class="text-primary font-weight-bold text-h10 underline"
                                    @click="oneClickGenerate('external_product_id')">一键生成</a>
                                  <a href="javascript:;" class="text-primary font-weight-bold text-h10 underline"
                                    @click="getExternalProductID">批量修改</a>
                                </div>
                              </div>
                            </template>
                            <template v-if="column.dataIndex === 'quantity'">
                              <div class="d-flex flex-column justify-center align-center">
                                <span class="header required">数量</span>
                                <a v-if="listingForm.state === 'draft' || listingForm.state === 'fail'"
                                  href="javascript:;" class="text-primary font-weight-bold text-h10 underline"
                                  @click="editVariationList('quantity')">批量修改</a>
                              </div>
                            </template>
                            <template v-if="column.dataIndex === 'standard_price'">
                              <div class="d-flex flex-column justify-center align-center">
                                <span class="header required">价格</span>
                                <a-space v-if="listingForm.state === 'draft' || listingForm.state === 'fail'">
                                  <a href="javascript:;" class="text-primary font-weight-bold text-h10 underline"
                                    @click="editVariationList('standard_price')">批量修改</a>
                                  <a-tooltip :overlayStyle="{ 'width': '750px', 'max-width': '750px' }">
                                    <template #title>
                                      <p>将第一个站点的金额，换算成其他站点对应币种的金额</p>
                                      <p>汇率设置请至--系统管理>汇率管理去设置，如果没有设置有效汇率，点击汇率换算，将不会执行换算，价格栏为空值</p>
                                    </template>
                                    <a href="javascript:;"
                                      class="text-primary font-weight-bold text-h10 underline">汇率换算</a>
                                  </a-tooltip>
                                </a-space>
                              </div>
                            </template>
                            <template v-if="column.dataIndex === 'item_name'">
                              <div class="d-flex flex-column justify-center align-center">
                                <span class="header">
                                  标题
                                  <a-tooltip color="#fff" :overlayStyle="{ 'width': '400px', 'max-width': '400px' }">
                                    <template #title>
                                      <div class="text-black pa-1">
                                        <div class="text-h6">多属性标题</div>
                                        标题建议修改成200字符以内，如仍要上传，商品能上传成功但后台数据可能禁止显示
                                      </div>
                                    </template>
                                    <QuestionCircleOutlined class="text-primary" />
                                  </a-tooltip>
                                </span>
                                <a v-if="listingForm.state === 'draft' || listingForm.state === 'fail'"
                                  href="javascript:;" class="text-primary font-weight-bold text-h10 underline"
                                  @click="oneClickGenerate('item_name')">一键生成</a>
                              </div>
                            </template>
                          </template>
                          <template #bodyCell="{ record, index, column }">
                            <template v-if="column.dataIndex === 'index'">
                              <div class="d-flex flex-column align-center">
                                <a-button v-if="listingForm.state === 'draft' || listingForm.state === 'fail'"
                                  style="width:20px;height:20px;padding:0;" :disabled="index === 0"
                                  @click="moveUp({ index })">
                                  <template #icon>
                                    <UpOutlined />
                                  </template>
                                </a-button>
                                {{ index + 1 }}
                                <a-button v-if="listingForm.state === 'draft' || listingForm.state === 'fail'"
                                  style="width:20px;height:20px;padding:0;"
                                  :disabled="index === variation_list.length - 1" @click="moveDown({ index })">
                                  <template #icon>
                                    <DownOutlined />
                                  </template>
                                </a-button>
                              </div>
                            </template>
                            <template v-if="column.dataIndex === 'color_name'">
                              <a-input v-model:value="record.color_name" style="width:100%"
                                @blur="(e) => blurVariationItemAttribute(e, record.color_name)"
                                :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                            </template>
                            <template v-if="column.dataIndex === 'size_name'">
                              <a-input v-model:value="record.size_name" style="width:100%"
                                @blur="(e) => blurVariationItemAttribute(e, record.size_name)"
                                :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                            </template>
                            <template v-if="column.dataIndex === 'material_type'">
                              <a-input v-model:value="record.material_type" style="width:100%"
                                @blur="(e) => blurVariationItemAttribute(e, record.material_type)"
                                :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                            </template>
                            <template v-if="column.dataIndex === 'item_sku'">
                              <div class="d-flex align-center">
                                <a-input v-model:value="record.item_sku" :maxlength="40" style="width:100%"
                                  :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                                <p class="text-gray text-right ml-1" style="min-width:40px;">
                                  {{ record.item_sku?.length || 0 }}/40
                                </p>
                              </div>
                            </template>
                            <template v-if="column.dataIndex === 'external_product_id'">
                              <div class="d-flex align-center">
                                <a-input v-model:value="record.external_product_id" :maxlength="40" style="width:100%"
                                  :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                                <p class="text-gray text-right ml-1" style="min-width:40px;">
                                  {{ record.external_product_id?.length || 0 }}/40
                                </p>
                              </div>
                            </template>
                            <template v-if="column.dataIndex === 'quantity'">
                              <a-input v-model:value="record.quantity" type="number" style="width:100%"
                                :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                            </template>
                            <template v-if="column.dataIndex === 'standard_price'">
                              <div class="d-flex align-center" style="width:100%">
                                <a-input v-model:value="record.standard_price" type="number" disabled />
                                <a-button v-if="listingForm.state === 'draft' || listingForm.state === 'fail'"
                                  @click="editVariationItem(index, record, 'standard_price')">
                                  <template #icon>
                                    <FormOutlined />
                                  </template>
                                </a-button>
                              </div>
                            </template>
                            <template v-if="column.dataIndex === 'item_name'">
                              <div class="d-flex align-center" style="width:100%">
                                <a-input v-model:value="record.item_name" disabled style="width:100%" />
                                <a-button v-if="listingForm.state === 'draft' || listingForm.state === 'fail'"
                                  @click="editVariationItem(index, record, 'item_name')">
                                  <template #icon>
                                    <FormOutlined />
                                  </template>
                                </a-button>
                              </div>
                            </template>
                            <template v-if="column.dataIndex === 'operation'">
                              <a-space v-if="listingForm.state === 'draft' || listingForm.state === 'fail'"
                                direction="vertical">
                                <a href="javascript:;" class="mb-2" @click="useAllVariationItem(index)">
                                  <CopyOutlined /> 应用到全部
                                </a>
                                <a href="javascript:;" class="text-error" @click="removeVariationItem(index)">
                                  <DeleteOutlined /> 删除
                                </a>
                              </a-space>
                            </template>
                          </template>
                        </a-table>
                        <a-table :columns="variation_headers2" :data-source="variation_list" bordered :pagination="false">
                          <template #bodyCell="{ record, index, column }">
                            <template v-if="column.dataIndex === 'index'">
                              {{ index + 1 }}
                            </template>
                            <template v-if="column.dataIndex === 'attribute'">
                              <a-space direction="vertical">
                                <div v-if="variation_color">
                                  color_name:<span class="text-primary">{{ record.color_name }}</span>
                                </div>
                                <div v-if="variation_size">
                                  size_name:<span class="text-primary">{{ record.size_name }}</span>
                                </div>
                                <div v-if="variation_material">
                                  material:<span class="text-primary">{{ record.material_type }}</span>
                                </div>
                                <a v-if="listingForm.state === 'draft' || listingForm.state === 'fail'"
                                  href="javascript:;" class="mt-2" @click="useAllImages(index)">
                                  <CopyOutlined /> 应用到全部图片
                                </a>
                                <a v-if="listingForm.state === 'draft' || listingForm.state === 'fail'"
                                  href="javascript:;" class="mt-2 mb-2" @click="useAllOtherImages(index)">
                                  <CopyOutlined /> 应用到全部附图
                                </a>
                              </a-space>
                            </template>
                            <template v-if="column.dataIndex === 'main_image'">
                              <a-dropdown v-if="listingForm.state === 'draft' || listingForm.state === 'fail'">
                                <template #overlay>
                                  <ImagePicker2 :max_count="1" :images="[]"
                                    :pre_menus="[{ label: '引用橱窗图', value: 'choose-from-images' }]"
                                    @choose-from-images-clicked="() => chooseFromImages({ type: 'main_image', index })"
                                    @local-upload-success="(data) => record.main_image = data.url"
                                    @network-input-success="(data) => record.main_image = data.images[0]"
                                    @space-pick-success="(data) => record.main_image = data.images[0]" />
                                </template>
                                <a-button>
                                  更改图片
                                  <DownOutlined />
                                </a-button>
                              </a-dropdown>
                              <div style="width:105px;height:125px;border:1px solid #e8e8e8;">
                                <template v-if="record.main_image">
                                  <a-image width="105px" height="105px" :src="record.main_image"
                                    style="object-fit: contain;height:100%;">
                                    <template #placeholder>
                                      <a-image :src="image_placeholder" width="105px" height="105px" :preview="false" />
                                    </template>
                                  </a-image>
                                  <div v-if="listingForm.state === 'draft' || listingForm.state === 'fail'"
                                    style="width:105px;height:20px;" class="d-flex justify-end">
                                    <a-tooltip>
                                      <template #title>删除图片</template>
                                      <DeleteOutlined @click="() => record.main_image = ''"
                                        style="font-size:20px;color:#ff4d4f;" />
                                    </a-tooltip>
                                  </div>
                                </template>
                              </div>
                            </template>
                            <template v-if="column.dataIndex === 'swatch_image'">
                              <a-dropdown v-if="listingForm.state === 'draft' || listingForm.state === 'fail'">
                                <template #overlay>
                                  <ImagePicker3 :max_count="1" :images="[]"
                                    :pre_menus="[{ label: '引用橱窗图', value: 'choose-from-images' }]"
                                    @choose-from-images-clicked="() => chooseFromImages({ type: 'swatch_image', index })"
                                    @local-upload-success="(data) => record.swatch_image = data.url"
                                    @network-input-success="(data) => record.swatch_image = data.images[0]"
                                    @space-pick-success="(data) => record.swatch_image = data.images[0]" />
                                </template>
                                <a-button>
                                  更改图片
                                  <DownOutlined />
                                </a-button>
                              </a-dropdown>
                              <div style="width:105px;height:125px;border:1px solid #e8e8e8;">
                                <template v-if="record.swatch_image">
                                  <a-image width="105px" height="105px" :src="record.swatch_image"
                                    style="object-fit: contain;height:100%;">
                                    <template #placeholder>
                                      <a-image :src="image_placeholder" width="105px" height="105px" :preview="false" />
                                    </template>
                                  </a-image>
                                  <div v-if="listingForm.state === 'draft' || listingForm.state === 'fail'"
                                    style="width:105px;height:20px;" class="d-flex justify-end">
                                    <a-tooltip>
                                      <template #title>删除图片</template>
                                      <DeleteOutlined @click="() => record.swatch_image = ''"
                                        style="font-size:20px;color:#ff4d4f;" />
                                    </a-tooltip>
                                  </div>
                                </template>
                              </div>
                            </template>
                            <template v-if="column.dataIndex === 'other_images'">
                              <div class="d-flex align-center">
                                <a-dropdown v-if="listingForm.state === 'draft' || listingForm.state === 'fail'"
                                  :disabled="record.other_images.length >= MAX_IMAGE_COUNT - 1">
                                  <template #overlay>
                                    <ImagePicker4 :max_count="MAX_IMAGE_COUNT - 1" :images="record.other_images"
                                      :pre_menus="[{ label: '引用橱窗图', value: 'choose-from-images' }]"
                                      @choose-from-images-clicked="() => chooseFromImages({ type: 'other_images', count: MAX_IMAGE_COUNT - 1 - record.other_images.length, index })"
                                      @local-upload-success="(data) => onLocalUploadSuccess({ type: 'other_images', index, data })"
                                      @network-input-success="(data) => onNetworkInputSuccess({ type: 'other_images', index, data })"
                                      @space-pick-success="(data) => onSpacePickSuccess({ type: 'other_images', index, data })" />
                                  </template>
                                  <a-button>
                                    添加图片
                                    <DownOutlined />
                                  </a-button>
                                </a-dropdown>
                                <span>【{{ record.other_images.length }} / {{ MAX_IMAGE_COUNT - 1 }}】</span>
                              </div>
                              <draggable v-model="record.other_images" class="d-flex flex-wrap" style="min-height:125px;"
                                :move="() => (listingForm.state === 'draft' || listingForm.state === 'fail')">
                                <template #item="{ index: iindex, element: image }">
                                  <div style="width:105px;height:125px;border:1px solid #e8e8e8;" class="mr-1">
                                    <a-image width="105px" height="105px" :src="image"
                                      style="object-fit: contain;height:100%;">
                                      <template #placeholder>
                                        <a-image :src="image_placeholder" width="105px" height="105px" :preview="false" />
                                      </template>
                                    </a-image>
                                    <div v-if="listingForm.state === 'draft' || listingForm.state === 'fail'"
                                      style="width:105px;height:20px;" class="d-flex justify-space-between">
                                      <a-dropdown>
                                        <template #overlay>
                                          <ImagePicker5 :max_count="1" :images="[]"
                                            :pre_menus="[{ label: '引用橱窗图', value: 'choose-from-images' }]"
                                            @choose-from-images-clicked="() => chooseFromImages({ type: 'other_images2', index, index2: iindex, count: 1 })"
                                            @local-upload-success="(data) => onLocalUploadSuccess({ type: 'other_images', index, index2: iindex, data })"
                                            @network-input-success="(data) => onNetworkInputSuccess({ type: 'other_images', index, index2: iindex, data })"
                                            @space-pick-success="(data) => onSpacePickSuccess({ type: 'other_images', index, index2: iindex, data })" />
                                        </template>
                                        <a-tooltip>
                                          <template #title>更换图片</template>
                                          <svg-icon iconClass="huantu"
                                            :style="{ 'height': '20px', 'width': '20px', cursor: 'pointer' }" />
                                        </a-tooltip>
                                      </a-dropdown>
                                      <a-tooltip>
                                        <template #title>删除图片</template>
                                        <DeleteOutlined
                                          @click="removeImage({ type: 'other_images', index, index2: iindex })"
                                          style="font-size:20px;color:#ff4d4f;" />
                                      </a-tooltip>
                                    </div>
                                  </div>
                                </template>
                              </draggable>
                            </template>
                            <template v-if="column.dataIndex === 'operation'">

                            </template>
                          </template>
                        </a-table>
                      </template>
                      <div v-else class="el-alert text-center no-data el-alert--info is-center mt15">
                        <div class="el-alert__content">
                          <span class="el-alert__title">
                            暂无多属性SKU，您可以
                            <a v-if="listingForm.state === 'draft' || listingForm.state === 'fail'" href="javascript:;"
                              @click="editVariationTheme">
                              <span>点击此处</span>
                            </a>
                            创建或编辑多属性
                          </span>
                        </div>
                      </div>
                    </a-col>
                  </template>
                </a-row>
              </a-card>
            </a-form>
            <div class="footer">
              <template v-if="listingForm.state === 'draft' || listingForm.state === 'fail'">
                <a-button class="btn-primary mr-2" size="large" @click="doCheck" :loading="saving">
                  检测侵权词
                </a-button>
                <!-- <a-button class="btn-warning mr-2" size="large" @click="doTranslate" disabled :loading="saving">
            一键翻译(标题、纯文本描述等)
          </a-button> -->
                <a-button class="btn-primary mr-2" size="large" @click="() => doSave({ publish: false })"
                  :loading="saving">
                  保存
                </a-button>
                <a-button class="btn-primary mr-2" size="large" @click="doSend" :loading="saving">
                  保存并刊登
                </a-button>
              </template>
              <a-button size="large" @click="doClose">关闭</a-button>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </a-spin>
  <!-- #region 修改账号和站点 -->
  <a-modal v-model:visible="shopaccountModal" centered title="修改账号和站点" @ok="confirmShopAccount" ok-text="确定"
    cancel-text="取消" width="600px">
    <a-alert type="warning" show-icon class="mb-4">
      <template #message>
        <span class="text-warning">修改账号和站点可能会导致已编辑的内容(差异化信息)清空，请谨慎修改</span>
      </template>
    </a-alert>
    <a-form ref="shopaccountformRef" name="shopaccountForm" :model="shopaccountForm" :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }" autocomplete="off">
      <a-form-item label="选择账号" name="account_username" :rules="[{ required: true, message: '请选择账号' }]">
        <a-select v-model:value="shopaccountForm.account_username" :options="shopaccounts" placeholder="请选择"
          show-search />
      </a-form-item>
      <a-form-item label="选择站点" name="regions"
        :rules="[{ required: true, message: '请选勾选至少一个站点', validator: validateShopRegion }]">
        <a-checkbox-group v-if="shop_regions.length > 0" v-model:value="shopaccountForm.regions"
          :options="shop_regions" />
        <div v-else style="background-color:#f4f4f5;color:#909399;padding:8px 16px;">
          <InfoCircleFilled style="font-size:16px;width:16px;" /> 请先选择账号
        </div>
      </a-form-item>
    </a-form>
  </a-modal>
  <!-- #endregion -->
  <!-- #region 变体多属性 -->
  <a-modal v-model:visible="variationThemeModal" centered title="批量编辑多属性" @ok="confirmVariationTheme" ok-text="确认添加"
    cancel-text="关闭" width="750px">
    <a-row :gutter="4">
      <a-col :span="8" v-if="variation_color">
        <div class="variation_panel">
          <div class="d-flex align-center variation_header">
            color_name
          </div>
          <div style="height:246px;">
            <a-input-search v-model:value="variation_input_color" placeholder="请输入属性项名称" @search="addVariationColor">
              <template #enterButton>
                <a-button style="background:#f5f7fa;">添加</a-button>
              </template>
            </a-input-search>
            <div class="d-flex flex-column">
              <a-space v-for="(color, index) in variationThemeForm.colors" :key="index" class="pt-2 pl-2 pr-2">
                <a-input v-model:value="color.value"></a-input>
                <DeleteOutlined @click="removeVariationColor(index)" />
              </a-space>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :span="8" v-if="variation_size">
        <div class="variation_panel">
          <div class="d-flex align-center variation_header">
            size_name
          </div>
          <div style="height:246px;">
            <a-input-search v-model:value="variation_input_size" placeholder="请输入属性项名称" @search="addVariationSize">
              <template #enterButton>
                <a-button style="background:#f5f7fa;">添加</a-button>
              </template>
            </a-input-search>
            <div class="d-flex flex-column">
              <a-space v-for="(size, index) in variationThemeForm.sizes" :key="index" class="pt-2 pl-2 pr-2">
                <a-input v-model:value="size.value"></a-input>
                <DeleteOutlined @click="removeVariationSize(index)" />
              </a-space>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :span="8" v-if="variation_material">
        <div class="variation_panel">
          <div class="d-flex align-center variation_header">
            material
          </div>
          <div style="height:246px;">
            <a-input-search v-model:value="variation_input_material" placeholder="请输入属性项名称"
              @search="addVariationMaterial">
              <template #enterButton>
                <a-button style="background:#f5f7fa;">添加</a-button>
              </template>
            </a-input-search>
            <div class="d-flex flex-column">
              <a-space v-for="(material, index) in variationThemeForm.materials" :key="index" class="pt-2 pl-2 pr-2">
                <a-input v-model:value="material.value"></a-input>
                <DeleteOutlined @click="removeVariationMaterial(index)" />
              </a-space>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </a-modal>
  <!-- #endregion -->
  <!-- #region 变体单行多属性 -->
  <a-modal v-model:visible="variationItemModal" centered title="添加单行多属性" @cancel="() => variationItemModal = false"
    width="600px">
    <template #footer>
      <div class="d-flex justify-end align-center">
        <a-space>
          <a-button @click="() => variationItemModal = false">取消</a-button>
          <a-button class="btn-primary" @click="confirmVariationItem">确定</a-button>
        </a-space>
      </div>
    </template>
    <a-form ref="variationItemFormRef" name="variationItemForm" :model="variationItemForm" :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }" autocomplete="off">
      <a-form-item label="SKU编号" name="item_sku" :rules="[{ required: true, message: 'SKU编号不能为空' }]">
        <a-input v-model:value="variationItemForm.item_sku" />
      </a-form-item>
      <a-form-item v-if="variation_color" label="color_name" name="color_name"
        :rules="[{ required: true, message: '属性项名称不能为空！' }]">
        <a-input v-model:value="variationItemForm.color_name" />
      </a-form-item>
      <a-form-item v-if="variation_size" label="size_name" name="size_name"
        :rules="[{ required: true, message: '属性项名称不能为空！' }]">
        <a-input v-model:value="variationItemForm.size_name" />
      </a-form-item>
      <a-form-item v-if="variation_material" label="material" name="material_type"
        :rules="[{ required: true, message: '属性项名称不能为空！' }]">
        <a-input v-model:value="variationItemForm.material_type" />
      </a-form-item>
    </a-form>
  </a-modal>
  <!-- #endregion -->
  <!-- #region 变体批量修改数量 -->
  <a-modal v-model:visible="variationListQuantityModal" centered title="批量修改数量"
    @cancel="() => variationListQuantityModal = false" width="400px">
    <template #footer>
      <div class="d-flex justify-end align-center">
        <a-space>
          <a-button @click="() => variationListQuantityModal = false">取消</a-button>
          <a-button class="btn-primary" @click="confirmVariationListQuantity">确定</a-button>
        </a-space>
      </div>
    </template>
    <div class="d-flex justify-center align-center">
      <a-form-item label="数量" name="variationListQuantity">
        <a-input v-model:value="variationListQuantity" type="number" />
      </a-form-item>
    </div>
  </a-modal>
  <!-- #endregion -->
  <!-- #region 变体批量修改价格 -->
  <a-modal v-model:visible="variationListPriceModal" centered title="批量修改多属性价格"
    @cancel="() => variationListPriceModal = false" width="400px">
    <template #footer>
      <div class="d-flex justify-end align-center">
        <a-space>
          <a-button @click="() => variationListPriceModal = false">取消</a-button>
          <a-button class="btn-primary" @click="confirmVariationListPrice">确定</a-button>
        </a-space>
      </div>
    </template>
    <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" autocomplete="off">
      <a-form-item v-for="(region, index) in listingForm.regions" :key="index" :label="listingForm.region_names[index]">
        <a-input v-model:value="variationListPrice[region].standard_price" type="number" />
      </a-form-item>
    </a-form>
  </a-modal>
  <!-- #endregion -->
  <!-- #region 变体修改站点价格 -->
  <a-modal v-model:visible="variationItemPriceModal" centered title="修改站点价格"
    @cancel="() => variationItemPriceModal = false" width="400px">
    <template #footer>
      <div class="d-flex justify-space-between align-center">
        <a-space>
          <a-button>
            汇率换算
          </a-button>
          <a-tooltip color="#fff" :overlayStyle="{ 'width': '750px', 'max-width': '750px' }">
            <template #title>
              <div class="text-black pa-1">
                <div class="text-h6">汇率换算</div>
                <p>将第一个站点的金额，换算成其他站点对应币种的金额</p>
                <p>汇率设置请至--系统管理>汇率管理去设置，如果没有设置有效汇率，点击汇率换算，将不会执行换算，价格栏为空值</p>
              </div>
            </template>
            <QuestionCircleOutlined class="text-primary" />
          </a-tooltip>
        </a-space>
        <a-space>
          <a-button @click="() => variationItemPriceModal = false">取消</a-button>
          <a-button class="btn-primary" @click="confirmVariationItemPrice">确定</a-button>
        </a-space>
      </div>
    </template>
    <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" autocomplete="off">
      <a-form-item v-for="(region, index) in listingForm.regions" :key="index" :label="listingForm.region_names[index]">
        <a-input v-model:value="variationItemPrice[region].standard_price" type="number" />
      </a-form-item>
    </a-form>
  </a-modal>
  <!-- #endregion -->
  <!-- #region 单品修改站点价格 -->
  <a-modal v-model:visible="listingPriceModal" centered title="修改站点价格" @cancel="() => listingPriceModal = false"
    width="400px">
    <template #footer>
      <div class="d-flex justify-space-between align-center">
        <a-space>
          <a-button>
            汇率换算
          </a-button>
          <a-tooltip placement="bottom" color="#fff" :overlayStyle="{ 'width': '750px', 'max-width': '750px' }">
            <template #title>
              <div class="text-black pa-1">
                <div class="text-h6">汇率换算</div>
                <p>将第一个站点的金额，换算成其他站点对应币种的金额</p>
                <p>汇率设置请至--系统管理>汇率管理去设置，如果没有设置有效汇率，点击汇率换算，将不会执行换算，价格栏为空值</p>
              </div>
            </template>
            <QuestionCircleOutlined class="text-primary" />
          </a-tooltip>
        </a-space>
        <a-space>
          <a-button @click="() => listingPriceModal = false">取消</a-button>
          <a-button class="btn-primary" @click="confirmListingPrice">确定</a-button>
        </a-space>
      </div>
    </template>
    <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" autocomplete="off">
      <a-form-item v-for="(region, index) in listingForm.regions" :key="index" :label="listingForm.region_names[index]">
        <a-input v-model:value="listingPrice[region].standard_price" type="number" />
      </a-form-item>
    </a-form>
  </a-modal>
  <!-- #endregion -->
  <!-- #region 变体修改标题 -->
  <a-modal v-model:visible="variationItemNameModal" centered title="修改标题" @cancel="() => variationItemNameModal = false"
    width="750px">
    <template #footer>
      <div class="d-flex justify-end align-center">
        <a-space>
          <a-button @click="() => variationItemNameModal = false">取消</a-button>
          <a-button class="btn-primary" @click="confirmVariationItemName">确定</a-button>
        </a-space>
      </div>
    </template>
    <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }" autocomplete="off">
      <a-form-item v-for="(region, index) in listingForm.regions" :key="index" :label="listingForm.region_names[index]">
        <div class="d-flex align-center">
          <a-input v-model:value="variationItemName[region].item_name" :maxlength="200" />
          <p class="text-gray text-right ml-1" style="min-width:50px;">
            {{ variationItemName[region]?.item_name?.length || 0 }}/200
          </p>
        </div>
      </a-form-item>
    </a-form>
  </a-modal>
  <!-- #endregion -->
  <!-- #region 变体引用橱窗图 -->
  <a-modal v-model:visible="chooseImagesModal" centered title="引用橱窗图" width="750px"
    @cancel="() => chooseImagesModal = false">
    <template #footer>
      <div
        :class="{ 'd-flex': true, 'justify-space-between': chooseImagesType === 'other_images', 'justify-end': chooseImagesType !== 'other_images', 'align-center': true }">
        <div v-if="chooseImagesType === 'other_images'">
          已选中 <span class="text-primary"> {{ chooseImagesList.length }} </span>
          张图片， 最多 <span class="text-error"> {{ chooseImagesMaxCount }} </span> 张
        </div>
        <a-space>
          <a-button @click="() => chooseImagesModal = false">关闭</a-button>
          <a-button v-if="chooseImagesType === 'other_images'" type="primary" @click="confirmChooseImages">确定</a-button>
        </a-space>
      </div>
    </template>
    <a-checkbox-group v-if="chooseImagesType === 'other_images'" v-model:value="chooseImagesList">
      <a-row :gutter="8">
        <a-col :span="4" v-for="(image, index) in listingForm.images" :key="index">
          <div class="d-flex flex-column align-center mb-2"
            style="border:1px solid #ddd;box-shadow:0 2px 12px 0 rgba(0,0,0,.1);">
            <a-image width="105px" height="105px" :src="image" style="object-fit: contain;height:100%;">
              <template #placeholder>
                <a-image :src="image_placeholder" width="105px" height="105px" :preview="false" />
              </template>
            </a-image>
            <a-checkbox class="mt-2 mb-2" :value="image">选择</a-checkbox>
          </div>
        </a-col>
      </a-row>
    </a-checkbox-group>
    <a-row :gutter="8" p v-else>
      <a-col :span="4" v-for="(image, index) in listingForm.images" :key="index">
        <div class="d-flex flex-column align-center mb-2"
          style="border:1px solid #ddd;box-shadow:0 2px 12px 0 rgba(0,0,0,.1);">
          <a-image width="105px" height="105px" :src="image" style="object-fit: contain;height:100%;">
            <template #placeholder>
              <a-image :src="image_placeholder" width="105px" height="105px" :preview="false" />
            </template>
          </a-image>
          <a-button class="btn-primary mt-2 mb-2" @click="confirmChooseImage(image)">选择</a-button>
        </div>
      </a-col>
    </a-row>
  </a-modal>
  <!-- #endregion -->
  <!-- #region 侵权词检测结果 -->
  <a-modal v-model:visible="tortwordsResultModal" centered :title="`检测到侵权词(共${tortwordsResult.length}条)`" width="750px"
    @cancel="() => tortwordsResultModal = false">
    <template #footer>
      <div class="d-flex justify-end">
        <a-button @click="() => tortwordsResultModal = false">关闭</a-button>
      </div>
    </template>
    <a-table :columns="tortwordsHeader" :data-source="tortwordsResult" :pagination="false" :scroll="{ y: 600 }">
      <template #bodyCell="{ record, index, column }">
        <template v-if="column.dataIndex === 'index'">
          {{ index + 1 }}
        </template>
        <template v-if="column.dataIndex === 'message'">
          <div class="text-danger" v-html="record.message"></div>
        </template>
      </template>
    </a-table>
  </a-modal>
  <!-- #endregion -->
  <!-- #region 产品类目 -->
  <a-modal v-model:visible="uistate.visible" width="1200px" centered @cancel="closeBrowseTree">
    <template #title>
      <a-space>
        <span class="text-h6">选择产品分类</span>
        <a-input v-model:value="browsetree_searchtext" addon-before="查找产品分类" placeholder="请输入内容" style="width:600px;"
          @keyup.enter="doSearchBrowseTree">
          <template #addonAfter>
            <SearchOutlined @click="doSearchBrowseTree" />
          </template>
        </a-input>
      </a-space>
    </template>
    <template #footer>
      <div class="d-flex justify-space-between">
        <div class="d-flex flex-wrap text-left">
          已选择：
          <span v-if="browsetree_searchresultitem" class="text-primary">
            {{ browsetree_searchresultitem.label }}
          </span>
          <template v-else>
            <span :class="{ 'text-orange': browsetreeID === browsetreeID1 }">
              {{ browsetreeNAME1 }}
            </span>
            <span v-if="browsetreeNAME2" :class="{ 'text-orange': browsetreeID === browsetreeID2 }">
              <span class="text-primary"> 》 </span>{{ browsetreeNAME2 }}
            </span>
            <span v-if="browsetreeNAME3" :class="{ 'text-orange': browsetreeID === browsetreeID3 }">
              <span class="text-primary"> 》 </span>{{ browsetreeNAME3 }}
            </span>
            <span v-if="browsetreeNAME4" :class="{ 'text-orange': browsetreeID === browsetreeID4 }">
              <span class="text-primary"> 》 </span>{{ browsetreeNAME4 }}
            </span>
            <span v-if="browsetreeNAME5" :class="{ 'text-orange': browsetreeID === browsetreeID5 }">
              <span class="text-primary"> 》 </span>{{ browsetreeNAME5 }}
            </span>
            <span v-if="browsetreeNAME6" :class="{ 'text-orange': browsetreeID === browsetreeID6 }">
              <span class="text-primary"> 》 </span>{{ browsetreeNAME6 }}
            </span>
          </template>
        </div>
        <a-space>
          <a-button @click="closeBrowseTree">取消</a-button>
          <a-button :class="{ 'btn-warning': browsetreeConfirmable, 'btn-warning-disabled': !browsetreeConfirmable }"
            :disabled="!browsetreeConfirmable" :loading="uistate.loading" @click="confirmBrowseTree">确定</a-button>
        </a-space>
      </div>
    </template>
    <a-spin :spinning="uistate.loading">
      <div v-if="browsetree_showsearchresults">
        <a-divider>
          <a-space>
            <div>查询到<span> {{ browsetree_searchresults.length }} </span>个产品分类</div>
            <a-button @click="backToSelect">
              返回产品分类选择
            </a-button>
          </a-space>
        </a-divider>
        <a-alert v-if="browsetree_searchresults.length === 0" message="暂无符合条件的分类" description="请尝试其他搜索关键字，或返回产品分类选择"
          type="warning" show-icon />
        <div v-else class="search-results-group">
          <a-list size="small" bordered :data-source="browsetree_searchresults" :pagination="false">
            <template #renderItem="{ item, index }">
              <a-list-item>
                <a href="javascript:;" :class="{ 'search-results-item': true, 'active': item.selected }"
                  @click="selectSearchItem(index)">
                  {{ item.label }}
                </a>
              </a-list-item>
            </template>
          </a-list>
        </div>
      </div>
      <div v-else class="tree-group">
        <a-card v-if="browsetreeCount >= 1 && browsetreeList1.length > 0" title="一级类目" size="small"
          class="tree-item-card">
          <div class="tree-item">
            <a-menu :selectedKeys="[browsetreeID1]">
              <a-menu-item v-for="item in browsetreeList1" :key="item._id" @click="selectTree1(item)"
                style="border-bottom: 1px solid #ddd;">
                <div v-if="item.hasChildren" class="d-flex justify-space-between align-center"
                  :title="`${item.browseNodeId}:${item.title}`">
                  <p class="in1line"> {{ item.title }} </p>
                  <RightOutlined />
                </div>
                <a-tooltip v-else :mouseEnterDelay="0.5">
                  <template #title>该分类产品类型为：{{ item.productTypeDefinitions }}</template>
                  <div class="d-flex justify-space-between align-center" :title="`${item.browseNodeId}:${item.title}`">
                    <p class="in1line"> {{ item.title }} </p>
                  </div>
                </a-tooltip>
              </a-menu-item>
            </a-menu>
          </div>
        </a-card>
        <a-card v-if="browsetreeCount >= 2 && browsetreeList2.length > 0" title="二级类目" size="small"
          class="tree-item-card">
          <div class="tree-item">
            <a-menu :selectedKeys="[browsetreeID2]">
              <a-menu-item v-for="item in browsetreeList2" :key="item._id" @click="selectTree2(item)"
                style="border-bottom: 1px solid #ddd;">
                <div v-if="item.hasChildren" class="d-flex justify-space-between align-center"
                  :title="`${item.browseNodeId}:${item.title}`">
                  <p class="in1line"> {{ item.title }} </p>
                  <RightOutlined />
                </div>
                <a-tooltip v-else :mouseEnterDelay="0.5">
                  <template #title>该分类产品类型为：{{ item.productTypeDefinitions }}</template>
                  <div class="d-flex justify-space-between align-center" :title="`${item.browseNodeId}:${item.title}`">
                    <p class="in1line"> {{ item.title }} </p>
                  </div>
                </a-tooltip>
              </a-menu-item>
            </a-menu>
          </div>
        </a-card>
        <a-card v-if="browsetreeCount >= 3 && browsetreeList3.length > 0" title="三级类目" size="small"
          class="tree-item-card">
          <div class="tree-item">
            <a-menu :selectedKeys="[browsetreeID3]">
              <a-menu-item v-for="item in browsetreeList3" :key="item._id" @click="selectTree3(item)"
                style="border-bottom: 1px solid #ddd;">
                <div v-if="item.hasChildren" class="d-flex justify-space-between align-center"
                  :title="`${item.browseNodeId}:${item.title}`">
                  <p class="in1line"> {{ item.title }} </p>
                  <RightOutlined />
                </div>
                <a-tooltip v-else :mouseEnterDelay="0.5">
                  <template #title>该分类产品类型为：{{ item.productTypeDefinitions }}</template>
                  <div class="d-flex justify-space-between align-center" :title="`${item.browseNodeId}:${item.title}`">
                    <p class="in1line"> {{ item.title }} </p>
                  </div>
                </a-tooltip>
              </a-menu-item>
            </a-menu>
          </div>
        </a-card>
        <a-card v-if="browsetreeCount >= 4 && browsetreeList4.length > 0" title="四级类目" size="small"
          class="tree-item-card">
          <div class="tree-item">
            <a-menu :selectedKeys="[browsetreeID4]">
              <a-menu-item v-for="item in browsetreeList4" :key="item._id" @click="selectTree4(item)"
                style="border-bottom: 1px solid #ddd;">
                <div v-if="item.hasChildren" class="d-flex justify-space-between align-center"
                  :title="`${item.browseNodeId}:${item.title}`">
                  <p class="in1line"> {{ item.title }} </p>
                  <RightOutlined />
                </div>
                <a-tooltip v-else :mouseEnterDelay="0.5">
                  <template #title>该分类产品类型为：{{ item.productTypeDefinitions }}</template>
                  <div class="d-flex justify-space-between align-center" :title="`${item.browseNodeId}:${item.title}`">
                    <p class="in1line"> {{ item.title }} </p>
                  </div>
                </a-tooltip>
              </a-menu-item>
            </a-menu>
          </div>
        </a-card>
        <a-card v-if="browsetreeCount >= 5 && browsetreeList5.length > 0" title="五级类目" size="small"
          class="tree-item-card">
          <div class="tree-item">
            <a-menu :selectedKeys="[browsetreeID5]">
              <a-menu-item v-for="item in browsetreeList5" :key="item._id" @click="selectTree5(item)"
                style="border-bottom: 1px solid #ddd;">
                <div v-if="item.hasChildren" class="d-flex justify-space-between align-center"
                  :title="`${item.browseNodeId}:${item.title}`">
                  <p class="in1line"> {{ item.title }} </p>
                  <RightOutlined />
                </div>
                <a-tooltip v-else :mouseEnterDelay="0.5">
                  <template #title>该分类产品类型为：{{ item.productTypeDefinitions }}</template>
                  <div class="d-flex justify-space-between align-center" :title="`${item.browseNodeId}:${item.title}`">
                    <p class="in1line"> {{ item.title }} </p>
                  </div>
                </a-tooltip>
              </a-menu-item>
            </a-menu>
          </div>
        </a-card>
        <a-card v-if="browsetreeCount >= 6 && browsetreeList6.length > 0" title="六级类目" size="small"
          class="tree-item-card">
          <div class="tree-item">
            <a-menu :selectedKeys="[browsetreeID6]">
              <a-menu-item v-for="item in browsetreeList6" :key="item._id" @click="selectTree6(item)"
                style="border-bottom: 1px solid #ddd;">
                <div v-if="item.hasChildren" class="d-flex justify-space-between align-center"
                  :title="`${item.browseNodeId}:${item.title}`">
                  <p class="in1line"> {{ item.title }} </p>
                  <RightOutlined />
                </div>
                <a-tooltip v-else :mouseEnterDelay="0.5">
                  <template #title>该分类产品类型为：{{ item.productTypeDefinitions }}</template>
                  <div class="d-flex justify-space-between align-center" :title="`${item.browseNodeId}:${item.title}`">
                    <p class="in1line"> {{ item.title }} </p>
                  </div>
                </a-tooltip>
              </a-menu-item>
            </a-menu>
          </div>
        </a-card>
      </div>
    </a-spin>
  </a-modal>
  <!-- #endregion -->
  <!-- #region external_product_id -->
  <a-modal v-model:visible="externalProductModal" centered :title="`获取${externalProductForm.type}`" width="600px"
    @cancel="() => externalProductModal = false" :maskClosable="false" :closable="!externalProductLoading">
    <template #footer>
      <div class="d-flex justify-space-between">
        <div>
          需要<span class="text-warning"> {{ externalProductForm.count }} </span>个{{ externalProductForm.type }}号码
        </div>
        <a-space>
          <a-button @click="() => externalProductModal = false">取消</a-button>
          <a-button class="btn-warning" @click="confirmExternalProduct">确定</a-button>
        </a-space>
      </div>
    </template>
    <a-spin :spinning="externalProductLoading">
      <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }" autocomplete="off">
        <a-form-item label="获取类型">
          <a-select v-model:value="externalProductForm.type" @change="changeExternalProductType" show-search>
            <a-select-option v-for="item in externalProductTypes" :key="item" :value="item">
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="获取方式">
          <a-radio-group v-model:value="externalProductForm.method" name="method">
            <a-radio value="auto">自动获取</a-radio>
            <a-radio value="manual">手动填写</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item v-if="externalProductForm.method === 'auto'" label="选择号码池">
          <template #extra>
            <div>剩余可用数量<span class="text-danger"> {{ externalProductForm.unused_count }} </span>个</div>
          </template>
          <div class="d-flex">
            <a-select v-model:value="externalProductForm.groupid" @change="changeExternalProductGroup" placeholder="请选择"
              show-search>
              <a-select-option v-for="item in externalProductGroups" :key="item._id" :value="item._id">
                {{ item.name }}
              </a-select-option>
            </a-select>
            <a-button class="ml-2" @click="gotoUPC">管理号码池</a-button>
          </div>
        </a-form-item>
        <template v-if="externalProductForm.method === 'manual'">
          <a-form-item label="输入号码">
            <a-input v-if="externalProductForm.count === 1" v-model:value="externalProductForm.codes" placeholder="请输入" />
            <a-textarea v-if="externalProductForm.count > 1" v-model:value="externalProductForm.codes"
              placeholder="多个号码用回车换行" :auto-size="{ minRows: 3, maxRows: 3 }" />
          </a-form-item>
        </template>
      </a-form>
    </a-spin>
  </a-modal>
  <!-- #endregion -->
</template>
<script>
import { InfoCircleFilled, QuestionCircleOutlined, UpOutlined, DownOutlined, HomeOutlined, DeleteOutlined, EditOutlined, FormOutlined, SaveOutlined, RollbackOutlined, PlusOutlined, LeftOutlined, RightOutlined, CopyOutlined, SearchOutlined, } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, shallowRef, reactive, toRefs, watch, computed, onMounted, onBeforeUnmount, onActivated, onDeactivated, getCurrentInstance, toRaw, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import _ from "underscore";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import '@wangeditor/editor/dist/css/style.css'
import { DomEditor } from '@wangeditor/editor'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
const MAX_IMAGE_COUNT = 9;
import ImagePicker0 from '@/components/ImagePicker.vue';
import ImagePicker1 from '@/components/ImagePicker.vue';
import ImagePicker2 from '@/components/ImagePicker.vue';
import ImagePicker3 from '@/components/ImagePicker.vue';
import ImagePicker4 from '@/components/ImagePicker.vue';
import ImagePicker5 from '@/components/ImagePicker.vue';
import draggable from 'vuedraggable';
let timer = 0;

export default defineComponent({
  name: 'AmazonListingFormRegion',
  components: {
    InfoCircleFilled, QuestionCircleOutlined, UpOutlined, DownOutlined, HomeOutlined, DeleteOutlined, EditOutlined, FormOutlined,
    SaveOutlined, RollbackOutlined, PlusOutlined, LeftOutlined, RightOutlined, CopyOutlined, SearchOutlined,
    Editor, Toolbar,
    ImagePicker0, ImagePicker1, ImagePicker2, ImagePicker3, ImagePicker4, ImagePicker5, draggable,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const shopaccountformRef = ref();
    const listingformRef = ref();
    const variationItemFormRef = ref();
    const EditorRef = shallowRef();
    const toolbarConfig = {
      toolbarKeys: ["bold", "italic", "bulletedList", "numberedList", "undo", "redo"]
    };
    const editorConfig = {

    };
    const state = reactive({
      activeMenuKeys: [],
      offsetTopArr: [],
      stopListingScroll: false,
      uistate: {
        loading: false,
        visible: false,
      },
      //#region 类目菜单选择
      browsetree_searchtext: "",
      browsetree_searchresults: [],
      browsetree_showsearchresults: false,
      browsetree_searchresultitem: null,
      browsetree: {
        US: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], confirm_data: null, product_types: [], attributes: [], },
        CA: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], confirm_data: null, product_types: [], attributes: [], },
        MX: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], confirm_data: null, product_types: [], attributes: [], },
        BR: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], confirm_data: null, product_types: [], attributes: [], },
        ES: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], confirm_data: null, product_types: [], attributes: [], },
        UK: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], confirm_data: null, product_types: [], attributes: [], },
        FR: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], confirm_data: null, product_types: [], attributes: [], },
        BE: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], confirm_data: null, product_types: [], attributes: [], },
        NL: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], confirm_data: null, product_types: [], attributes: [], },
        DE: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], confirm_data: null, product_types: [], attributes: [], },
        IT: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], confirm_data: null, product_types: [], attributes: [], },
        SE: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], confirm_data: null, product_types: [], attributes: [], },
        PL: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], confirm_data: null, product_types: [], attributes: [], },
        AU: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], confirm_data: null, product_types: [], attributes: [], },
        JP: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], confirm_data: null, product_types: [], attributes: [], },
      },
      //#endregion
      shopaccounts: [],
      brands: [],
      manufacturers: [],
      showAllAttributes: {},
      shopaccountModal: false,
      shopaccountForm: {},
      listingForm: {},
      attribute_search_key: {},
      attribute_search_enabled: {},
      curr_region: "",
      product_description: "",
      product_description2: "",
      product_description_edit_type: "editor",
      condition_types: ['New'],
      external_product_id_types: ["UPC", "EAN", "ISBN", "GTIN", "GCID", "PZN"],
      externalProductForm: {},
      externalProductGroups: [],
      externalProductModal: false,
      externalProductLoading: false,
      externalProductTypes: ["UPC", "EAN", "ISBN", "GTIN"],
      variation_themes: [
        { label: "Color", value: "color_name" },
        { label: "Size", value: "size_name" },
        { label: "Material", value: "material_type" },
        { label: "Color-Size", value: "color_name,size_name" },
        { label: "Color-Material", value: "color_name,material_type" },
        { label: "Size-Material", value: "size_name,material_type" },
        { label: "Color-Size-Material", value: "color_name,size_name,material_type" },
      ],
      variation_color: false,
      variation_size: false,
      variation_material: false,
      variation_colors: [],
      variation_sizes: [],
      variation_materials: [],
      variation_input_color: "",
      variation_input_size: "",
      variation_input_material: "",
      variation_ids: [],
      variation_list: [],
      variation_headers2: [
        { title: '序号', dataIndex: 'index', width: 50, },
        { title: '多属性', dataIndex: 'attribute', width: 150, },
        { title: '主图', dataIndex: 'main_image', width: 120, },
        { title: '样品图', dataIndex: 'swatch_image', width: 120, },
        { title: '附图(最多8张，可左右拖动排序)', dataIndex: 'other_images', },
        { title: '操作', dataIndex: 'operation', width: 100, fixed: "right", },
      ],
      variationThemeModal: false,
      variationThemeForm: {},
      variationItemModal: false,
      variationItemForm: {},
      chooseImagesType: null,
      chooseImagesModal: false,
      chooseImagesList: [],
      chooseImagesMaxCount: 0,
      chooseImagesIndex: -1,
      chooseImagesIndex2: -1,
      variationListQuantity: "",
      variationListQuantityModal: false,
      variationListPrice: {},
      variationListPriceModal: false,
      variationItemIndex: -1,
      variationItemPrice: {},
      variationItemPriceModal: false,
      listingPrice: {},
      listingPriceModal: false,
      variationItemName: {},
      variationItemNameModal: false,
      tortwordsResultModal: false,
      tortwordsResult: [],
      tortwordsHeader: [
        { title: '序号', dataIndex: 'index', width: 50, },
        { title: '站点', dataIndex: 'region', width: 150, },
        { title: '细节', dataIndex: 'message', width: 300, },
      ],
      saving: false,
      gening: false,
    });
    const shop_regions = computed(() => {
      return _.findWhere(state.shopaccounts, { value: state.shopaccountForm.account_username })?.regions || [];
    });
    const region_sites = computed(() => {
      let shopaccount = _.findWhere(state.shopaccounts, { value: state.listingForm.account_username });
      if (shopaccount) {
        let select_regions = _.filter(shopaccount.regions, n => state.listingForm.regions?.indexOf(n.value) > -1);
        return _.pluck(select_regions, "label").join(',');
      }
      return "";
    });
    const fail_region_sites = computed(() => {
      let { regions, region_names, region_state } = state.listingForm;
      let sites = [];
      for (let i = 0; i < regions.length; i++) {
        let region = regions[i];
        if (region_state[region] === 'fail') {
          sites.push(region_names[i]);
        }
      }
      return sites.join(',');
    });
    const variation_headers = computed(() => {
      let headers = [{ title: "序号", dataIndex: "index", width: 50 }];
      if (state.variation_color) headers.push({ title: "color_name", dataIndex: "color_name", width: 120 });
      if (state.variation_size) headers.push({ title: "size_name", dataIndex: "size_name", width: 120 });
      if (state.variation_material) headers.push({ title: "material_type", dataIndex: "material_type", width: 120 });
      headers.push({ title: "SKU编号", dataIndex: "item_sku", width: 200 });
      headers.push({ title: "", dataIndex: "external_product_id", width: 180 });
      headers.push({ title: "数量", dataIndex: "quantity", width: 120 });
      headers.push({ title: "价格", dataIndex: "standard_price", width: 180 });
      headers.push({ title: "标题", dataIndex: "item_name", width: 400 });
      headers.push({ title: "操作", dataIndex: "operation", width: 150 });
      return headers;
    });
    const browsetreeCount = computed(() => state.browsetree[state.curr_region]?.count);
    const browsetreeID1 = computed(() => state.browsetree[state.curr_region]?.id1);
    const browsetreeID2 = computed(() => state.browsetree[state.curr_region]?.id2);
    const browsetreeID3 = computed(() => state.browsetree[state.curr_region]?.id3);
    const browsetreeID4 = computed(() => state.browsetree[state.curr_region]?.id4);
    const browsetreeID5 = computed(() => state.browsetree[state.curr_region]?.id5);
    const browsetreeID6 = computed(() => state.browsetree[state.curr_region]?.id6);
    const browsetreeList1 = computed(() => state.browsetree[state.curr_region]?.list1 || []);
    const browsetreeList2 = computed(() => state.browsetree[state.curr_region]?.list2 || []);
    const browsetreeList3 = computed(() => state.browsetree[state.curr_region]?.list3 || []);
    const browsetreeList4 = computed(() => state.browsetree[state.curr_region]?.list4 || []);
    const browsetreeList5 = computed(() => state.browsetree[state.curr_region]?.list5 || []);
    const browsetreeList6 = computed(() => state.browsetree[state.curr_region]?.list6 || []);
    const browsetreeID = computed(() => {
      if (state.browsetree[state.curr_region]?.list6?.length > 0) return state.browsetree[state.curr_region]?.id6 || '';
      if (state.browsetree[state.curr_region]?.list5?.length > 0) return state.browsetree[state.curr_region]?.id5 || '';
      if (state.browsetree[state.curr_region]?.list4?.length > 0) return state.browsetree[state.curr_region]?.id4 || '';
      if (state.browsetree[state.curr_region]?.list3?.length > 0) return state.browsetree[state.curr_region]?.id3 || '';
      if (state.browsetree[state.curr_region]?.list2?.length > 0) return state.browsetree[state.curr_region]?.id2 || '';
      if (state.browsetree[state.curr_region]?.list1?.length > 0) return state.browsetree[state.curr_region]?.id1 || '';
      return '';
    });
    const browsetreeNAME1 = computed(() => _.findWhere(state.browsetree[state.curr_region]?.list1 || [], { _id: state.browsetree[state.curr_region]?.id1 })?.title || '')
    const browsetreeNAME2 = computed(() => _.findWhere(state.browsetree[state.curr_region]?.list2 || [], { _id: state.browsetree[state.curr_region]?.id2 })?.title || '')
    const browsetreeNAME3 = computed(() => _.findWhere(state.browsetree[state.curr_region]?.list3 || [], { _id: state.browsetree[state.curr_region]?.id3 })?.title || '')
    const browsetreeNAME4 = computed(() => _.findWhere(state.browsetree[state.curr_region]?.list4 || [], { _id: state.browsetree[state.curr_region]?.id4 })?.title || '')
    const browsetreeNAME5 = computed(() => _.findWhere(state.browsetree[state.curr_region]?.list5 || [], { _id: state.browsetree[state.curr_region]?.id5 })?.title || '')
    const browsetreeNAME6 = computed(() => _.findWhere(state.browsetree[state.curr_region]?.list6 || [], { _id: state.browsetree[state.curr_region]?.id6 })?.title || '')
    const browsetreeConfirmable = computed(() => {
      if (state.browsetree[state.curr_region]?.list6?.length > 0) return !!state.browsetree[state.curr_region]?.id6 || !!state.browsetree_searchresultitem;
      if (state.browsetree[state.curr_region]?.list5?.length > 0) return !!state.browsetree[state.curr_region]?.id5 || !!state.browsetree_searchresultitem;
      if (state.browsetree[state.curr_region]?.list4?.length > 0) return !!state.browsetree[state.curr_region]?.id4 || !!state.browsetree_searchresultitem;
      if (state.browsetree[state.curr_region]?.list3?.length > 0) return !!state.browsetree[state.curr_region]?.id3 || !!state.browsetree_searchresultitem;
      if (state.browsetree[state.curr_region]?.list2?.length > 0) return !!state.browsetree[state.curr_region]?.id2 || !!state.browsetree_searchresultitem;
      if (state.browsetree[state.curr_region]?.list1?.length > 0) return !!state.browsetree[state.curr_region]?.id1 || !!state.browsetree_searchresultitem;
      return !!state.browsetree_searchresultitem;
    })
    const browsetreeProductTypes = computed(() => state.browsetree[state.curr_region]?.product_types || []);
    const browsetreeFilterAttributes = computed(() => {
      let region = state.curr_region;
      if (state.attribute_search_enabled[region] && state.attribute_search_key[region]) {
        return _.filter(state.browsetree[region].attributes, n => {
          let matched = n.field_key.toLowerCase().indexOf(state.attribute_search_key[region].toLowerCase()) > -1
            || n.label.toLowerCase().indexOf(state.attribute_search_key[region].toLowerCase()) > -1
            || n.label_cn?.toLowerCase().indexOf(state.attribute_search_key[region].toLowerCase()) > -1;;
          if (n.field_key === 'max_order_quantity') {
            console.log(matched, [state.attribute_search_key[region]]);
          }
          return matched;
        });
      }
      return state.browsetree[region]?.attributes || [];
    });
    watch(() => state.curr_region, (region) => {
      if (state.product_description_edit_type === 'editor') {
        state.product_description = state.listingForm[region]?.product_description || '';
      }
      if (state.product_description_edit_type === 'textarea') {
        state.product_description2 = state.listingForm[region]?.product_description || '';
      }
    });
    watch(() => state.product_description, (product_description) => {
      if (state.listingForm[state.curr_region]) {
        state.listingForm[state.curr_region].product_description = product_description;
      }
    });
    watch(() => state.product_description2, (product_description) => {
      if (state.listingForm[state.curr_region]) {
        state.listingForm[state.curr_region].product_description = product_description;
      }
    });
    watch(() => state.browsetree.US, () => { state.listingForm.US.browsenodeid = browsetree_id('US'); state.listingForm.US.browsenodename = browsetree_name('US'); }, { deep: true });
    watch(() => state.browsetree.CA, () => { state.listingForm.CA.browsenodeid = browsetree_id('CA'); state.listingForm.CA.browsenodename = browsetree_name('CA'); }, { deep: true });
    watch(() => state.browsetree.MX, () => { state.listingForm.MX.browsenodeid = browsetree_id('MX'); state.listingForm.MX.browsenodename = browsetree_name('MX'); }, { deep: true });
    watch(() => state.browsetree.BR, () => { state.listingForm.BR.browsenodeid = browsetree_id('BR'); state.listingForm.BR.browsenodename = browsetree_name('BR'); }, { deep: true });
    watch(() => state.browsetree.ES, () => { state.listingForm.ES.browsenodeid = browsetree_id('ES'); state.listingForm.ES.browsenodename = browsetree_name('ES'); }, { deep: true });
    watch(() => state.browsetree.UK, () => { state.listingForm.UK.browsenodeid = browsetree_id('UK'); state.listingForm.UK.browsenodename = browsetree_name('UK'); }, { deep: true });
    watch(() => state.browsetree.FR, () => { state.listingForm.FR.browsenodeid = browsetree_id('FR'); state.listingForm.FR.browsenodename = browsetree_name('FR'); }, { deep: true });
    watch(() => state.browsetree.BE, () => { state.listingForm.BE.browsenodeid = browsetree_id('BE'); state.listingForm.BE.browsenodename = browsetree_name('BE'); }, { deep: true });
    watch(() => state.browsetree.NL, () => { state.listingForm.NL.browsenodeid = browsetree_id('NL'); state.listingForm.NL.browsenodename = browsetree_name('NL'); }, { deep: true });
    watch(() => state.browsetree.DE, () => { state.listingForm.DE.browsenodeid = browsetree_id('DE'); state.listingForm.DE.browsenodename = browsetree_name('DE'); }, { deep: true });
    watch(() => state.browsetree.IT, () => { state.listingForm.IT.browsenodeid = browsetree_id('IT'); state.listingForm.IT.browsenodename = browsetree_name('IT'); }, { deep: true });
    watch(() => state.browsetree.SE, () => { state.listingForm.SE.browsenodeid = browsetree_id('SE'); state.listingForm.SE.browsenodename = browsetree_name('SE'); }, { deep: true });
    watch(() => state.browsetree.PL, () => { state.listingForm.PL.browsenodeid = browsetree_id('PL'); state.listingForm.PL.browsenodename = browsetree_name('PL'); }, { deep: true });
    watch(() => state.browsetree.AU, () => { state.listingForm.AU.browsenodeid = browsetree_id('AU'); state.listingForm.AU.browsenodename = browsetree_name('AU'); }, { deep: true });
    watch(() => state.browsetree.JP, () => { state.listingForm.JP.browsenodeid = browsetree_id('JP'); state.listingForm.JP.browsenodename = browsetree_name('JP'); }, { deep: true });

    watch(() => state.browsetree.US.id1, (node_id) => state.listingForm.US.node_id1 = node_id);
    watch(() => state.browsetree.CA.id1, (node_id) => state.listingForm.CA.node_id1 = node_id);
    watch(() => state.browsetree.MX.id1, (node_id) => state.listingForm.MX.node_id1 = node_id);
    watch(() => state.browsetree.BR.id1, (node_id) => state.listingForm.BR.node_id1 = node_id);
    watch(() => state.browsetree.ES.id1, (node_id) => state.listingForm.ES.node_id1 = node_id);
    watch(() => state.browsetree.UK.id1, (node_id) => state.listingForm.UK.node_id1 = node_id);
    watch(() => state.browsetree.FR.id1, (node_id) => state.listingForm.FR.node_id1 = node_id);
    watch(() => state.browsetree.BE.id1, (node_id) => state.listingForm.BE.node_id1 = node_id);
    watch(() => state.browsetree.NL.id1, (node_id) => state.listingForm.NL.node_id1 = node_id);
    watch(() => state.browsetree.DE.id1, (node_id) => state.listingForm.DE.node_id1 = node_id);
    watch(() => state.browsetree.IT.id1, (node_id) => state.listingForm.IT.node_id1 = node_id);
    watch(() => state.browsetree.SE.id1, (node_id) => state.listingForm.SE.node_id1 = node_id);
    watch(() => state.browsetree.PL.id1, (node_id) => state.listingForm.PL.node_id1 = node_id);
    watch(() => state.browsetree.AU.id1, (node_id) => state.listingForm.AU.node_id1 = node_id);
    watch(() => state.browsetree.JP.id1, (node_id) => state.listingForm.JP.node_id1 = node_id);

    watch(() => state.browsetree.US.id2, (node_id) => state.listingForm.US.node_id2 = node_id);
    watch(() => state.browsetree.CA.id2, (node_id) => state.listingForm.CA.node_id2 = node_id);
    watch(() => state.browsetree.MX.id2, (node_id) => state.listingForm.MX.node_id2 = node_id);
    watch(() => state.browsetree.BR.id2, (node_id) => state.listingForm.BR.node_id2 = node_id);
    watch(() => state.browsetree.ES.id2, (node_id) => state.listingForm.ES.node_id2 = node_id);
    watch(() => state.browsetree.UK.id2, (node_id) => state.listingForm.UK.node_id2 = node_id);
    watch(() => state.browsetree.FR.id2, (node_id) => state.listingForm.FR.node_id2 = node_id);
    watch(() => state.browsetree.BE.id2, (node_id) => state.listingForm.BE.node_id2 = node_id);
    watch(() => state.browsetree.NL.id2, (node_id) => state.listingForm.NL.node_id2 = node_id);
    watch(() => state.browsetree.DE.id2, (node_id) => state.listingForm.DE.node_id2 = node_id);
    watch(() => state.browsetree.IT.id2, (node_id) => state.listingForm.IT.node_id2 = node_id);
    watch(() => state.browsetree.SE.id2, (node_id) => state.listingForm.SE.node_id2 = node_id);
    watch(() => state.browsetree.PL.id2, (node_id) => state.listingForm.PL.node_id2 = node_id);
    watch(() => state.browsetree.AU.id2, (node_id) => state.listingForm.AU.node_id2 = node_id);
    watch(() => state.browsetree.JP.id2, (node_id) => state.listingForm.JP.node_id2 = node_id);

    watch(() => state.browsetree.US.id3, (node_id) => state.listingForm.US.node_id3 = node_id);
    watch(() => state.browsetree.CA.id3, (node_id) => state.listingForm.CA.node_id3 = node_id);
    watch(() => state.browsetree.MX.id3, (node_id) => state.listingForm.MX.node_id3 = node_id);
    watch(() => state.browsetree.BR.id3, (node_id) => state.listingForm.BR.node_id3 = node_id);
    watch(() => state.browsetree.ES.id3, (node_id) => state.listingForm.ES.node_id3 = node_id);
    watch(() => state.browsetree.UK.id3, (node_id) => state.listingForm.UK.node_id3 = node_id);
    watch(() => state.browsetree.FR.id3, (node_id) => state.listingForm.FR.node_id3 = node_id);
    watch(() => state.browsetree.BE.id3, (node_id) => state.listingForm.BE.node_id3 = node_id);
    watch(() => state.browsetree.NL.id3, (node_id) => state.listingForm.NL.node_id3 = node_id);
    watch(() => state.browsetree.DE.id3, (node_id) => state.listingForm.DE.node_id3 = node_id);
    watch(() => state.browsetree.IT.id3, (node_id) => state.listingForm.IT.node_id3 = node_id);
    watch(() => state.browsetree.SE.id3, (node_id) => state.listingForm.SE.node_id3 = node_id);
    watch(() => state.browsetree.PL.id3, (node_id) => state.listingForm.PL.node_id3 = node_id);
    watch(() => state.browsetree.AU.id3, (node_id) => state.listingForm.AU.node_id3 = node_id);
    watch(() => state.browsetree.JP.id3, (node_id) => state.listingForm.JP.node_id3 = node_id);

    watch(() => state.browsetree.US.id4, (node_id) => state.listingForm.US.node_id4 = node_id);
    watch(() => state.browsetree.CA.id4, (node_id) => state.listingForm.CA.node_id4 = node_id);
    watch(() => state.browsetree.MX.id4, (node_id) => state.listingForm.MX.node_id4 = node_id);
    watch(() => state.browsetree.BR.id4, (node_id) => state.listingForm.BR.node_id4 = node_id);
    watch(() => state.browsetree.ES.id4, (node_id) => state.listingForm.ES.node_id4 = node_id);
    watch(() => state.browsetree.UK.id4, (node_id) => state.listingForm.UK.node_id4 = node_id);
    watch(() => state.browsetree.FR.id4, (node_id) => state.listingForm.FR.node_id4 = node_id);
    watch(() => state.browsetree.BE.id4, (node_id) => state.listingForm.BE.node_id4 = node_id);
    watch(() => state.browsetree.NL.id4, (node_id) => state.listingForm.NL.node_id4 = node_id);
    watch(() => state.browsetree.DE.id4, (node_id) => state.listingForm.DE.node_id4 = node_id);
    watch(() => state.browsetree.IT.id4, (node_id) => state.listingForm.IT.node_id4 = node_id);
    watch(() => state.browsetree.SE.id4, (node_id) => state.listingForm.SE.node_id4 = node_id);
    watch(() => state.browsetree.PL.id4, (node_id) => state.listingForm.PL.node_id4 = node_id);
    watch(() => state.browsetree.AU.id4, (node_id) => state.listingForm.AU.node_id4 = node_id);
    watch(() => state.browsetree.JP.id4, (node_id) => state.listingForm.JP.node_id4 = node_id);

    watch(() => state.browsetree.US.id5, (node_id) => state.listingForm.US.node_id5 = node_id);
    watch(() => state.browsetree.CA.id5, (node_id) => state.listingForm.CA.node_id5 = node_id);
    watch(() => state.browsetree.MX.id5, (node_id) => state.listingForm.MX.node_id5 = node_id);
    watch(() => state.browsetree.BR.id5, (node_id) => state.listingForm.BR.node_id5 = node_id);
    watch(() => state.browsetree.ES.id5, (node_id) => state.listingForm.ES.node_id5 = node_id);
    watch(() => state.browsetree.UK.id5, (node_id) => state.listingForm.UK.node_id5 = node_id);
    watch(() => state.browsetree.FR.id5, (node_id) => state.listingForm.FR.node_id5 = node_id);
    watch(() => state.browsetree.BE.id5, (node_id) => state.listingForm.BE.node_id5 = node_id);
    watch(() => state.browsetree.NL.id5, (node_id) => state.listingForm.NL.node_id5 = node_id);
    watch(() => state.browsetree.DE.id5, (node_id) => state.listingForm.DE.node_id5 = node_id);
    watch(() => state.browsetree.IT.id5, (node_id) => state.listingForm.IT.node_id5 = node_id);
    watch(() => state.browsetree.SE.id5, (node_id) => state.listingForm.SE.node_id5 = node_id);
    watch(() => state.browsetree.PL.id5, (node_id) => state.listingForm.PL.node_id5 = node_id);
    watch(() => state.browsetree.AU.id5, (node_id) => state.listingForm.AU.node_id5 = node_id);
    watch(() => state.browsetree.JP.id5, (node_id) => state.listingForm.JP.node_id5 = node_id);

    watch(() => state.browsetree.US.id6, (node_id) => state.listingForm.US.node_id6 = node_id);
    watch(() => state.browsetree.CA.id6, (node_id) => state.listingForm.CA.node_id6 = node_id);
    watch(() => state.browsetree.MX.id6, (node_id) => state.listingForm.MX.node_id6 = node_id);
    watch(() => state.browsetree.BR.id6, (node_id) => state.listingForm.BR.node_id6 = node_id);
    watch(() => state.browsetree.ES.id6, (node_id) => state.listingForm.ES.node_id6 = node_id);
    watch(() => state.browsetree.UK.id6, (node_id) => state.listingForm.UK.node_id6 = node_id);
    watch(() => state.browsetree.FR.id6, (node_id) => state.listingForm.FR.node_id6 = node_id);
    watch(() => state.browsetree.BE.id6, (node_id) => state.listingForm.BE.node_id6 = node_id);
    watch(() => state.browsetree.NL.id6, (node_id) => state.listingForm.NL.node_id6 = node_id);
    watch(() => state.browsetree.DE.id6, (node_id) => state.listingForm.DE.node_id6 = node_id);
    watch(() => state.browsetree.IT.id6, (node_id) => state.listingForm.IT.node_id6 = node_id);
    watch(() => state.browsetree.SE.id6, (node_id) => state.listingForm.SE.node_id6 = node_id);
    watch(() => state.browsetree.PL.id6, (node_id) => state.listingForm.PL.node_id6 = node_id);
    watch(() => state.browsetree.AU.id6, (node_id) => state.listingForm.AU.node_id6 = node_id);
    watch(() => state.browsetree.JP.id6, (node_id) => state.listingForm.JP.node_id6 = node_id);

    watch(() => state.browsetree.US.product_types, (product_types) => { if (!state.listingForm.US.feed_product_type && product_types.length > 0) changeProductType("home", "US") });
    watch(() => state.browsetree.CA.product_types, (product_types) => { if (!state.listingForm.CA.feed_product_type && product_types.length > 0) changeProductType("home", "CA") });
    watch(() => state.browsetree.MX.product_types, (product_types) => { if (!state.listingForm.MX.feed_product_type && product_types.length > 0) changeProductType("home", "MX") });
    watch(() => state.browsetree.BR.product_types, (product_types) => { if (!state.listingForm.BR.feed_product_type && product_types.length > 0) changeProductType("home", "BR") });
    watch(() => state.browsetree.ES.product_types, (product_types) => { if (!state.listingForm.ES.feed_product_type && product_types.length > 0) changeProductType("home", "ES") });
    watch(() => state.browsetree.UK.product_types, (product_types) => { if (!state.listingForm.UK.feed_product_type && product_types.length > 0) changeProductType("home", "UK") });
    watch(() => state.browsetree.FR.product_types, (product_types) => { if (!state.listingForm.FR.feed_product_type && product_types.length > 0) changeProductType("home", "FR") });
    watch(() => state.browsetree.BE.product_types, (product_types) => { if (!state.listingForm.BE.feed_product_type && product_types.length > 0) changeProductType("home", "BE") });
    watch(() => state.browsetree.NL.product_types, (product_types) => { if (!state.listingForm.NL.feed_product_type && product_types.length > 0) changeProductType("home", "NL") });
    watch(() => state.browsetree.DE.product_types, (product_types) => { if (!state.listingForm.DE.feed_product_type && product_types.length > 0) changeProductType("home", "DE") });
    watch(() => state.browsetree.IT.product_types, (product_types) => { if (!state.listingForm.IT.feed_product_type && product_types.length > 0) changeProductType("home", "IT") });
    watch(() => state.browsetree.SE.product_types, (product_types) => { if (!state.listingForm.SE.feed_product_type && product_types.length > 0) changeProductType("home", "SE") });
    watch(() => state.browsetree.PL.product_types, (product_types) => { if (!state.listingForm.PL.feed_product_type && product_types.length > 0) changeProductType("home", "PL") });
    watch(() => state.browsetree.AU.product_types, (product_types) => { if (!state.listingForm.AU.feed_product_type && product_types.length > 0) changeProductType("home", "AU") });
    watch(() => state.browsetree.JP.product_types, (product_types) => { if (!state.listingForm.JP.feed_product_type && product_types.length > 0) changeProductType("home", "JP") });
    watch(() => route.query.id, (id1, id2) => {
      console.log([id1, id2]);
      if (!id1 && id2) init();
    });
    const onScroll = () => {
      if (state.stopListingScroll) return;
      // 获取所有锚点元素
      const navContents = document.querySelectorAll('.code-box')
      // 所有锚点元素的 offsetTop
      const offsetTopArr = []
      navContents.forEach(item => {
        offsetTopArr.push(item.offsetTop)
      })
      // 获取当前文档流的 scrollTop
      const scrollTop = document.getElementById('mainform').scrollTop
      // 定义当前点亮的导航下标
      let navIndex = 0
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是 n 了
        if (scrollTop >= offsetTopArr[n]) {
          navIndex = n
        }
      }
      console.log('onScroll', offsetTopArr, scrollTop, navIndex);
      state.offsetTopArr = offsetTopArr;
      // 把下标赋值给 vue 的 data
      if (navIndex === 0) state.activeMenuKeys = ['card1'];
      if (navIndex === 1) state.activeMenuKeys = ['card2'];
      if (navIndex === 2) state.activeMenuKeys = ['card3'];
      if (navIndex === 3) state.activeMenuKeys = ['card4'];
      if (navIndex === 4) state.activeMenuKeys = ['card5'];
      if (navIndex === 5) state.activeMenuKeys = ['card6'];
    }
    const clickMenu = ({ key }) => {
      state.stopListingScroll = true;
      document.getElementById(key).scrollIntoView();
      setTimeout(() => {
        state.stopListingScroll = false;
      }, 100);
    }
    onActivated(() => {
      console.log('onActivated');
      state.activeMenuKeys = ['card1'];
      document.getElementById('mainform').addEventListener('scroll', onScroll);
      init();
    });
    onDeactivated(() => {
      console.log('onDeactivated');
      stopAutoSave();
      document.getElementById('mainform')?.removeEventListener('scroll', onScroll)
    });
    onBeforeUnmount(() => {
      console.log('onBeforeUnmount');
      EditorRef.value?.destroy()
    });
    const startAutoSave = () => {
      if (route.query.id && process.env.OS !== 'darwin') {
        stopAutoSave();
        if (state.listingForm.state === 'draft' || state.listingForm.state === 'fail') {
          timer = setTimeout(() => {
            doSave({ autosave: true }).then(() => {
              startAutoSave();
            });
          }, 5 * 60 * 1000);
        }
      }
    }
    const stopAutoSave = () => {
      clearTimeout(timer);
    }
    const init = () => {
      state.shopaccountForm = initShopAccountForm();
      state.listingForm = initListingForm();
      getData();
    }
    const initShopAccountForm = (formdata = {}) => {
      return {
        account_username: null, regions: [], region_names: [],
        ...formdata,
      };
    }
    const initRegionForm = (formdata = {}) => {
      return {
        item_name: '', generic_keywords: '', bullet_points: '',
        node_id1: '', node_id2: '', node_id3: '', node_id4: '', node_id5: '', node_id6: '',
        browsenodeid: '', browsenodename: '', feed_product_type: null, product_description: '', standard_price: '',
        ...formdata,
      };
    }
    const initListingForm = (formdata = {}) => {
      return {
        _id: "", account_username: null, regions: [], region_names: [], state: "draft",
        US: initRegionForm(),
        CA: initRegionForm(),
        MX: initRegionForm(),
        BR: initRegionForm(),
        JP: initRegionForm(),
        UK: initRegionForm(),
        FR: initRegionForm(),
        DE: initRegionForm(),
        IT: initRegionForm(),
        ES: initRegionForm(),
        NL: initRegionForm(),
        SE: initRegionForm(),
        PL: initRegionForm(),
        BE: initRegionForm(),
        AU: initRegionForm(),
        item_sku: '', brand_name: '', manufacturer: '', part_number: '', condition_type: 'New', condition_note: '',
        platinum_keywords1: '', platinum_keywords2: '', platinum_keywords3: '', platinum_keywords4: '', platinum_keywords5: '',
        images: [], sale_type: "2", product_tax_code: '', fulfillment_latency: '',
        external_product_id_type: 'UPC', external_product_id: '', standard_price: '', quantity: '', variation_theme: null,
        ...formdata,
      };
    }
    const getData = () => {
      update_uistate({ loading: true });
      proxy.$api.doAPI("/listingformregion/shopaccounts").then((res) => {
        update_uistate({ loading: false });
        if (res.code === 0) {
          state.shopaccounts = res.data.shopaccounts;
          state.brands = res.data.brands;
          state.manufacturers = res.data.manufacturers;
          if (route.query.id) {
            initForm(route.query.id).then(() => {
              startAutoSave();
            });
          }
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        update_uistate({ loading: false });
        console.error(err);
      });
    };
    const initForm = (_id) => {
      return new Promise(resolve => {
        update_uistate({ loading: true });
        proxy.$api.doAPI("/listingformregion/init", { _id }).then(async (res) => {
          update_uistate({ loading: false });
          if (res.code === 0) {
            if (res.data?.listingForm) {
              state.listingForm = res.data.listingForm;
              state.variation_list = _.map(res.data.variation_list, n => {
                return {
                  ...n,
                  other_images: n.other_images || [],
                };
              });
              initTheme(res.data.listingForm, res.data.variation_list);
              let { regions } = res.data.listingForm;
              state.curr_region = regions[0];
              initData(regions);
            } else if (!state.listingForm._id) {
              state.listingForm._id = _id;
            }
            if (!state.listingForm.account_username) state.shopaccountModal = true;
          } else {
            message.error(res.msg);
          }
          resolve();
        }).catch((err) => {
          update_uistate({ loading: false });
          console.error(err);
          resolve();
        });
      })
    }
    const initTheme = (listingForm, variation_list) => {
      if (listingForm.sale_type === '2') {
        let themes = listingForm.variation_theme.split(',');
        if (themes.indexOf('color_name') > -1) {
          state.variation_color = true;
          state.variation_colors = _.pluck(variation_list, "color_name");
        } else {
          state.variation_color = false;
          state.variation_colors = [];
        }
        if (themes.indexOf('size_name') > -1) {
          state.variation_size = true;
          state.variation_colors = _.pluck(variation_list, "size_name");
        } else {
          state.variation_size = false;
          state.variation_sizes = [];
        }
        if (themes.indexOf('material_type') > -1) {
          state.variation_material = true;
          state.variation_colors = _.pluck(variation_list, "material_type");
        } else {
          state.variation_material = false;
          state.variation_materials = [];
        }
      }
    }
    const initData = (regions) => {
      let list = [];
      for (let i = 0; i < regions.length; i++) {
        let region = regions[i];
        clearData(region);
        let { node_id1, node_id2, node_id3, node_id4, node_id5, node_id6, browsenodeid, feed_product_type } = state.listingForm[region];
        list.push({
          region, node_id1, node_id2, node_id3, node_id4, node_id5, node_id6, browsenodeid, feed_product_type,
        });
      }
      getNodesAndAttributes(list);
    }
    const showShopAccounts = () => {
      state.shopaccountForm = initShopAccountForm(_.pick(state.listingForm, ["account_username", "regions"]));
      state.shopaccountModal = true;
    }
    const confirmShopAccount = async () => {
      try {
        await shopaccountformRef.value.validateFields();
        state.listingForm.account_username = state.shopaccountForm.account_username;
        let regions = _.pluck(_.findWhere(state.shopaccounts, { value: state.shopaccountForm.account_username }).regions, "value");
        let region_names = _.pluck(_.findWhere(state.shopaccounts, { value: state.shopaccountForm.account_username }).regions, "label");
        state.listingForm.regions = state.shopaccountForm.regions;
        state.listingForm.region_names = _.map(state.shopaccountForm.regions, region => {
          let region_index = regions.indexOf(region);
          return region_names[region_index];
        });
        for (let i = 0; i < state.listingForm.regions.length; i++) {
          let region = state.listingForm.regions[i];
          state.listingForm[region] = initRegionForm(state.listingForm[region] || {});
          clearData(region);
        }
        state.curr_region = state.listingForm.regions[0];
        state.shopaccountModal = false;
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    }
    const validateShopRegion = async (_rule, value) => {
      if (!value || (_.isArray(value) && value.length > 0)) {
        return Promise.resolve();
      } else {
        return Promise.reject('请选勾选至少一个站点');
      }
    }
    const initShowBrowseTree = (formdata = {}) => {
      return {
        ..._.reduce(state.listingForm.regions, (a, b) => _.extend(a, { [b]: false }), {}),
        ...formdata,
      };
    }
    const selectBrowseTree = () => {
      update_uistate({ visible: true });
      if (state.browsetree[state.curr_region]?.list1?.length > 0) return;
      update_uistate({ loading: true });
      setTimeout(() => {
        getNodes();
      }, 100);
    }
    const changeProductType = (product_type, region) => {
      state.listingForm[region].feed_product_type = product_type;
      getAttributes({ browsenodeid: state.listingForm[region].browsenodeid, product_type }, region);
    }
    const handleCreated = (editor) => {
      EditorRef.value = editor;
    }
    const onLocalUploadSuccess = ({ type, data, index, index2 }) => {
      console.log('onLocalUploadSuccess', type, data, index, index2);
      if (type === 'listingform' || type === 'listingform2') {
        let images = toRaw(state.listingForm.images);
        if (type === 'listingform') images.push(data.url);
        if (type === 'listingform2') images[index] = data.url;
        state.listingForm.images = _.uniq(images);
      }
      if (type === 'other_images') {
        let other_images = toRaw(state.variation_list[index].other_images);
        if (index2 >= 0) other_images[index2] = data.url;
        else other_images.push(data.url);
        state.variation_list[index].other_images = _.uniq(other_images);
      }
    }
    const onNetworkInputSuccess = ({ type, data, index, index2 }) => {
      console.log('onNetworkInputSuccess', type, data, index, index2);
      if (type === 'listingform' || type === 'listingform2') {
        let images = toRaw(state.listingForm.images);
        if (type === 'listingform') images.push(...data.images);
        if (type === 'listingform2') images[index] = data.images[0];
        state.listingForm.images = _.uniq(images);
      }
      if (type === 'other_images') {
        let other_images = toRaw(state.variation_list[index].other_images);
        if (index2 >= 0) other_images[index2] = data.images[0];
        else other_images.push(...data.images);
        state.variation_list[index].other_images = _.uniq(other_images);
      }
    }
    const onSpacePickSuccess = ({ type, data, index, index2 }) => {
      console.log('onSpacePickSuccess', type, data, index, index2);
      if (type === 'listingform' || type === 'listingform2') {
        let images = toRaw(state.listingForm.images);
        if (type === 'listingform') images.push(...data.images);
        if (type === 'listingform2') images[index] = data.images[0];
        state.listingForm.images = _.uniq(images);
      }
      if (type === 'other_images') {
        let other_images = toRaw(state.variation_list[index].other_images);
        if (index2 >= 0) other_images[index2] = data.images[0];
        else other_images.push(...data.images);
        state.variation_list[index].other_images = _.uniq(other_images);
      }
    }
    const removeImage = ({ type, index, index2 }) => {
      if (type === 'listingform') state.listingForm.images.splice(index, 1);
      if (type === 'other_images') state.variation_list[index].other_images.splice(index2, 1);
    }
    const getExternalProductID = () => {
      let externalProductForm = {}, externalProductGroups = [];
      if (state.listingForm.sale_type === '1') {
        _.extend(externalProductForm, {
          type: state.listingForm.external_product_id_type,
          method: "auto",
          groupid: null,
          unused_count: 0,
          codes: "",
          count: 1,
        });
      } else if (state.listingForm.sale_type === '2') {
        _.extend(externalProductForm, {
          type: state.variation_list[0].external_product_id_type,
          method: "auto",
          groupid: null,
          unused_count: 0,
          codes: "",
          count: state.variation_list.length,
        });
      }
      state.externalProductForm = externalProductForm;
      state.externalProductGroups = externalProductGroups;
      state.externalProductModal = true;
      getProductGroups();
    }
    const getProductGroups = () => {
      state.externalProductLoading = true;
      proxy.$api.doAPI("/listingformregion/productgroups", { type: state.externalProductForm.type }).then((res) => {
        state.externalProductLoading = false;
        if (res.code === 0) {
          // state.externalProductTypes = res.data.types;
          state.externalProductGroups = res.data.list;
          state.externalProductForm.groupid = null;
          state.externalProductForm.unused_count = 0;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.externalProductLoading = false;
        console.error(err);
      });
    }
    const confirmExternalProduct = () => {
      if (state.externalProductForm.method === 'auto') {//自动获取
        if (state.externalProductForm.unused_count === 0) return message.error("请选择剩余可用数量大于0的号码池");
        state.externalProductLoading = true;
        proxy.$api.doAPI("/listingidentifier/getcodes", { groupid: state.externalProductForm.groupid, count: state.externalProductForm.count }).then((res) => {
          state.externalProductLoading = false;
          if (res.code === 0) {
            fillExternalProductID(res.data.codes);
            state.externalProductModal = false;
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.externalProductLoading = false;
          console.error(err);
        });
      } else if (state.externalProductForm.method === 'manual') {//手动输入
        if (!state.externalProductForm.codes || !state.externalProductForm.codes.trim()) return message.error("请输入号码");
        let codes = _.filter(_.uniq(state.externalProductForm.codes.trim().split('\n')), n => !!n);
        fillExternalProductID(codes);
        state.externalProductModal = false;
      } else return;
    }
    const fillExternalProductID = (codes) => {
      if (state.listingForm.sale_type === '1') {
        state.listingForm.external_product_id = codes[0];
      } else if (state.listingForm.sale_type === '2') {
        state.variation_list = _.map(toRaw(state.variation_list), (n, i) => {
          if (codes[i]) n.external_product_id = codes[i];
          return n;
        });
      }
    }
    const changeExternalProductType = () => {
      getProductGroups();
    }
    const changeExternalProductGroup = (groupid) => {
      let group = _.findWhere(state.externalProductGroups, { _id: groupid });
      state.externalProductForm.unused_count = group.unused_count;
    }
    const gotoUPC = () => {
      proxy.$utils.downloadBrowser({ href: "/shops/published/upc" });
    }
    const editListingPrice = () => {
      state.listingPrice = _.reduce(state.listingForm.regions, (a, b) => _.extend(a, { [b]: { standard_price: state.listingForm[b].standard_price || "" } }), {});
      state.listingPriceModal = true;
    }
    const confirmListingPrice = () => {
      for (let i = 0; i < state.listingForm.regions.length; i++) {
        let region = state.listingForm.regions[i];
        if (!state.listingPrice[region].standard_price) return message.error(`站点：${state.listingForm.region_names[i]}的价格不能为空！`);
      }
      for (let i = 0; i < state.listingForm.regions.length; i++) {
        let region = state.listingForm.regions[i];
        state.listingForm[region].standard_price = state.listingPrice[region].standard_price;
      }
      state.listingForm.standard_price = state.listingForm[state.listingForm.regions[0]].standard_price;
      state.listingPriceModal = false;
    }
    const moveLeft = ({ type, index, index2 }) => {
      if (type === 'listingform') {
        let image1 = toRaw(state.listingForm.images)[index];
        let image2 = toRaw(state.listingForm.images)[index - 1]
        state.listingForm.images[index - 1] = image1;
        state.listingForm.images[index] = image2;
      } else if (type === 'other_images') {
        let image1 = state.variation_list[index].other_images[index2];
        let image2 = state.variation_list[index].other_images[index2 - 1];
        state.variation_list[index].other_images[index2 - 1] = image1;
        state.variation_list[index].other_images[index2] = image2;
      }
    }
    const moveRight = ({ type, index, index2 }) => {
      if (type === 'listingform') {
        let image1 = toRaw(state.listingForm.images)[index];
        let image2 = toRaw(state.listingForm.images)[index + 1]
        state.listingForm.images[index + 1] = image1;
        state.listingForm.images[index] = image2;
      } else if (type === 'other_images') {
        let image1 = state.variation_list[index].other_images[index2];
        let image2 = state.variation_list[index].other_images[index2 + 1];
        state.variation_list[index].other_images[index2 + 1] = image1;
        state.variation_list[index].other_images[index2] = image2;
      }
    }
    const moveUp = ({ index }) => {
      let variation_item1 = toRaw(state.variation_list)[index];
      let variation_item2 = toRaw(state.variation_list)[index - 1];
      state.variation_list[index - 1] = variation_item1;
      state.variation_list[index] = variation_item2;
    }
    const moveDown = ({ index }) => {
      let variation_item1 = toRaw(state.variation_list)[index];
      let variation_item2 = toRaw(state.variation_list)[index + 1];
      state.variation_list[index + 1] = variation_item1;
      state.variation_list[index] = variation_item2;
    }
    //变更商品属性
    const changeVariationTheme = (theme) => {
      let themes = theme.split(',');
      if (themes.indexOf('color_name') > -1) {
        state.variation_color = true;
        state.variation_colors = toRaw(state.variation_colors);
      } else {
        state.variation_color = false;
        state.variation_colors = [];
      }
      if (themes.indexOf('size_name') > -1) {
        state.variation_size = true;
        state.variation_sizes = toRaw(state.variation_sizes);
      } else {
        state.variation_size = false;
        state.variation_sizes = [];
      }
      if (themes.indexOf('material_type') > -1) {
        state.variation_material = true;
        state.variation_materials = toRaw(state.variation_materials);
      } else {
        state.variation_material = false;
        state.variation_materials = [];
      }
      createVariationList(false);
    }
    //批量删除多属性
    const removeVariationItems = () => {
      if (state.variation_ids.length === 0) return message.error("请先勾选您要删除的多属性");
      state.variation_list = _.filter(toRaw(state.variation_list), n => state.variation_ids.indexOf(n._id) === -1);
      state.variation_colors = _.filter(_.uniq(_.pluck(state.variation_list, "color_name")), n => !!n);
      state.variation_sizes = _.filter(_.uniq(_.pluck(state.variation_list, "size_name")), n => !!n);
      state.variation_materials = _.filter(_.uniq(_.pluck(state.variation_list, "material_type")), n => !!n);
      state.variation_ids = [];
    }
    //添加一行多属性
    const addVariationItem = () => {
      if (!state.listingForm.variation_theme) return message.error("暂无商品属性，请先选择商品属性！");
      if (!state.listingForm.account_username) return message.error("暂无站点，请先选择站点！");
      state.variationItemForm = {
        item_sku: state.listingForm.item_sku,
        color_name: "",
        size_name: "",
        material_type: "",
      };
      state.variationItemModal = true;
    }
    const confirmVariationItem = async () => {
      try {
        await variationItemFormRef.value.validateFields();
        let variation_item = initVariationItem();
        _.extend(variation_item, _.pick(state.variationItemForm, ["color_name", "size_name", "material_type"]))
        let { color_name, size_name, material_type } = variation_item;
        let { item_sku } = state.variationItemForm;
        let values = [];
        if (color_name) values.push(color_name);
        if (size_name) values.push(size_name);
        if (material_type) values.push(material_type);
        _.extend(variation_item, { item_sku: `${item_sku}-${values.join('||')}` });
        state.variation_list.push(variation_item);
        state.variationItemModal = false;
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    }
    //#region 批量编辑多属性
    const editVariationTheme = () => {
      if (!state.listingForm.variation_theme) return message.error("暂无商品属性，请先选择商品属性！");
      if (!state.listingForm.account_username) return message.error("暂无站点，请先选择站点！");
      let variation_list = toRaw(state.variation_list);
      let colors = _.filter(_.uniq(_.pluck(variation_list, "color_name")));
      if (colors.length === 0) colors = toRaw(state.variation_colors);
      let sizes = _.filter(_.uniq(_.pluck(variation_list, "size_name")));
      if (sizes.length === 0) sizes = toRaw(state.variation_sizes);
      let materials = _.filter(_.uniq(_.pluck(variation_list, "material_type")));
      if (materials.length === 0) materials = toRaw(state.variation_materials);
      state.variationThemeForm = {
        colors: _.map(colors, n => ({ key: n, value: n })),
        sizes: _.map(sizes, n => ({ key: n, value: n })),
        materials: _.map(materials, n => ({ key: n, value: n })),
      };
      state.variationThemeModal = true;
    }
    const addVariationColor = () => {
      if (!state.variation_input_color) return message.error("请输入属性项名称");
      if (state.variationThemeForm.colors.findIndex(n => n.value.toLowerCase() === state.variation_input_color.toLowerCase()) >= 0) {
        return message.error("名称不能重复，请输入其他名称");
      }
      state.variationThemeForm.colors.push({ key: state.variation_input_color, value: state.variation_input_color });
      state.variation_input_color = "";
    }
    const removeVariationColor = (index) => {
      state.variationThemeForm.colors.splice(index, 1);
    }
    const addVariationSize = () => {
      if (!state.variation_input_size) return message.error("请输入属性项名称");
      if (state.variationThemeForm.sizes.findIndex(n => n.value.toLowerCase() === state.variation_input_size.toLowerCase()) >= 0) {
        return message.error("名称不能重复，请输入其他名称");
      }
      state.variationThemeForm.sizes.push({ key: state.variation_input_size, value: state.variation_input_size });
      state.variation_input_size = "";
    }
    const removeVariationSize = (index) => {
      state.variationThemeForm.sizes.splice(index, 1);
    }
    const addVariationMaterial = () => {
      if (!state.variation_input_material) return message.error("请输入属性项名称");
      if (state.variationThemeForm.materials.findIndex(n => n.value.toLowerCase() === state.variation_input_material.toLowerCase()) >= 0) {
        return message.error("名称不能重复，请输入其他名称");
      }
      state.variationThemeForm.materials.push({ key: state.variation_input_material, value: state.variation_input_material });
      state.variation_input_material = "";
    }
    const removeVariationMaterial = (index) => {
      state.variationThemeForm.materials.splice(index, 1);
    }
    const confirmVariationTheme = () => {
      if (state.variation_color) {
        if (state.variationThemeForm.colors.length === 0) return message.error(`请为color_name添加至少一项属性项`);
        state.variation_colors = _.pluck(state.variationThemeForm.colors, "value");
      }
      if (state.variation_size) {
        if (state.variationThemeForm.sizes.length === 0) return message.error(`请为size_name添加至少一项属性项`);
        state.variation_sizes = _.pluck(state.variationThemeForm.sizes, "value");
      }
      if (state.variation_material) {
        if (state.variationThemeForm.materials.length === 0) return message.error(`请为material添加至少一项属性项`);
        state.variation_materials = _.pluck(state.variationThemeForm.materials, "value");
      }
      createVariationList(true);
      state.variationThemeModal = false;
    }
    //#endregion批量编辑多属性
    const combineVariation = () => {
      let variations = [];
      if (state.variation_color) variations.push({ name: "color_name", values: state.variation_colors });
      if (state.variation_size) variations.push({ name: "size_name", values: state.variation_sizes });
      if (state.variation_material) variations.push({ name: "material_type", values: state.variation_materials });
      let list = {};
      for (let i = 0; i < variations.length; i++) {
        let { name, values } = variations[i];
        if (!list[name]) list[name] = [];
        for (let j = 0; j < values.length; j++) {
          list[name].push({ [name]: values[j] });
        }
      }
      list = _.values(list);
      let list2 = proxy.$utils.choose(_.reduce(list, (a, b) => a.concat(b), []), list.length);
      list2 = _.map(list2, n => _.extend({}, ...n));
      list2 = _.filter(list2, n => _.keys(n).length === list.length);
      return list2;
    }
    const initVariationItem = (item_data = {}) => {
      let item = {
        _id: proxy.$utils.uuid(), color_name: "", size_name: "", material_type: "", item_sku: "", external_product_id_type: "UPC", external_product_id: "", quantity: "", standard_price: "", item_name: "", main_image: "", swatch_image: "", other_images: [],
      };
      for (let i = 0; i < state.listingForm.regions.length; i++) {
        let region = state.listingForm.regions[i];
        _.extend(item, { [region]: { item_name: "", standard_price: "" } });
      }
      _.extend(item, item_data);
      item.other_images = item.other_images || [];
      item.item_name = item[state.listingForm.regions[0]]?.item_name || "";
      item.standard_price = item[state.listingForm.regions[0]]?.standard_price || "";
      return item;
    }
    const createVariationList = (init) => {
      let has_color = state.variation_color;
      let has_size = state.variation_size;
      let has_material = state.variation_material;
      let colors = toRaw(state.variation_colors);
      let sizes = toRaw(state.variation_sizes);
      let materials = toRaw(state.variation_materials);
      let list = combineVariation();
      let variation_list = toRaw(state.variation_list);
      if (init) variation_list = [];
      let item_sku = state.listingForm.item_sku || "";
      let item_data = {};
      if (variation_list[0]?.external_product_id_type) item_data.external_product_id_type = variation_list[0].external_product_id_type;
      for (let i = 0; i < list.length; i++) {
        for (let j = 0; j < colors.length; j++) {
          if (!_.findWhere(variation_list, list[i])) {
            let vname = "color_name";
            let value = colors[j];
            let vitem = _.find(variation_list, n => !_.has(n, vname));
            if (!vitem) vitem = _.find(variation_list, n => n[vname] === value);
            if (vitem) {
              vitem[vname] = value;
            } else {
              let item = _.extend({}, initVariationItem(item_data), list[i]);
              variation_list.push(item);
            }
          }
        }
        for (let j = 0; j < sizes.length; j++) {
          if (!_.findWhere(variation_list, list[i])) {
            let vname = "size_name";
            let value = sizes[j];
            let vitem = _.find(variation_list, n => (!has_color || n.color_name === list[i].color_name) && !_.has(n, vname));
            if (!vitem) vitem = _.find(variation_list, n => (!has_color || n.color_name === list[i].color_name) && n[vname] === value);
            if (vitem) {
              vitem[vname] = value;
            } else {
              let item = _.extend({}, initVariationItem(item_data), list[i]);
              variation_list.push(item);
            }
          }
        }
        for (let j = 0; j < materials.length; j++) {
          if (!_.findWhere(variation_list, list[i])) {
            let vname = "meterial_type";
            let value = materials[j];
            let vitem = _.find(variation_list, n => (!has_color || n.color_name === list[i].color_name) && (!has_size || n.size_name === list[i].size_name) && !_.has(n, vname));
            if (!vitem) vitem = _.find(variation_list, n => (!has_color || n.color_name === list[i].color_name) && (!has_size || n.size_name === list[i].size_name) && n[vname] === value);
            if (vitem) {
              vitem[vname] = value;
            } else {
              let item = _.extend({}, initVariationItem(item_data), list[i]);
              variation_list.push(item);
            }
          }
        }
      }
      variation_list = _.map(variation_list, n => {
        let values = [];
        if (n.color_name) values.push(n.color_name);
        if (n.size_name) values.push(n.size_name);
        if (n.material_type) values.push(n.material_type);
        n.item_sku = `${item_sku ? (item_sku + '-') : ''}${values.join('||')}`
        return n;
      });
      state.variation_list = variation_list;
      state.variation_colors = _.filter(_.uniq(_.pluck(state.variation_list, "color_name")), n => !!n);
      state.variation_sizes = _.filter(_.uniq(_.pluck(state.variation_list, "size_name")), n => !!n);
      state.variation_materials = _.filter(_.uniq(_.pluck(state.variation_list, "material_type")), n => !!n);
      state.variation_ids = [];
    }
    //应用到全部 数量 价格
    const useAllVariationItem = (index) => {
      let variation_list = toRaw(state.variation_list);
      let variation_item = variation_list[index];
      let { quantity, standard_price } = variation_item;
      variation_list = variation_list.map((n, idx) => {
        if (idx !== index) {
          for (let i = 0; i < state.listingForm.regions.length; i++) {
            let region = state.listingForm.regions[i];
            n[region].standard_price = variation_item[region].standard_price;
          };
          _.extend(n, { quantity, standard_price });
        }
        return n;
      });
      state.variation_list = variation_list;
    }
    //删除变种
    const removeVariationItem = (index) => {
      state.variation_list.splice(index, 1);
      state.variation_colors = _.filter(_.uniq(_.pluck(state.variation_list, "color_name")), n => !!n);
      state.variation_sizes = _.filter(_.uniq(_.pluck(state.variation_list, "size_name")), n => !!n);
      state.variation_materials = _.filter(_.uniq(_.pluck(state.variation_list, "material_type")), n => !!n);
      state.variation_ids = [];
    }
    const blurVariationItemAttribute = (e, v) => {
      if (!v.trim()) {
        message.error(`属性名称不能为空`);
        e.target.focus();
      }
    }
    //批量修改 UPC EAN 等 类型
    const changeVariationListProductIDType = (type) => {
      state.variation_list.map(n => n.external_product_id_type = type);
    }
    //一键生成
    const oneClickGenerate = (key) => {
      if (key === "item_sku") {
        let item_sku = state.listingForm.item_sku || "";
        state.variation_list = _.map(toRaw(state.variation_list), n => {
          let values = [];
          if (n.color_name) values.push(n.color_name);
          if (n.size_name) values.push(n.size_name);
          if (n.material_type) values.push(n.material_type);
          n.item_sku = `${item_sku ? (item_sku + '-') : ''}${values.join('||')}`;
          return n;
        });
      } else if (key === "item_name") {
        let item_name = state.listingForm[state.listingForm.regions?.[0]]?.item_name || "";
        state.variation_list = _.map(toRaw(state.variation_list), n => {
          let values = [];
          if (n.color_name) values.push(n.color_name);
          if (n.size_name) values.push(n.size_name);
          if (n.material_type) values.push(n.material_type);
          let item_name_suffix = values.join('||');
          for (let i = 0; i < state.listingForm.regions.length; i++) {
            let region = state.listingForm.regions[i];
            let item_name = state.listingForm[region].item_name;
            n[region].item_name = `${item_name ? (item_name + '-') : ''}${item_name_suffix}`;
          };
          n.item_name = `${item_name ? (item_name + '-') : ''}${item_name_suffix}`;
          return n;
        });
      } else if (key === 'external_product_id') {
        state.variation_list = _.map(toRaw(state.variation_list), n => {
          n.external_product_id = n.item_sku;
          return n;
        });
      }
    }
    //批量编辑
    const editVariationList = (key) => {
      if (key === 'quantity') {
        state.variationListQuantity = "";
        state.variationListQuantityModal = true;
      } else if (key === 'standard_price') {
        state.variationListPrice = _.reduce(state.listingForm.regions, (a, b) => _.extend(a, { [b]: { standard_price: "" } }), {});
        state.variationListPriceModal = true;
      }
    }
    const confirmVariationListQuantity = () => {
      if (!state.variationListQuantity) return message.error("请输入修改数量");
      if (!proxy.$utils.isDecimal(state.variationListQuantity) || proxy.$utils.d(state.variationListQuantity).lt(0)) return message.error("请输入正确数量");
      state.variation_list = _.map(toRaw(state.variation_list), n => {
        n.quantity = state.variationListQuantity;
        return n;
      });
      state.variationListQuantityModal = false;
    }
    const confirmVariationListPrice = () => {
      for (let i = 0; i < state.listingForm.regions.length; i++) {
        let region = state.listingForm.regions[i];
        if (!state.variationListPrice[region].standard_price) return message.error(`站点：${state.listingForm.region_names[i]}的价格不能为空！`);
      }
      state.variation_list = _.map(toRaw(state.variation_list), n => {
        for (let i = 0; i < state.listingForm.regions.length; i++) {
          let region = state.listingForm.regions[i];
          n[region].standard_price = state.variationListPrice[region].standard_price;
        };
        n.standard_price = n[state.listingForm.regions[0]].standard_price;
        return n;
      });
      state.variationListPriceModal = false;
    }
    const editVariationItem = (index, item, key) => {
      state.variationItemIndex = index;
      if (key === 'standard_price') {
        state.variationItemPrice = _.reduce(state.listingForm.regions, (a, b) => _.extend(a, { [b]: { standard_price: item[b].standard_price || "" } }), {});
        state.variationItemPriceModal = true;
      } else if (key === 'item_name') {
        state.variationItemName = _.reduce(state.listingForm.regions, (a, b) => _.extend(a, { [b]: { item_name: item[b].item_name || "" } }), {});
        state.variationItemNameModal = true;
      }
    }
    const confirmVariationItemPrice = () => {
      for (let i = 0; i < state.listingForm.regions.length; i++) {
        let region = state.listingForm.regions[i];
        if (!state.variationItemPrice[region].standard_price) return message.error(`站点：${state.listingForm.region_names[i]}的价格不能为空！`);
      }
      state.variation_list = _.map(toRaw(state.variation_list), (n, index) => {
        if (index === state.variationItemIndex) {
          for (let i = 0; i < state.listingForm.regions.length; i++) {
            let region = state.listingForm.regions[i];
            n[region].standard_price = state.variationItemPrice[region].standard_price;
          };
          n.standard_price = n[state.listingForm.regions[0]].standard_price;
        }
        return n;
      });
      state.variationItemPriceModal = false;
    }
    const confirmVariationItemName = () => {
      for (let i = 0; i < state.listingForm.regions.length; i++) {
        let region = state.listingForm.regions[i];
        if (!state.variationItemName[region].item_name) return message.error(`站点：${state.listingForm.region_names[i]}的标题不能为空！`);
      }
      state.variation_list = _.map(toRaw(state.variation_list), (n, index) => {
        if (index === state.variationItemIndex) {
          for (let i = 0; i < state.listingForm.regions.length; i++) {
            let region = state.listingForm.regions[i];
            n[region].item_name = state.variationItemName[region].item_name;
          };
          n.item_name = n[state.listingForm.regions[0]].item_name;
        }
        return n;
      });
      state.variationItemNameModal = false;
    }
    const useAllImages = (index) => {
      let variation_list = toRaw(state.variation_list);
      let { main_image, swatch_image, other_images } = variation_list[index];
      variation_list = variation_list.map((n, i) => {
        if (i !== index) {
          _.extend(n, { main_image, swatch_image, other_images: [...other_images] });
        }
        return n;
      });
      state.variation_list = variation_list;
    }
    const useAllOtherImages = (index) => {
      let variation_list = toRaw(state.variation_list);
      let { other_images } = variation_list[index];
      variation_list = variation_list.map((n, i) => {
        if (i !== index) {
          n.other_images = [...other_images];
        }
        return n;
      });
      state.variation_list = variation_list;
    }
    const chooseFromImages = ({ type, count, index, index2 }) => {
      if (state.listingForm.images.length === 0) return message.error("暂无橱窗图");
      state.chooseImagesType = type;
      state.chooseImagesList = [];
      state.chooseImagesMaxCount = count;
      state.chooseImagesIndex = index;
      state.chooseImagesIndex2 = index2;
      state.chooseImagesModal = true;
    }
    const confirmChooseImages = () => {
      if (state.chooseImagesList.length === 0) return message.error("请勾选橱窗图");
      if (state.chooseImagesList.length > state.chooseImagesMaxCount) return message.error(`本次上传最多${state.chooseImagesMaxCount}张图片，您勾选了${state.chooseImagesList.length}张`);
      state.variation_list[state.chooseImagesIndex].other_images.push(...state.chooseImagesList);
      state.chooseImagesModal = false;
    }
    const confirmChooseImage = (image) => {
      if (state.chooseImagesType === 'other_images2') {
        state.variation_list[state.chooseImagesIndex].other_images[state.chooseImagesIndex2] = image;
      } else {
        state.variation_list[state.chooseImagesIndex][state.chooseImagesType] = image;
      }
      state.chooseImagesModal = false;
    }
    const validatePrice = async (_rule, value) => {
      if (!value) {
        return Promise.resolve();
      } else if (!proxy.$utils.isDecimal(value) || proxy.$utils.d(value).lte(0)) {
        return Promise.reject("价格无效");
      } else {
        return Promise.resolve();
      }
    };
    const validateQuantity = async (_rule, value) => {
      if (!value) {
        return Promise.resolve();
      } else if (!proxy.$utils.isDecimal(value) || proxy.$utils.d(value).lte(0)) {
        return Promise.reject("数量无效");
      } else {
        return Promise.resolve();
      }
    };
    const doCheck = () => {
      state.saving = true;
      let check_data = {};
      for (let i = 0; i < state.listingForm.regions.length; i++) {
        let region = state.listingForm.regions[i];
        check_data[region] = {
          item_name: state.listingForm[region].item_name,
          generic_keywords: state.listingForm[region].generic_keywords,
          bullet_points: state.listingForm[region].bullet_points,
          product_description: state.listingForm[region].product_description,
          brand_name: state.listingForm.brand_name,
          condition_note: state.listingForm.condition_note,
          platinum_keywords: _.filter([
            state.listingForm.platinum_keywords1 || "",
            state.listingForm.platinum_keywords2 || "",
            state.listingForm.platinum_keywords3 || "",
            state.listingForm.platinum_keywords4 || "",
            state.listingForm.platinum_keywords5 || "",
          ], n => !!n),
          item_names: [state.listingForm[region].item_name, ..._.filter(_.uniq(_.map(state.variation_list, n => n[region].item_name)), n => !!n)],
        };
      }
      proxy.$api.doAPI("/tortword/checkreq", { check_data }).then((res) => {
        if (res.code === 0) {
          doCheckResult(res.data._id);
        } else {
          message.error(res.msg);
          state.saving = false;
        }
      }).catch((err) => {
        state.saving = false;
        console.error(err);
      });
    }
    const doCheckResult = (check_id) => {
      proxy.$api.doAPI("/tortword/checkres", { check_id }).then((res) => {
        if (res.code === 0) {
          if (res.data.state === 'finished') {
            if (res.data.result.length === 0) {
              message.success("没有发现所包含的侵权词");
            } else {
              state.tortwordsResult = res.data.result;
              state.tortwordsResultModal = true;
            }
            state.saving = false;
          } else if (res.data.state === 'error') {
            message.error(res.data.error_message);
            state.saving = false;
          } else {
            setTimeout(() => {
              doCheckResult(check_id);
            }, 1500);
          }
        } else {
          message.error(res.msg);
          state.saving = false;
        }
      }).catch((err) => {
        state.saving = false;
        console.error(err);
      });
    }
    const doTranslate = () => {

    }
    const checkListingForm = (autosave = false) => {
      let { account_username, regions, region_names, item_sku, brand_name, manufacturer, sale_type, external_product_id_type, external_product_id, quantity, variation_theme } = state.listingForm;
      if (!account_username || regions.length === 0) return "暂无站点，请先选择站点！";
      for (let i = 0; i < regions.length; i++) {
        let region = regions[i];
        let region_name = region_names[i];
        let { item_name, generic_keywords, bullet_points, browsenodeid, feed_product_type, product_description, standard_price } = state.listingForm[region];
        if (!item_name) return `${region_name}站点:产品标题 数据不完整不允许刊登`;
        // if (proxy.$utils.u8length(item_name).length > 200) return `${region_name}站点:产品标题 长度不能超过200字节`;
        if (!autosave) {
          if (!generic_keywords) return `${region_name}站点:Search Terms 数据不完整不允许刊登`;
          // if (proxy.$utils.u8length(generic_keywords).length > 255) return `${region_name}站点:Search Terms 长度不能超过255字节`;
          if (!bullet_points || !bullet_points.trim()) return `${region_name}站点:Bullet Point 数据不完整不允许刊登`;
          if (bullet_points.split('\n').length > 5) return `${region_name}站点:Bullet Point 不能超过5条`;
          if (_.filter(bullet_points.split('\n'), n => n.length > 500).length > 0) return `${region_name}站点：Bullet Point每行长度不能超过500字符`;
          if (!browsenodeid) return `${region_name}站点:产品分类 数据不完整不允许刊登`;
          if (!feed_product_type) return `${region_name}站点:Product Type 数据不完整不允许刊登`;
          if (!product_description) return `${region_name}站点:产品描述 数据不完整不允许刊登`;
          if (sale_type === '1') {
            if (!standard_price) return `${region_name}站点:价格不能为空`;
            if (!proxy.$utils.isDecimal(standard_price) || proxy.$utils.d(standard_price).lte(0)) return `${region_name}站点:价格无效`;
          } else if (sale_type === '2') {
            if (state.variation_list.length === 0) return `${region_name}站点:多属性商品不能为空`;
            // for (let j = 0; j < state.variation_list.length; j++) {
            //   let { item_name } = state.variation_list[j][region];
            //   if (proxy.$utils.u8length(item_name) > 200) return `${region_name}站点：变体产品标题长度不能超过200字节`;
            // }
          }
        }
      }
      if (!item_sku) return `主sku不能为空`;
      if (!autosave) {
        if (!brand_name) return `品牌不能为空`;
        if (!manufacturer) return `制造商不能为空`;
        if (sale_type === '1') {
          if (external_product_id_type !== 'GTIN' && !external_product_id) return `单品产品ID不能为空`;
          if (!quantity) return `数量不能为空`;
          if (!proxy.$utils.isDecimal(quantity) || proxy.$utils.d(quantity).lt(0)) return `数量无效`;
        } else if (sale_type === '2') {
          if (!variation_theme) return `商品属性不能为空`;
        }
      }
    }
    const doSave = ({ publish = false, autosave = false }) => {
      if (!autosave) state.saving = true;
      return new Promise(resolve => {
        setTimeout(async () => {
          try {
            await listingformRef.value.validateFields();
            let result = checkListingForm(autosave);
            if (typeof result === "string") {
              if (!autosave) state.saving = false;
              message.error(result);
              return resolve({ success: false });
            }
            proxy.$api.doAPI("/listingformregion/save", { listingForm: state.listingForm, variation_list: state.variation_list, autosave }).then((res) => {
              if (!autosave) state.saving = false;
              if (res.code === 0) {
                if (!autosave) {
                  Modal.success({
                    title: "提示",
                    content: "刊登信息保存成功",
                    centered: true,
                    closable: false,
                    maskClosable: false,
                    keyboard: false,
                    onOk: () => {
                      resolve({ success: true, _id: res.data._id });
                      if (!publish) router.replace({ path: route.path, query: { id: res.data._id } });
                    }
                  });
                } else {
                  resolve({ success: true, _id: res.data._id });
                }
              } else {
                if (!autosave) message.error(res.msg);
                resolve({ success: false });
              }
            }).catch((err) => {
              if (!autosave) state.saving = false;
              console.error(err);
              resolve({ success: false });
            });
          } catch (errorInfo) {
            if (!autosave) state.saving = false;
            console.log("Failed:", errorInfo);
            // message.error(_.reduce(errorInfo.errorFields, (a, b) => a.concat(b.errors), []).join('；'));
            let result = checkListingForm(autosave);
            if (!autosave && typeof result === "string") message.error(result);
            resolve({ success: false });
          }
        }, 100);
      })
    }
    const doSend = () => {
      doSave({ publish: true }).then(({ success, _id }) => {
        if (success) {
          state.saving = true;
          proxy.$api.doAPI("/listingformregion/publish", { _id }).then((res) => {
            state.saving = false;
            if (res.code === 0) {
              if (route.query.id) getData();
              else router.replace({ path: route.path, query: { id: res.data._id } });
            } else {
              message.error(res.msg);
            }
          }).catch((err) => {
            state.saving = false;
            console.error(err);
          });
        }
      });
    }
    const doClose = () => {
      window.close();
    }
    const doSearchAttribute = (text, region) => {
      state.attribute_search_enabled[region] = !!text;
    }
    const filterOption = (input, option) => {
      return option.value.toUpperCase().indexOf(input.toUpperCase()) >= 0;
    };
    //#region 类目菜单选择
    const set_data = (payload, region) => {
      state.browsetree[region] = { ...toRaw(state.browsetree[region]), ...payload };
    }
    const confirm_data = (region) => {
      state.browsetree[region].confirm_data = {
        index: state.browsetree[region].index,
        count: state.browsetree[region].count,
        list1: state.browsetree[region].list1,
        list2: state.browsetree[region].list2,
        list3: state.browsetree[region].list3,
        list4: state.browsetree[region].list4,
        list5: state.browsetree[region].list5,
        list6: state.browsetree[region].list6,
        id1: state.browsetree[region].id1,
        id2: state.browsetree[region].id2,
        id3: state.browsetree[region].id3,
        id4: state.browsetree[region].id4,
        id5: state.browsetree[region].id5,
        id6: state.browsetree[region].id6,
      };
    }
    const getProductTypes = (region) => {
      update_uistate({ loading: true });
      proxy.$api.doAPI("/browsetree/product_types", { node_id: state.browsetree[region].id1 }).then((res) => {
        if (res.code === 0) {
          set_data({ product_types: res.data.product_types }, region);
        } else {
          message.error(res.msg);
        }
        update_uistate({ loading: false });
      }).catch((err) => {
        update_uistate({ loading: false });
        console.error(err);
      });
    }
    const clearData = (region) => {
      update_uistate({ visible: false });
      setTimeout(() => {
        set_data(
          state.browsetree[region].confirm_data ?
            toRaw(state.browsetree[region].confirm_data) :
            {
              index: 0, count: 0,
              list1: [], list2: [], list3: [], list4: [], list5: [], list6: [],
              id1: '', id2: '', id3: '', id4: '', id5: '', id6: '',
              confirm_data: null, product_types: [],
            },
          region
        );
      }, 100);
    }
    const getNodes = () => {
      console.time('getNodes');
      let region = state.curr_region;
      let id = '';
      if (state.browsetree[region].index === 1) id = state.browsetree[region].id1;
      else if (state.browsetree[region].index === 2) id = state.browsetree[region].id2;
      else if (state.browsetree[region].index === 3) id = state.browsetree[region].id3;
      else if (state.browsetree[region].index === 4) id = state.browsetree[region].id4;
      else if (state.browsetree[region].index === 5) id = state.browsetree[region].id5;
      else if (state.browsetree[region].index === 6) id = state.browsetree[region].id6;
      console.timeEnd('getNodes');
      proxy.$api.doAPI("/browsetree/nodes", { region, node_id: id }).then((res) => {
        if (res.code === 0) {
          let payload = {};
          if (state.browsetree[region].index === 0) _.extend(payload, { list1: res.data.list, count: 1 });
          if (state.browsetree[region].index === 1) _.extend(payload, { list2: res.data.list, count: 2 });
          if (state.browsetree[region].index === 2) _.extend(payload, { list3: res.data.list, count: 3 });
          if (state.browsetree[region].index === 3) _.extend(payload, { list4: res.data.list, count: 4 });
          if (state.browsetree[region].index === 4) _.extend(payload, { list5: res.data.list, count: 5 });
          if (state.browsetree[region].index === 5) _.extend(payload, { list6: res.data.list, count: 6 });
          set_data(payload, region);
        } else {
          message.error(res.msg);
        }
        update_uistate({ loading: false });
      }).catch((err) => {
        update_uistate({ loading: false });
        console.error(err);
      });
    }
    const getRegionNodes = ({ index, id1, id2, id3, id4, id5 }) => {
      let region = state.curr_region;
      proxy.$api.doAPI("/browsetree/region_nodes", { region, index, id1, id2, id3, id4, id5 }).then((res) => {
        if (res.code === 0) {
          set_data(res.data.result, state.curr_region);
        } else {
          message.error(res.msg);
        }
        update_uistate({ loading: false });
      }).catch((err) => {
        update_uistate({ loading: false });
        console.error(err);
      });
    }
    const getAttributes = ({ browsenodeid, product_type }, region) => {
      update_uistate({ loading: true });
      return proxy.$api.doAPI("/browsetree/region_attributes", { region, node_id: state.browsetree[region].id1, browsenodeid, product_type }).then((res) => {
        if (res.code === 0) {
          set_data({ attributes: res.data.attributes }, region);
        } else {
          message.error(res.msg);
        }
        update_uistate({ loading: false });
      }).catch((err) => {
        update_uistate({ loading: false });
        console.error(err);
      });
    }
    const getNodesAndAttributes = (regions) => {
      update_uistate({ loading: true });
      proxy.$api.doAPI("/browsetree/nodes_attributes", { regions }).then((res) => {
        if (res.code === 0) {
          for (let i = 0; i < res.data.result.length; i++) {
            let { region,
              index, count,
              list1, id1,
              list2, id2,
              list3, id3,
              list4, id4,
              list5, id5,
              list6, id6, } = res.data.result[i];
            let payload = { index, count, list1, attributes: res.data.attributes, product_types: res.data.product_types };
            if (index >= 1) _.extend(payload, { list2, id1 });
            if (index >= 2) _.extend(payload, { list3, id2, });
            if (index >= 3) _.extend(payload, { list4, id3, });
            if (index >= 4) _.extend(payload, { list5, id4, });
            if (index >= 5) _.extend(payload, { list6, id5, });
            if (index >= 6) _.extend(payload, { id6, });
            set_data(payload, region);
            confirm_data(region);
          }
        } else {
          message.error(res.msg);
        }
        update_uistate({ loading: false });
      }).catch((err) => {
        update_uistate({ loading: false });
        console.error(err);
      });
    }
    const browsetree_id = (region) => {
      if (state.browsetree[region]?.list6?.length > 0) return state.browsetree[region].id6;
      if (state.browsetree[region]?.list5?.length > 0) return state.browsetree[region].id5;
      if (state.browsetree[region]?.list4?.length > 0) return state.browsetree[region].id4;
      if (state.browsetree[region]?.list3?.length > 0) return state.browsetree[region].id3;
      if (state.browsetree[region]?.list2?.length > 0) return state.browsetree[region].id2;
      if (state.browsetree[region]?.list1?.length > 0) return state.browsetree[region].id1;
      return '';
    };
    const browsetree_name = (region) => {
      if (state.browsetree[region]?.list6?.length > 0) return _.findWhere(state.browsetree[region].list6, { _id: state.browsetree[region].id6 })?.title || '';
      if (state.browsetree[region]?.list5?.length > 0) return _.findWhere(state.browsetree[region].list5, { _id: state.browsetree[region].id5 })?.title || '';
      if (state.browsetree[region]?.list4?.length > 0) return _.findWhere(state.browsetree[region].list4, { _id: state.browsetree[region].id4 })?.title || '';
      if (state.browsetree[region]?.list3?.length > 0) return _.findWhere(state.browsetree[region].list3, { _id: state.browsetree[region].id3 })?.title || '';
      if (state.browsetree[region]?.list2?.length > 0) return _.findWhere(state.browsetree[region].list2, { _id: state.browsetree[region].id2 })?.title || '';
      if (state.browsetree[region]?.list1?.length > 0) return _.findWhere(state.browsetree[region].list1, { _id: state.browsetree[region].id1 })?.title || '';
      return '';
    }
    const selectTree1 = (item) => {
      let data = { index: 1, id1: item._id, id2: '', id3: '', id4: '', id5: '', id6: '', }
      if (item.hasChildren) selectTree(data);
      else set_data({ ...data, list2: [], list3: [], list4: [], list5: [], list6: [], }, state.curr_region);
    }
    const selectTree2 = (item) => {
      let data = { index: 2, id2: item._id, id3: '', id4: '', id5: '', id6: '', }
      if (item.hasChildren) selectTree(data);
      else set_data({ ...data, list3: [], list4: [], list5: [], list6: [], }, state.curr_region);
    }
    const selectTree3 = (item) => {
      let data = { index: 3, id3: item._id, id4: '', id5: '', id6: '', }
      if (item.hasChildren) selectTree(data);
      else set_data({ ...data, list4: [], list5: [], list6: [], }, state.curr_region);
    }
    const selectTree4 = (item) => {
      let data = { index: 4, id4: item._id, id5: '', id6: '', }
      if (item.hasChildren) selectTree(data);
      else set_data({ ...data, list5: [], list6: [], }, state.curr_region);
    }
    const selectTree5 = (item) => {
      let data = { index: 5, id5: item._id, id6: '', }
      if (item.hasChildren) selectTree(data);
      else set_data({ ...data, list6: [], }, state.curr_region);
    }
    const selectTree6 = (item) => {
      let data = { index: 6, id6: item._id, }
      if (item.hasChildren) selectTree(data)
      else set_data(data, state.curr_region);
    }
    const selectTree = (data) => {
      update_uistate({ loading: true });
      setTimeout(() => {
        getRegionNodes(data);
      }, 100);
    }
    const closeBrowseTree = () => {
      clearData(state.curr_region);
    }
    const confirmBrowseTree = () => {
      if (state.browsetree_searchresultitem) {
        let { id1, id2, id3, id4, id5, id6 } = state.browsetree_searchresultitem;
        update_uistate({ visible: false, loading: true });
        setTimeout(() => {
          getNodesAndAttributes([{
            region: state.curr_region,
            node_id1: id1,
            node_id2: id2,
            node_id3: id3,
            node_id4: id4,
            node_id5: id5,
            node_id6: id6,
          }]);
        }, 100);
      } else {
        update_uistate({ visible: false, loading: true });
        nextTick(() => {
          confirm_data(state.curr_region);
          getProductTypes(state.curr_region);
        });
      }
      backToSelect();
    }
    const selectSearchItem = (index) => {
      console.log('selectSearchItem', index);
      let searchresults = toRaw(state.browsetree_searchresults);
      state.browsetree_searchresultitem = searchresults[index];
      state.browsetree_searchresults = _.map(searchresults, (n, i) => {
        return {
          ...n,
          selected: i === index,
        }
      });
    }
    const backToSelect = () => {
      state.browsetree_showsearchresults = false;
      state.browsetree_searchresults = [];
      state.browsetree_searchresultitem = null;
      state.browsetree_searchtext = "";
    }
    const doSearchBrowseTree = () => {
      update_uistate({ loading: true });
      setTimeout(() => {
        proxy.$api.doAPI("/browsetree/search", { country_code: state.curr_region, searchtext: state.browsetree_searchtext }).then((res) => {
          update_uistate({ loading: false });
          if (res.code === 0) {
            state.browsetree_searchresults = res.data.list;
            state.browsetree_showsearchresults = true;
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          update_uistate({ loading: false });
          console.error(err);
        });
      }, 100);
    }
    //#endregion
    const update_uistate = (payload = {}) => {
      if (typeof payload.visible === 'boolean' && typeof payload.loading === 'boolean') state.uistate = payload;
      else if (typeof payload.visible === 'boolean') state.uistate.visible = payload.visible;
      else if (typeof payload.loading === 'boolean') state.uistate.loading = payload.loading;
    }
    return {
      ...toRefs(state),
      locale, MAX_IMAGE_COUNT,
      shopaccountformRef, listingformRef, variationItemFormRef,
      editorMode: 'default', toolbarConfig, editorConfig,
      shop_regions, region_sites, fail_region_sites,
      variation_headers,
      EditorRef,
      clickMenu,
      getData,
      showShopAccounts,
      confirmShopAccount,
      validateShopRegion,
      selectBrowseTree,
      changeProductType,
      handleCreated,
      onLocalUploadSuccess,
      onNetworkInputSuccess,
      onSpacePickSuccess,
      removeImage,
      getExternalProductID,
      confirmExternalProduct,
      changeExternalProductType,
      changeExternalProductGroup,
      gotoUPC,
      editListingPrice,
      confirmListingPrice,
      moveLeft,
      moveRight,
      moveUp,
      moveDown,
      changeVariationTheme,
      useAllVariationItem,
      removeVariationItems,
      addVariationItem,
      confirmVariationItem,
      editVariationTheme,
      addVariationColor,
      removeVariationColor,
      addVariationSize,
      removeVariationSize,
      addVariationMaterial,
      removeVariationMaterial,
      confirmVariationTheme,
      removeVariationItem,
      blurVariationItemAttribute,
      changeVariationListProductIDType,
      oneClickGenerate,
      editVariationList,
      confirmVariationListQuantity,
      confirmVariationListPrice,
      editVariationItem,
      confirmVariationItemPrice,
      confirmVariationItemName,
      useAllImages,
      useAllOtherImages,
      chooseFromImages,
      confirmChooseImages,
      confirmChooseImage,
      validatePrice,
      validateQuantity,
      doCheck,
      doTranslate,
      doSave,
      doSend,
      doClose,
      doSearchAttribute,
      filterOption,
      set_data, confirm_data,
      browsetreeCount,
      browsetreeID1,
      browsetreeID2,
      browsetreeID3,
      browsetreeID4,
      browsetreeID5,
      browsetreeID6,
      browsetreeID,
      browsetreeList1,
      browsetreeList2,
      browsetreeList3,
      browsetreeList4,
      browsetreeList5,
      browsetreeList6,
      browsetreeNAME1,
      browsetreeNAME2,
      browsetreeNAME3,
      browsetreeNAME4,
      browsetreeNAME5,
      browsetreeNAME6,
      browsetreeConfirmable,
      browsetreeProductTypes,
      browsetreeFilterAttributes,
      selectTree1,
      selectTree2,
      selectTree3,
      selectTree4,
      selectTree5,
      selectTree6,
      closeBrowseTree,
      confirmBrowseTree,
      selectSearchItem,
      backToSelect,
      doSearchBrowseTree,
      image_placeholder: proxy.$constant.image_placeholder,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-form-item-extra) {
  min-height: 0;
}

p {
  margin: 0;
  padding: 0;
}

.form-item {
  display: flex;
}

.label {
  min-width: 150px;
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 3px;
  padding-right: 10px;
  text-align: right;
}

.label>span:first-child::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin-left: 2px;
}

.label>.en {
  word-break: break-word;
}

.required>span:first-child::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.header.required::before {
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.mainform {
  overflow: auto;
  height: 100vh;
  padding-bottom: 80px;
}

.footer {
  // box-shadow: 0 0 6px 0 #d9d9d9;
  height: 64px !important;
  line-height: 64px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  text-align: center;
  z-index: 9;
  background-color: #fff;
  border-radius: 2px;
}

.el-alert {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  opacity: 1;
  overflow: hidden;
  padding: 8px 16px;
  position: relative;
  -webkit-transition: opacity .2s;
  transition: opacity .2s;
  width: 100%;
}

.el-alert--warning {
  background-color: #fdf6ec;
  color: #e6a23c;
}

.mb15 {
  margin-bottom: 15px !important;
}

.el-alert--warning.is-light {
  background-color: #fdf6ec;
  color: #e6a23c;
}

.el-alert__content {
  display: table-cell;
  padding: 0 8px;
}

.el-alert__title {
  font-size: 13px;
  line-height: 18px;
}

.el-alert__title.is-bold {
  font-weight: 700;
}

.el-alert .el-alert__description {
  font-size: 12px;
  margin: 5px 0 0;
}

.el-alert--warning.is-light .el-alert__description {
  color: #e6a23c;
}

.text-danger {
  color: #f56c6c !important;
}

.bold {
  font-weight: 700;
}

.el-alert--info {
  background-color: #f4f4f5;
  color: #909399;
}

.mt15 {
  margin-top: 15px !important;
}

.text-center {
  text-align: center;
}

.el-alert.is-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.el-alert.no-data {
  background-color: #f0f0f0;
  color: #999;
  line-height: 80px;
}

.loadtext {
  color: #7a7a7a;
  line-height: 16px;
  margin: 0;
  padding: 8px 0;
}

.pb0 {
  padding-bottom: 0 !important;
}

.text-warning {
  color: #e6a23c !important;
}

.variation_panel {
  width: 100%;
  border: 1px solid #ebeef5;
}

.variation_header {
  background: #f5f7fa;
  border-bottom: 1px solid #ebeef5;
  color: #000;
  height: 40px;
  padding-left: 15px;
}

:deep(.ant-card-small > .ant-card-body) {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.tree-group {
  display: flex;
  overflow: auto;
  height: 500px;
}

.tree-item-card {
  min-width: 220px;
  width: 23%;
}

.tree-item {
  height: 400px;
  overflow: auto;
}

.search-results-group {
  display: flex;
  overflow: auto;
  height: 500px;
}

.search-results-item {
  text-decoration: none;
}

.search-results-item:hover {
  text-decoration: underline;
}

.search-results-item.active {
  background-color: #7ca4d2;
  color: #fff;
}

.code-box:target {
  border: 1px solid #1890ff;
}
</style>