<template>
  <a-tabs v-model:activeKey="activeKey">
    <a-tab-pane key="1" tab="待发布商品">
      <div class="d-flex align-center mb-2">
        <span style="width:75px;">筛选：</span>
        <a-radio-group v-model:value="publishType">
          <a-radio-button value="ALL"> 全部 </a-radio-button>
          <a-radio-button value="Deal"> 待刊登 </a-radio-button>
          <a-radio-button value="Incomplete"> 数据不全 </a-radio-button>
        </a-radio-group>
      </div>
      <div class="d-flex align-center mb-2">
        <span style="width:80px;">查询搜索：</span>
        <a-input-search v-model:value="searchText" placeholder="输入名称查询" enter-button="搜索" allow-clear @search="getData" />
      </div>
      <div class="d-flex justify-space-between align-center mb-2">
        <a-space>
          <a-button type="primary" @click="getData">刷新数据</a-button>
        </a-space>
        <a-space>
          <a-dropdown>
            <template #overlay>
              <a-menu @click="handleExportClick">
                <a-menu-item-group title="导入">
                  <a-menu-item key="bulkimport">模板导入</a-menu-item>
                </a-menu-item-group>
              </a-menu>
            </template>
            <a-button type="primary">
              导入导出
              <DownOutlined />
            </a-button>
          </a-dropdown>
          <a-button type="danger" @click="addPublish">
            <template #icon>
              <PlusCircleOutlined />
            </template>新增刊登
          </a-button>
        </a-space>
      </div>
      <a-table :columns="columns" bordered :data-source="data" :loading="dataLoading" :scroll="{ x: width, y: 600 }"
        :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
        @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
        :row-selection="{ selectedRowKeys, onChange: (keys) => { selectedRowKeys = keys; } }">
        <template #bodyCell="{ record, column }">
          <template v-if="column.dataIndex === 'url'">
            <a v-if="record.url" :href="record.url" target="_blank">
              {{ record.name }}
              <LinkOutlined />
            </a>
          </template>
          <template v-if="column.dataIndex === 'operation'">
            <a-space>
              <a href="javascript:;" @click="editSupplier(record)">编辑</a>
              <a href="javascript:;" @click="editSupplier(record)">复制</a>
            </a-space>
          </template>
        </template>
      </a-table>
    </a-tab-pane>
    <a-tab-pane key="2" tab="在线商品">
      <div class="d-flex align-center mb-2">
        <span style="width:75px;">筛选：</span>
        <a-radio-group v-model:value="publishType">
          <a-radio-button value="0"> 在线 </a-radio-button>
          <a-radio-button value="1"> 停售 </a-radio-button>
          <a-radio-button value="2"> 已删除 </a-radio-button>
        </a-radio-group>
      </div>
      <div class="d-flex align-center mb-2">
        <span style="width:80px;">查询搜索：</span>
        <a-input-search v-model:value="searchText" placeholder="输入关键字查询" enter-button="搜索" allow-clear
          @search="getData" />
      </div>
      <div class="d-flex justify-space-between align-center mb-2">
        <a-space>
          <a-button type="primary" @click="getData">刷新数据</a-button>
        </a-space>
        <a-space>
          <a-dropdown>
            <template #overlay>
              <a-menu @click="handleExportClick">
                <a-menu-item-group title="编辑">
                  <a-menu-item key="bulkimport">批量编辑</a-menu-item>
                  <a-menu-item key="bulkimport">批量改价格</a-menu-item>
                  <a-menu-item key="bulkimport">批量改数量</a-menu-item>
                </a-menu-item-group>
                <a-menu-item-group title="更多">
                  <a-menu-item key="bulkimport">批量改销售人员</a-menu-item>
                  <a-menu-item key="bulkimport">批量备注格</a-menu-item>
                  <a-menu-item key="bulkimport">批量删除</a-menu-item>
                </a-menu-item-group>
              </a-menu>
            </template>
            <a-button type="primary">
              批量操作
              <DownOutlined />
            </a-button>
          </a-dropdown>
          <a-button type="danger" @click="syncGoods">
            <template #icon>
              <RedoOutlined />
            </template>同步商品
          </a-button>
        </a-space>
      </div>
      <a-table :columns="lineColumns" bordered :data-source="data" :loading="dataLoading" :scroll="{ x: width, y: 600 }"
        :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
        @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
        :row-selection="{ selectedRowKeys, onChange: (keys) => { selectedRowKeys = keys; } }">
        <template #bodyCell="{ record, column }">
          <template v-if="column.dataIndex === 'url'">
            <a v-if="record.url" :href="record.url" target="_blank">
              {{ record.name }}
              <LinkOutlined />
            </a>
          </template>
          <template v-if="column.dataIndex === 'operation'">
            <a-space>
              <a href="javascript:;" @click="editSupplier(record)">编辑</a>
            </a-space>
          </template>
        </template>
      </a-table>
    </a-tab-pane>
  </a-tabs>
  <a-drawer :title="`${publishForm._id !== '' ? '编辑' : '新增'}刊登`" :visible="publishModal"
    :body-style="{ paddingBottom: '80px' }" :footer-style="{ textAlign: 'right' }" @close="cancelSave" width="70%"
    :closable="!saving" :maskClosable="!saving">
    <template #extra>
      <a-button class="mr-2" @click="cancelSave">取消</a-button>
      <a-button type="primary" @click="savePublish">保存</a-button>
    </template>
    <a-form ref="formRef" name="publishForm" :model="publishForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }"
      autocomplete="off">
      <a-row>
        <a-col :span="12">
          <a-card title="基本信息" size="big">
            <a-form-item label="店铺名称" name="platform" :rules="[{ required: true, message: '店铺不能为空' }]">
              <a-select v-model:value="publishForm.shop" :options="shops" placeholder="请选择店铺" :disabled="saving"
                show-search>
              </a-select>
            </a-form-item>
            <a-form-item label="产品类目" name="category" :rules="[{ required: true, message: '产品类目不能为空' }]">
              <a-select v-model:value="publishForm.category" :options="categorys" placeholder="请选择产品类目" :disabled="saving"
                show-search>
              </a-select>
            </a-form-item>
            <a-form-item label="产品标题" name="productname" :rules="[{ required: true, message: '商品标题不能为空' }]">
              <a-input v-model:value="publishForm.srcurl" :disabled="saving" show-count :maxlength="128" />
            </a-form-item>
            <a-form-item label="产品分组" name="saletype" :rules="[{ required: true, message: '销售类型不能为空' }]">
              <a-select v-model:value="publishForm.filemodule" :options="filemodules" placeholder="请选择文件模版"
                :disabled="saving" show-search>
              </a-select>
            </a-form-item>
          </a-card>
        </a-col>
        <a-col :span="12">
          <a-card title="产品属性" size="big">
            <a-form-item label="来源URL" name="srcurl">
              <a-input-search v-model:value="publishForm.srcurl" enter-button="访问链接" :disabled="saving" />
            </a-form-item>
            <a-form-item label="品牌" name="bandname" :rules="[{ required: true, message: '品牌不能为空' }]">
              <a-select v-model:value="publishForm.category" :options="categorys" placeholder="请选择品牌" :disabled="saving"
                show-search>
              </a-select>
            </a-form-item>
            <a-form-item label="产地" name="origin" :rules="[{ required: true, message: '产地不能为空' }]">
              <a-select v-model:value="publishForm.origin" :options="categorys" placeholder="请选择产地" :disabled="saving"
                show-search>
              </a-select>
            </a-form-item>
            <a-form-item label="材质" name="origin" :rules="[{ required: true, message: '产地不能为空' }]">
              <a-select v-model:value="publishForm.origin" :options="categorys" placeholder="请选择产地" :disabled="saving"
                show-search>
              </a-select>
            </a-form-item>
            <a-form-item label="风格" name="origin" :rules="[{ required: true, message: '产地不能为空' }]">
              <a-select v-model:value="publishForm.origin" :options="categorys" placeholder="请选择产地" :disabled="saving"
                show-search>
              </a-select>
            </a-form-item>
            <a-form-item label="裙型" name="origin" :rules="[{ required: true, message: '产地不能为空' }]">
              <a-select v-model:value="publishForm.origin" :options="categorys" placeholder="请选择产地" :disabled="saving"
                show-search>
              </a-select>
            </a-form-item>
            <a-form-item label="装饰" name="origin" :rules="[{ required: true, message: '产地不能为空' }]">
              <a-select v-model:value="publishForm.origin" :options="categorys" placeholder="请选择产地" :disabled="saving"
                show-search>
              </a-select>
            </a-form-item>
            <a-form-item label="裙长" name="origin" :rules="[{ required: true, message: '产地不能为空' }]">
              <a-select v-model:value="publishForm.origin" :options="categorys" placeholder="请选择产地" :disabled="saving"
                show-search>
              </a-select>
            </a-form-item>
            <a-form-item label="袖型" name="origin" :rules="[{ required: true, message: '产地不能为空' }]">
              <a-select v-model:value="publishForm.origin" :options="categorys" placeholder="请选择产地" :disabled="saving"
                show-search>
              </a-select>
            </a-form-item>
            <a-form-item label="领型" name="origin" :rules="[{ required: true, message: '产地不能为空' }]">
              <a-select v-model:value="publishForm.origin" :options="categorys" placeholder="请选择产地" :disabled="saving"
                show-search>
              </a-select>
            </a-form-item>
            <a-form-item label="季节" name="origin" :rules="[{ required: true, message: '产地不能为空' }]">
              <a-select v-model:value="publishForm.origin" :options="categorys" placeholder="请选择产地" :disabled="saving"
                show-search>
              </a-select>
            </a-form-item>
            <a-form-item label="性别" name="origin">
              <a-select v-model:value="publishForm.origin" :options="categorys" placeholder="请选择产地" :disabled="saving"
                show-search>
              </a-select>
            </a-form-item>
            <a-form-item label="记件单位" name="filemodule" :rules="[{ required: true, message: '文件模版不能为空' }]">
              <a-select v-model:value="publishForm.filemodule" :options="filemodules" placeholder="请选择文件模版"
                :disabled="saving" show-search>
              </a-select>
            </a-form-item>
            <a-form-item label="颜色" name="filemodule" :rules="[{ required: true, message: '文件模版不能为空' }]">
              <a-select v-model:value="publishForm.filemodule" :options="filemodules" placeholder="请选择文件模版"
                :disabled="saving" show-search>
              </a-select>
            </a-form-item>
            <a-form-item label="尺寸" name="filemodule" :rules="[{ required: true, message: '文件模版不能为空' }]">
              <a-select v-model:value="publishForm.filemodule" :options="filemodules" placeholder="请选择文件模版"
                :disabled="saving" show-search>
              </a-select>
            </a-form-item>
            <a-form-item label="发货地" name="filemodule" :rules="[{ required: true, message: '文件模版不能为空' }]">
              <a-select v-model:value="publishForm.filemodule" :options="filemodules" placeholder="请选择文件模版"
                :disabled="saving" show-search>
              </a-select>
            </a-form-item>
          </a-card>
        </a-col>
        <a-col :span="24">
          <a-card title="产品图片" size="big">
            <a-alert
              message="产品至少占用图片 85% 的篇幅。图片只能展示出售的商品，可少量包含或不包含其他道具。不可包含商标、水印或内嵌图片。图片中的文字只能是商品信息。主图必须为纯白色背景，不能包含其他搭配品。图片尺寸不得小于 500*1000，不超过 10000*10000。支持 JPEG、TIFF 和 GIF 格式。1张主图，8张附图"
              type="info" show-icon class="mb-2" />
            <a-form-item label="产品图" name="upload" :rules="[{ required: true, message: '产品图不能为空' }]">
              <a-form-item name="dragger" no-style>
                <a-upload-dragger v-model:fileList="publishForm.upload" name="files" action="/upload.do">
                  <p class="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p class="ant-upload-text">可点击或拖拽上传</p>
                </a-upload-dragger>
              </a-form-item>
            </a-form-item>
          </a-card>
          <a-card title="营销图片" size="big">
            <a-alert
              message="产品至少占用图片 85% 的篇幅。图片只能展示出售的商品，可少量包含或不包含其他道具。不可包含商标、水印或内嵌图片。图片中的文字只能是商品信息。主图必须为纯白色背景，不能包含其他搭配品。图片尺寸不得小于 500*1000，不超过 10000*10000。支持 JPEG、TIFF 和 GIF 格式。1张主图，8张附图"
              type="info" show-icon class="mb-2" />
            <a-form-item label="产品图" name="upload" :rules="[{ required: true, message: '产品图不能为空' }]">
              <a-form-item name="dragger" no-style>
                <a-upload-dragger v-model:fileList="publishForm.upload" name="files" action="/upload.do">
                  <p class="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p class="ant-upload-text">可点击或拖拽上传</p>
                </a-upload-dragger>
              </a-form-item>
            </a-form-item>
          </a-card>
        </a-col>
      </a-row>
    </a-form>
  </a-drawer>
</template>
<script>
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import {
  DownOutlined,
  SearchOutlined,
  PlusCircleOutlined,
  UploadOutlined,
  InboxOutlined,
  LinkOutlined,
  RedoOutlined
} from '@ant-design/icons-vue';
import { message } from "ant-design-vue";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onActivated,
  getCurrentInstance,
} from "vue";
import _ from "underscore";
const columns = [
  { title: '缩略图', dataIndex: 'thumbpic', width: 100, },
  { title: '商品标题', dataIndex: 'title', width: 150, },
  { title: '父SKU', dataIndex: 'psku', width: 150, },
  { title: '子SKU', dataIndex: 'csku', width: 150, },
  { title: '价格', dataIndex: 'price', width: 150, },
  { title: '数量', dataIndex: 'num', width: 100, },
  { title: '更多信息', dataIndex: 'moreInfo', width: 150, },
  { title: '操作', dataIndex: 'operation', width: 150, fixed: 'right' },
];
const lineColumns = [
  { title: '缩略图', dataIndex: 'thumbpic', width: 60, },
  { title: '商品标题', dataIndex: 'title', width: 200, },
  { title: 'SKU', dataIndex: 'sku', width: 100, },
  { title: '价格', dataIndex: 'price', width: 200, },
  { title: '数量', dataIndex: 'num', width: 150, },
  { title: '更多信息', dataIndex: 'moreInfo', width: 150, },
  { title: '操作', dataIndex: 'operation', width: 150, fixed: 'right' },
];
const optionssaletypes = [
  { label: '单品', value: '1' },
  { label: '变种', value: '2' }
];
const optionsgtins = [
  { label: '是', value: '1' },
  { label: '否', value: '0' }
];
export default defineComponent({
  name: 'ShopsPublishedAliexpressPublish',
  components: {
    DownOutlined,
    SearchOutlined,
    PlusCircleOutlined,
    UploadOutlined,
    InboxOutlined,
    LinkOutlined,
    RedoOutlined
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const formRef = ref();
    const publicPath = reactive(process.env.BASE_URL);
    const state = reactive({
      searchText: "",
      publishType: "ALL",
      dataLoading: false,
      page: 1,
      limit: 10,
      length: 0,
      count: 0,
      data: [],
      saving: false,
      publishModal: false,
      publishForm: {},
      shops: [],
      uploading: false,
      activeKey: "1",
      optionssaletype: 1,
      optionsgtin: 1
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    onActivated(() => {
      // console.log("onActivated");
      getData();
    });
    const getData = () => {

    };
    const addPublish = () => {
      state.publishForm = {
        _id: "",
        shop: "仵献1688",
        category: "",
        filemodule: "",
        upload: [],
        srcurl: '',
      };
      state.publishModal = true;
    }
    const cancelSave = () => {
      console.log('cancelSave');
      formRef.value.clearValidate();
      state.publishModal = false;
    }
    return {
      ...toRefs(state),
      locale,
      publicPath,
      formRef,
      columns,
      lineColumns,
      width,
      getData,
      addPublish,
      cancelSave,
      optionsgtins,
      optionssaletypes
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>

