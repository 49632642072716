<template>
  <a-tabs v-model:activeKey="activeTab" type="card" @change="changeTab">
    <a-tab-pane key="1" tab="按平台">
      <div class="header">
        <a-form layout="inline" autocomplete="off">
          <a-form-item>
            <a-select v-model:value="queryForm1.datetype">
              <a-select-option value="purchase_date">支付时间</a-select-option>
              <a-select-option value="delivery_date">发货时间</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-range-picker v-model:value="queryForm1.daterange" value-format="YYYY-MM-DD" allow-clear />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="getData">查询</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="content">
        <a-table bordered :columns="columns1" :data-source="data1" :loading="dataLoading" :scroll="{ x: width1 }">
          <template #bodyCell="{ column, text, index }">
            <template v-if="column.dataIndex === 'index'">
              <p>{{ index + 1 }}</p>
            </template>
            <template v-if="column.dataIndex === 'ordercount'">
              <a>{{ text }}</a>
            </template>
            <template v-if="column.dataIndex === 'operation'">
              <a-space>
                <a href="javascript:;">查看详情</a>
                <a href="javascript:;">收支明细</a>
              </a-space>
            </template>
          </template>
          <template #title>
            <div class="theader">
              <span class="title">订单收支报告</span>
              <a-button type="primary">导出订单收支明细</a-button>
            </div>
          </template>
          <template #footer>合计</template>
        </a-table>
      </div>
    </a-tab-pane>
    <a-tab-pane key="2" tab="按店铺">
      <div class="header">
        <a-form layout="inline" autocomplete="off">
          <a-form-item>
            <a-select v-model:value="queryForm2.datetype">
              <a-select-option value="purchase_date">支付时间</a-select-option>
              <a-select-option value="delivery_date">发货时间</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-range-picker v-model:value="queryForm2.daterange" value-format="YYYY-MM-DD" allow-clear />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="getData">查询</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="content">
        <a-table bordered :columns="columns2" :data-source="data2" :loading="dataLoading" :scroll="{ x: width2 }">
          <template #bodyCell="{ column, text, index }">
            <template v-if="column.dataIndex === 'index'">
              <p>{{ index + 1 }}</p>
            </template>
            <template v-if="column.dataIndex === 'ordercount'">
              <a>{{ text }}</a>
            </template>
            <template v-if="column.dataIndex === 'operation'">
              <a-space>
                <a href="javascript:;">查看详情</a>
                <a href="javascript:;">收支明细</a>
              </a-space>
            </template>
          </template>
          <template #title>
            <div class="theader">
              <span class="title">订单收支报告</span>
              <a-button type="primary">导出订单收支明细</a-button>
            </div>
          </template>
          <template #footer>合计</template>
        </a-table>
      </div>
    </a-tab-pane>
    <a-tab-pane key="3" tab="按国家/地区">
      <div class="header">
        <a-form layout="inline" autocomplete="off">
          <a-form-item>
            <a-select v-model:value="queryForm3.datetype">
              <a-select-option value="purchase_date">支付时间</a-select-option>
              <a-select-option value="delivery_date">发货时间</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-range-picker v-model:value="queryForm3.daterange" value-format="YYYY-MM-DD" allow-clear />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="getData">查询</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="content">
        <a-table bordered :columns="columns3" :data-source="data3" :loading="dataLoading" :scroll="{ x: width3 }">
          <template #bodyCell="{ column, text, index }">
            <template v-if="column.dataIndex === 'index'">
              <p>{{ index + 1 }}</p>
            </template>
            <template v-if="column.dataIndex === 'ordercount'">
              <a>{{ text }}</a>
            </template>
            <template v-if="column.dataIndex === 'operation'">
              <a-space>
                <a href="javascript:;">查看详情</a>
                <a href="javascript:;">收支明细</a>
              </a-space>
            </template>
          </template>
          <template #title>
            <div class="theader">
              <span class="title">订单收支报告</span>
              <a-button type="primary">导出订单收支明细</a-button>
            </div>
          </template>
          <template #footer>合计</template>
        </a-table>
      </div>
    </a-tab-pane>
  </a-tabs>
</template>
<script>
import { DownOutlined } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from 'vue';
import _ from "underscore";
const columns1 = [
  { title: '序号', dataIndex: 'index', width: 70, fixed: 'left', align: 'center' },
  { title: '平台', dataIndex: 'platform', width: 100, fixed: 'left', align: 'center' },
  { title: '订单数', dataIndex: 'ordercount', width: 100, align: 'center' },
  { title: '商品数', dataIndex: 'goodscount', width: 100, align: 'center' },
  { title: '平台SKU数', dataIndex: 'skucount', width: 120, align: 'center' },
  {
    title: '收入',
    children: [
      { title: '应收货款', dataIndex: 'amount', width: 100, align: 'center' },
      { title: '运费', dataIndex: 'freight', width: 100, align: 'center' },
      { title: '其他收入', dataIndex: 'otherin', width: 100, align: 'center' },
      { title: '合计', dataIndex: 'totalin', width: 100, align: 'center' }
    ]
  },
  {
    title: '支出',
    children: [
      { title: '商品成本', dataIndex: 'costofgoods', width: 100, align: 'center' },
      { title: '平台费', dataIndex: 'platformfee', width: 100, align: 'center' },
      { title: 'FBA费用', dataIndex: 'FBAfee', width: 100, align: 'center' },
      { title: '其他费用', dataIndex: 'otherout', width: 100, align: 'center' },
      { title: '合计', dataIndex: 'totalout', width: 100, align: 'center' }
    ]
  },
  { title: '退款金额', dataIndex: 'refundamount', width: 100, align: 'center' },
  { title: '退款率%', dataIndex: 'refundpersent', width: 100, align: 'center' },
  { title: '毛利', dataIndex: 'grossprofit', width: 100, align: 'center' },
  { title: '毛利率%', dataIndex: 'grossprofitpersent', width: 100, align: 'center' },
  { title: '操作', dataIndex: 'operation', width: 180, fixed: 'right', align: 'center' },
];
const columns2 = [
  { title: '序号', dataIndex: 'index', width: 70, fixed: 'left', align: 'center' },
  { title: '店铺', dataIndex: 'shop', width: 150, fixed: 'left', align: 'center' },
  { title: '订单数', dataIndex: 'ordercount', width: 100, align: 'center' },
  { title: '商品数', dataIndex: 'goodscount', width: 100, align: 'center' },
  { title: '平台SKU数', dataIndex: 'skucount', width: 120, align: 'center' },
  {
    title: '收入',
    children: [
      { title: '应收货款', dataIndex: 'amount', width: 100, align: 'center' },
      { title: '运费', dataIndex: 'freight', width: 100, align: 'center' },
      { title: '其他收入', dataIndex: 'otherin', width: 100, align: 'center' },
      { title: '合计', dataIndex: 'totalin', width: 100, align: 'center' }
    ]
  },
  {
    title: '支出',
    children: [
      { title: '商品成本', dataIndex: 'costofgoods', width: 100, align: 'center' },
      { title: '平台费', dataIndex: 'platformfee', width: 100, align: 'center' },
      { title: 'FBA费用', dataIndex: 'FBAfee', width: 100, align: 'center' },
      { title: '其他费用', dataIndex: 'otherout', width: 100, align: 'center' },
      { title: '合计', dataIndex: 'totalout', width: 100, align: 'center' }
    ]
  },
  { title: '退款金额', dataIndex: 'refundamount', width: 100, align: 'center' },
  { title: '退款率%', dataIndex: 'refundpersent', width: 100, align: 'center' },
  { title: '毛利', dataIndex: 'grossprofit', width: 100, align: 'center' },
  { title: '毛利率%', dataIndex: 'grossprofitpersent', width: 100, align: 'center' },
  { title: '操作', dataIndex: 'operation', width: 180, fixed: 'right', align: 'center' },
];
const columns3 = [
  { title: '序号', dataIndex: 'index', width: 70, fixed: 'left', align: 'center' },
  { title: '国家', dataIndex: 'country', width: 150, fixed: 'left', align: 'center' },
  { title: '订单数', dataIndex: 'ordercount', width: 100, align: 'center' },
  { title: '商品数', dataIndex: 'goodscount', width: 100, align: 'center' },
  { title: '平台SKU数', dataIndex: 'skucount', width: 120, align: 'center' },
  {
    title: '收入',
    children: [
      { title: '应收货款', dataIndex: 'amount', width: 100, align: 'center' },
      { title: '运费', dataIndex: 'freight', width: 100, align: 'center' },
      { title: '其他收入', dataIndex: 'otherin', width: 100, align: 'center' },
      { title: '合计', dataIndex: 'totalin', width: 100, align: 'center' }
    ]
  },
  {
    title: '支出',
    children: [
      { title: '商品成本', dataIndex: 'costofgoods', width: 100, align: 'center' },
      { title: '平台费', dataIndex: 'platformfee', width: 100, align: 'center' },
      { title: 'FBA费用', dataIndex: 'FBAfee', width: 100, align: 'center' },
      { title: '其他费用', dataIndex: 'otherout', width: 100, align: 'center' },
      { title: '合计', dataIndex: 'totalout', width: 100, align: 'center' }
    ]
  },
  { title: '退款金额', dataIndex: 'refundamount', width: 100, align: 'center' },
  { title: '退款率%', dataIndex: 'refundpersent', width: 100, align: 'center' },
  { title: '毛利', dataIndex: 'grossprofit', width: 100, align: 'center' },
  { title: '毛利率%', dataIndex: 'grossprofitpersent', width: 100, align: 'center' },
  { title: '操作', dataIndex: 'operation', width: 180, fixed: 'right', align: 'center' },
];
export default defineComponent({
  name: 'FinanceIncomeReport',
  components: {

  },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      activeTab: "1",
      queryForm1: {
        datetype: "purchase_date",
        daterange: [null, null],
      },
      queryForm2: {
        datetype: "purchase_date",
        daterange: [null, null],
      },
      queryForm3: {
        datetype: "purchase_date",
        daterange: [null, null],
      },
      dataLoading: false,
      data1: [],
      data2: [],
      data3: [],
    });
    const width1 = computed(() => proxy.$utils.twidth(columns1));
    const width2 = computed(() => proxy.$utils.twidth(columns2));
    const width3 = computed(() => proxy.$utils.twidth(columns3));
    onActivated(() => {
      changeTab();
    });
    const changeTab = () => {
      getData();
    };
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/report/recedisreport", { activeTab: state.activeTab, queryForm: state[`queryForm${state.activeTab}`] }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state[`data${state.activeTab}`] = res.data.list;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    return {
      ...toRefs(state),
      columns1, width1,
      columns2, width2,
      columns3, width3,
      changeTab,
      getData,
    }
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

:deep(.ant-table-tbody > tr > td) {
  padding: 8px 8px !important;
}

.header {
  margin-bottom: 10px;
}

.theader {
  display: flex;
  justify-content: space-between;

  .title {
    font-weight: 600;
  }
}

a {
  text-decoration: underline;
}

p {
  margin: 0;
  padding: 0;
}
</style>