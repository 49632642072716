<template>
  <div class="d-flex align-center mb-2">
    <span>类型：</span>
    <a-tag :color="codetype === 0 ? 'processing' : 'default'" @click="changeCodeType(0)" class="click">
      全部
    </a-tag>
    <a-tag v-for="item in codetypes" :key="item" :color="codetype === item ? 'processing' : 'default'"
      @click="changeCodeType(item)" class="click">
      {{ item }}
    </a-tag>
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-button type="primary" @click="getData">刷新</a-button>
    <a-space>
      <a-button class="btn-success" @click="addGroup">
        新增号码池
      </a-button>
    </a-space>
  </div>
  <a-table :columns="columns" :data-source="data" :scroll="{ x: width, y: 600 }" :loading="dataLoading"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
    :row-selection="{ selectedRowKeys, onChange: (keys) => selectedRowKeys = keys }">
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'unused_count'">
        <a href="javascript:;" class="text-h7 text-success">
          {{ record.unused_count }}
        </a>
      </template>
      <template v-if="column.dataIndex === 'total_count'">
        <a href="javascript:;" class="text-h7 text-primary">
          {{ record.total_count }}
        </a>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <a href="javascript:;" class="text-success" @click="editCodes(record)">
            管理号码池
          </a>
          <a href="javascript:;" class="text-primary" @click="editGroup(record)">
            编辑
          </a>
          <a-popconfirm title="删除该号码池" @confirm="removeGroup(record._id)">
            <a href="javascript:;" class="text-error">
              删除
            </a>
          </a-popconfirm>
        </a-space>
      </template>
    </template>
  </a-table>
  <a-modal v-model:visible="groupModal" :title="`${groupForm._id === '' ? '新建' : '编辑'}号码池`" @ok="saveGroup"
    @cancel="cancelSaveGroup" ok-text="确定" cancel-text="取消" :confirm-loading="saving"
    :cancel-button-props="{ disabled: saving }" :maskClosable="false" :closable="!saving" width="600px">
    <a-form ref="groupFormRef" name="groupForm" :model="groupForm" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }"
      autocomplete="off">
      <a-form-item label="名称" name="name" :rules="[{ required: true, message: '请填写号码池名称' }]">
        <a-input v-model:value="groupForm.name" :disabled="saving" />
      </a-form-item>
      <a-form-item label="类型" name="type">
        <a-radio-group v-if="groupForm._id === ''" v-model:value="groupForm.type" :options="codetypes" />
        <span v-else>{{ groupForm.type }}</span>
      </a-form-item>
      <a-form-item v-if="groupForm._id === ''" label="号码池" name="codes">
        <a-textarea v-model:value="groupForm.codes" placeholder="请输入号码，可一行一个号码批量添加"
          :auto-size="{ minRows: 6, maxRows: 6 }" />
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="codeModal" title="管理号码池" @cancel="() => codeModal = false" :maskClosable="false"
    :closable="!saving" width="600px">
    <template #footer>
      <div class="d-flex justify-end align-center">
        <a-space>
          <a-button v-if="codestate === 'unused'" class="btn-primary" @click="saveCode">保存</a-button>
          <a-button @click="() => codeModal = false">取消</a-button>
        </a-space>
      </div>
    </template>
    <a-row :gutter="16">
      <a-col :span="8">号码池：{{ codeForm.name }}</a-col>
      <a-col :span="6">类型：{{ codeForm.type }}</a-col>
      <a-col :span="5" class="text-success text-h7">
        未使用：{{ codeForm.unused_codes.length }}
      </a-col>
      <a-col :span="5" class="text-error text-h7">
        已使用：{{ codeForm.used_codes.length }}
      </a-col>
    </a-row>
    <a-tabs v-model:activeKey="codestate">
      <a-tab-pane key="unused" tab="未使用">
        <a-textarea v-model:value="codeForm.unused_codes_text" placeholder="请输入号码，可一行一个号码批量添加"
          :auto-size="{ minRows: 10, maxRows: 10 }" />
      </a-tab-pane>
      <a-tab-pane key="used" tab="已使用">
        <a-textarea v-model:value="codeForm.used_codes_text" :auto-size="{ minRows: 10, maxRows: 10 }" disabled />
      </a-tab-pane>
    </a-tabs>
  </a-modal>
</template>
<script>
import { DownOutlined, SearchOutlined, PlusCircleOutlined, EyeOutlined } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import _ from "underscore";
const columns = [
  { title: '号码池名称', dataIndex: 'name', width: 120, },
  { title: '号码池类型', dataIndex: 'type', width: 120, },
  { title: '可用数量', dataIndex: 'unused_count', width: 100, },
  { title: '总数量', dataIndex: 'total_count', width: 100, },
  { title: '更新时间', dataIndex: 'update_time', width: 180, },
  { title: '修改人', dataIndex: 'update_by', width: 100, },
  { title: '操作', dataIndex: 'operation', width: 150 },
];
export default defineComponent({
  name: 'AmazonListingsApi',
  components: { DownOutlined, SearchOutlined, PlusCircleOutlined, EyeOutlined },
  setup() {
    const { proxy } = getCurrentInstance();
    const groupFormRef = ref();
    const state = reactive({
      codetype: 0,
      codetypes: ["UPC", "EAN", "ISBN", "GTIN", "其他"],
      page: 1,
      limit: 10,
      data: [],
      length: 0,
      count: 0,
      dataLoading: false,
      selectedRowKeys: [],
      groupForm: {},
      groupModal: false,
      saving: false,
      codeForm: {
        unused_codes: [],
        unused_codes_text: "",
        used_codes: [],
        used_codes_text: "",
      },
      codestate: "unused",
      codeModal: false,
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    watch(() => state.page, () => {
      getData();
    });
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    onActivated(() => {
      getData();
    });
    const getData = () => {
      state.dataLoading = true;
      state.selectedRowKeys = [];
      return new Promise((resolve) => {
        proxy.$api.doAPI("/listingidentifier/groups", { codetype: state.codetype, page: state.page, limit: state.limit }).then((res) => {
          state.dataLoading = false;
          if (res.code === 0) {
            state.data = _.map(res.data.list, n => {
              return {
                ...n,
                total_count: n.unused_count + n.used_count,
                update_time: proxy.$utils.dateFormat(n.update_time, 'YYYY-MM-DD HH:mm:ss'),
              };
            });
            state.length = res.data.length;
            state.count = res.data.count;
            if (state.length > 0 && state.page > state.length) state.page = 1;
          } else {
            message.error(res.msg);
          }
          resolve();
        }).catch((err) => {
          state.dataLoading = false;
          console.error(err);
          resolve();
        });
      });
    };
    const addGroup = () => {
      state.groupForm = {
        _id: "", name: "", type: "UPC", codes: ""
      };
      state.groupModal = true;
    }
    const editGroup = (item) => {
      state.groupForm = {
        _id: item._id,
        name: item.name,
        type: item.type,
      };
      state.groupModal = true;
    }
    const saveGroup = async () => {
      try {
        await groupFormRef.value.validateFields();
        state.saving = true;
        proxy.$api.doAPI("/listingidentifier/savegroup", { groupForm: state.groupForm }).then((res) => {
          state.saving = false;
          if (res.code === 0) {
            state.groupModal = false;
            getData();
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.saving = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
        message.error(_.reduce(errorInfo.errorFields, (a, b) => a.concat(b.errors), []).join('；'));
      }
    }
    const cancelSaveGroup = () => {
      groupFormRef.value.clearValidate();
      state.groupModal = false;
    }
    const removeGroup = (_id) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/listingidentifier/removegroup", { _id }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const editCodes = (item) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/listingidentifier/codes", { groupid: item._id }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          let unused_codes = _.pluck(_.where(res.data.list, { state: "unused" }), "code");
          let used_codes = _.pluck(_.where(res.data.list, { state: "used" }), "code");
          state.codeForm = {
            groupid: item._id,
            name: item.name,
            type: item.type,
            unused_codes,
            unused_codes_text: unused_codes.join('\n'),
            used_codes,
            used_codes_text: used_codes.join('\n'),
            codes: unused_codes.concat(used_codes),
          };
          state.codeModal = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const saveCode = () => {
      let unused_codes = state.codeForm.unused_codes_text.split('\n');
      let new_codes = _.filter(_.uniq(_.without(unused_codes, ...state.codeForm.unused_codes)), n => !!n);
      console.log('new_codes', new_codes);
      let remove_codes = _.filter(_.uniq(_.without(state.codeForm.unused_codes, ...unused_codes)), n => !!n);
      console.log('remove_codes', remove_codes);
      if (new_codes.length === 0 && remove_codes.length === 0) return;
      state.dataLoading = true;
      proxy.$api.doAPI("/listingidentifier/savecodes", { groupid: state.codeForm.groupid, remove_codes, new_codes }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.codeModal = false;
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const changeCodeType = (codetype) => {
      state.codetype = codetype;
      getData();
    }
    return {
      ...toRefs(state),
      groupFormRef,
      columns,
      width,
      getData,
      addGroup,
      editGroup,
      saveGroup,
      cancelSaveGroup,
      removeGroup,
      editCodes,
      saveCode,
      changeCodeType,
    };
  }
});
</script>

<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>
